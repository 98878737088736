import React from 'react';
// import {ToggleButtonGroup, ToggleButtonGroup} from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCartStateContext } from '../../../Store/CartState';
import { STOCK_TARGET } from '../../../Store/Reducer';

const SearchBar = () => {
  const { searchTarget, updateFilter, availableEmployees, availableStock, filter } =
    useCartStateContext();
  const isStock = searchTarget === STOCK_TARGET;
  const getLabel = (option) => {
    return isStock
      ? `${option.equipment} - ${option.description}`
      : `${option.names} ${option.lastName1} ${option.lastName2}`;
  };

  const getOptions = () => {
    if (isStock) {
      return availableStock.filter((stock) => {
        if (!filter?.stockType) {
          return true;
        }
        const result = stock.stockType === filter.stockType;
        return result;
      });
    } else {
      return availableEmployees
        .filter((employee) => {
          if (filter.employeeTitle) {
            return employee.degree.id === filter.employeeTitle;
          } else {
            return true;
          }
        })
        .filter((employee) => {
          if (!filter?.project) {
            return true;
          }
          if (employee?.projects?.length) {
            return filter?.project?.id === employee.projects[0].id;
          } else {
            return false;
          }
        });
    }
  };

  const handleChange = (event, newValue) => {
    if (isStock) {
      if (newValue) {
        const { projects } = newValue;
        if (projects?.length) {
          updateFilter({ project: projects[0], stockId: newValue.stockId });
        }
      } else {
        updateFilter({ project: null, stockId: null });
      }
    } else {
      if (newValue) {
        const { projects } = newValue;
        const newFilter = {
          employeeId: newValue.id,
        };
        if (projects?.length) {
          newFilter.project = projects[0];
        }
        updateFilter(newFilter);
      } else {
        updateFilter({ project: null, employeeId: null });
      }
    }
  };

  return (
    <Autocomplete
      id="tipo"
      options={getOptions() || []}
      getOptionLabel={getLabel}
      onChange={handleChange}
      style={{ width: '100%', flex: '1' }}
      renderInput={(params) => {
        return (
          <TextField {...params} label={isStock ? 'Descripción' : 'Nombre'} variant="outlined" />
        );
      }}
    />
  );
};

export default SearchBar;
