import React from 'react';
import { MainSpreadSheetContainer } from './SpreadSheetStyles';
import Row from './Row';

const SpreadSheet = ({ data, headers, headerless }) => {
  const createHeaderRow = () => {
    return headers.map((header) => header.title);
  };

  const createEmptyRow = () => {
    return headers.map(() => {
      return null;
    });
  };

  const createDataRow = (dataRowObject) => {
    return headers.reduce((prev, curr) => {
      return [...prev, dataRowObject[curr.field]];
    }, []);
  };

  const header = headerless ? [] : [createHeaderRow()];

  const getRows = (rowSection, headerLess) => {
    const dataRows = rowSection.map(createDataRow);

    const _header = headerLess ? [] : header;

    return [..._header, ...dataRows];
  };

  const createAllTable = () => {
    const groupsOfRows = data.map((groupOfRows, index) => {
      return getRows(groupOfRows, index);
    });

    const allRows = groupsOfRows.reduce((accumulator, current, index, array) => {
      const emptyRow = array.length - 1 === index ? [] : createEmptyRow();
      const groups = [...accumulator, ...current, ...[emptyRow]];
      return groups;
    }, []);
    return allRows;
  };

  return (
    <MainSpreadSheetContainer>
      {createAllTable().map((row, index) => {
        return (
          <Row
            row={{
              rowCells: row,
              index: index + 1,
            }}
            header={!index}
            key={`row_${index}`}
          />
        );
      })}
    </MainSpreadSheetContainer>
  );
};

export default SpreadSheet;
