import { useContext } from 'react';
import { userContext } from '../Contexts/UserContext/UserContext';

export const useModuleGrants = (module) => {
  const [userState] = useContext(userContext);

  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === module)
    .map((grant) => grant.id);

  const canIdoThis = (grantAction) => {
    return allowedModuleSections.includes(grantAction);
  };

  return { allowedModuleSections, canIdoThis };
};
