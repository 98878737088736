import React from 'react';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import { useStyles } from './FormikCheckboxesStyle';

function FormikCheckboxesDisplay({ formikName, label, groupLabel, props, noPadding }) {
  const { form } = props;
  const { handleChange, handleBlur, touched, errors, values } = form;
  const styles = useStyles();
  const value = values[formikName];
  const error = touched[formikName] && errors[formikName];

  return (
    <div className={styles.root}>
      <FormControl
        component="fieldset"
        className={!noPadding ? styles.formControl : ''}
        error={error ? true : false}
      >
        <FormLabel component="legend">{groupLabel}</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name={formikName}
                size="small"
                className={styles.group}
                onChange={handleChange}
                onBlur={handleBlur}
                checked={value}
              />
            }
            label={label}
          />
        </FormGroup>
        <FormHelperText id={formikName}>{error}</FormHelperText>
      </FormControl>
    </div>
  );
}

export default FormikCheckboxesDisplay;
