import styled from 'styled-components';

export const VehicleStateScreenMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const VehicleStateScreenHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const VehicleStateScreenTitle = styled.h1`
  font-weight: 200;
`;
export const VehicleStateTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
