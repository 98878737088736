import styled from 'styled-components';

export const ItemListTableMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const DataElementContainer = styled.div`
  ${(props) => (props.flex ? `flex: ${props.flex}` : '')};
  margin: 5px;
`;

export const InputElementsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
