/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import FolderIcon from '@material-ui/icons/Folder';
import { validationSchema, getInitialValues } from './validation';
// Icons
import { useStyles } from './materialStyles';
import { createFolder } from './../../../Utils/s3Utils';
import { QUALITY_LIBRARY_BUCKET } from './../../../Constants/urls';
import Input from '../../../Components/FormFields/FormikIconInput';

const NewFolderFormulary = (props) => {
  const { setOpen, refetch, selectedUser, path, folders } = props;
  const styles = useStyles();

  const handleNewFolder = async (values) => {
    const { name } = values;
    const newFolderName = values.name;
    const newFolderPath = path;
    const currentFolders = folders;

    if (
      newFolderName &&
      !currentFolders?.map((f) => f.key.toLowerCase()).includes(`${path}${name}/`.toLowerCase())
    ) {
      createFolder(newFolderName, newFolderPath, QUALITY_LIBRARY_BUCKET, (result) => {
        console.log(result);
        refetch();
      });
    } else {
      alert('El folder que estas intentando crear ya existe');
    }
    setOpen(false);
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(selectedUser)}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleNewFolder(values);
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={6}>
                <Grid item xs={12}>
                  <Field name="name">
                    {(props) => (
                      <Input
                        formikName={'name'}
                        label={'Nombre del nuevo folder'}
                        Icon={FolderIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={3}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    {selectedUser ? 'Guardar' : 'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default NewFolderFormulary;
