import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  deliveryAddress: yup.string().required('Campo Obligatorio'),
});

export const getInitialValues = (currentElement = {}) => {
  const { deliveryAddress = '' } = currentElement || {};

  return {
    deliveryAddress,
  };
};
