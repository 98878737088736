import React from 'react';
import { Grid } from '@material-ui/core';
import { Box, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useStyles } from './CostSupervisionScreenStyles';
import FundrequestTable from './components/FundrequestsTable';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { FUNDREQUEST_STATUS, useFundRequests } from '../../Hooks/useFundRequests';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CostSupervisionScreen = () => {
  //---------------------------------------------------------------------------
  // GENERAL USE HOOKS
  //---------------------------------------------------------------------------
  const styles = useStyles();
  const { projectId } = useParams();
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // Fetching hooks
  //---------------------------------------------------------------------------

  const { areFundRequestsLoading, fundRequests, refetchFundRequests } = useFundRequests(
    projectId
      ? {
          filters: {
            projectIdFilter: projectId,
          },
        }
      : undefined,
  );

  const requesterView = (
    <FundrequestTable
      fundRequests={(fundRequests || []).filter(
        ({ status }) => status !== FUNDREQUEST_STATUS.DRAFT || !!projectId,
      )}
      areFundRequestsLoading={areFundRequestsLoading}
      refetchFundRequests={refetchFundRequests}
    />
  );

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const approverView = (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Por aprobar" {...a11yProps(0)} />
          <Tab label="Por depositar" {...a11yProps(1)} />
          <Tab label="Pagadas" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FundrequestTable
          fundRequests={(fundRequests || []).filter(
            ({ status }) => status === FUNDREQUEST_STATUS.REQUESTED,
          )}
          areFundRequestsLoading={areFundRequestsLoading}
          refetchFundRequests={refetchFundRequests}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FundrequestTable
          fundRequests={(fundRequests || []).filter(
            ({ status }) => status === FUNDREQUEST_STATUS.APPROVED,
          )}
          areFundRequestsLoading={areFundRequestsLoading}
          refetchFundRequests={refetchFundRequests}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FundrequestTable
          fundRequests={(fundRequests || []).filter(
            ({ status }) => status === FUNDREQUEST_STATUS.CLOSED,
          )}
          areFundRequestsLoading={areFundRequestsLoading}
          refetchFundRequests={refetchFundRequests}
        />
      </TabPanel>
    </>
  );

  return (
    <div className={styles.container}>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>{projectId ? requesterView : approverView}</Grid>
      </Grid>
    </div>
  );
};

export default withNavigation(CostSupervisionScreen);
