import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
// Icons
import ScatterPlot from '@material-ui/icons/ScatterPlot';
import ComputerIcon from '@material-ui/icons/Computer';
import DescriptionIcon from '@material-ui/icons/Description';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import { TextField } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import SerialNumberInput from './SerialNumberInput/SerialNumberInput';
import { getInitialValues, getValidationSchema } from './validation';
import { useMasterDataCatalogs } from './../../../Hooks/useMasterdataCatalogs';
import { NO_PROJECT, useProjectsForCatalogs } from './../../../Hooks/useProjectsForCatalogs';
import { mapValues } from './formMapper';
import { inventoryRouteList } from './../../InventoryScreen/InventoryData';
import { useStyles } from './materialStyles';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
import Input from './../../../Components/FormFields/FormikIconInput';
import SubmitButton from './SubmitButton/SubmitButton';
import InsuranceForm from './VehicleRecordForms/InsuranceForm/InsuranceForm';
import CirculationCardForm from './VehicleRecordForms/CirculationCardForm/CirculationCardForm';
import VerificationForm from './VehicleRecordForms/VerificationForm/VerificationForm';
import { useInventoryTypeOptions } from '../hooks/useInventoryTypeOptions';
import { CREATING, useUrlData } from '../hooks/useUrlData';
import FormikAutocomplete from '../../../Components/AutoComplete/FormikAutocomplete';
import { useEmployeesForCatalogs } from '../../../Hooks/useEmployeesForCatalogs';
import {
  VEHICULOS,
  AUSCULTACION,
  AUSCULTACION_ESTRUCTURA,
  GEOFISICA,
  GEOTECNIA,
  TOPOGRAFIA,
  HERRAMIENTAS,
  LABORATORIO,
  INGENIERIA_TRANSITO,
} from '../../../Hooks/useAssetData';

const StockElementForm = (props) => {
  const { tableConfiguration } = props;
  const styles = useStyles();
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);
  const { project } = useParams();

  const {
    currentElement,
    isCurrentElementLoading,
    stockId,
    projectId,
    stockType: urlStockType,
    action,
    editStocksGrant,
  } = useUrlData();

  const inventoryTypeOptions = useInventoryTypeOptions();
  const [stockType, setStockType] = useState(
    currentElement?.inventoryType ||
      urlStockType ||
      tableConfiguration?.stockType ||
      inventoryTypeOptions[0].itemValue,
  );
  const [stockFormConfiguration, setStockFormConfiguration] = useState(() => {
    return (
      tableConfiguration ||
      inventoryRouteList.find((inventory) => inventory.stockType === stockType) ||
      {}
    );
  });
  const [originalProjectId, setOriginalProjectId] = useState(null);

  const {
    catalogs: masterDataCatalogs,
    updateEntityList: updateMasterDataParams,
    areCatalogsLoading,
  } = useMasterDataCatalogs({
    entityList: ['Status Inventario', 'Conjunto', 'Almacenes', 'Proveedores de calibración'],
    skip: !stockFormConfiguration,
  });
  const userDropdownOptions = useEmployeesForCatalogs({ project });
  const projectsInPlatform = useProjectsForCatalogs();

  useEffect(() => {
    if (currentElement?.stockType) {
      setStockType(currentElement?.stockType);
    }
  }, [currentElement]);

  useEffect(() => {
    if (stockType) {
      const newStockFormConfiguration = inventoryRouteList.find(
        (inventory) => inventory.stockType === stockType,
      );
      const { brandEntityType, entityType, modelEntityType } = newStockFormConfiguration;
      updateMasterDataParams([
        brandEntityType,
        entityType,
        modelEntityType,
        'Status Inventario',
        'Conjunto',
        'Almacenes',
        'Proveedores de calibración',
      ]);
      setStockFormConfiguration(newStockFormConfiguration);
    }
  }, [stockType]);

  useEffect(() => {
    if (!editStocksGrant) {
      history.push('/404');
    }
  }, [editStocksGrant]);

  useEffect(() => {
    if (currentElement) {
      console.log(currentElement);
      const { projects } = currentElement;
      if (projects?.length) {
        setOriginalProjectId(projects[0].id);
      }
    }
  }, [currentElement]);

  const yesNoArray = [
    {
      itemLabel: 'SI',
      itemValue: 'SI',
    },
    {
      itemLabel: 'NO',
      itemValue: 'NO',
    },
  ];

  const handlePost = async (values) => {
    const payload = mapValues(values);
    const { projects } = payload;
    const deleteInventoryFromProject = !projects?.length;
    const method = stockId ? 'put' : 'post';
    try {
      const result = await AxiosInstance[method]('/api/stock', payload);
      if (projects[0]) {
        const { id: projectId } = projects[0];
        await AxiosInstance.post(`/api/project/${projectId}/stock/${result.data.id}`);
      }
      if (deleteInventoryFromProject && originalProjectId !== null) {
        await AxiosInstance.delete(`/api/project/${originalProjectId}/stock/${result.data.id}`);
      }
      if (!deleteInventoryFromProject) {
        const { id: newProjectId } = (payload?.projects || [])[0];
        await AxiosInstance.post(`/api/project/${newProjectId}/stock/${result.data.id}`);
      }
      history.goBack();
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.errorMessage?.includes('Duplicate entry')) {
        alert(
          'El número de serie proporcionado ya existe y no puede ser duplicado, cambie el número de serie para continuar.',
        );
      }
    }
  };

  const isStockTypeDisabled = () =>
    currentElement?.stockType || urlStockType || tableConfiguration?.stockType;

  const getClassificationOptions = () => {
    const result = (masterDataCatalogs || {})[stockFormConfiguration?.entityType || 'na'] || [];
    return result;
  };

  const CALIBRATION_STATUS = [
    { itemValue: 'CALIBRADO', itemLabel: 'Calibrado' },
    { itemValue: 'VERIFICADO', itemLabel: 'Verificado' },
    { itemValue: 'N_A', itemLabel: 'N/A' },
  ];

  const shouldRender = () => {
    if (action === CREATING) {
      return masterDataCatalogs && !areCatalogsLoading && !isCurrentElementLoading;
    } else {
      return (
        masterDataCatalogs && !areCatalogsLoading && currentElement && !isCurrentElementLoading
      );
    }
  };

  const STOCK_WITH_CALIBRATION = [
    AUSCULTACION,
    AUSCULTACION_ESTRUCTURA,
    GEOFISICA,
    GEOTECNIA,
    TOPOGRAFIA,
    HERRAMIENTAS,
    LABORATORIO,
    INGENIERIA_TRANSITO,
  ];

  return shouldRender() ? (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentElement, {
          tableConfiguration,
          projectId,
          stockType,
        })}
        validationSchema={getValidationSchema(stockType)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ setFieldValue, setFieldTouched, isSubmitting, values, touched, isValid, errors }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Field name="stockType">
                    {(props) => (
                      <FormikAutocomplete
                        options={inventoryTypeOptions}
                        customOnChange={(newValue) => setStockType(newValue?.itemValue)}
                        label="Tipo de inventario"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        disabled={isStockTypeDisabled()}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="classification.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Clasificación'}
                        options={getClassificationOptions()}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="equipment">
                    {(props) => (
                      <Input
                        formikName={'equipment'}
                        label={'Equipo'}
                        Icon={ComputerIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="description">
                    {(props) => (
                      <Input
                        formikName={'description'}
                        label={'Descripción'}
                        Icon={DescriptionIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                {(stockType === 'VEHICULOS' || stockType === 'Vehículos') && (
                  <Grid item xs={12} md={6}>
                    <Field name="plate">
                      {(props) => (
                        <Input
                          formikName={'plate'}
                          label={'Placas'}
                          Icon={DescriptionIcon}
                          {...props}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                {STOCK_WITH_CALIBRATION.includes(stockType) && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Field name="lastCalibrationDate">
                        {(props) => (
                          <Input
                            formikName={'lastCalibrationDate'}
                            label={'Última calibración'}
                            Icon={DescriptionIcon}
                            type="date"
                            setFieldValue={setFieldValue}
                            {...props}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field name="calibrationExpirationDate">
                        {(props) => (
                          <Input
                            formikName={'calibrationExpirationDate'}
                            label={'Próxima calibración'}
                            Icon={DescriptionIcon}
                            type="date"
                            setFieldValue={setFieldValue}
                            {...props}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field name="calibrationStatus">
                        {(props) => (
                          <FormikAutocomplete
                            label={'Estado de calibración'}
                            options={CALIBRATION_STATUS}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            {...props}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field name="calibrationProvider.id">
                        {(props) => (
                          <FormikAutocomplete
                            label={'Proveedor de calibración'}
                            options={(masterDataCatalogs || {})['Proveedores de calibración'] || []}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            {...props}
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={6}>
                  <Field name="brand.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Marca'}
                        options={
                          (masterDataCatalogs || {})[
                            stockFormConfiguration?.brandEntityType || 'na'
                          ] || []
                        }
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="model.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Modelo'}
                        options={
                          (masterDataCatalogs || {})[
                            stockFormConfiguration?.modelEntityType || 'na'
                          ] || []
                        }
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="stockId">
                    {(props) => (
                      <Input
                        formikName={'stockId'}
                        label={'# de Inventario'}
                        Icon={ScatterPlot}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                {
                  <Grid item xs={12} md={6}>
                    <SerialNumberInput
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </Grid>
                }
                <Grid item xs={12} md={6}>
                  <Field name="responsible.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Responsable'}
                        options={userDropdownOptions || []}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="status.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Status'}
                        options={masterDataCatalogs['Status Inventario'] || []}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="group.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Conjunto'}
                        options={masterDataCatalogs['Conjunto'] || []}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="project">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Proyecto'}
                        options={projectsInPlatform}
                        customOnChange={(newValue) => {
                          if (newValue) {
                            setFieldValue('warehouse.id', 877);
                          }
                        }}
                        disabled={
                          !!projectId || (values?.warehouse?.id && values?.warehouse?.id !== 877)
                        }
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="warehouse.id">
                    {values?.project === NO_PROJECT
                      ? (props) => (
                          <FormikAutocomplete
                            label={'Almacen'}
                            options={masterDataCatalogs['Almacenes'] || []}
                            customOnChange={(newValue) => {
                              if (newValue) {
                                setFieldValue('project', NO_PROJECT);
                              }
                            }}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            {...props}
                            disabled={values?.project >= 0}
                          />
                        )
                      : () => {
                          return (
                            <TextField
                              value="Fuera de almacen"
                              disabled
                              variant="outlined"
                              style={{ width: '100%' }}
                              label="Almacén"
                            />
                          );
                        }}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="nickname">
                    {(props) => (
                      <Input
                        formikName={'nickname'}
                        label={'Apodo'}
                        Icon={ChromeReaderModeIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="ema">
                    {(props) => (
                      <FormikAutocomplete
                        label={'EMA'}
                        options={yesNoArray || []}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="amaac">
                    {(props) => (
                      <FormikAutocomplete
                        label={'AMAAC'}
                        options={yesNoArray || []}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                {stockType === VEHICULOS && (
                  <InsuranceForm setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} />
                )}
                {stockType === VEHICULOS && (
                  <CirculationCardForm
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                )}
                {stockType === VEHICULOS && (
                  <VerificationForm
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="space-around"
                spacing={1}
                style={{ marginTop: '20px' }}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    touched={touched}
                    action={action}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    style={{ width: '100%' }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default StockElementForm;
