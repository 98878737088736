import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FakeButton,
  QualityProfileScreenMainContainer,
  TableContainer,
} from './QualityProfilesScreenStyles';
import Table from './../../Components/Table';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from '../../Hooks/useFetch';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';

const QualityProfilesScreen = () => {
  const AxiosInstance = useContext(AxiosContext);
  const history = useHistory();
  const [deleteWarningVisibility, setDeletingWarningVisibility] = useState(false);
  const [documentProfileToDelete, setDocumentProfileToDelete] = useState(null);

  const { data: accessTemplateList, refetch: refetchProfiles } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/qd-access-template/list',
  });

  const handleDelete = async () => {
    const { id } = documentProfileToDelete;
    await AxiosInstance.delete(`/api/qd-access-template/${id}`);
    refetchProfiles();
    setDeletingWarningVisibility(false);
    setDocumentProfileToDelete(null);
  };

  const actions = [
    {
      icon: () => (
        <FakeButton>
          <p>NUEVO PERFIL</p>
        </FakeButton>
      ),
      tooltip: 'editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        history.push(`/perfiles-calidad/nuevo`);
      },
      isFreeAction: true,
    },
    {
      icon: 'edit',
      tooltip: 'editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { id } = rowElement;
        history.push(`/perfiles-calidad/${id}`);
      },
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setDocumentProfileToDelete(rowElement);
        setDeletingWarningVisibility(true);
      },
    },
  ];

  const TABLE_HEADERS = [{ title: 'Nombre del perfil', field: 'name' }];

  return (
    <>
      <QualityProfileScreenMainContainer>
        <TableContainer>
          <Table
            title="Perfiles Sistemas de Gestión"
            data={accessTemplateList || []}
            columns={TABLE_HEADERS}
            actions={actions}
            options={{
              pageSize: 10,
              actionsColumnIndex: -1,
            }}
          />
        </TableContainer>
      </QualityProfileScreenMainContainer>
      {/* ------------------------------------------------------------------------ */}
      {/* --- DELETING PROFILE WARNING ---------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}

      <Alert
        type={WARNING_TYPE}
        title="Eliminar perfil de usuario"
        content="Estas intentando eliminar un perfil acceso a documentos, esta acción es irreversible, ¿estás seguro?"
        actions={[
          {
            text: 'ELIMINAR',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'CANCELAR',
            onClick: () => {
              setDeletingWarningVisibility(false);
            },
          },
        ]}
        open={deleteWarningVisibility}
        onClose={() => setDeletingWarningVisibility(false)}
        onBackdropPress={() => {
          setDeletingWarningVisibility(false);
        }}
      />
    </>
  );
};

export default withNavigation(QualityProfilesScreen);
