import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import axios from 'axios';
import { Button } from '@material-ui/core';
import queryString from 'query-string';
import { CsvUploadMainContainer } from './DropZoneStyles';
import ProgressData from './ProgressData';

const EXCEL_BUCKET = 'excel-csv-processing';
const CSV_API_URL = '/api/stock';

const CustomDropZone = (props) => {
  const { apiurl, apimethod, apimode, headers, bucketName, socketAction, map, onClose } = props;

  const [socket, setSocket] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const socket = new WebSocket('wss://8usjqn5uj0.execute-api.us-east-1.amazonaws.com/test');
    socket.onopen = function () {
      setSocket(socket);
    };
    socket.onmessage = function (data) {
      const { data: dataString } = data;
      const dataObject = dataString ? JSON.parse(dataString) : {};
      const { progress: socketProgress } = dataObject;
      if (socketProgress) {
        const _progress = Number(socketProgress);
        setProgress(_progress);
      }
    };
    socket.onclose = function () {
      setSocket(null);
    };
  }, []);

  const getUploadParams = async ({ file, meta }) => {
    const key = `${meta.name}`;
    const params = {
      bucket: EXCEL_BUCKET,
      key,
      filetype: file.type,
    };
    const url = `https://x3v5fdavwf.execute-api.us-east-1.amazonaws.com/beta?${queryString.stringify(
      params,
    )}`;
    const postUrl = await axios.get(url);
    const headers = {
      'Content-Type': params.filetype,
    };
    const result = { url: postUrl.data, body: file, method: 'put', headers };
    return result;
  };

  const handleSubmit = async (files) => {
    const file = files.pop();
    const fileName = file.file.name;
    const params = {
      apiurl: apiurl || `${CSV_API_URL}`,
      apimethod: apimethod || 'post',
      apimode: apimode || 'single',
      fileKey: fileName,
      bucketName: bucketName || EXCEL_BUCKET,
      action: socketAction || 'csvprocessing',
      map: map || {
        'First name': 'firstName',
        'Last name': 'lastName',
        'e-Mail': 'email',
      },
      headers: headers || {},
    };
    socket && socket.send(JSON.stringify(params));
    setProgress(0.01);
    const result = {};
    return result;
  };

  return (
    <CsvUploadMainContainer>
      <Dropzone
        getUploadParams={getUploadParams}
        onSubmit={handleSubmit}
        inputContent={'Haz click o arrastra un archivo para subirlo a la nube'}
        submitButtonContent={'Guardar'}
        inputWithFilesContent="Agregar más archivos"
      />
      {progress ? <ProgressData progressValue={progress} onClose={onClose} /> : null}
      <Button onClick={() => onClose && onClose()}>Cerrar</Button>
    </CsvUploadMainContainer>
  );
};

export default CustomDropZone;
