import { Button } from '@mui/material';
import MaterialTable from 'material-table';
import React, { useContext } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Typography } from '@material-ui/core';
import { normalizePath } from '@sentry/utils';
import { format } from 'date-fns';
import { useValidateFolders } from './hooks/useValidateFolders';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { userContext } from '../../Contexts/UserContext/UserContext';
import GenericDialog from '../../Containers/GenericDialog';
import CustomDropzone from '../../Components/DropZone/Dropzone';
import axios from '../../Utils/AxiosS3Instance';
import { downloadFile } from '../../Utils/s3Utils';

const StockCsvScreen = () => {
  const [userState] = useContext(userContext);
  const todaysPath = normalizePath(
    `sources/${userState.username}/${format(new Date(), 'dd-MM-yyyy')}/`,
  );
  const FILES_BUCKET = 'roshare-stock-csv';

  const getDateFromKey = (key) =>
    key
      .split('/')
      .filter((value) => !!value)
      .pop();

  const columns = [
    {
      title: 'Fecha',
      field: 'date',
      render: ({ folderName }) => (
        <Typography variant="body1">{getDateFromKey(folderName)}</Typography>
      ),
    },
    {
      title: 'CSV Original',
      render: ({ originalFile }) => {
        return (
          <Button
            disabled={!originalFile}
            endIcon={<DownloadIcon />}
            onClick={() => {
              if (originalFile) {
                downloadFile(originalFile.key, FILES_BUCKET, (data) => {
                  window.open(data, '_blank');
                });
              }
            }}
          >
            DESCARGAR CSV ORIGINAL
          </Button>
        );
      },
    },
    {
      title: 'Log de errores',
      render: ({ errorFile }) => {
        return (
          <Button
            disabled={!errorFile}
            endIcon={<DownloadIcon />}
            onClick={() => {
              if (errorFile) {
                downloadFile(errorFile.key, FILES_BUCKET, (data) => {
                  window.open(data, '_blank');
                });
              }
            }}
          >
            DESCARGAR LOG
          </Button>
        );
      },
    },
    {
      title: 'Log de errores',
      render: ({ wrongStockCsvFile }) => {
        return (
          <Button
            disabled={!wrongStockCsvFile}
            endIcon={<DownloadIcon />}
            onClick={() => {
              if (wrongStockCsvFile) {
                downloadFile(wrongStockCsvFile.key, FILES_BUCKET, (data) => {
                  window.open(data, '_blank');
                });
              }
            }}
          >
            DESCARGAR FILAS ERRONEAS
          </Button>
        );
      },
    },
  ];

  const { foldersData } = useValidateFolders();

  const [openCsvDialog, setOpenCsvDialog] = React.useState(false);

  const actions = [
    {
      icon: () => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding="5px"
          marign="5px"
          border="solid 1px #A0A0A0"
          borderRadius="5px"
        >
          <Typography variant="body1">Cargar nuevo inventario</Typography>
        </Box>
      ),
      isFreeAction: true,
      onClick: () => setOpenCsvDialog(true),
    },
  ];

  const createFolder = async () => {
    const folderName = format(new Date(), 'dd-MM-yyyy');
    const newFolderPath = normalizePath(`resultados/${userState.username}/`);
    const bodyForm = new FormData();
    bodyForm.append('bucket', FILES_BUCKET);
    bodyForm.append('path', newFolderPath);
    bodyForm.append('name', folderName);
    bodyForm.append('isFolder', true);

    await axios.post('/s3-object', bodyForm);
  };

  return (
    <>
      <MaterialTable
        title="Cargas de inventarios por CSV"
        data={foldersData || []}
        actions={actions}
        columns={columns}
        options={{
          pageSizeOptions: [5, 10, 15, 20, 25, 30],
          pageSize: 10,
        }}
      />
      <GenericDialog
        openDialog={openCsvDialog}
        dialogTitle="Cargar nuevo archivo CSV"
        maxWidth="md"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContect="flex-start"
          alignItems="flex-end"
        >
          <CustomDropzone
            closeDialog={() => setOpenCsvDialog(false)}
            bucket={FILES_BUCKET}
            path={todaysPath}
            refetch={createFolder}
            multiple={false}
          />
          <Box margin="5px">
            <Button onClick={() => setOpenCsvDialog(false)} variant="contained" color="secondary">
              Cerrar
            </Button>
          </Box>
        </Box>
      </GenericDialog>
    </>
  );
};

export default withNavigation(StockCsvScreen);
