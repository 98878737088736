import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { MainContainer } from './UserLogScreenStyles';
import Table from './../../Components/Table';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from '../../Hooks/useFetch';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';

const useStyles = makeStyles(() => ({
  container: {},
  button: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  fakeButton: {
    backgroundColor: '#3f4ab5',
    fontSize: '15px',
    fontWeight: 200,
    color: 'white',
    borderRadius: '5px',
    padding: '10px',
    margin: 0,
  },
}));

const UserLogScreen = () => {
  const classes = useStyles();

  let { userId } = useParams();
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);

  const { data: userData, updateUrl: updateUserUrl } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `api/user/${userId}`,
    skip: !userId,
  });
  const { data: auditData, updateParams: updateAuditParams } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `api/audit/events`,
    skip: !userData,
  });

  useEffect(() => {
    if (userData) {
      console.log(userData);
      updateAuditParams({ auditUsername: userData.username });
    }
  }, [userData]);

  useEffect(() => {
    if (userId) {
      updateUserUrl(`/api/user/${userId}`);
    }
  }, [userId]);

  const mapData = (element) => {
    const { user, event, timestamp, modifiedTable } = element;
    return {
      user: user,
      event: event,
      timestamp: formatDate(new Date(timestamp), 'dd-MM-yyyy - HH:mm:ss'),
      dateObject: new Date(timestamp),
      modifiedTable,
    };
  };

  const TABLE_HEADERS = [
    { title: 'Usuario', field: 'user.name' },
    { title: 'Evento', field: 'event' },
    { title: 'Fecha', field: 'timestamp' },
    { title: 'Tabla', field: 'modified_table' },
  ];

  const actions = [
    {
      icon: () => <p className={classes.fakeButton}>REGRESAR</p>,
      onClick: () => {
        history.push('/usuarios');
      },
      isFreeAction: true,
    },
  ];

  return (
    <MainContainer>
      <Table
        title={`Actividad de ${userData?.name || ''}`}
        data={(auditData || []).map(mapData)}
        columns={TABLE_HEADERS}
        actions={actions}
      />
      {/* <p>{JSON.stringify(auditData||{})}</p> */}
    </MainContainer>
  );
};

export default withNavigation(UserLogScreen);
