import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';
import FlightRounded from '@material-ui/icons/FlightRounded';
import ScatterPlot from '@material-ui/icons/ScatterPlot';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { format as formatDate } from 'date-fns';
import { validationSchema, getInitialValues } from './validation';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
import { userContext } from './../../../Contexts/UserContext/UserContext';
import { S3_BACKUP_BUCKET } from './../../../Constants/urls';
// Icons
import { useStyles } from './materialStyles';
import * as dropdowns from './../../../Constants/dropdownsOptions';
import FormikAutocomplete from '../../../Components/AutoComplete/FormikAutocomplete';
import Input from '../../../Components/FormFields/FormikIconInput';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';
import { useClients } from '../../../Hooks/useClients';
import { useModuleGrants } from '../../../Hooks/useUsersGrants';
import { ROSHARE_GRANTS } from '../../../.gen/grantsAndModules.generated';

function BackupFormulary(props) {
  const { setOpen, refetchProjects, currentElement, historicos } = props;
  const styles = useStyles();
  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);
  const { userDetails = {} } = userState || {};
  const { canIdoThis } = useModuleGrants(ROSHARE_GRANTS.PROYECTOS.MAIN_MODULE);
  const API_ENDPOINT = 'https://s3-api.ro-share.com';

  const { clients } = useClients();
  const historicScreen = window.location.href.includes('historicos');

  const createFile = (formFields) => {
    const matriculaDeCarpeta = `${formFields.dbId}`;

    try {
      axios
        .post(
          `${API_ENDPOINT}/api/root-folder?name=${matriculaDeCarpeta}&bucket=${S3_BACKUP_BUCKET}`,
          {
            name: matriculaDeCarpeta,
            bucket: S3_BACKUP_BUCKET,
          },
        )
        .catch((error) => {
          alert('ERROR AL CREAR CARPETA: ', error);
          console.error(error);
        })
        .finally(() => {
          setOpen(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const mapValues = (values) => {
    const { latitude, longitude, startDate, endDate } = values;
    const result = {
      ...values,
      endDate: formatDate(endDate, BACKEND_DATE_FORMAT),
      startDate: formatDate(startDate, BACKEND_DATE_FORMAT),
      projectStatus: historicos ? 'CLOSED' : 'ACTIVE',
      latitude: Number(latitude),
      longitude: Number(longitude),
    };
    if (currentElement) {
      result.id = currentElement.id;
    } else {
      result.projectGrants = [
        {
          grantName: 'READ_PERMISSIONS',
          user: {
            id: Number(userDetails.id),
          },
        },
        {
          grantName: 'EDIT_PERMISSIONS',
          user: {
            id: Number(userDetails.id),
          },
        },
      ];
    }
    return result;
  };

  const handlePost = async (values) => {
    const payload = mapValues(values);
    const method = currentElement ? 'put' : 'post';
    const { data } = await AxiosInstance[method]('/api/project', payload);

    const { id: dbId } = data || {};
    if (!currentElement) {
      await createFile({ ...values, dbId });
    }
    refetchProjects && refetchProjects();
    setOpen(false);
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentElement, { historicScreen })}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isValid, isSubmitting, values, setFieldValue, setFieldTouched }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Field name="projectStatus">
                    {(props) => {
                      return !historicScreen ? (
                        <FormikAutocomplete
                          options={dropdowns.PROJECT_STATUSES}
                          label="Estado del proyecto"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          {...props}
                        />
                      ) : (
                        <Input
                          formikName="projectStatus"
                          label="Estado del proyecto"
                          props={props}
                          disabled={true}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="projectId">
                    {(props) => (
                      <Input
                        formikName={'projectId'}
                        label={'Número de contrato interno'}
                        Icon={FlightRounded}
                        props={props}
                        disabled={!canIdoThis(ROSHARE_GRANTS.PROYECTOS.EDITAR_PROYECTOS)}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="projectName">
                    {(props) => (
                      <Input
                        formikName={'projectName'}
                        label={'Nombre Corto'}
                        Icon={FlightRounded}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="projectDescription">
                    {(props) => (
                      <Input
                        formikName={'projectDescription'}
                        label={'Nombre del proyecto'}
                        Icon={ScatterPlot}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field name="company">
                    {(props) => (
                      <Input
                        formikName={'company'}
                        label={'Empresas'}
                        Icon={ApartmentIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="contractKey">
                    {(props) => (
                      <Input
                        formikName={'contractKey'}
                        label={'Número de contrato con la dependencia'}
                        Icon={FlightRounded}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field name="country">
                    {(props) => (
                      <FormikAutocomplete
                        options={dropdowns.PAISES}
                        label="País"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="region">
                    {(props) => (
                      <FormikAutocomplete
                        options={
                          // eslint-disable-next-line import/namespace
                          dropdowns[`ESTADOS_${(values?.country || 'MEXICO').toUpperCase()}`]
                        }
                        label={values?.country?.toLowerCase() === 'mexico' ? 'Estado' : 'Región'}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="client.id">
                    {(props) => (
                      <FormikAutocomplete
                        label="Dependencia / Cliente"
                        options={clients}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="classification">
                    {(props) => (
                      <FormikAutocomplete
                        label="Clasificación de trabajo"
                        options={dropdowns.CLASIFICACION_TRABAJO}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field name="clientType">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Tipo de Cliente'}
                        options={dropdowns.TIPO_CLIENTES}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="projectType">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Tipo de trabajo'}
                        options={dropdowns.TIPO_TRABAJO}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="amount">
                    {(props) => (
                      <Input
                        formikName={'amount'}
                        label={'Monto sin IVA'}
                        Icon={FlightRounded}
                        props={props}
                        format={{
                          style: 'currency',
                          currency: 'MXN',
                        }}
                        type="tel"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="latitude">
                    {(props) => <Input formikName={'latitude'} label={'Latitud'} props={props} />}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="longitude">
                    {(props) => <Input formikName={'longitude'} label={'Logitud'} props={props} />}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="startDate">
                    {(props) => (
                      <Input
                        formikName={'startDate'}
                        label={'Fecha de inicio'}
                        props={props}
                        type="date"
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="endDate">
                    {(props) => (
                      <Input
                        formikName={'endDate'}
                        label={'Fecha final'}
                        props={props}
                        type="date"
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="space-evenly"
                spacing={1}
                style={{ marginTop: '5px' }}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    style={{ width: '100%' }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    {currentElement ? 'Editar' : 'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    style={{ width: '100%' }}
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default BackupFormulary;
