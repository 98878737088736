import MaterialTable from 'material-table';
import React from 'react';

const LabReportTable = ({ data }) => {
  const TABLE_COLUMNS = [
    { title: 'No', field: 'id' },
    { title: 'Código de identificación', field: 'stockId' },
    { title: 'Equipo o instrumento', field: 'equipment' },
    { title: 'No de Piezas', field: 'stockAmount' },
    { title: 'Marca', field: 'brand.value' },
    {
      title: 'No de serie',
      field: 'serialNumber',
      render: (rowData) => rowData?.serialNumber || 'N/A',
    },
    { title: 'Modelo', field: 'model.value' },
    {
      title: 'Alcance de medición',
      field: 'precision',
      render: (rowData) => rowData?.equipmentPrecision || 'Valor no registrado',
    },
    {
      title: 'Fecha de calibración o verificación',
      field: 'lastCalibrationDate',
      render: (rowData) => rowData.lastCalibrationDate || 'No hay fecha registrada',
    },
    {
      title: 'Próxima fecha de calibración o verificación',
      field: 'calibrationExpirationDate',
      render: (rowData) => rowData.calibrationExpirationDate || 'No hay fecha registrada',
    },
    {
      title: 'Equipo calibrado o verificado',
      field: 'calibrationStatus',
      render: (rowData) => {
        if (rowData?.calibrationStatus) {
          if (rowData.calibrationStatus !== 'N_A') {
            return rowData.calibrationStatus;
          }
        }
        return 'N/A';
      },
    },
    {
      title: 'Ubicación actual',
      field: 'classification.value',
    },
  ];

  return (
    <MaterialTable
      data={data}
      title="Reporte de inventario de laboratorio"
      columns={TABLE_COLUMNS}
      options={{
        actionsColumnIndex: -1,
        pageSize: 15,
        pageSizeOptions: [10, 15, 25],
        sorting: true,
        filtering: true,
      }}
    />
  );
};

export default LabReportTable;
