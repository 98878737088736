import React from 'react';
import {
  ProgressBarContainer,
  PercentageSection,
  MainComponentContainer,
  IconContainer,
  SectionValue,
} from './ProgressBarStyles';

const ProgressBar = (props) => {
  const { sections, Icon } = props;

  const sectionsTotal = sections.reduce((prev, curr) => {
    return prev.percentage + curr;
  }, 0);

  if (sectionsTotal < 1) {
    sections.push({ percentage: 1 - sectionsTotal });
  }

  return (
    <MainComponentContainer>
      <IconContainer>{Icon && <Icon color="primary" fontSize="large" />}</IconContainer>
      <ProgressBarContainer radius={5}>
        {sections.map((section, index, originalArray) => {
          const { color, percentage } = section;
          let kind = null;
          if (index === 0) {
            kind = 'first';
          } else if (index === originalArray.length - 1) {
            kind = 'last';
          }
          return (
            <PercentageSection
              radius={5}
              key={index}
              kind={kind}
              color={color}
              percentage={percentage}
            >
              <SectionValue>{(percentage * 100).toFixed(2)}%</SectionValue>
            </PercentageSection>
          );
        })}
      </ProgressBarContainer>
    </MainComponentContainer>
  );
};

export default ProgressBar;
