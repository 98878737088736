import styled from 'styled-components';

export const MapContainer = styled.div`
  position: absolute;
  width: calc(100% - 120px);
  z-index: 0;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FloatingButton = styled.div`
  padding: 15px;
  border-radius: 50%;
  background-color: #4287f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.53);
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.53);
  margin: 15px;
`;

export const MarkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  background-color: #f0f0f0;
  -webkit-box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.53);
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.53);
  color: black;
  font-size: 15px;
`;

export const MarkerTitle = styled.p`
  font-weight: 500;
  font-size: 1rem;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  margin: 10px;
  color: white;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => Number(props.zoom)}px;
  border-radius: 50%;
  background-color: ${(props) => (props.isTargetProject ? 'blue' : 'red')};
  -webkit-box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.53);
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.53);
  color: white;
  font-size: ${(props) => Number(props.zoom) * 2}px;
`;

export const WarehouseIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => Number(props.zoom)}px;
  border-radius: 50%;
  background-color: green;
  -webkit-box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.53);
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.53);
  color: white;
  font-size: ${(props) => Number(props.zoom) * 2}px;
`;

export const TitleOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
