import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const NewLabworksMainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.h1`
  font-weight: 400;
  margin: 5px;
  padding: 12px;
`;

export const MainDataContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  margin: 5px;
  align-items: center;
  justify-content: space-between;
`;

export const DataElementContainer = styled.div`
  ${(props) => (props.flex ? `flex: ${props.flex}` : '')};
  margin: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
export const SamplesDataContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DescriptionContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  margin: 5px;
`;
export const SampleDetailContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex: 3;
  margin: 5px;
`;

export const CustomInput = styled.textarea`
  border: solid #a0a0a0 1px;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
