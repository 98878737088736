import React from 'react';
import { AppContextProvider } from './AppContext/AppContext';
import { GpsContextProvider } from './GpsContext/GpsContext';
import { UserContextProvider } from './UserContext/UserContext';
import { AxiosContextProvider } from './AxiosContext/AxiosContext';
import { ProjectContextProvider } from './ProjectContext/ProjectContext';

const AllContextProvider = (props) => {
  return (
    <AppContextProvider>
      <GpsContextProvider>
        <UserContextProvider>
          <ProjectContextProvider>
            <AxiosContextProvider>{props.children}</AxiosContextProvider>
          </ProjectContextProvider>
        </UserContextProvider>
      </GpsContextProvider>
    </AppContextProvider>
  );
};

export default AllContextProvider;
