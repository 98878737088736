import React from 'react';
import PageviewIcon from '@mui/icons-material/Pageview';
import { useHistory } from 'react-router-dom';
import { ShoppingMapHistoryContainer } from './ShoppingMapHistory.styled';
import Table from './../../Components/Table';
import axios from './../../Utils/AxiosS3Instance';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { useFetch } from '../../Hooks/useFetch';

const FILES_BUCKET = 'roshare-shopping-cart';

const ShoppingMapHistory = () => {
  const history = useHistory();

  const TABLE_HEADERS = [
    { title: 'Proyecto destino', field: 'project' },
    { title: 'Fecha', field: 'date' },
    { title: 'Autor', field: 'author' },
  ];

  const { data: cartFiles } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: {
      bucket: FILES_BUCKET,
    },
  });

  const getFile = async (s3DownloadKey) => {
    const signedUrl = (
      await axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`)
    ).data;

    window.open(signedUrl, '_blank');
  };

  const actions = [
    {
      icon: 'download',
      tooltip: 'descargar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowData) => {
        const { key } = rowData;
        getFile(key);
      },
      // hidden: !allowedModuleSections?.includes(tableConfiguration?.canEditProfile)
    },
    {
      icon: PageviewIcon,
      tooltip: 'previsualización',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowData) => {
        const { key } = rowData;
        history.push(`/historial-carrito/view?pdfkey=${key}`);
        // getFile(key);
      },
      // hidden: !allowedModuleSections?.includes(tableConfiguration?.canEditProfile)
      hidden: true,
    },
  ];

  const mapElement = (cartDocument) => {
    const { key } = cartDocument;
    const values = key.replace('requisiciones', '').split('_');
    const author = values[0] || '';
    const project = values[3] || '';
    const date = values[2] || '';
    return { ...cartDocument, project, date, author };
  };

  return (
    <ShoppingMapHistoryContainer>
      <Table
        title={`Historial de carritos de compra`}
        data={(cartFiles?.files || []).map((e) => mapElement(e))}
        columns={TABLE_HEADERS}
        actions={actions}
        options={{
          filtering: true,
          search: false,
          paging: false,
          maxBodyHeight: '85vh',
        }}
      />
    </ShoppingMapHistoryContainer>
  );
};

export default withNavigation(ShoppingMapHistory);
