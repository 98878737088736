export const mapElement = (element) => {
  const resultado = {
    ...element,
    project: element?.projects?.length ? element?.projects[0] : { projectName: 'Sin proyecto' },
  };
  if (element.id === 231) {
    console.log(resultado);
  }

  return resultado;
};
