import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { withNavigation } from './../../Containers/AppNavigation/AppNavigation';
import Dialog from './../../Containers/GenericDialog';
import NewProjectFormulary from './../AllProjectsScreen/NewProjectFormulary/Formulary';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';

const FakeButton = styled.div`
  background-color: #3f4ab5;
  font-size: 12px;
  font-weight: 200;
  color: white;
  border-radius: 5px;
  padding: 0 10px;
  margin: 0;
`;

const HistoricProjectScreen = () => {
  const axiosInstance = useContext(AxiosContext);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const history = useHistory();

  const { data: allProjects, refetch: refetchProjects } = useFetch({
    axiosInstance,
    initialUrl: '/api/project/list',
  });

  const columns = [
    { title: 'Proyecto', field: 'projectName' },
    { title: 'Id', field: 'projectId' },
    { title: 'Contrato', field: 'contractKey' },
    {
      title: 'Estado del proyecto',
      field: 'projectStatus',
      render: (rowData) => (
        <Typography>{rowData?.projectStatus === 'ACTIVE' ? 'Activo' : 'Cerrado'}</Typography>
      ),
    },
  ];

  const actions = [
    {
      icon: () => (
        <FakeButton>
          <p>Agregar proyecto</p>
        </FakeButton>
      ),
      isFreeAction: true,
      onClick: () => {
        setDialogOpen(true);
      },
    },
  ];

  const handleRowClick = (rowData) => {
    const { id } = rowData;
    history.push(`historicos/${id}`);
  };

  return (
    <>
      <MaterialTable
        title="Historicos"
        data={allProjects || []}
        columns={columns}
        options={{ pageSize: 20, search: false, filtering: true }}
        onRowClick={(event, rowData) => {
          handleRowClick(rowData);
        }}
        actions={actions}
      />
      <Dialog openDialog={dialogOpen} dialogTitle={'Nuevo Proyecto'} maxWidth="md">
        <NewProjectFormulary setOpen={setDialogOpen} refetchProjects={refetchProjects} />
      </Dialog>
    </>
  );
};

export default withNavigation(HistoricProjectScreen);
