import styled from 'styled-components';

export const ProgramScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 115px);
  margin-left: 10px;
`;
export const CalendarElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 115px); */
  margin-left: 10px;
  flex: 1;
  border: solid #f0f0f0 1px;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
  padding: 25px;
`;
export const MileStoneElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 115px);
  margin-left: 10px;
  flex: 1;
`;

export const ProgramActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
`;

export const TableContainer = styled.div`
  /* display: block; */
`;
export const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 5px;
`;
