import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import { getInitialValues, validationSchema } from './validation';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
// Icons
import { useStyles } from './materialStyles';
import Input from '../../../Components/FormFields/FormikIconInput';

const AddressFormulary = (props) => {
  const { setOpen, labTest, refetchLabTests } = props;
  const styles = useStyles();

  const AxiosInstance = useContext(AxiosContext);

  const handlePost = async (values) => {
    const { deliveryAddress } = values;
    const payload = {
      orderNumber: labTest.orderNumber,
      deliveryAddress,
    };

    await AxiosInstance.put('/api/lab-test-order', payload);
    await AxiosInstance.put(
      `/api/lab-test-order/${labTest.orderNumber}/status/IN_TRANSIT`,
      payload,
    );
    refetchLabTests && refetchLabTests();
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(labTest)}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={6}>
                <Grid item xs={12} md={12}>
                  <Field name="deliveryAddress">
                    {(props) => (
                      <Input
                        formikName={'deliveryAddress'}
                        label={'Dirección de entrega'}
                        Icon={MarkunreadMailboxIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={1}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default AddressFormulary;
