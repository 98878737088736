import { Divider, Grid } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import Input from './../../../../../Components/FormFields/FormikIconInput';
import FormikAutocomplete from '../../../../../Components/AutoComplete/FormikAutocomplete';
import { useMasterDataCatalogs } from '../../../../../Hooks/useMasterdataCatalogs';

const INSURANCE_PROVIDERS = 'Proveedores de Seguros';
const INSURANCE_COVERAGES = 'Coverturas de Seguros';

const masterDataEntities = [INSURANCE_PROVIDERS, INSURANCE_COVERAGES];

const InsuranceForm = ({ setFieldTouched, setFieldValue }) => {
  const { catalogs, areCatalogsLoading } = useMasterDataCatalogs({
    entityList: masterDataEntities,
  });

  return (
    !!catalogs &&
    !areCatalogsLoading && (
      <>
        <Grid item xs={12} md={12}>
          <h1>Póliza de seguro</h1>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="vehicleRecord.insuranceProvider.id">
            {(props) => (
              <FormikAutocomplete
                label={'Proveedor de seguros'}
                options={(catalogs || {})[INSURANCE_PROVIDERS] || []}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                {...props}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="vehicleRecord.coverage.id">
            {(props) => (
              <FormikAutocomplete
                label={'Cobertura de seguros'}
                options={(catalogs || {})[INSURANCE_COVERAGES] || []}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                {...props}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="vehicleRecord.clause">
            {(props) => (
              <Input formikName={'vehicleRecord.clause'} label={'Número de póliza'} {...props} />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="vehicleRecord.endorsement">
            {(props) => (
              <Input formikName={'vehicleRecord.endorsement'} label={'Endoso'} {...props} />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="vehicleRecord.policyIndex">
            {(props) => (
              <Input formikName={'vehicleRecord.policyIndex'} label={'Inciso'} {...props} />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="vehicleRecord.policyDate">
            {(props) => (
              <Input
                label={'Fecha de expedición'}
                type="date"
                formikName="vehicleRecord.policyDate"
                {...props}
                props={props}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="vehicleRecord.insuranceExpirationDate">
            {(props) => (
              <Input
                label={'Fecha de expiración'}
                type="date"
                formikName="vehicleRecord.insuranceExpirationDate"
                {...props}
                props={props}
              />
            )}
          </Field>
        </Grid>
      </>
    )
  );
};

export default InsuranceForm;
