import * as yup from 'yup';

export const validationSchema = yup.object({
  value: yup.string().required('This field is required'),
});

export const getInitialValues = (masterDataElement) => {
  let initialValues = {
    value: masterDataElement.value,
  };

  return initialValues;
};
