import styled from 'styled-components';

export const MainSingleProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProjectMainTitle = styled.h1`
  font-size: 30px;
  color: #707070;
  margin: 5px;
  padding-bottom: 15px;
  border-bottom: solid #707070 1px;
`;
