import styled from 'styled-components';

export const MarkerOptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  background-color: rgba(100, 100, 100, 1);
  &:hover {
    background-color: rgb(150, 150, 150);
    border: solid white 2px;
  }
  cursor: pointer;
  border-radius: 5px;
  margin: 2px 10px;
`;

export const MarkerOptionLabel = styled.p`
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
  color: white;
`;
