import { useContext } from 'react';
import { useFetch } from './useFetch';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const EQUIPO_COMPUTO = 'EQUIPO_COMPUTO';
export const EQUIPO_OFICINA = 'EQUIPO_OFICINA';
export const MOBILIARIO = 'MOBILIARIO';
export const INGENIERIA_TRANSITO = 'INGENIERIA_TRANSITO';
export const DRONES = 'DRONES';
export const LABORATORIO = 'LABORATORIO';
export const MAQUINARIA_PESADA = 'MAQUINARIA_PESADA';
export const MAQUINARIA = 'MAQUINARIA';
export const AUSCULTACION = 'AUSCULTACION';
export const AUSCULTACION_ESTRUCTURA = 'AUSCULTACION_ESTRUCTURA';
export const GEOFISICA = 'GEOFISICA';
export const GEOTECNIA = 'GEOTECNIA';
export const TOPOGRAFIA = 'TOPOGRAFIA';
export const HERRAMIENTAS = 'HERRAMIENTAS';
export const VEHICULOS = 'VEHICULOS';
export const RECURSOS_HUMANOS = 'RECURSOS_HUMANOS';

export const useAssetData = (params) => {
  const { initialAssetType } = params;

  const axiosInstance = useContext(AxiosContext);

  const createUrl = (elementType) => {
    if (elementType !== RECURSOS_HUMANOS) {
      return `/api/stock/list?stockType=${elementType}`;
    } else {
      return '/api/employee/list';
    }
  };

  const setAssetType = (newAssetType) => {
    newAssetType && updateUrl(createUrl(newAssetType));
  };

  const { data, updateUrl, isLoading } = useFetch({
    axiosInstance,
    initialUrl: createUrl(initialAssetType),
    skip: !initialAssetType,
  });

  return {
    assetData: data,
    setAssetType,
    isLoading,
  };
};
