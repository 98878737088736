import { Divider, Grid } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import Input from '../../../../../Components/FormFields/FormikIconInput';

const VerificationForm = () => {
  return (
    <>
      <Grid item xs={12} md={12}>
        <h1>Verificación</h1>
      </Grid>
      <Grid item xs={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field name="vehicleRecord.verificationSticker">
          {(props) => (
            <Input
              formikName="vehicleRecord.verificationSticker"
              label={'Engomado Obtenido'}
              {...props}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={4}>
        <Field name="vehicleRecord.lastVerificationDate">
          {(props) => (
            <Input
              formikName="vehicleRecord.lastVerificationDate"
              label={'Fecha de última verificación'}
              type="date"
              {...props}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={4}>
        <Field name="vehicleRecord.nextVerificationDate">
          {(props) => (
            <Input
              formikName="vehicleRecord.nextVerificationDate"
              label={'Fecha de próxima verificación'}
              type="date"
              {...props}
            />
          )}
        </Field>
      </Grid>
    </>
  );
};

export default VerificationForm;
