import React from 'react';
import './App.css';
import CacheBuster from 'react-cache-buster';
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button } from '@mui/material';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
import BrowserValidator from './Components/BrowserValidator/BrowserValidator';
import ThemeProvider from './Theme';
import AllContextProvider from './Contexts/AllContextsProvider';
import AppRouter from './Screens/Router';
import materialTheme from './materialtheme';
import packageInfo from '../package.json';

function App() {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<h1>Cargando...</h1>}
    >
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CookiesProvider>
            <AllContextProvider>
              <MaterialThemeProvider theme={materialTheme}>
                <ThemeProvider>
                  <SnackbarProvider
                    dense
                    maxSnack={3}
                    ref={notistackRef}
                    action={(key) => (
                      <Button onClick={onClickDismiss(key)} style={{ color: '#FFF' }}>
                        CERRAR
                      </Button>
                    )}
                  >
                    <BrowserValidator />
                    <AppRouter />
                  </SnackbarProvider>
                </ThemeProvider>
              </MaterialThemeProvider>
            </AllContextProvider>
          </CookiesProvider>
        </LocalizationProvider>
      </div>
    </CacheBuster>
  );
}

export default App;
