import React, { useState, useContext } from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { useStyles } from './ClientsScreenStyles';
import NewElementFormulary from './NewElementFormulary/Formulary';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Dialog from '../../Containers/GenericDialog';
import Table from '../../Components/Table';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import { userContext } from '../../Contexts/UserContext/UserContext';

const QuelityLibraryScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();
  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [dialogOpen, setDialogOpen] = useState(false);
  const [elementToEdit, setElementToEdit] = useState(null);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //---------------------------------------------------------------------------

  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === 'RECURSOS HUMANOS')
    .map((grant) => grant.name);
  //---------------------------------------------------------------------------
  // useFetch hooks
  // //---------------------------------------------------------------------------
  const {
    data: clientsElements,
    isLoading,
    refetch,
  } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/client/list`,
  });

  //---------------------------------------------------------------------------
  // Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'Nombre(s)', field: 'clientName' },
    { title: 'ID', field: 'id' },
  ];

  const actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Nuevo Registro
          </Button>
        );
      },
      tooltip: 'New Record',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setDialogOpen(true);
        setElementToEdit(null);
      },
      isFreeAction: true,
      hidden: !allowedModuleSections.includes('EDITAR'),
    },
    {
      icon: 'edit',
      tooltip: 'editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        // getFile(rowElement.key);
        setElementToEdit(rowElement);
        setDialogOpen(true);
      },
      hidden: !allowedModuleSections.includes('EDITAR'),
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setElementToDelete(rowElement);
        setOpenDeleteDialog(true);
      },
      hidden: !allowedModuleSections.includes('EDITAR'),
    },
  ];
  //---------------------------------------------------------------------------
  // Actions
  //---------------------------------------------------------------------------
  const handleDelete = async () => {
    await AxiosInstance.delete(`/api/client/${elementToDelete.id}`);
    refetch();
    setOpenDeleteDialog(false);
    setElementToDelete(null);
  };

  const ClientsTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Table
            title={`Clientes`}
            data={clientsElements}
            columns={TABLE_HEADERS}
            actions={actions}
          />
        </Grid>
      </Grid>
      <Dialog
        openDialog={dialogOpen}
        dialogTitle={elementToEdit ? 'Editar Registro' : 'Nuevo Registro'}
        maxWidth="md"
      >
        <NewElementFormulary
          setOpen={setDialogOpen}
          currentElement={elementToEdit}
          refetchClients={refetch}
        />
      </Dialog>
      {/* ------------------------------------------------------------------------ */}
      {/* --- DELETE CONFIRM DIALOG ---------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}

      <Alert
        type={WARNING_TYPE}
        title="Borrar elemento de catalogo"
        content="Estas a punto de borrar un cliente, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenDeleteDialog(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onBackdropPress={() => {
          setOpenDeleteDialog(false);
        }}
      />
    </>
  );

  const renderScreen = () => {
    if (!isLoading) {
      return ClientsTable;
    } else {
      return <CircularProgress />;
    }
  };

  return <div className={styles.container}>{renderScreen()}</div>;
};

export default withNavigation(QuelityLibraryScreen);
