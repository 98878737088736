import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
// Icons
import ScatterPlot from '@material-ui/icons/ScatterPlot';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ClassIcon from '@material-ui/icons/Class';
import ComputerIcon from '@material-ui/icons/Computer';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import AirplayIcon from '@material-ui/icons/Airplay';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { mapValues } from './formMapper';
import { useStyles } from './materialStyles';
import { useFetch } from './../../../Hooks/useFetch';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
import { getInitialValues, validationSchema } from './validation';
import { useProjectsForCatalogs } from '../../../Hooks/useProjectsForCatalogs';
import { inventoryRouteList } from '../../InventoryScreen/InventoryData';
import Select from '../../../Components/FormFields/FormikSelect';
import Input from '../../../Components/FormFields/FormikIconInput';

function BackupFormulary(props) {
  const {
    setOpen,
    tableConfiguration,
    currentElement,
    refetchInventory,
    setProjectState,
    projectId,
  } = props;
  const styles = useStyles();
  const [clasificationValuesDropdown, setClassificationValuesDropdown] = useState(null);
  const [userDropdownOptions, setUserDropdownOptions] = useState([]);
  const [inventoryTypeOptions, setInventoryTypeOptions] = useState([]);
  const [notSerialNumber, setNotSerialNumberCondition] = useState(false);
  const [supportTableConfiguration, setSupportTableConfiguration] = useState([]);
  const [stockType, setStockType] = useState(
    currentElement?.inventoryType || tableConfiguration?.stockType || null,
  );
  const [originalProjectId, setOriginalProjectId] = useState(null);

  const AxiosInstance = useContext(AxiosContext);

  const { entityType, brandEntityType, modelEntityType } =
    tableConfiguration ||
    inventoryRouteList.find((inventory) => inventory.stockType === stockType) ||
    {};

  const { data: masterData, updateParams: updateMasterDataParams } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/masterData/list',
    initialParams: {
      entityList: [
        brandEntityType,
        entityType,
        modelEntityType,
        'Status Inventario',
        'Conjunto',
        'Almacenes',
      ],
    },
  });
  const { data: userData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/employee/list',
  });

  const projectsInPlatform = useProjectsForCatalogs();

  useEffect(() => {
    updateMasterDataParams({
      entityList: [
        brandEntityType,
        entityType,
        modelEntityType,
        'Status Inventario',
        'Conjunto',
        'Almacenes',
      ],
    });
  }, [stockType]);

  useEffect(() => {
    if (masterData) {
      const valuesForSelectableFields = masterData.reduce((accumulator, currentElement) => {
        const { id: itemValue, value: itemLabel, entity } = currentElement;
        const newElement = { itemValue: itemValue.toString(), itemLabel };
        accumulator[entity] = accumulator[entity]
          ? [...accumulator[entity], newElement]
          : [newElement];
        return accumulator;
      }, {});

      setClassificationValuesDropdown(valuesForSelectableFields);
    }
  }, [masterData]);

  useEffect(() => {
    if (currentElement) {
      const { projects } = currentElement;
      if (projects?.length) {
        setOriginalProjectId(projects[0].id);
      }
    }
  }, [currentElement]);

  useEffect(() => {
    if (userData) {
      const _userDropdownOptions = userData.map((user) => {
        const { names, lastName1, lastName2 } = user;
        const orderName = `${lastName1} ${lastName2}, ${names}`;
        return { itemLabel: orderName, itemValue: `${user.id}`, orderName };
      });
      _userDropdownOptions.sort((itemA, itemB) => (itemA.orderName > itemB.orderName ? 1 : -1));
      setUserDropdownOptions([{ itemLabel: 'N/A', itemValue: '-1' }, ..._userDropdownOptions]);
    }
  }, [userData]);

  useEffect(() => {
    if (projectId) {
      const temp = inventoryRouteList.map((element) => {
        return {
          itemLabel: element.elementName,
          itemValue: element.stockType,
        };
      });
      setInventoryTypeOptions(temp);
    }
  }, []);

  const yesNoArray = [
    {
      itemLabel: 'SI',
      itemValue: 'SI',
    },
    {
      itemLabel: 'NO',
      itemValue: 'NO',
    },
  ];

  const handlePost = async (values) => {
    const payload = mapValues(values, {
      projectId,
      tableConfiguration,
      currentElement,
      notSerialNumber,
    });

    const deleteInventoryFromProject = !payload?.projects?.length;
    const method = currentElement ? 'put' : 'post';
    const result = await AxiosInstance[method]('/api/stock', payload);
    if (projectId) {
      const newDataRes = await AxiosInstance.post(
        `/api/project/${projectId}/stock/${result.data.id}`,
      );
      const { data: newProjectValues } = newDataRes;
      setProjectState(newProjectValues);
    }
    if (deleteInventoryFromProject && originalProjectId !== null) {
      const stockDeleteResult = await AxiosInstance.delete(
        `/api/project/${originalProjectId}/stock/${result.data.id}`,
      );
      const { data: newProjectValues } = stockDeleteResult;
      if (projectId) {
        setProjectState(newProjectValues);
      }
    }
    if (!deleteInventoryFromProject) {
      const { id: newProjectId } = (payload?.projects || [])[0];
      const { data: stockAddingResult } = await AxiosInstance.post(
        `/api/project/${newProjectId}/stock/${result.data.id}`,
      );
      if (projectId) {
        setProjectState(stockAddingResult);
      }
    }
    await refetchInventory();
  };

  const handleNotSerialNumber = (event) => {
    setNotSerialNumberCondition(event.target.checked);
  };

  const handleTypeChange = (values) => {
    if (projectId && values.inventoryType) {
      let temp = inventoryRouteList.filter((element) => {
        return element.stockType === values.inventoryType;
      });
      setSupportTableConfiguration(temp[0]);
    }
  };

  return clasificationValuesDropdown ? (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentElement, {
          tableConfiguration,
          projectId,
        })}
        handleChange={() => handleTypeChange()}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, setFieldValue, isSubmitting, values }) => {
          return (
            <Form className={styles.formulary} onChange={handleTypeChange(values)}>
              <Grid container alignItems="center" alignContent="center" spacing={6}>
                <Grid item xs={12} md={6}>
                  {!projectId && (
                    <Field name="inventoryType">
                      {(props) => (
                        <Input
                          formikName={'inventoryType'}
                          label={'Tipo de inventario'}
                          Icon={PostAddIcon}
                          props={props}
                          disabled
                        />
                      )}
                    </Field>
                  )}
                  {projectId && (
                    <Field name="inventoryType">
                      {(props) => (
                        <Select
                          formikName={'inventoryType'}
                          label={'Tipo de inventario'}
                          Icon={PostAddIcon}
                          menuItemList={inventoryTypeOptions || []}
                          props={props}
                          customOnChange={(event) => setStockType(event.target.value)}
                        />
                      )}
                    </Field>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="clasification">
                    {(props) => (
                      <Select
                        formikName={'clasification'}
                        label={'Clasificación'}
                        Icon={ClassIcon}
                        menuItemList={
                          (clasificationValuesDropdown || {})[
                            (!projectId
                              ? tableConfiguration?.entityType
                              : supportTableConfiguration?.entityType) || 'na'
                          ] || []
                        }
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="equipment">
                    {(props) => (
                      <Input
                        formikName={'equipment'}
                        label={'Equipo'}
                        Icon={ComputerIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="description">
                    {(props) => (
                      <Input
                        formikName={'description'}
                        label={'Descripción'}
                        Icon={DescriptionIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                {(stockType === 'VEHICULOS' || stockType === 'Vehículos') && (
                  <Grid item xs={12} md={6}>
                    <Field name="plate">
                      {(props) => (
                        <Input
                          formikName={'plate'}
                          label={'Placas'}
                          Icon={DescriptionIcon}
                          props={props}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Field name="brand">
                    {(props) => (
                      <Select
                        formikName={'brand'}
                        label={'Marca'}
                        Icon={DesktopMacIcon}
                        menuItemList={
                          (clasificationValuesDropdown || {})[
                            (!projectId
                              ? tableConfiguration?.brandEntityType
                              : supportTableConfiguration?.brandEntityType) || 'na'
                          ] || []
                        }
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  {(clasificationValuesDropdown || {})[
                    (!projectId
                      ? tableConfiguration?.modelEntityType
                      : supportTableConfiguration?.modelEntityType) || 'na'
                  ]?.length && (
                    <Field name="model">
                      {(props) => (
                        <Select
                          formikName={'model'}
                          label={'Modelo'}
                          Icon={AirplayIcon}
                          menuItemList={
                            (clasificationValuesDropdown || {})[
                              (!projectId
                                ? tableConfiguration?.modelEntityType
                                : supportTableConfiguration?.modelEntityType) || 'na'
                            ] || []
                          }
                          props={props}
                        />
                      )}
                    </Field>
                  )}
                </Grid>
                <Grid item xs={12} md={notSerialNumber ? 10 : 5}>
                  <Field name="inventoryNumber">
                    {(props) => (
                      <Input
                        formikName={'inventoryNumber'}
                        label={'# de Inventario'}
                        Icon={ScatterPlot}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                {!notSerialNumber && (
                  <Grid item xs={12} md={5}>
                    <Field name="serialNumber">
                      {(props) => (
                        <Input
                          formikName={'serialNumber'}
                          label={'# Serie'}
                          Icon={ScatterPlot}
                          props={props}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={notSerialNumber} onChange={handleNotSerialNumber} />
                    }
                    label="N/A"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="responsable">
                    {(props) => (
                      <Select
                        formikName={'responsable'}
                        label={'Responsable'}
                        Icon={PersonIcon}
                        menuItemList={userDropdownOptions || []}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="status">
                    {(props) => (
                      <Select
                        formikName={'status'}
                        label={'Status'}
                        Icon={EqualizerIcon}
                        menuItemList={clasificationValuesDropdown['Status Inventario'] || []}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="set">
                    {(props) => (
                      <Select
                        formikName={'set'}
                        label={'Conjunto'}
                        Icon={GroupWorkIcon}
                        menuItemList={clasificationValuesDropdown['Conjunto'] || []}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="project">
                    {(props) => (
                      <Select
                        formikName={'project'}
                        label={'Proyecto'}
                        Icon={GroupWorkIcon}
                        menuItemList={projectsInPlatform}
                        customOnChange={(event) => {
                          if (event.target.value) {
                            setFieldValue('warehouse', 877);
                          }
                        }}
                        disabled={!!projectId}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="warehouse">
                    {(props) => (
                      <Select
                        formikName={'warehouse'}
                        label={'Almacen'}
                        Icon={GroupWorkIcon}
                        menuItemList={clasificationValuesDropdown['Almacenes'] || []}
                        props={props}
                        disabled={values?.project >= 0}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="nickname">
                    {(props) => (
                      <Input
                        formikName={'nickname'}
                        label={'Apodo'}
                        Icon={ChromeReaderModeIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="EMA">
                    {(props) => (
                      <Select
                        formikName={'EMA'}
                        label={'EMA'}
                        Icon={ScatterPlot}
                        menuItemList={yesNoArray || []}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="AMAAC">
                    {(props) => (
                      <Select
                        formikName={'AMAAC'}
                        label={'AMAAC'}
                        Icon={ScatterPlot}
                        menuItemList={yesNoArray || []}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    {currentElement ? 'Guardar cambios' : 'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  ) : (
    <></>
  );
}

export default BackupFormulary;
