export const getInitialValues = (currentFilter) => {
  return {
    position: currentFilter?.position || '',
    names: currentFilter?.names || '',
    lastName1: currentFilter?.lastName1 || '',
    lastName2: currentFilter?.lastName2 || '',
    area: currentFilter?.area || '',
    phoneNumber: currentFilter?.phoneNumber || '',
    email: currentFilter?.email || '',
    bankAccountNumber: currentFilter?.bankAccountNumber || '',
    bankName: currentFilter?.bankName || '',
    curp: currentFilter?.curp || '',
    degree: currentFilter?.degree || '',
    jobStartDate: currentFilter?.jobStartDate || '',
    professionalLicense: currentFilter?.professionalLicense || '',
    rfc: currentFilter?.rfc || '',
    socialSecurityNumber: currentFilter?.socialSecurityNumber || '',
    company: currentFilter?.company || '',
    project: currentFilter?.project || '',
  };
};
