import React, { useState, useContext } from 'react';
import moment from 'moment';
import { Button, Grid } from '@material-ui/core';
import { useStyles } from './DownloadLogScreenStyles';
import FilterFormulary from './FilterFormulary/Formulary';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Dialog from '../../Containers/GenericDialog';
import Table from '../../Components/Table';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { FILE_DOWNLOADED } from '../../Constants/auditEventsConstants';

const QuelityLibraryScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------
  const [currentFilter, setCurrentFilter] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { data: downloadEvents } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/audit/events',
    initialParams: {
      events: FILE_DOWNLOADED,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapData = (downloadEvent) => {
    return {
      ...downloadEvent,
      timestamp: moment(new Date(downloadEvent.timestamp)).format('DD/MM/YYYY - hh:mm A'),
      email: downloadEvent.user.username,
      username: downloadEvent.user.name,
    };
  };

  const filterData = (downloadEvent) => {
    if (!currentFilter) {
      return true;
    }
    const basicFilter = { ...currentFilter };
    delete basicFilter.fecha1;
    delete basicFilter.fecha2;
    const conditions = {};
    conditions.basicConditions = Object.keys(basicFilter).reduce((prev, curr) => {
      return (
        (basicFilter[curr]
          ? downloadEvent[curr].toLowerCase() === basicFilter[curr].toLowerCase()
          : true) && prev
      );
    }, true);

    const date1 = currentFilter.fecha1 && new Date(currentFilter.fecha1);
    const date2 = currentFilter.fecha2 && new Date(currentFilter.fecha2);
    const downloadDate = new Date(downloadEvent.timestamp);

    conditions.conditionDate1 = date1 ? downloadDate > date1 : true;
    conditions.conditionDate2 = date2 ? downloadDate < date2 : true;

    return Object.keys(conditions).reduce((p, c) => conditions[c] && p, true);
  };

  //---------------------------------------------------------------------------
  // Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'Archivo', field: 'param' },
    { title: 'Fecha de descarga', field: 'timestamp' },
    { title: 'Descargado por', field: 'username' },
    { title: 'Correo', field: 'email' },
  ];
  const actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            {!currentFilter ? 'Filtrar Registro' : 'Editar Filtro'}
          </Button>
        );
      },
      tooltip: 'Upload files',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setDialogOpen(true);
      },
      isFreeAction: true,
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Eliminar Filtro
          </Button>
        );
      },
      tooltip: 'Upload files',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setCurrentFilter(null);
      },
      isFreeAction: true,
      hidden: !currentFilter,
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Descargar Archivo
          </Button>
        );
      },
      tooltip: 'Upload files',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        const toCSV = (json) => {
          json = Object.values(json);
          let csv = '';
          let keys = (json[0] && Object.keys(json[0])) || [];
          csv += `${keys.join(',')}\n`;
          for (let line of json) {
            csv += `${keys.map((key) => line[key]).join(',')}\n`;
          }
          return csv;
        };

        const csv = toCSV(
          tableData.map((element) => ({
            id: element.id,
            archivo: element.param,
            fecha: element.timestamp,
            usuario: element.username,
          })),
        );

        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`);
        element.setAttribute('download', 'report.csv');
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
      },
      isFreeAction: true,
    },
  ];
  //---------------------------------------------------------------------------

  const tableData = (downloadEvents || []).filter(filterData).map(mapData);

  const AllFoldersTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Table
            title="Registro de descargas"
            columns={TABLE_HEADERS}
            data={tableData}
            actions={actions}
          />
        </Grid>
      </Grid>
      <Dialog openDialog={dialogOpen} dialogTitle={'Filtro de busqueda'} maxWidth="md">
        <FilterFormulary
          setOpen={setDialogOpen}
          downloadEvents={downloadEvents}
          setCurrentFilter={setCurrentFilter}
          currentFilter={currentFilter}
        />
      </Dialog>
    </>
  );

  return <div className={styles.container}>{AllFoldersTable}</div>;
};

export default withNavigation(QuelityLibraryScreen);
