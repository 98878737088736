import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

function Table(props) {
  const { options, style, editable, cellEditable } = props;
  return (
    <MaterialTable
      toolbar
      title={props.title}
      isLoading={props.isLoading}
      components={props.components}
      columns={props.columns}
      data={props.data}
      actions={props.actions}
      onRowClick={props.onRowClick}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 15, 25],
        sorting: true,
        ...options,
      }}
      style={style}
      editable={editable}
      cellEditable={cellEditable}
    />
  );
}

Table.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  actions: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Table;
