import { NO_PROJECT } from '../../../Hooks/useProjectsForCatalogs';

export const mapValues = (values, otherValues) => {
  const { projectId, tableConfiguration, currentElement, notSerialNumber } = otherValues;

  const {
    AMAAC,
    EMA,
    brand,
    clasification,
    description,
    equipment,
    inventoryNumber,
    model,
    nickname,
    responsable,
    serialNumber,
    warehouse,
    set,
    inventoryType,
    status,
    project,
    plate,
  } = values;

  const result = {
    plate,
    amaac: AMAAC,
    brand: {
      id: Number(brand),
    },
    classification: {
      id: Number(clasification),
    },
    description,
    ema: EMA,
    equipment,
    group: {
      id: Number(set),
    },
    model: {
      id: Number(model),
    },
    nickname,
    responsible:
      Number(responsable) < 0
        ? null
        : {
            id: Number(responsable),
          },
    serialNumber: notSerialNumber ? null : serialNumber,
    status: {
      id: Number(status),
    },
    warehouse: {
      id: Number(warehouse),
    },
    stockId: inventoryNumber,
    stockType: !projectId ? tableConfiguration.stockType : inventoryType,
  };

  if (projectId) {
    result.projects = [{ id: projectId }];
  } else {
    result.projects = project === NO_PROJECT ? [] : [{ id: project }];
  }

  // haciendo put
  if (currentElement) {
    result.id = currentElement.id;
  }
  return result;
};
