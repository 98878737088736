import { useContext, useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const NO_USER = 'NO_USER';

export const useUsersForCatalogs = () => {
  const AxiosInstance = useContext(AxiosContext);
  const [options, setOptions] = useState([]);

  const { data: usersInPlatform } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/user/list`,
  });

  const mapUser = (user) => ({
    itemLabel: user.username,
    itemValue: `${user.id}`,
  });

  const noUser = {
    itemLabel: 'Sin usuario',
    itemValue: NO_USER,
  };

  useEffect(() => {
    if (usersInPlatform) {
      const selectableOptions = usersInPlatform.map(mapUser);
      setOptions([noUser, ...selectableOptions]);
    }
  }, [usersInPlatform]);

  return options;
};
