export const ROSHARE_GRANTS = {
  INVENTARIOS: {
    MAIN_MODULE: 'INVENTARIOS',
    VER_EQUIPO_DE_COMPUTO: 1,
    EDITAR_EQUIPO_DE_COMPUTO: 2,
    VER_EQUIPO_DE_OFICINA: 3,
    EDITAR_EQUIPO_DE_OFICINA: 4,
    VER_MOBILIARIO: 5,
    EDITAR_MOBILIRARIO: 6,
    VER_AUSCULTACION_DE_INFRAESTRUCTURA: 7,
    EDITAR_AUSCULTACION_DE_INFRAESTRUCTURA: 8,
    VER_INGENIERIA_DE_TRANSITO: 9,
    EDITAR_INGENIERIA_DE_TRANSITO: 10,
    VER_AUSCULTACION_DE_LA_ESTRUCTURA: 11,
    EDITAR_AUSCULTACION_DE_LA_ESTRUCTURA: 12,
    VER_GEOLOGIA_GEOFISICA_Y_GEORADAR: 13,
    EDITAR_GEOLOGIA_GEOFISICA_Y_GEORADAR: 14,
    VER_GEOTECNIA: 15,
    EDITAR_GEOTECNIA: 16,
    VER_TOPOGRAFIA: 17,
    EDITAR_TOPOGRAFIA: 18,
    VER_DRONES: 19,
    EDITAR_DRONES: 20,
    VER_LABORATORIO: 21,
    EDITAR_LABORATORIO: 22,
    VER_MAQUINARIA_PESADA_Y_ESPECIAL: 23,
    EDITAR_MAQUINARIA_PESADA_Y_ESPECIAL: 24,
    VER_MAQUINARIA_LIGERA: 25,
    EDITAR_MAQUINARIA_LIGERA: 26,
    VER_HERRAMIENTA_MENOR: 27,
    EDITAR_HERRAMIENTA_MENOR: 28,
    VER_VEHICULOS: 29,
    EDITAR_VEHICULOS: 30,
    VER_CSV: 98,
    EDITAR_EQUIPOS_DE_SEGURIDAD: 104,
    VER_EQUIPOS_DE_SEGURIDAD: 105,
  },
  PROYECTOS: {
    MAIN_MODULE: 'PROYECTOS',
    ACCESO_BASE: 31,
    VER_TODOS_LOS_PROYECTOS: 62,
    EDITAR_PROYECTOS: 63,
    ELIMINAR_PROYECTOS: 64,
    CREAR_PROYECTOS: 68,
    ACCESO_GLOBAL_A_PERMISOS_DE_PROYECTO: 69,
  },
  RECURSOS_HUMANOS: {
    MAIN_MODULE: 'RECURSOS HUMANOS',
    VER: 43,
    EDITAR: 44,
    VER_ARCHIVOS: 60,
    VER_ARCHIVOS_RESTRINGIDOS: 61,
  },
  DATOS_CATALOGOS: {
    MAIN_MODULE: 'DATOS CATALOGOS',
    ACCESO: 45,
  },
  USUARIOS: {
    MAIN_MODULE: 'USUARIOS',
    ACCESO: 46,
    EDITAR_SISTEMAS_DE_GESTION: 71,
    EDICION_DE_RECURSOS_HUMANOS: 73,
    VER_PERFIL_DE_USUARIOS: 78,
  },
  COBROS: {
    MAIN_MODULE: 'COBROS',
    ACCESO: 47,
  },
  REGISTRO_DESCARGAS: {
    MAIN_MODULE: 'REGISTRO DESCARGAS',
    VER: 48,
    DESCARGAR: 49,
  },
  HISTORICOS: {
    MAIN_MODULE: 'HISTORICOS',
    CREAR_PROYECTO: 50,
    CARGAR_ARCHIVOS: 51,
    DESCARGAR: 52,
    ELIMINAR_ARCHIVOS: 53,
  },
  PERFILES: {
    MAIN_MODULE: 'PERFILES',
    ACCESO: 58,
  },
  CLIENTES: {
    MAIN_MODULE: 'CLIENTES',
    VER_CLIENTES: 65,
    EDITAR_CLIENTES: 66,
    ELIMINAR_CLIENTES: 67,
  },
  BIBLIOTECA_DE_CALIDAD: {
    MAIN_MODULE: 'BIBLIOTECA DE CALIDAD',
    SUBIR_ARCHIVOS: 74,
    DESCARGAR_ARCHIVOS: 75,
    LECTURA_DOCUMENTOS_BIBILIOTECA_DE_CALIDAD: 76,
    CREAR_NUEVA_CARPETA_Y_SUBCARPETAS: 77,
    ELIMINAR_ARCHIVOS: 100,
  },
  COSTOS: {
    MAIN_MODULE: 'COSTOS',
    CONSULTAR: 79,
    AGREGAR_Y_EDITAR: 80,
  },
  CARRITO_DE_COMPRAS: {
    MAIN_MODULE: 'CARRITO DE COMPRAS',
    ACCESO_GENERAL: 81,
    HISTORICOS: 82,
    CREAR_CARRITO: 85,
  },
  MASTER: {
    MAIN_MODULE: 'MASTER',
    MASTER: 83,
  },
  ADMINISTRATIVO: {
    MAIN_MODULE: 'ADMINISTRATIVO',
    CIERRE_DE_EMERGENCIA: 84,
  },
  SOLICITUDES_DE_FONDOS: {
    MAIN_MODULE: 'SOLICITUDES DE FONDOS',
    APROBAR_MONTOS: 87,
  },
  SISTEMAS_DE_GESTION: {
    MAIN_MODULE: 'SISTEMAS DE GESTION',
    LECTURA_DE_LISTA_MAESTRA_GENERALES: 88,
    LECTURA_DE_LISTA_MAESTRA_RESTRINGIDOS: 89,
    DESCARGAR_ARCHIVOS: 90,
    ASIGNAR_ACCESO_A_LOS_DOCUMENTOS: 91,
    VISUALIZAR_TODAS_LAS_VERSIONES_DE_LOS_ARCHIVOS: 92,
    SUBIR_ARCHIVOS_A_LA_LISTA_MAESTRA: 93,
    ACTUALIZAR_VERSIONES: 94,
    CREAR_PERFILES_DE_SISTEMAS_DE_GESTION: 95,
    ELIMINAR_ARCHIVO_DE_LISTA_MAESTRA: 101,
    EDITAR_PARAMETROS_DE_ARCHIVO_DE_LISTA_MAESTRA: 102,
    VER_HISTORIAL_DE_VERSIONES: 103,
  },
  ANUNCIOS: {
    MAIN_MODULE: 'ANUNCIOS',
    SUBIR_ANUNCIOS: 99,
  },
};
