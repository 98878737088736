import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import React from 'react';
import { BACKEND_DATE_FORMAT, validateDateObject } from '../../../../Constants/generalConstants';

const EstimationDetails = ({ estimation, onEdit }) => {
  const { estimationTotal, creationDate, contractNumber, period, convenio } = estimation || {};
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Box borderBottom="solid #A0A0A0 1px" padding="5px" borderRadius="5px">
          <Typography variant="body1">Monto: ${Number(estimationTotal).toFixed(2)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box borderBottom="solid #A0A0A0 1px" padding="5px" borderRadius="5px">
          <Typography variant="body1">
            Fecha de creación: {format(validateDateObject(creationDate), BACKEND_DATE_FORMAT)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box borderBottom="solid #A0A0A0 1px" padding="5px" borderRadius="5px">
          <Typography variant="body1">Número de estimación: {contractNumber}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box borderBottom="solid #A0A0A0 1px" padding="5px" borderRadius="5px">
          <Typography variant="body1">Periodo: {period}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box borderBottom="solid #A0A0A0 1px" padding="5px" borderRadius="5px">
          <Typography variant="body1">Contrato o convenio al que pertenece: {convenio}</Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
      >
        <Button variant="outlined" onClick={onEdit}>
          Editar
        </Button>
      </Grid>
    </Grid>
  );
};

export default EstimationDetails;
