import React from 'react';
import { CellText } from './SpreadSheetStyles';

const Row = ({ row, fixed, header }) => {
  if (!row) {
    return null;
  }

  const { index: rowIndex, rowCells } = row;

  const moneyFormat = (text) => {
    const amount = Number(text);
    return Number.isNaN(amount) ? text : `$ ${amount.toFixed(2)}`;
  };

  return rowCells.map((text, index, array) => {
    return (
      <CellText
        column={index + 1}
        row={0}
        key={`${rowIndex}${index}`}
        fixed={fixed}
        header={header}
        empty={!`${text ?? ''}`}
        last={index === array.length - 1}
      >
        {moneyFormat(text ?? '-')}
      </CellText>
    );
  });
};

export default Row;
