import { Button, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getInitialValues, validationSchema } from './validation';
import { useStyles } from './materialStyles';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
import Alert, { WARNING_TYPE } from '../../../Components/Alert/Alert';
import { LEER_CONCEPTOS } from '../../../Constants/grantsModules';
import FormikAutocomplete from '../../../Components/AutoComplete/FormikAutocomplete';

const NewProfileFormulary = (props) => {
  const { users, setOpen, refetchProject } = props;
  const styles = useStyles();
  const AxiosInstance = useContext(AxiosContext);
  const [noNameWarningVisibility, setNoNameWarningVisibility] = useState(false);

  let { project } = useParams();
  const getClientesForSelect = () => {
    return (users || [])
      .map((user) => {
        const { name, id } = user;
        return { itemValue: id, itemLabel: name };
      })
      .sort((a, b) => (a.itemLabel > b.itemLabel ? 1 : -1));
  };

  const handlePost = async (values) => {
    const { user } = values;
    await AxiosInstance.post(`/api/project/${project}/grant`, {
      grantName: LEER_CONCEPTOS,
      user: {
        id: user,
      },
    });
    setOpen(false);
    refetchProject();
  };

  return (
    <>
      <Grid container>
        <Formik
          initialValues={getInitialValues()}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            handlePost(values);
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, setFieldTouched, isSubmitting }) => {
            return (
              <Form className={styles.formulary}>
                <Grid container alignItems="center" justify="space-between" spacing={6}>
                  <Grid item xs={12}>
                    <Field name="user">
                      {(props) => (
                        <FormikAutocomplete
                          label={'Usuario'}
                          options={getClientesForSelect()}
                          formikName={'user'}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          {...props}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setOpen(false);
                      }}
                      disabled={isSubmitting}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        // setNoNameWarningVisibility(!isValid);
                      }}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        {/* ------------------------------------------------------------------------ */}
        {/* --- NO NAME WARNING ---------------------------------------------- */}
        {/* ------------------------------------------------------------------------ */}

        <Alert
          type={WARNING_TYPE}
          title="Imposible crear perfil sin nombre"
          content="Estas intentando crear un perfil sin nombre, esto no es posible"
          actions={[
            {
              text: 'OK',
              onClick: () => {
                setNoNameWarningVisibility(false);
              },
            },
          ]}
          open={noNameWarningVisibility}
          onClose={() => setNoNameWarningVisibility(false)}
          onBackdropPress={() => {
            setNoNameWarningVisibility(false);
          }}
        />
      </Grid>
    </>
  );
};

export default NewProfileFormulary;
