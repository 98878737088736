import React, { useState, useContext } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { useStyles } from './QualityLibraryScreenStyles';
import { QUALITY_LIBRARY_BUCKET } from './../../Constants/urls';
import NewFolderFormulary from './NewFolderFormulary/Formulary';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Dialog from '../../Containers/GenericDialog';
import DialogActions from '../../Containers/GenericDialog/DialogActions';
import Table from '../../Components/Table';
import { useFetch } from '../../Hooks/useFetch';
import Dropzone from '../../Components/DropZone';
import { FILE_TYPE, FOLDER_TYPE } from '../../Constants/backupScreenConstants';
import { deleteFile } from '../../Utils/s3Utils';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import { userContext } from '../../Contexts/UserContext/UserContext';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { handleDownload } from '../../Utils/QualityFilesUtils';
import { CALIDAD } from '../../Constants/grantsModules';
import { ROSHARE_GRANTS } from '../../.gen/grantsAndModules.generated';

const QuelityLibraryScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const history = useHistory();
  let location = useLocation();
  const [userState] = useContext(userContext);
  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [path, setPath] = useState(() => {
    return (
      location?.pathname?.replace('/biblioteca-calidad/', '').replace('/biblioteca-calidad', '') ||
      ''
    );
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [newFileDialogOpen, setNewFileDialogOpen] = useState(false);
  const [deleteAlertOpen, setDeleteOpenAlert] = useState(false);
  const [downloadAlertOpen, setDownloadOpenAlert] = useState(false);
  const [fileToDownload, setFileToDownload] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  //---------------------------------------------------------------------------

  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === CALIDAD)
    .map((grant) => grant.id);

  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { data, isLoading, updateParams, refetch } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: {
      ...(path ? { path } : undefined),
      bucket: QUALITY_LIBRARY_BUCKET,
    },
  });

  const { folders = [], files = [] } = data || {};
  //---------------------------------------------------------------------------
  // Mapping functions for files and folders
  //---------------------------------------------------------------------------
  const folderElements = folders.map((folder) => ({
    ...folder,
    name: folder.key
      .split('/')
      .reduce((p, c) => {
        return c || p;
      }, 'x')
      .replace(/_/g, ' ')
      .toUpperCase(),
    type: FOLDER_TYPE,
    lastModified: moment(folder.lastModified).format('DD/MM/YYYY, h:mm:ss a'),
  }));
  const fileElements = files.map((file) => ({
    ...file,
    name: file.key.split('/').reduce((p, c) => {
      return c || p;
    }, 'x'),
    type: FILE_TYPE,
    lastModified: moment(file.lastModified).format('DD/MM/YYYY, h:mm:ss a'),
  }));
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------
  const handleDelete = () => {
    deleteFile(fileToDelete.key, 'roshare-qualitylibrary', () => {
      setDeleteOpenAlert(false);
      refetch();
    });
  };
  //---------------------------------------------------------------------------
  // Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'Nombre', field: 'name' },
    { title: 'Ultimo cambio', field: 'lastModified' },
  ];
  const actions = [
    (rowData) => ({
      icon: 'cloud_download',
      tooltip: 'Download',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setFileToDownload(rowElement);
        setDownloadOpenAlert(true);
      },
      hidden:
        rowData.type === FOLDER_TYPE ||
        !allowedModuleSections.includes(ROSHARE_GRANTS.BIBLIOTECA_DE_CALIDAD.DESCARGAR_ARCHIVOS),
    }),
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Cargar Archivo
          </Button>
        );
      },
      tooltip: 'Upload files',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setNewFileDialogOpen(true);
      },
      isFreeAction: true,
      // hidden: false,
      hidden:
        !path ||
        !allowedModuleSections.includes(ROSHARE_GRANTS.BIBLIOTECA_DE_CALIDAD.SUBIR_ARCHIVOS),
    },
    (rowData) => ({
      icon: 'delete',
      tooltip: 'Delete',
      hidden:
        rowData.type === FOLDER_TYPE ||
        !allowedModuleSections.includes(ROSHARE_GRANTS.BIBLIOTECA_DE_CALIDAD.ELIMINAR_ARCHIVOS),
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setFileToDelete(rowData);
        setDeleteOpenAlert(true);
      },
    }),
  ];
  //---------------------------------------------------------------------------

  const getButton = () => {
    return (
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={3}>
        {path && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => {
                const tempPathArray = path?.split('/');
                while (!tempPathArray.pop());
                const newPath = tempPathArray.join('/');
                setPath(newPath);
                updateParams({
                  path: newPath,
                  bucket: QUALITY_LIBRARY_BUCKET,
                });
                history.push(`/biblioteca-calidad/${newPath}`);
              }}
            >
              Regresar
            </Button>
          </Grid>
        )}

        {allowedModuleSections.includes(
          ROSHARE_GRANTS.BIBLIOTECA_DE_CALIDAD.CREAR_NUEVA_CARPETA_Y_SUBCARPETAS,
        ) && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
              Nueva Carpeta
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const AllFoldersTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>{getButton()}</Grid>
        <Grid item>
          <Table
            title="Biblioteca de Calidad"
            columns={TABLE_HEADERS}
            data={[...folderElements, ...fileElements]}
            actions={actions}
            onRowClick={(event, rowElement) => {
              if (!isLoading) {
                if (rowElement.type === FOLDER_TYPE) {
                  history.push(`/biblioteca-calidad/${rowElement.key}`);
                  setPath(rowElement.key);
                  updateParams({
                    path: rowElement.key,
                    bucket: QUALITY_LIBRARY_BUCKET,
                  });
                } else {
                  setFileToDownload(rowElement);
                  setDownloadOpenAlert(true);
                }
              }
            }}
          />
        </Grid>
      </Grid>
      <Dialog openDialog={dialogOpen} dialogTitle={'Nueva carpeta'} maxWidth="md">
        <NewFolderFormulary
          folders={folders}
          setOpen={setDialogOpen}
          path={path}
          refetch={refetch}
        />
      </Dialog>
      <Dialog openDialog={newFileDialogOpen} dialogTitle={'Subir archivos'} maxWidth="md">
        <Dropzone
          path={path}
          closeDialog={() => setNewFileDialogOpen(false)}
          refetch={refetch}
          bucket="roshare-qualitylibrary"
        />
        <DialogActions
          secondaryLabel="Cancelar"
          primaryLabel={null}
          secondaryAction={() => setNewFileDialogOpen(false)}
        />
      </Dialog>
      <Alert
        type={WARNING_TYPE}
        title="Eliminar"
        content="Estas por eliminar un archivo del sistema, ¿estás seguro de que deseas continuar?"
        actions={[
          {
            text: 'ELIMINAR',
            onClick: handleDelete,
          },
        ]}
        open={deleteAlertOpen}
        onClose={() => setDeleteOpenAlert(false)}
        onBackdropPress={() => {
          setDeleteOpenAlert(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Registro de descarga"
        content="Estás por descargar un archivo del sistema, recuerda que esta acción dejará un registro en el sistema"
        actions={[
          {
            text: 'DESCARGAR',
            onClick: () => handleDownload(fileToDownload, AxiosInstance, setDownloadOpenAlert),
          },
        ]}
        open={downloadAlertOpen}
        onClose={() => setDownloadOpenAlert(false)}
        onBackdropPress={() => {
          setDownloadOpenAlert(false);
        }}
      />
    </>
  );

  return <div className={styles.container}>{AllFoldersTable}</div>;
};

export default withNavigation(QuelityLibraryScreen);
