import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import CustomDropzone from '../../Components/DropZone/Dropzone';
import { NEWSLETTER_DASHBOARD_BUCKET } from '../../Constants/urls';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { useFetch } from '../../Hooks/useFetch';
import { deleteFile } from '../../Utils/s3Utils';

const HomeScreenConfig = () => {
  const [fileToDelete, setFileToDelete] = React.useState(null);

  const { data, refetch: refetchFiles } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: {
      bucket: NEWSLETTER_DASHBOARD_BUCKET,
    },
  });

  const tableActions = [
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setFileToDelete(rowElement);
      },
      // hidden: !allowedModuleSections.includes("EDITAR"),
    },
  ];

  const handleDeleteFile = () => {
    deleteFile(fileToDelete.key, NEWSLETTER_DASHBOARD_BUCKET, () => {
      setFileToDelete(null);
      refetchFiles();
    });
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h2>Foto del anuncio de la página principal</h2>
        </Grid>
        <Grid item xs={12}>
          <CustomDropzone
            bucket={NEWSLETTER_DASHBOARD_BUCKET}
            multiple={true}
            refetch={refetchFiles}
          />
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Archivos del carrusel"
            data={data?.files || []}
            actions={tableActions}
            columns={[
              {
                title: 'Archivo',
                field: 'key',
              },
            ]}
            options={{
              pageSizeOptions: [5, 10, 15, 20, 25, 30],
              pageSize: 15,
              actionsColumnIndex: -1,
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
                <Box display="flex" flexDirection="column" height="calc(100vh - 112px)">
                    <Grid container spacing={2} alignItems="stretch" style={{flex:1}} >
                        <Grid item style={{display:"flex", flexDirection:"column"}} md={8}>
                            <NewsletterCard/>
                        </Grid>
                    </Grid>
                </Box>
            </Grid> */}
      </Grid>

      <Alert
        type={WARNING_TYPE}
        title="Borrar un archivo del carrusel"
        content="Estas a punto de borrar un archivo del carrusel, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDeleteFile();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setFileToDelete(null);
            },
          },
        ]}
        open={fileToDelete}
        onClose={() => setFileToDelete(null)}
        onBackdropPress={() => {
          setFileToDelete(null);
        }}
      />
    </>
  );
};

export default withNavigation(HomeScreenConfig);
