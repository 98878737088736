import React from 'react';
import { MarkerOptionDiv, MarkerOptionLabel } from './MarkerOption.styled';

const MarkerOption = ({ label, onClick }) => {
  if (!label) {
    return null;
  }

  return (
    <MarkerOptionDiv
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <MarkerOptionLabel>{label}</MarkerOptionLabel>
    </MarkerOptionDiv>
  );
};

export default MarkerOption;
