import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Field } from 'formik';
import React, { useState } from 'react';
import Input from './../../../../Components/FormFields/FormikIconInput';

const SerialNumberInput = ({ setFieldValue, setFieldTouched }) => {
  const [notSerialNumber, setNotSerialNumberCondition] = useState(false);
  const handleNotSerialNumber = (event) => {
    if (event.target.checked) {
      setFieldValue && setFieldValue('serialNumber', null);
    }
    setFieldTouched && setFieldTouched('serialNumber');
    setNotSerialNumberCondition(event.target.checked);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Field name="serialNumber">
          {(props) => (
            <Input
              formikName={'serialNumber'}
              label={'# Serie'}
              {...props}
              disabled={notSerialNumber}
            />
          )}
        </Field>
        <FormControlLabel
          style={{ marginLeft: '10px' }}
          control={<Checkbox checked={notSerialNumber} onChange={handleNotSerialNumber} />}
          label="N/A"
        />
      </div>
    </>
  );
};

export default SerialNumberInput;
