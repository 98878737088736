import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  createHeaderArray,
  getAccumulatedDifferences,
  getAccumulatedMonthlyExpense,
  getAccumulatedMonthlyPayments,
  getCostsRows,
  getDifferences,
  getMilestonesPerMonth,
  getPaidEstimations,
  getTotalPerMonth,
  getUnpaidEstimations,
} from './ProjectResumeUtils';
import { MainProjectResumeContainer, TableContainer } from './ProjectResumeStyles';
import ProjectResumeHeader from './ProjecResumeHeader/ProjectResumeHeader';
import SpreadSheet from '../../Components/SpreadSheet';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from '../../Hooks/useFetch';
// import currency from 'currency';

export const ProjectResume = () => {
  const AxiosInstance = useContext(AxiosContext);
  const [headers, setHeaders] = useState([]);
  const [totalCostsPerMonth, setTotalCostsPerMonth] = useState([]);
  const [totalAccumulatedMonths, setTotalAccumulatedMonths] = useState([]);
  const [costs, setCosts] = useState([]);
  const [ready, setReady] = useState(false);
  const [milestonesPerMonth, setMilestonesPerMonth] = useState([]);
  const [unpaidEstimations, setUnpaidEstimations] = useState([]);
  const [paidEstimations, setPaidEstimations] = useState([]);
  const [accumulatedPayments, setAccumulatedPayments] = useState([]);
  const [accumulatedDifferences, setAccumulatedDifferences] = useState([]);
  const [differences, setDifferences] = useState([]);
  let { projectId } = useParams();

  const { data: projectData } = useFetch({
    initialUrl: `/api/project/${projectId}`,
    axiosInstance: AxiosInstance,
  });

  useEffect(() => {
    if (projectData) {
      console.log(projectData);
      const { milestones, estimations, projectCosts: rawProjectCosts } = projectData;
      const projectCosts = rawProjectCosts || [];
      const totalsPerMonth = getTotalPerMonth(projectCosts);
      setTotalCostsPerMonth(totalsPerMonth);
      const headers = createHeaderArray(projectCosts);
      setHeaders(headers);
      const costRows = getCostsRows(projectCosts);
      setCosts(costRows);
      const accumulateMonths = getAccumulatedMonthlyExpense(projectCosts);
      setTotalAccumulatedMonths(accumulateMonths);

      const _milestonesPerMonth = getMilestonesPerMonth(milestones, projectCosts || []);
      setMilestonesPerMonth(_milestonesPerMonth);
      const _unpaidEstimations = getUnpaidEstimations(estimations, projectCosts || []);
      setUnpaidEstimations(_unpaidEstimations);
      const _paidEstaimations = getPaidEstimations(estimations, projectCosts || []);
      setPaidEstimations(_paidEstaimations);
      const _accumulatedPayments = getAccumulatedMonthlyPayments(estimations, projectCosts || []);
      setAccumulatedPayments(_accumulatedPayments);
      const _accumulatedDifferences = getAccumulatedDifferences(estimations, projectCosts || []);
      setAccumulatedDifferences(_accumulatedDifferences);
      const _differences = getDifferences(estimations, projectCosts || []);
      setDifferences(_differences);
      setReady(true);
    }
  }, [projectData]);

  // const {section: costsSection, setCostsOffset, setCostVisibleRows} = useResumeSection(costsRows);
  // const {section: totales} = useResumeSection([getTotalFromConceptRow(costsRows)]);
  // const {section: accumulative} = useResumeSection([getAccumulativeTotals(costsRows, headers)],{forcedTotal: Number(totales[0].total.replaceAll("$",''))});

  return ready ? (
    <MainProjectResumeContainer>
      <ProjectResumeHeader projectData={projectData} />
      <TableContainer>
        <SpreadSheet
          data={[
            costs,
            [totalCostsPerMonth, totalAccumulatedMonths],
            [milestonesPerMonth, unpaidEstimations, paidEstimations],
            [accumulatedPayments],
            [differences],
            [accumulatedDifferences],
          ]}
          headers={headers}
        />
      </TableContainer>
    </MainProjectResumeContainer>
  ) : (
    <></>
  );
};

export default withNavigation(ProjectResume);
