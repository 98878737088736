import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const StockResumeCardContainer = styled(Paper)`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 5px;
  margin: 5px;
  width: 25vw;
`;

export const CardTitle = styled.h1`
  font-weight: 200;
  font-size: 2rem;
  border-bottom: 1px solid black;
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
  text-align: center;
`;

export const PropPairContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: solid 1px #f0f0f0;
  margin-top: 5px;
  padding-bottom: 5px;
`;
export const PropLabel = styled.p`
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0;
  margin-right: 20px;
`;
export const PropValue = styled.p`
  font-weight: 200;
  font-size: 1.2rem;
  margin: 0;
  text-align: justify;
`;

export const CardButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px; ;
`;
