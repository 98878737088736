export const getInitialValues = (currentElement) => {
  console.log(currentElement);
  return {
    title: currentElement?.title || '',
    concept: currentElement?.conceptObject?.id || '',
    quantity: currentElement?.quantity || 0,
    description: currentElement?.description || '',
    estimable: currentElement?.estimable || '',
  };
};

export const createFolderPath = (createdEvidence, currentProject) => {
  if (!createdEvidence || !currentProject) {
    return '';
  }
  const { id: projectId } = currentProject;
  const { id: evidenceId } = createdEvidence;

  return `project${projectId}/evidence${evidenceId}/`;
};
