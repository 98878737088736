import React, { useState, useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import { useStyles } from './UserCrudScreenStyles';
import BackupFormulary from './NewElementFormulary/Formulary';
import { AxiosContext } from './../../Contexts/AxiosContext/AxiosContext';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Dialog from '../../Containers/GenericDialog';
import Table from '../../Components/Table';
import { useFetch } from '../../Hooks/useFetch';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import { userContext } from '../../Contexts/UserContext/UserContext';

const BackupScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selfDeleteOpenAlert, setSelfDeleteOpenAlert] = useState(false);
  const [deleteAlertOpen, setDeleteOpenAlert] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  //---------------------------------------------------------------------------
  const [userState] = useContext(userContext);
  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === 'USUARIOS')
    .map((grant) => grant.name);
  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { data: userList, refetch } = useFetch({
    initialUrl: 'api/user/list',
    axiosInstance: AxiosInstance,
  });

  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------
  const deleteUser = async (id, callback) => {
    try {
      await AxiosInstance.delete(`/api/user/${id}`);
      callback && callback();
    } catch (err) {
      alert('No ha sido posible eliminar el usuario');
    }
  };

  const handleDelete = () => {
    deleteUser(userToDelete.id, () => {
      setDeleteOpenAlert(false);
      setUserToDelete(null);
      refetch();
    });
  };

  //---------------------------------------------------------------------------

  const mapElement = (element) => {
    const { profile } = element;

    const mappedElement = {
      ...element,
      profile: profile?.name || '',
      profileObject: profile,
    };
    return mappedElement;
  };
  //---------------------------------------------------------------------------
  // Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'Nombre', field: 'name' },
    { title: 'Usuario', field: 'username' },
  ];

  if (allowedModuleSections.includes('VER PERFIL DE USUARIOS')) {
    TABLE_HEADERS.push({ title: 'Perfil', field: 'profile' });
  }

  const actions = [
    (userData) => ({
      icon: 'delete',
      tooltip: 'Delete',
      iconProps: {
        color: userData.username === userState.username ? 'grey' : 'secondary',
      },
      onClick: () => {
        if (userData.username === userState.username) {
          setSelfDeleteOpenAlert(true);
        } else {
          setUserToDelete(userData);
          setDeleteOpenAlert(true);
        }
      },
      hidden: !allowedModuleSections.includes('EDICION DE RECURSOS HUMANOS'),
    }),
    (userData) => ({
      icon: 'edit',
      tooltip: 'Edit',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setUserToEdit(userData);
        setDialogOpen(true);
      },
      hidden: !allowedModuleSections.includes('EDICION DE RECURSOS HUMANOS'),
    }),
    {
      icon: AssignmentIcon,
      tooltip: 'log de acciones',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowData) => {
        history.push(`/usuarios/log/${rowData.id}`);
      },
      hidden: !allowedModuleSections.includes('EDICION DE RECURSOS HUMANOS'),
    },
    () => ({
      icon: DescriptionIcon,
      tooltip: 'Asignar documentos de calidad',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowData) => {
        history.push(`/usuarios/qa-docs/${rowData.id}`);
      },
      hidden: !allowedModuleSections.includes('EDITAR SISTEMAS DE GESTION'),
    }),
  ];
  //---------------------------------------------------------------------------

  const getButton = () => {
    return allowedModuleSections.includes('EDICION DE RECURSOS HUMANOS') ? (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDialogOpen(true);
            setUserToEdit(null);
          }}
        >
          Nuevo Usuario
        </Button>
      </Grid>
    ) : null;
  };

  const AllFoldersTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>{getButton()}</Grid>
        <Grid item>
          <Table
            title="Respaldos históricos"
            columns={TABLE_HEADERS}
            data={(userList || []).map(mapElement)}
            actions={actions}
            onRowClick={(event, userElement) => {
              console.log(userElement);
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        openDialog={dialogOpen}
        dialogTitle={userToEdit ? 'Editar usuario' : 'Nuevo Usuario'}
        maxWidth="md"
      >
        <BackupFormulary selectedUser={userToEdit} setOpen={setDialogOpen} refetch={refetch} />
      </Dialog>
      <Alert
        type={WARNING_TYPE}
        title="Eliminar"
        content="Estas apunto de eliminar un usuario del sistema, ¿estás seguro de que deseas continuar?"
        actions={[
          {
            text: 'ELIMINAR',
            onClick: handleDelete,
          },
          {
            text: 'CANCELAR',
            onClick: () => {
              setDeleteOpenAlert(false);
            },
          },
        ]}
        open={deleteAlertOpen}
        onClose={() => setDeleteOpenAlert(false)}
        onBackdropPress={() => {
          setDeleteOpenAlert(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Eliminar"
        content="Lo sentimos, pero no es posible que elimines tu propio usuario"
        actions={[
          {
            text: 'OK',
            onClick: () => {
              setSelfDeleteOpenAlert(false);
            },
          },
        ]}
        open={selfDeleteOpenAlert}
        onClose={() => setSelfDeleteOpenAlert(false)}
        onBackdropPress={() => {
          setSelfDeleteOpenAlert(false);
        }}
      />
    </>
  );

  return <div className={styles.container}>{AllFoldersTable}</div>;
};

export default withNavigation(BackupScreen);
