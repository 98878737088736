//AXIOS TO ENDPOINT TO POST VALUE LIST
import queryString from 'query-string';
import { S3_BACKUP_BUCKET, S3_ENDPOINT } from './../Constants/urls';
import axios from './AxiosS3Instance';

export const postNewFile = async (file, fileName, path, extraParams = {}) => {
  const { handleError, handleSuccess, customBucket } = extraParams;
  const uploadParams = await getUploadLink({
    file,
    fileName,
    path,
    bucket: customBucket || S3_BACKUP_BUCKET,
  });

  const { url, body } = uploadParams;

  try {
    const result = await axios.put(url, body);
    if (handleSuccess) {
      handleSuccess(result);
    } else {
      return result;
    }
  } catch (error) {
    console.log(error);
    handleError && handleError(error);
  }
};

export const getFolderFiles = async (path, callback) => {
  const queryObject = {
    bucket: S3_BACKUP_BUCKET,
    path,
  };
  const url = `${S3_ENDPOINT}/list-all-objects?${queryString.stringify(queryObject)}`;

  const { data } = await axios.get(url);
  callback && callback(data);
  return data;
};

export const downloadFile = async (fileKey, bucket, callback) => {
  const queryObject = {
    bucket: bucket || S3_BACKUP_BUCKET,
    key: fileKey,
  };
  const url = `${S3_ENDPOINT}/s3-object/url?${queryString.stringify(queryObject)}`;

  const result = await axios.get(url);
  const { data } = result;
  callback && callback(data);
  return data;
};

export const deleteFile = async (fileKey, bucket, callback) => {
  const queryObject = {
    bucket: bucket || S3_BACKUP_BUCKET,
    key: fileKey,
  };

  const url = `${S3_ENDPOINT}/delete?${queryString.stringify(queryObject)}`;
  const result = await axios.delete(url);
  callback && callback(result);
  return result;
};

export const createFolder = async (name, path, bucket, callback) => {
  const queryObject = {
    bucket: bucket || S3_BACKUP_BUCKET,
    name,
    path,
  };

  const result = await axios.post(`/s3-folder?${queryString.stringify(queryObject)}`);

  callback && callback(result);
  return result;
};

export const getUploadLink = async ({ file, fileName, path, bucket }) => {
  const key = `${path}${fileName}`;
  const params = {
    bucket: bucket || 'respaldo-roshare',
    key,
    filetype: file.type,
  };
  const url = `https://x3v5fdavwf.execute-api.us-east-1.amazonaws.com/beta?${queryString.stringify(
    params,
  )}`;
  const postUrl = await axios.get(url);
  const headers = {
    'Content-Type': params.filetype,
  };
  return { url: postUrl.data, body: file, method: 'put', headers };
};
