import React from 'react';
import queryString from 'query-string';
import s3Axios from './../../../Utils/AxiosS3Instance';
import { userContext } from '../../../Contexts/UserContext/UserContext';
import { useFetch } from '../../../Hooks/useFetch';

const bringAllFoldersData = async (folders, callback) => {
  const newFolders = [];
  for (let i = 0; i < folders.length; i++) {
    const currentFolder = folders[i];
    const { key } = currentFolder;
    const folderName = key
      .split('/')
      .filter((pathElement) => !!pathElement)
      .pop();
    const queryParams = {
      bucket: 'roshare-stock-csv',
      path: key,
    };
    const url = `/list-all-objects?${queryString.stringify(queryParams)}`;
    const { data } = await s3Axios.get(url);
    const { files } = data;

    console.log(files);
    const errorFile = files.find(({ key }) => key.includes('errors.txt'));
    const wrongStockCsvFile = files.find(({ key }) => key.includes('inventariosErroneos.csv'));
    const originalFile = files.find(({ key }) => key.includes('datosOriginales.csv'));

    newFolders.push({
      folderName,
      errorFile,
      wrongStockCsvFile,
      originalFile,
    });
  }
  callback && callback(newFolders);
  return newFolders;
};

export const useValidateFolders = () => {
  const [folderFiles, setFolderFiles] = React.useState([]);
  const [userState] = React.useContext(userContext);

  const { data, refetch } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: {
      bucket: 'roshare-stock-csv',
      path: `resultados/${userState.username}/`,
    },
  });

  React.useEffect(() => {
    if (data?.folders) {
      bringAllFoldersData(data?.folders, (newFolderFiles) => {
        console.log(newFolderFiles);
        setFolderFiles(newFolderFiles);
      });
    }
  }, [data]);

  return { foldersData: folderFiles || [], refetchFolders: refetch };
};
