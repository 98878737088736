import React from 'react';
import {
  DataHeaderTitle,
  DataPairText,
  DataPairResumeContainer,
  MainProjectResumeContainer,
} from './ProjectResumeHeaderStyles';

/**
 * Nombre de obra
 * No. de contrato
 * Monto del contrato sin iva
 * importe del aticipo
 * importe no cobrado
 * periodo de contrato
 */

const ProjectResumeHeader = ({ projectData }) => {
  const { projectName, contractKey, amount, startDate, endDate } = projectData;

  return (
    <MainProjectResumeContainer>
      <DataHeaderTitle>{`${projectName} - ${contractKey}`}</DataHeaderTitle>
      <DataPairResumeContainer>
        <DataPairText>Monto:</DataPairText>
        <DataPairText>{amount.toFixed(2)}</DataPairText>
      </DataPairResumeContainer>
      <DataPairResumeContainer>
        <DataPairText>Fecha de inicio:</DataPairText>
        <DataPairText>{startDate}</DataPairText>
      </DataPairResumeContainer>
      <DataPairResumeContainer>
        <DataPairText>Fecha de termino:</DataPairText>
        <DataPairText>{endDate}</DataPairText>
      </DataPairResumeContainer>
    </MainProjectResumeContainer>
  );
};

export default ProjectResumeHeader;
