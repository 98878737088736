import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Typography } from '@mui/material';
import { useStyles } from './EstimacionesScreenStyles';
import ColumnEditFormulary from './ColumnEditFormulary/Formulary';
import { userContext } from './../../../Contexts/UserContext/UserContext';
import DependencyFeedbackDialog, {
  ESTIMATION_STATUS_DICTIONARY,
} from './DependencyFeedbackDialog/DependencyFeedbackDialog';
import { ALL_ESTIMATION_STATUS, getStatusChange } from './utils/getStatusChanges';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import Table from '../../../Components/Table';
import Dialog from '../../../Containers/GenericDialog';
import { withNavigation } from '../../../Containers/AppNavigation/AppNavigation';
import { ProjectMainTitle } from '../../SingleProjectScreen/SingleProjectScreenStyles';
import { useFetch } from '../../../Hooks/useFetch';
import { FakeButton } from '../../QualityProfilesScreen/QualityProfilesScreenStyles';

const EstimacionesScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);
  const location = useLocation();
  const { pathname } = location;
  const { project: projectId } = useParams();

  //---------------------------------------------------------------------------
  const [userState] = useContext(userContext);
  const { data: projectState, refetch } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `api/project/${projectId}`,
    skip: !projectId,
  });

  //---------------------------------------------------------------------------
  // useState hooks
  //--------------------------------------------------------------------------- setOpenSaveDialog
  const [formularyDialogOpen, setFormularyDialogOpen] = useState(false);
  const [userProjectGrants, setUserProjectGrants] = useState({});
  const [dependencyFeedbackDialogStatus, setDependencyFeedbackDialogStatus] = useState(null);
  const [selectedEstimation, setSelectedEstimation] = useState(null);
  const [columnTableState, setColumnTableState] = useState({
    inventoryType: true,
    clasification: true,
    inventoryNumber: true,
    equipment: true,
    description: true,
    brand: false,
    model: false,
    serialNumber: false,
    status: false,
    responsable: false,
    project: false,
    nickname: false,
    set: false,
    EMA: false,
    AMAAC: false,
  });

  const { userDetails = {} } = userState || {};
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useFetch hooks
  // //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (projectState) {
      const { projectGrants } = projectState;

      let tempGrants = {};
      projectGrants?.forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });
      setUserProjectGrants(tempGrants);
    }
  }, [projectState]);

  //---------------------------------------------------------------------------
  const mapElement = (element) => {
    const { estimationStatus, comments } = element;
    let finalComment = comments;
    let parsedComments;
    try {
      parsedComments = JSON.parse(comments);
      if (Array.isArray(parsedComments)) {
        if (parsedComments.length) {
          const { comment, state } = parsedComments[parsedComments.length - 1];
          finalComment = `${ESTIMATION_STATUS_DICTIONARY[state]}: ${
            comment || 'Sin comentarios en el cambio de estado'
          }`;
        } else {
          finalComment = 'Sin comentarios aún';
        }
      }
    } catch (error) {
      finalComment = comments;
    }
    if (finalComment?.length > 50) {
      finalComment = 'Comentario muy largo para mostrar, ver en detalles';
    }
    const _element = {
      ...element,
      estimationStatusString: ESTIMATION_STATUS_DICTIONARY[estimationStatus],
      comments: finalComment,
      parsedComments,
    };
    return _element;
  };

  //---------------------------------------------------------------------------
  // COMMON Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'ID', field: 'id' },
    { title: 'Periodo', field: 'period' },
    { title: 'No. Contrato', field: 'contractNumber' },
    { title: 'Convenio', field: 'convenio' },
    // { title: 'Comentarios', field: 'comments' },
    { title: 'Total de la estimación', field: 'estimationTotal' },
    { title: 'Fecha de creación', field: 'creationDate' },
    { title: 'Estado', field: 'estimationStatusString' },
  ];

  //---------------------------------------------------------------------------
  // MAIN Table Actions
  //---------------------------------------------------------------------------
  const actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Crear Estimación
          </Button>
        );
      },
      tooltip: 'Create',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        history.push(`${pathname}/crear`);
      },
      isFreeAction: true,
      hidden: !userProjectGrants?.EDIT_ESTIMATIONS?.includes(userDetails?.username),
    },
    {
      icon: 'delete',
      tooltip: 'Eliminar estimación',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        const { id: estimationId } = rowElement;
        const { id: projectId } = projectState;
        await AxiosInstance.delete(`/api/project/${projectId}/estimation/${estimationId}`);
        refetch();
      },
      hidden: !userProjectGrants?.EDIT_ESTIMATIONS?.includes(userDetails?.username),
    },
    {
      icon: 'visibilityIcon',
      tooltip: 'Detalles',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        history.push(`${pathname}/${rowElement.id}`);
      },
    },
    ...ALL_ESTIMATION_STATUS.map((ESTIMATION_STATUS) => {
      const actionFunction = (rowData) => {
        const actionButtons = getStatusChange(rowData.estimationStatus);
        return actionButtons.includes(ESTIMATION_STATUS)
          ? {
              icon: () => {
                return (
                  <FakeButton>
                    <div style={{ width: '60px', padding: '5px' }}>
                      <Typography variant="p">
                        Cambiar a: {ESTIMATION_STATUS_DICTIONARY[ESTIMATION_STATUS]}
                      </Typography>
                    </div>
                  </FakeButton>
                );
              },
              tooltip: 'Marcar esperando factura',
              iconProps: {
                color: 'secondary',
              },
              onClick: (_event, rowElement) => {
                setDependencyFeedbackDialogStatus(ESTIMATION_STATUS);
                setSelectedEstimation(rowElement);
              },
            }
          : null;
      };
      return actionFunction;
    }),
  ];

  const MainEstimacionesTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid container direction="row" justify="flex-starte" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/proyectos/${projectId}`);
              }}
            >
              {'REGRESAR <<'}
            </Button>
          </Grid>
          <Grid item>
            <ProjectMainTitle>
              {`${projectState?.projectId || ''} - ${projectState?.projectName || ''} - ${
                projectState?.contractKey || ''
              }` || 'Proyecto'}
            </ProjectMainTitle>
          </Grid>
        </Grid>

        <Grid item>
          <Table
            title={`Estimaciones`}
            data={projectState?.estimations?.map(mapElement) || []}
            columns={TABLE_HEADERS}
            actions={actions}
            options={{
              search: false,
              filtering: true,
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        openDialog={formularyDialogOpen}
        dialogTitle={'Configuración de Columnas'}
        maxWidth="md"
      >
        <ColumnEditFormulary
          setOpen={setFormularyDialogOpen}
          currentFilter={columnTableState}
          columnFields={columnTableState}
          setColumnFields={setColumnTableState}
        />
      </Dialog>
      <DependencyFeedbackDialog
        dependencyFeedbackOpen={dependencyFeedbackDialogStatus}
        handleDependencyFeedbackClose={() => setDependencyFeedbackDialogStatus(null)}
        nextState={dependencyFeedbackDialogStatus}
        estimation={selectedEstimation}
        refetch={refetch}
      />
    </>
  );

  return <div className={styles.container}>{MainEstimacionesTable}</div>;
};

export default withNavigation(EstimacionesScreen);
