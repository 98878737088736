const theme = {
  primary: {
    main: '#263A57',
  },
  secondary: {
    main: '#b63c53',
  },
  error: {
    main: '#c40505',
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Cambay'].join(','),
  },
};

export default theme;
