import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withNavigation } from './../../Containers/AppNavigation/AppNavigation';
import axios from './../../Utils/AxiosS3Instance';

const FILES_BUCKET = 'roshare-shopping-cart';
const getFile = async (s3DownloadKey) => {
  const signedUrl = (await axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`))
    .data;
  return signedUrl;
};

const ShoppingCartOrderView = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const search = useLocation().search;

  useEffect(() => {
    const pdfKey = new URLSearchParams(search).get('pdfkey');

    const getPdfUrl = async () => {
      const newUrl = await getFile(pdfKey);
      const blob = await fetch(newUrl).then((r) => r.blob());
      // blob.set()
      console.log(blob);
      setPdfUrl(newUrl);
    };

    if (pdfKey && !pdfUrl) {
      getPdfUrl();
    }
  }, [search]);

  return <h1>PDF VIEW</h1>;
};

export default withNavigation(ShoppingCartOrderView);
