import styled from 'styled-components';

export const ProjectCloudScreenMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const PCSHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: solid #f0f0f0 1px;
`;

export const HeaderTitle = styled.h1`
  font-weight: 300;
  margin: 5px;
  font-size: 20px;
`;

export const FolderDiv = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  font-size: 100px;
  margin: 5px;
  padding: 5px;
  &:hover {
    background-color: #f0f0ff;
  }
  &:active {
    background-color: #e0e0ff;
  }
`;

export const FolderLabel = styled.p`
  font-size: 20px;
  margin: 0;
  font-weight: 200;
`;
