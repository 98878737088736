import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { RH_TARGET, STOCK_TARGET } from '../../../Store/Reducer';
import { useCartStateContext } from '../../../Store/CartState';

const TargetButtons = () => {
  const { setSearchTarget, searchTarget } = useCartStateContext();

  return (
    <ToggleButtonGroup
      orientation="vertical"
      color="primary"
      value={searchTarget}
      exclusive
      onChange={(event, newTarget) => {
        setSearchTarget(newTarget);
      }}
    >
      <ToggleButton
        style={{
          fontSize: '10px',
          padding: '4px',
        }}
        value={STOCK_TARGET}
      >
        Inventarios
      </ToggleButton>
      <ToggleButton
        style={{
          fontSize: '10px',
          padding: '4px',
        }}
        value={RH_TARGET}
      >
        RH
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TargetButtons;
