import { Grid, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';
import InventorySearchForm from './InventorySearchForm/InventorySearchForm';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';

const InventoryGeneralSearch = () => {
  const [stockList, setStockList] = React.useState([]);
  const TABLE_HEADERS = [
    { title: 'Tipo de Inventario', field: 'stockType' },
    { title: 'Clasificación', field: 'classification.value' },
    { title: '# de Inventario', field: 'stockId' },
    { title: 'Equipo', field: 'equipment' },
    // { title: "Descripción", field: "description" },
    { title: 'Marca', field: 'brand.value' },
    { title: 'Modelo', field: 'model.value' },
    { title: '# Serie', field: 'serialNumber' },
    { title: 'Status', field: 'status.value' },
    {
      title: 'Responsable',
      field: 'responsable.id',
      render: (rowData) => {
        if (!rowData?.responsible) {
          return 'Sin responsable';
        }
        const { names, lastName1, lastName2 } = rowData?.responsible || {};
        return `${names} ${lastName1} ${lastName2}`;
      },
    },
    {
      title: 'Ubicación',
      render: (rowData) => {
        if (rowData.projects?.length) {
          return `${rowData.projects[0].projectId} - ${rowData.projects[0].projectName}`;
        } else if (rowData?.warehouse) {
          return rowData?.warehouse?.value;
        } else {
          return 'Desconocida';
        }
      },
    },
    { title: 'Apodo', field: 'nickname' },
    { title: 'EMA', field: 'ema' },
    { title: 'AMAAC', field: 'amaac' },
  ];

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h4">Consulta general de inventarios</Typography>
      </Grid>
      <Grid item>
        <InventorySearchForm
          onSearchChange={(newList) => {
            setStockList(newList);
          }}
          currentData={stockList}
        />
      </Grid>
      <Grid item>
        <MaterialTable
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 15, 25],
            sorting: true,
            filtering: true,
          }}
          title=""
          data={stockList}
          columns={TABLE_HEADERS}
        />
      </Grid>
    </Grid>
  );
};

export default withNavigation(InventoryGeneralSearch);
