export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CREATED = 'CREATED';
export const UPDATED = 'UPDATED';
export const COMPLETED = 'COMPLETED';
export const CLOSED = 'CLOSED';
export const HEARTBEAT = 'HEARTBEAT';
export const DELETED = 'DELETED';
export const CANCELED = 'CANCELED';
export const FILE_DOWNLOADED = 'FILE_DOWNLOADED';
