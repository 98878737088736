import React, { useState } from 'react';
import AppBar from './AppBar';
import { StyledMainBlock, useStyles } from './AppNavigationStyles';
import ResponsiveDrawer from './Drawer';

const AppNavigation = (props) => {
  const styles = useStyles();
  const [showDrawer, setShowDrawer] = useState(false);
  const [displayDesktopMenuElements, setDisplayDesktopMenuElements] = useState(true);

  const handleDrawerToggle = () => setShowDrawer((prevState) => !prevState);

  return (
    <div>
      <AppBar
        handleDrawerToggle={handleDrawerToggle}
        displayDesktopMenu={displayDesktopMenuElements}
      />
      <ResponsiveDrawer
        handleDrawerToggle={handleDrawerToggle}
        showDrawer={showDrawer}
        displayDesktopMenu={displayDesktopMenuElements}
        setDisplayDesktopMenu={setDisplayDesktopMenuElements}
      />
      <StyledMainBlock className={styles.content} fullMenuView={displayDesktopMenuElements}>
        <div className={styles.toolbar} />
        {props.children}
      </StyledMainBlock>
    </div>
  );
};

export default AppNavigation;

export const withNavigation = (WrappedComponent) => {
  const ResultComponent = (props) => {
    return (
      <AppNavigation {...props}>
        <WrappedComponent {...props} />
      </AppNavigation>
    );
  };

  return ResultComponent;
};
