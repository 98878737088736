import { CircularProgress } from '@material-ui/core';
import { Autocomplete, TextField } from '@mui/material';
import { getIn } from 'formik';
import React from 'react';

const FormikAutocomplete = (props) => {
  const {
    field: { name: fieldName, value: fieldValue },
    form: { touched, errors, handleBlur },
    label,
    customOnChange,
    options,
    setFieldValue,
    setFieldTouched,
    valueExtractor,
    disabled,
  } = props;

  const error = getIn(touched, fieldName) && getIn(errors, fieldName);

  return options?.length ? (
    <Autocomplete
      id="inventory-type"
      options={options || []}
      getOptionLabel={(option) => option.itemLabel}
      renderInput={(params) => {
        return (
          <TextField
            label={label}
            helperText={error}
            error={!!error}
            handleBlur={handleBlur}
            {...params}
          />
        );
      }}
      disabled={disabled}
      onFocus={() => {
        setFieldTouched && setFieldTouched(fieldName);
      }}
      onChange={(_event, newValue) => {
        const value = valueExtractor ? valueExtractor(newValue) : newValue?.itemValue;
        setFieldValue && setFieldValue(fieldName, value);
        customOnChange && customOnChange(newValue);
      }}
      defaultValue={options.find(
        ({ itemValue }) => `${itemValue}`.toLowerCase() === `${fieldValue}`.toLowerCase(),
      )}
    />
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default FormikAutocomplete;
