import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useStyles, TableContainerWithScrollBars } from './InventariosScreenStyles';
import axios from './../../../Utils/AxiosS3Instance';
import ColumnEditFormulary from './ColumnEditFormulary/Formulary';
import { ProjectContext } from './../../../Contexts/ProjectContext/ProjectContext';
import { userContext } from './../../../Contexts/UserContext/UserContext';
import { normalizePath } from './../../../Utils/normalizePath';
import { withNavigation } from '../../../Containers/AppNavigation/AppNavigation';
import Dialog from '../../../Containers/GenericDialog';
import Table from '../../../Components/Table';
import { useFetch } from '../../../Hooks/useFetch';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import Alert from '../../../Components/Alert/Alert';
import { ProjectMainTitle } from '../../SingleProjectScreen/SingleProjectScreenStyles';
import NewElementFormulary from '../../InventoryScreen/NewElementFormulary/Formulary';
import DropZone from '../../../Components/DropZone';
import DialogActions from '../../../Containers/GenericDialog/DialogActions';
import { deleteFile } from '../../../Utils/s3Utils';
import { OUT_OF_WAREHOUSE } from '../../../Hooks/useWarehousesCatalogs';
const FILES_BUCKET = 'roshare-inventory-files';

const InventariosScreen = () => {
  let { project } = useParams();
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);

  //---------------------------------------------------------------------------
  const [projectState, setProjectState] = useContext(ProjectContext);

  //---------------------------------------------------------------------------
  // useState hooks
  //--------------------------------------------------------------------------- setOpenSaveDialog
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [tableComparisonView, setTableComparisonView] = useState(false);
  const [formularyDialogOpen, setFormularyDialogOpen] = useState(false);
  const [userProjectGrants, setUserProjectGrants] = useState({});
  const [columnTableState, setColumnTableState] = useState({
    inventoryType: true,
    clasification: true,
    inventoryNumber: true,
    stockId: true,
    equipment: true,
    description: true,
    brand: false,
    model: false,
    serialNumber: false,
    status: false,
    responsable: false,
    project: false,
    nickname: false,
    set: false,
    EMA: false,
    AMAAC: false,
    warehouse: false,
  });
  const [columnSplitTableState] = useState({
    inventoryType: true,
    clasification: true,
    inventoryNumber: true,
    stockId: true,
    equipment: true,
    description: false,
    brand: false,
    model: false,
    serialNumber: false,
    status: false,
    responsable: false,
    project: false,
    nickname: false,
    set: false,
    EMA: false,
    AMAAC: false,
  });
  const [selectableStock, setSelectableStock] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { userDetails = {} } = userState || {};
  const [currentElement] = useState(null);
  const [uploadPath, setUploadPath] = useState('');
  const [newFileDialogOpen, setNewFileDialogOpen] = useState(false);

  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useFetch hooks
  // //---------------------------------------------------------------------------
  const { data: allStock, refetch: refetchStock } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/stock/list',
  });

  const { data: projectData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${project}`,
  });

  const {
    data: elementFiles,
    updateParams: updateS3FolderParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: uploadPath ? { path: uploadPath } : undefined,
    skip: !uploadPath,
  });

  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------

  useEffect(() => {
    if (allStock) {
      setSelectableStock(allStock.filter((stockElement) => !stockElement.assignedToProject));
    }
  }, [allStock]);

  useEffect(() => {
    setProjectState(projectData);
  }, [projectData]);

  useEffect(() => {
    if (projectState) {
      const { projectGrants } = projectState;

      let tempGrants = {};
      projectGrants &&
        projectGrants.forEach((grant) => {
          if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
          tempGrants[grant.grantName].push(grant?.user?.username);
        });
      setUserProjectGrants(tempGrants);
    }
  }, [projectState]);

  //---------------------------------------------------------------------------
  const mapElement = (element) => {
    const {
      id,
      equipment,
      description,
      brand,
      model,
      serialNumber,
      status,
      responsible,
      warehouse,
      nickname,
      ema,
      amaac,
      classification,
      group,
      stockType,
      stockId,
      projects,
    } = element;

    const _element = {
      inventoryType: stockType,
      stockId,
      clasification: classification?.value,
      clasificationObject: classification,
      warehouse: warehouse?.value,
      warehouseObject: warehouse,
      inventoryNumber: stockId,
      equipment: equipment,
      description: description,
      brand: brand?.value,
      model: model?.value || '',
      brandObject: brand,
      modelObject: model,
      serialNumber: serialNumber,
      status: status?.value || '',
      statusObject: status,
      responsable: responsible?.name || '',
      responsableObject: responsible,
      nickname: nickname,
      set: group?.value,
      setObject: group,
      EMA: ema,
      AMAAC: amaac,
      projects,
      project: projects[0],
      id,
    };
    return _element;
  };

  const filterColumns = (column) => {
    return tableComparisonView
      ? columnSplitTableState[column.field]
      : columnTableState[column.field];
  };
  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------
  const getFile = async (s3DownloadKey) => {
    const signedUrl = (
      await axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`)
    ).data;

    window.open(signedUrl, '_blank');
    //win.focus();
  };

  const handleFileDelete = (key) => {
    deleteFile(key, FILES_BUCKET, () => {
      // setDeleteOpenAlert(false);
      refetchFiles();
    });
  };
  //---------------------------------------------------------------------------
  // COMMON Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'Tipo de Inventario', field: 'inventoryType' },
    { title: 'Clasificación', field: 'clasification' },
    { title: '# de Inventario', field: 'stockId' },
    { title: 'Equipo', field: 'equipment' },
    { title: 'Descripción', field: 'description' },
    { title: 'Marca', field: 'brand' },
    { title: 'Modelo', field: 'model' },
    { title: '# Serie', field: 'serialNumber' },
    { title: 'Status', field: 'status' },
    { title: 'Responsable', field: 'responsable' },
    { title: 'Proyecto', field: 'project' },
    { title: 'Apodo', field: 'nickname' },
    { title: 'Conjunto', field: 'set' },
    { title: 'EMA', field: 'EMA' },
    { title: 'AMAAC', field: 'AMAAC' },
    { title: 'Almacén', field: 'warehouse' },
  ];

  const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];

  const fileTableActions = [
    {
      icon: 'cloud_download',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${uploadPath}${fileName}`;
        getFile(key);
      },
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${uploadPath}${fileName}`;
        handleFileDelete(key);
        refetchFiles();
      },
      hidden: !userProjectGrants?.EDIT_STOCKS?.includes(userDetails?.username),
    },
  ];
  //---------------------------------------------------------------------------
  // MAIN Table Actions
  //---------------------------------------------------------------------------

  const tableComparisonButton = {
    icon: () => {
      return (
        <Button variant="contained" color="primary">
          {tableComparisonView ? 'Regresar' : 'Editar Inventario'}
        </Button>
      );
    },
    tooltip: 'Seleccionar nuevo inventario',
    iconProps: {
      color: 'secondary',
    },
    onClick: () => {
      setTableComparisonView(!tableComparisonView);
    },
    isFreeAction: true,
    hidden: !userProjectGrants?.EDIT_STOCKS?.includes(userDetails?.username),
  };

  const actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Nuevo Registro
          </Button>
        );
      },
      tooltip: 'Nuevo Registro',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        // setDialogOpen(true);
        history.push(`/proyectos/${projectState.id}/inventarios/nuevo`);
      },
      isFreeAction: true,
      hidden: !userProjectGrants?.EDIT_STOCKS?.includes(userDetails?.username),
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        console.log('Eliminar', rowElement);
        const { id: inventoryNumber } = rowElement;
        const { id: projectId } = projectState;
        const result = await AxiosInstance.delete(
          `/api/project/${projectId}/stock/${inventoryNumber}`,
        );
        const { data: newProjectValues } = result;
        await refetchStock();
        setProjectState(newProjectValues);
      },
      hidden:
        !tableComparisonView || !userProjectGrants?.EDIT_STOCKS?.includes(userDetails?.username),
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            {tableComparisonView ? 'Regresar' : 'Editar Inventario'}
          </Button>
        );
      },
      tooltip: 'Seleccionar nuevo inventario',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setTableComparisonView(!tableComparisonView);
      },
      isFreeAction: true,
      hidden:
        !userProjectGrants?.EDIT_STOCKS?.includes(userDetails?.username) || tableComparisonView,
    },
    {
      icon: SettingsIcon,
      tooltip: 'Add / Remove Columns',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setFormularyDialogOpen(true);
      },
      isFreeAction: true,
      hidden: tableComparisonView,
    },
    {
      icon: 'edit',
      tooltip: 'editar elemento de inventarios',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        history.push(`/proyectos/${projectState.id}/inventarios/editar/${rowElement.id}`);
      },
      hidden:
        !userProjectGrants?.EDIT_STOCKS?.includes(userDetails?.username) || tableComparisonView,
    },
    {
      icon: 'folder',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { inventoryType, inventoryNumber } = rowElement;
        const path = `${normalizePath(inventoryType.replace(/ /g, ''))}/${inventoryNumber}/`;
        setUploadPath(path);
        updateS3FolderParams({ path: `${path}`, bucket: FILES_BUCKET });
        setNewFileDialogOpen(true);
      },
      // hidden: !allowedModuleSections?.includes(tableConfiguration?.canEditProfile)
    },
  ];

  const MainInventoryTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        {!tableComparisonView && (
          <Grid container direction="row" justify="flex-starte" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.goBack();
                }}
              >
                {'REGRESAR <<'}
              </Button>
            </Grid>
            <Grid item>
              <ProjectMainTitle>
                {`${projectState?.projectId || ''} - ${projectState?.projectName || ''} - ${
                  projectState?.contractKey || ''
                }` || 'Proyecto'}
              </ProjectMainTitle>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Table
            title={`Inventarios agregados a proyecto`}
            data={(projectState?.stocks || []).map(mapElement)}
            columns={TABLE_HEADERS.filter(filterColumns)}
            actions={actions}
          />
        </Grid>
      </Grid>
      <Dialog
        openDialog={formularyDialogOpen}
        dialogTitle={'Configuración de Columnas'}
        maxWidth="md"
      >
        <ColumnEditFormulary
          setOpen={setFormularyDialogOpen}
          currentFilter={columnTableState}
          columnFields={columnTableState}
          setColumnFields={setColumnTableState}
        />
      </Dialog>
      <Dialog openDialog={dialogOpen} dialogTitle={'Nuevo Registro'} maxWidth="md">
        <NewElementFormulary
          projectId={projectState?.id || ''}
          setOpen={setDialogOpen}
          refetchInventory={refetchStock}
          currentElement={currentElement}
          setProjectState={setProjectState}
        />
      </Dialog>
      <Dialog openDialog={newFileDialogOpen} dialogTitle={'Subir archivos'} maxWidth="md">
        <DropZone
          path={uploadPath}
          closeDialog={() => setNewFileDialogOpen(false)}
          refetch={() => {
            console.log('refetch');
          }}
          bucket={FILES_BUCKET}
        />
        <DialogActions
          secondaryLabel="Cancelar"
          primaryLabel={null}
          secondaryAction={() => setNewFileDialogOpen(false)}
        />
        <Table
          title="Archivos de Elemento"
          data={elementFiles?.files.map((file) => ({
            fileName: file.key.split('/').pop(),
          }))}
          columns={FILE_TABLE_HEADERS}
          actions={fileTableActions}
        />
      </Dialog>
    </>
  );

  //---------------------------------------------------------------------------
  // SECONDARY Table Actions
  //---------------------------------------------------------------------------
  const secondaryTableActions = [
    {
      icon: 'add',
      tooltip: 'agregar',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        const { id: inventoryNumber } = rowElement;
        const { id: projectId } = projectState;
        const result = await AxiosInstance.post(
          `/api/project/${projectId}/stock/${inventoryNumber}`,
        );

        const { data: stock } = await AxiosInstance.get(`/api/stock/${inventoryNumber}`);
        delete stock.projects;
        const updateObject = {
          ...stock,
          warehouse: OUT_OF_WAREHOUSE,
        };
        await AxiosInstance.put(`/api/stock`, updateObject);
        const { data: newProjectValues } = result;
        setProjectState(newProjectValues);
        refetchStock();
      },
    },
  ];

  if (tableComparisonView === true) {
    secondaryTableActions.push(tableComparisonButton);
  }

  const SecondaryInventoryTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Table
            title={`Inventarios disponibles`}
            data={(selectableStock || []).map(mapElement)}
            columns={TABLE_HEADERS.filter(filterColumns)}
            actions={secondaryTableActions}
          />
        </Grid>
      </Grid>
      <Alert
        type={'none'}
        title="Guardar"
        content="Estas seguro de querer guardar tus cambios?"
        actions={[
          {
            text: 'Guardar',
            onClick: () => {
              setTableComparisonView(false);
              setOpenSaveDialog(false);
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenSaveDialog(false);
            },
          },
        ]}
        open={openSaveDialog}
        onClose={() => setOpenSaveDialog(false)}
        onBackdropPress={() => {
          setOpenSaveDialog(false);
        }}
      />
    </>
  );

  const renderScreen = () => {
    let tablesView = (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TableContainerWithScrollBars>{SecondaryInventoryTable}</TableContainerWithScrollBars>
        </Grid>
        <Grid item xs={6}>
          <TableContainerWithScrollBars>{MainInventoryTable}</TableContainerWithScrollBars>
        </Grid>
      </Grid>
    );

    return tableComparisonView ? tablesView : MainInventoryTable;
  };

  return <div className={styles.container}>{renderScreen()}</div>;
};

export default withNavigation(InventariosScreen);
