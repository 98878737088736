/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import AccountBalanceRounded from '@material-ui/icons/AccountBalanceRounded';
import { getInitialValues } from './validation';
// Icons
import { useStyles } from './materialStyles';
import Select from '../../../Components/FormFields/FormikSelect';
import Input from '../../../Components/FormFields/FormikIconInput';

function BackupFormulary(props) {
  const { setOpen, downloadEvents, currentFilter, setCurrentFilter } = props;
  const styles = useStyles();

  const users = downloadEvents?.map((event) => event.user.name);
  const dropdownUsers = users
    ?.reduce((prev, curr) => {
      return prev.includes(curr) ? prev : [...prev, curr];
    }, [])
    .map((user) => ({
      itemValue: user,
      itemLabel: user,
    }));

  const files = downloadEvents?.map((event) => event.param);
  const dropdownFiles = files
    ?.reduce((prev, curr) => {
      return prev.includes(curr) ? prev : [...prev, curr];
    }, [])
    .map((file) => ({
      itemValue: file,
      itemLabel: file,
    }));

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentFilter)}
        // validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setCurrentFilter(values);
          resetForm();
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, isSubmitting, setFieldValue }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={6}>
                <Grid item xs={12}>
                  <Field name="username">
                    {(props) => (
                      <Select
                        formikName={'username'}
                        label={'Usuario'}
                        Icon={AccountBalanceRounded}
                        menuItemList={dropdownUsers || []}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field name="param">
                    {(props) => (
                      <Select
                        formikName={'param'}
                        label={'Archivo'}
                        Icon={AccountBalanceRounded}
                        menuItemList={dropdownFiles || []}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <h4>Desde</h4>
                  <Field name="fecha1">
                    {(props) => (
                      <Input
                        formikName={'fecha1'}
                        type="date"
                        props={props}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <h4>Hasta</h4>
                  <Field name="fecha2">
                    {(props) => (
                      <Input
                        formikName={'fecha2'}
                        type="date"
                        props={props}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    Filtrar
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default BackupFormulary;
