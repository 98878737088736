import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { BasicSearchContainer, SearchFormContainer, SearchFormDiv } from './SearchForm.styled';
import SearchBar from './SearchBar/SearchBar';
import TargetButtons from './TargetButtons/TargetButtons';
import AdvancedSearchFrom from './AdvancedSearchForm/AdvancedSearchForm';
import StockResumeCard from '../StockResumeCard/StockResumeCard';
import EmployeeResumeCard from '../EmployeeResumeCard/EmployeeResumeCard';

const SearchForm = () => {
  const [moreOptionsVisibility, setMoreOptionsVisibility] = useState(false);

  return (
    <SearchFormContainer>
      <SearchFormDiv>
        <BasicSearchContainer>
          <TargetButtons />
          <SearchBar />
          <IconButton onClick={() => setMoreOptionsVisibility((prev) => !prev)}>
            {!moreOptionsVisibility ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </BasicSearchContainer>
      </SearchFormDiv>
      {moreOptionsVisibility && (
        <SearchFormDiv>
          <AdvancedSearchFrom />
        </SearchFormDiv>
      )}
      <StockResumeCard />
      <EmployeeResumeCard />
    </SearchFormContainer>
  );
};

export default SearchForm;
