import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DataContainer, CardTitle, ListItemContainer, ListText } from './DataCardListStyles';
import { DataCardMainContainer, MoreInfoLink } from '../DataCardStyles';
import { normalizeTitleToUrl } from '../DataCardsUtils';

const DataCardList = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { title, list = [] } = props;

  while (list.length < 5) {
    list.push({ title: '-', path: '-' });
  }

  const handleSeeMore = () => {
    title && history.push(`${pathname}/${normalizeTitleToUrl(title)}`);
  };

  const handleItemClick = (path) => {
    if (path !== '-') {
      history.push(`${pathname}/${normalizeTitleToUrl(title)}/${normalizeTitleToUrl(`${path}`)}`);
    }
  };

  return (
    <DataCardMainContainer>
      <CardTitle>{title}</CardTitle>
      <DataContainer>
        {list.slice(list.length > 5 ? list.length - 5 : 0, list.length).map((item, itemIndex) => {
          return (
            <ListItemContainer key={itemIndex}>
              <ListText
                onClick={() => {
                  handleItemClick(item.path);
                }}
              >
                {item.title}
              </ListText>
            </ListItemContainer>
          );
        })}
      </DataContainer>
      <MoreInfoLink onClick={handleSeeMore}>Ver mas ...</MoreInfoLink>
    </DataCardMainContainer>
  );
};

export default DataCardList;
