export const csvConceptMap = {
  CLASIFICACION: 'classification',
  CODIGO: 'code',
  DESCRIPCION: 'conceptDescription',
  'NOMBRE CORTO': 'conceptName',
  COSTO: 'cost',
  NUMERO: 'number',
  ORDEN: 'orderNumber',
  'NUMERO PADRE': 'parentNumber',
  CANTIDAD: 'quantity',
  'UNIDAD DE MEDIDA': 'unitOfMeasure',
  'CONCEPTO PADRE': 'isParent',
};
