import React from 'react';
import LabReportTable from './LabReportTable/LabReportTable';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { LABORATORIO } from '../../Hooks/useAssetData';
import { useFetch } from '../../Hooks/useFetch';

const LaboratoryReportScreen = () => {
  const axiosInstance = React.useContext(AxiosContext);

  const { data, isLoading } = useFetch({
    axiosInstance,
    initialUrl: '/api/stock/list',
    initialParams: {
      stockType: LABORATORIO,
    },
  });

  return isLoading ? <h1>Loading</h1> : <LabReportTable data={data} />;
};

export default withNavigation(LaboratoryReportScreen);
