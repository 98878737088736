import React, { createContext, useEffect, useState } from 'react';
import {
  loadStoredData,
  createSetAndStoreFunction,
} from './../ProjectContext/ProjectDataStorageUtils/projectDataStorage';

const ProjectContext = createContext([{}, () => {}]);

const ProjectContextProvider = (props) => {
  const [state, setState] = useState({});

  useEffect(() => {
    const retrieveData = async () => {
      const storedData = await loadStoredData();
      setState(storedData);
    };
    retrieveData();
  }, [setState]);

  const setAndStoreProjectState = createSetAndStoreFunction(setState);

  return (
    <ProjectContext.Provider value={[state, setAndStoreProjectState]}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export { ProjectContext, ProjectContextProvider };
