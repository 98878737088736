import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CostScreenMainContainer } from './CostScreenStyles';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import WeekReportTable from './WeekReportTable/WeekReportTable';
import WeeksTable from './WeeksTable/WeeksTable';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';

const CostosScreen = () => {
  let { project, week } = useParams();
  const [arrangedData, setArrangedData] = useState([]);
  const [value, setValue] = useState(0);
  const AxiosInstance = useContext(AxiosContext);

  const {
    data: projectData,
    updateUrl,
    refetch: refetchProjectData,
  } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${project}`,
  });

  useEffect(() => {
    updateUrl(`/api/project/${project}`);
  }, [project, updateUrl]);

  const groupByWeek = (costs = []) => {
    const byWeek = costs.reduce((accumulator, current) => {
      accumulator[current.week] = [...(accumulator[current.week] || []), current];
      return accumulator;
    }, []);
    return byWeek;
  };

  const groupeByType = (costs = []) => {
    const result = costs.reduce((accumulator, current) => {
      const { type } = current;
      accumulator[type] = [...(accumulator[type] || []), current];
      return accumulator;
    }, {});
    return result;
  };

  useEffect(() => {
    const { projectCosts } = projectData || {};
    if (projectCosts && setArrangedData) {
      const byType = groupeByType(projectCosts);
      Object.keys(byType).forEach((type) => {
        byType[type] = groupByWeek(byType[type]);
      });
      setArrangedData(byType);
    }
  }, [projectData, setArrangedData]);

  const getTable = () => {
    if (!project && !week) {
      return <ProjectsTable />;
    } else if (!week) {
      return (
        <WeeksTable
          updateUrl={updateUrl}
          projectData={projectData}
          arrangedData={arrangedData}
          setArrangedData={setArrangedData}
          value={value}
          setValue={setValue}
        />
      );
    } else {
      return (
        <WeekReportTable
          arrangedData={arrangedData}
          value={value}
          refetchProjectData={refetchProjectData}
        />
      );
    }
  };

  return <CostScreenMainContainer>{getTable()}</CostScreenMainContainer>;
};

export default withNavigation(CostosScreen);
