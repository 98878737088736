import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  CardButtonsContainer,
  CardTitle,
  PropLabel,
  PropPairContainer,
  PropValue,
  StockResumeCardContainer,
} from './EmployeeResumeCard.styled';
import { useCartStateContext } from '../../Store/CartState';

const EmployeeResumeCard = () => {
  const { filter, availableEmployees, resetFilters, addEmployeeToCart, updateFilter } =
    useCartStateContext();
  const [cardEmployee, setCardEmployee] = useState(null);

  useEffect(() => {
    const { employeeId } = filter || {};
    setCardEmployee(availableEmployees.find((employee) => employee.id === employeeId));
  }, [filter.employeeId]);

  const { degree: degreeRaw, names, lastName1, lastName2, projects } = cardEmployee || {};

  const employeeName =
    names || lastName1 || lastName2
      ? `${names} ${lastName1} ${lastName2}`
      : 'Sin responsable asignado';
  const project = projects?.length ? projects[0].projectName : 'Sin proyecto asignado';
  const degree = degreeRaw?.value || 'Sin título';

  const employeeCard = (
    <StockResumeCardContainer>
      <CardTitle>Resumen de Elemento</CardTitle>
      <PropPairContainer>
        <PropLabel>Nombre:</PropLabel>
        <PropValue>{employeeName}</PropValue>
      </PropPairContainer>
      <PropPairContainer>
        <PropLabel>Título:</PropLabel>
        <PropValue>{degree}</PropValue>
      </PropPairContainer>
      <PropPairContainer>
        <PropLabel>Proyecto:</PropLabel>
        <PropValue>{project}</PropValue>
      </PropPairContainer>
      <CardButtonsContainer>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            updateFilter({
              employeeId: null,
            });
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            addEmployeeToCart(cardEmployee);
            resetFilters();
          }}
        >
          Agregar
        </Button>
      </CardButtonsContainer>
    </StockResumeCardContainer>
  );

  return cardEmployee ? employeeCard : null;
};

export default EmployeeResumeCard;
