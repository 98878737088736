import { parse as parseDate } from 'date-fns';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';

const daysToMs = (amountOfDays) => amountOfDays * 24 * 60 * 60 * 1000;

const CRITIC_TIME = daysToMs(0);
const URGENT_TIME = daysToMs(7);
const ATTENTION_TIME = daysToMs(14);

export const CRITIC = 'CRITIC';
export const URGENT = 'URGENT';
export const ATTENTION = 'ATTENTION';
export const RIGHT = 'RIGHT';

export const CRITIC_COLOR = '#FF0000';
export const URGENT_COLOR = '#FA9600';
export const ATTENTION_COLOR = '#FAFA00';
export const RIGHT_COLOR = '#00FF00';

export const safetyObjectCheck = (date) => {
  if (date instanceof Date) {
    return date;
  }
  if (typeof date !== 'string') {
    throw new Error("Can't parse something that is not an string");
  }

  return parseDate(date, BACKEND_DATE_FORMAT, new Date());
};

export const getWarningLevel = (dateToTest) => {
  if (!dateToTest) {
    return '';
  }
  const today = new Date().getTime();
  const currentDate = safetyObjectCheck(dateToTest).getTime();

  const diff = currentDate - today;

  if (diff < CRITIC_TIME) {
    return CRITIC_COLOR;
  } else if (diff < URGENT_TIME) {
    return URGENT_COLOR;
  } else if (diff < ATTENTION_TIME) {
    return ATTENTION_COLOR;
  } else {
    return RIGHT_COLOR;
  }
};
