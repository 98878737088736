import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useMasterDataCatalogs } from '../../../../../Hooks/useMasterdataCatalogs';
import { SELECTABLE_ELEMENTS } from '../../../SelectableElements';
import { useCartStateContext } from '../../../Store/CartState';
import { STOCK_TARGET } from '../../../Store/Reducer';

const AdvancedSearchFrom = () => {
  const {
    searchTarget,
    availableEmployees,
    updateFilter,
    availableStock: stockData,
    filteredProjects,
  } = useCartStateContext();
  const [titles, setTitles] = useState([]);
  const { catalogs } = useMasterDataCatalogs({ entityList: ['Titulos'] });
  useEffect(() => {
    if (catalogs) {
      const { Titulos = [] } = catalogs;
      setTitles(Titulos);
    }
  }, [catalogs]);

  const isStock = searchTarget === STOCK_TARGET;

  const stockTypeAutocomplete = (
    <Autocomplete
      id="tipo"
      options={SELECTABLE_ELEMENTS || []}
      getOptionLabel={(option) => option.title}
      onChange={(event, newValue) => {
        const { assetType = null } = newValue || {};
        updateFilter({ stockType: assetType });
      }}
      renderInput={(params) => {
        return <TextField {...params} label="Tipo de inventario" variant="outlined" />;
      }}
    />
  );
  const projectAutocomplete = (
    <Autocomplete
      id="tipo"
      options={filteredProjects || []}
      getOptionLabel={(option) => option.projectName}
      onChange={(event, newValue) => {
        updateFilter({ project: newValue });
      }}
      renderInput={(params) => {
        return <TextField {...params} label="Proyecto" variant="outlined" />;
      }}
    />
  );

  const stockIdAutocomplete = (
    <Autocomplete
      id="stockId"
      options={stockData || []}
      getOptionLabel={(option) => option.stockId}
      onChange={(event, newValue) => {
        if (newValue) {
          const { stockId } = newValue;
          updateFilter({ stockId });
        } else {
          updateFilter({ stockId: null });
        }
      }}
      renderInput={(params) => {
        return <TextField {...params} label="ID" variant="outlined" />;
      }}
    />
  );

  const employeeTitleAutocomplete = (
    <Autocomplete
      id="employeeTitle"
      options={titles || []}
      getOptionLabel={(option) => option.itemLabel}
      onChange={(event, newValue) => {
        if (newValue) {
          const { itemValue: employeeTitle } = newValue;
          updateFilter({ employeeTitle });
        } else {
          updateFilter({ employeeTitle: null });
        }
      }}
      renderInput={(params) => {
        return <TextField {...params} label="Título profesional" variant="outlined" />;
      }}
    />
  );

  const employeeIdAutocomplete = (
    <Autocomplete
      id="employeeId"
      options={availableEmployees || []}
      getOptionLabel={(option) => `${option.id}`}
      onChange={(event, newValue) => {
        if (newValue) {
          const { id: employeeId } = newValue;
          updateFilter({ employeeId });
        } else {
          updateFilter({ employeeId: null });
        }
      }}
      renderInput={(params) => {
        return <TextField {...params} label="ID" variant="outlined" />;
      }}
    />
  );

  return (
    <>
      {isStock && stockTypeAutocomplete}
      {isStock && stockIdAutocomplete}
      {!isStock && employeeTitleAutocomplete}
      {!isStock && employeeIdAutocomplete}
      {!isStock && projectAutocomplete}
    </>
  );
};

export default AdvancedSearchFrom;
