import Excel from 'exceljs';
import FileSaver from 'file-saver';

export const excelInventory = (data, title, callback) => {
  if (!Array.isArray(data)) {
    console.error('EXCEL INVENTORY: Necesitas enviar un arreglo');
    return;
  }
  const workbook = new Excel.Workbook();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];
  let worksheet = workbook.addWorksheet(title);

  worksheet.columns = [
    { header: 'Tipo de inventario', key: 'stockType', width: 13 },
    { header: 'AMAAC', key: 'amaac', width: 10 },
    { header: 'Asignado', key: 'assignedToProject', width: 12 },
    { header: 'Marca', key: 'brand', width: 15 },
    { header: 'Clasificación', key: 'classification', width: 20 },
    { header: 'Descripción', key: 'description', width: 80 },
    { header: 'EMA', key: 'ema', width: 7 },
    { header: 'Equipo', key: 'equipment', width: 25 },
    { header: 'Grupo', key: 'group', width: 15 },
    { header: 'Modelo', key: 'model', width: 20 },
    { header: 'Apodo', key: 'nickname', width: 15 },
    { header: 'Proyectos', key: 'projects', width: 25 },
    { header: 'Responsable', key: 'responsible', width: 25 },
    { header: 'No. Serie', key: 'serialNumber', width: 25 },
    { header: 'Status', key: 'status', width: 15 },
    { header: 'ID Inventario', key: 'stockId', width: 15 },
    { header: 'Almacen', key: 'warehouse', width: 20 },
  ];

  // worksheet.getColumn(3).numFmt = '"$"#,##0.00;[Red]-"£"#,##0.00';
  // worksheet.getColumn(6).numFmt = "#";
  worksheet.properties.defaultRowHeight = 15;

  const headers = worksheet.getRow(1);
  headers.alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  headers.height = 30;
  headers.font = { color: 'FFFFFFFF', family: 2, name: 'Arial', bold: true };

  data.forEach((element) => {
    let row = worksheet.addRow({
      ...element,
      assignedToProject: element.assignedToProject ? 'SI' : 'NO',
      brand: element.brand.value,
      classification: element.classification.value,
      group: element.group.value,
      model: element.model.value,
      status: element.status.value,
      responsible: `${element.responsible?.names || ''} ${element.responsible?.lastName1 || ''} ${
        element.responsible?.lastName2 || ''
      }`,
      projects: element.projects.reduce(
        (acc, curr) =>
          `${acc ? `${acc},` : ''} ${curr.projectId || ''} - ${curr.projectName || ''}`,
        '',
      ),
      warehouse: element.warehouse?.value,
    });
    row.height = 20;
    row.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  workbook.xlsx
    .writeBuffer()
    .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${title}.xlsx`))
    .catch((err) => console.log('Error escribiendo el excel', err));
  callback && callback(false);
};

export const excelRH = (data, title, callback) => {
  if (!Array.isArray(data)) {
    console.error('EXCEL RH: Necesitas enviar un arreglo');
    return;
  }
  const workbook = new Excel.Workbook();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];
  let worksheet = workbook.addWorksheet(title);

  worksheet.columns = [
    { header: 'Inicio en empresa', key: 'jobStartDate', width: 25 },
    { header: 'Nombres', key: 'names', width: 15 },
    { header: 'Apellido paterno', key: 'lastName1', width: 15 },
    { header: 'Apellido materno', key: 'lastName2', width: 20 },
    { header: 'Area', key: 'area', width: 16 },
    { header: 'Asignado', key: 'assignedToProject', width: 10 },
    { header: 'Cuenta de banco', key: 'bankAccountNumber', width: 18 },
    { header: 'Banco', key: 'bankName', width: 15 },
    { header: 'CURP', key: 'curp', width: 23 },
    { header: 'Grado', key: 'degree', width: 40 },
    { header: 'Correo', key: 'email', width: 22 },
    { header: 'Teléfono', key: 'phoneNumber', width: 20 },
    { header: 'Posición', key: 'position', width: 28 },
    { header: 'RFC', key: 'rfc', width: 18 },
    { header: 'IMSS', key: 'socialSecurityNumber', width: 15 },
    { header: 'Proyectos', key: 'projects', width: 80 },
    { header: 'Cédula', key: 'professionalLicense', width: 15 },
  ];

  // worksheet.getColumn(3).numFmt = '"$"#,##0.00;[Red]-"£"#,##0.00';
  // worksheet.getColumn(6).numFmt = "#";
  worksheet.properties.defaultRowHeight = 15;

  const headers = worksheet.getRow(1);
  headers.alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  headers.height = 30;
  headers.font = { color: 'FFFFFFFF', family: 2, name: 'Arial', bold: true };

  data.forEach((element) => {
    let row = worksheet.addRow({
      ...element,
      assignedToProject: element.assignedToProject ? 'SI' : 'NO',
      area: element.area?.value || '',
      degree: element.degree?.value || '',
      position: element.position?.value || '',
      projects: element.projects.reduce(
        (acc, curr) =>
          `${acc ? `${acc},` : ''} ${curr.projectId || ''} - ${curr.projectName || ''}`,
        '',
      ),
    });
    row.height = 20;
    row.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  workbook.xlsx
    .writeBuffer()
    .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${title}.xlsx`))
    .catch((err) => console.log('Error escribiendo el excel', err));
  callback && callback(false);
};
