import queryString from 'query-string';
import axios from './AxiosS3Instance';

export const downloadFile = async (s3DownloadKey) => {
  const signedUrl = (
    await axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${'roshare-qualitylibrary'}`)
  ).data;
  window.open(signedUrl, '_blank');
};

export const recordDownload = async (key, AxiosInstance, setDownloadOpenAlert) => {
  const queryObject = {
    fileName: key,
  };
  await AxiosInstance.post(`/api/audit/fileDownloaded?${queryString.stringify(queryObject)}`);
  setDownloadOpenAlert && setDownloadOpenAlert(false);
};

export const handleDownload = (fileToDownload, AxiosInstance, setDownloadOpenAlert) => {
  downloadFile(fileToDownload.key);
  recordDownload(fileToDownload.key, AxiosInstance, setDownloadOpenAlert);
};
