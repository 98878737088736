import React, { useState, useContext } from 'react';
import { AppBar, Button, CircularProgress, Grid, Tab, Tabs } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
import { useStyles } from './HumanResourcesScreenStyles';
import DialogActions from './../../Containers/GenericDialog/DialogActions';
import DropZone from './../../Components/DropZone';
import NewElementFormulary from './NewElementFormulary/Formulary';
import ColumnEditFormulary from './ColumnEditFormulary/Formulary';
import axios from './../../Utils/AxiosS3Instance';
import { mapElement } from './tableMapper';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Dialog from '../../Containers/GenericDialog';
import Table from '../../Components/Table';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import { normalizePath } from '../../Utils/normalizePath';
import { deleteFile } from '../../Utils/s3Utils';
import { userContext } from '../../Contexts/UserContext/UserContext';
import { excelRH } from '../../Utils/documentsCreator';

const FILES_BUCKET = 'rh-roshare';
const QuelityLibraryScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();
  const history = useHistory();
  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [dialogOpen, setDialogOpen] = useState(false);
  const [elementToEdit, setElementToEdit] = useState(null);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [formularyDialogOpen, setFormularyDialogOpen] = useState(false);
  const [newFileDialogOpen, setNewFileDialogOpen] = useState(false);
  const [uploadPath, setUploadPath] = useState(null);
  const [specialUploadPath, setSpecialUploadPath] = useState(null);
  const [secretMode, setSecretMode] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const [columnTableState, setColumnTableState] = useState({
    position: true,
    names: true,
    lastName1: true,
    lastName2: true,
    area: true,
    phoneNumber: true,
    email: true,
    bankAccountNumber: false,
    bankName: false,
    curp: false,
    degree: false,
    jobStartDate: false,
    professionalLicense: false,
    companies: false,
    rfc: false,
    socialSecurityNumber: false,
  });

  //---------------------------------------------------------------------------

  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === 'RECURSOS HUMANOS')
    .map((grant) => grant.name);
  //---------------------------------------------------------------------------
  // useFetch hooks
  // //---------------------------------------------------------------------------
  const {
    data: humanResourcesElements,
    isLoading,
    refetch,
  } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/employee/list`,
  });

  const {
    data: elementFiles,
    updateParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: uploadPath ? { path: uploadPath } : undefined,
  });

  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------

  const filterColumns = (column) => {
    return columnTableState[column.field];
  };
  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------
  const getFile = async (s3DownloadKey) => {
    const signedUrl = (
      await axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`)
    ).data;

    window.open(signedUrl, '_blank');
    //win.focus();
  };

  const handleFileDelete = (key) => {
    deleteFile(key, FILES_BUCKET, () => {
      // setDeleteOpenAlert(false);
      refetchFiles();
    });
  };

  //---------------------------------------------------------------------------
  // Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'Puesto', field: 'position.value' },
    { title: 'Proyecto', field: 'project.projectName' },
    { title: 'Nombre(s)', field: 'names' },
    { title: 'Apellido Paterno', field: 'lastName1' },
    { title: 'Apellido Materno', field: 'lastName2' },
    { title: 'Area', field: 'area.value' },
    { title: 'Teléfono', field: 'phoneNumber' },
    { title: 'Email', field: 'email' },
    { title: 'Título / Profesión', field: 'degree.value' },
    { title: 'CURP', field: 'curp' },
    { title: 'RFC', field: 'rfc' },
    { title: 'Fecha de Ingreso', field: 'jobStartDate' },
    { title: 'Institución Bancaria', field: 'bankName' },
    { title: '# Cuenta Bancaria', field: 'bankAccountNumber' },
    { title: 'Cédula Profesional', field: 'professionalLicense' },
    { title: '# Seguro Social', field: 'socialSecurityNumber' },
    { title: 'Empresas', field: 'company' },
  ];

  const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];

  const actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Nuevo Registro
          </Button>
        );
      },
      tooltip: 'New Record',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setDialogOpen(true);
        setElementToEdit(null);
      },
      isFreeAction: true,
      hidden: !allowedModuleSections.includes('EDITAR'),
    },
    {
      icon: SettingsIcon,
      tooltip: 'Add / Remove Columns',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setFormularyDialogOpen(true);
      },
      isFreeAction: true,
    },
    {
      icon: 'edit',
      tooltip: 'editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        // getFile(rowElement.key);
        setElementToEdit(rowElement);
        setDialogOpen(true);
      },
      hidden: !allowedModuleSections.includes('EDITAR'),
    },
    {
      icon: 'folder',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { curp } = rowElement;
        const path = `${normalizePath('RECURSOS_HUMANOS')}/${curp}/`;
        const specialPath = `${normalizePath('RECURSOS_HUMANOS')}/${curp}_secret/`;
        setUploadPath(path);
        setSpecialUploadPath(specialPath);
        updateParams({ path: `${path}`, bucket: FILES_BUCKET });
        setNewFileDialogOpen(true);
      },
      hidden: !allowedModuleSections.includes('VER ARCHIVOS'),
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setElementToDelete(rowElement);
        setOpenDeleteDialog(true);
      },
      hidden: !allowedModuleSections.includes('EDITAR'),
    },
    (userData) => ({
      icon: 'visibility',
      tooltip: 'Ver detalles del perfil',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        history.push(`/recursos-humanos/${userData.id}`);
      },
    }),
  ];
  const fileTableActions = [
    {
      icon: 'cloud_download',
      tooltip: 'descargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileKey } = rowElement;
        getFile(fileKey);
      },
      hidden: !allowedModuleSections.includes('EDITAR'),
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${secretMode ? specialUploadPath : uploadPath}${fileName}`;
        handleFileDelete(key);
      },
      hidden: !allowedModuleSections.includes('EDITAR'),
    },
  ];
  //---------------------------------------------------------------------------
  // EXCEL
  //---------------------------------------------------------------------------
  const downloadExcel = async () => {
    try {
      setLoading(true);
      const response = await AxiosInstance.get(`/api/employee/list`);
      if (response.status === 200) {
        const records = response?.data;
        await excelRH(records, `Recursos humanos`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  //---------------------------------------------------------------------------
  // Actions
  //---------------------------------------------------------------------------
  const handleDelete = async () => {
    await AxiosInstance.delete(`/api/employee/${elementToDelete.id}`);
    refetch();
    setOpenDeleteDialog(false);
    setElementToDelete(null);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const HumanResourcesTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Table
            title={`Recursos Humanos`}
            data={(humanResourcesElements || []).map(mapElement)}
            columns={TABLE_HEADERS.filter(filterColumns)}
            actions={actions}
          />
        </Grid>
      </Grid>
      <Dialog
        openDialog={dialogOpen}
        dialogTitle={elementToEdit ? 'Editar Registro' : 'Nuevo Registro'}
        maxWidth="lg"
      >
        <NewElementFormulary
          setOpen={setDialogOpen}
          currentElement={elementToEdit}
          refetchInventory={refetch}
        />
      </Dialog>
      <Dialog
        openDialog={formularyDialogOpen}
        dialogTitle={'Configuración de Columnas'}
        maxWidth="md"
      >
        <ColumnEditFormulary
          setOpen={setFormularyDialogOpen}
          // downloadEvents={downloadEvents}
          currentFilter={columnTableState}
          columnFields={columnTableState}
          setColumnFields={setColumnTableState}
        />
      </Dialog>
      <Dialog
        openDialog={newFileDialogOpen}
        dialogTitle={`${!secretMode ? 'Archivo general' : 'Carpeta restringida'}`}
        maxWidth="md"
      >
        <DropZone
          path={secretMode ? specialUploadPath : uploadPath}
          closeDialog={() => setNewFileDialogOpen(false)}
          refetch={() => {
            console.log('refetch');
          }}
          bucket={FILES_BUCKET}
        />
        <DialogActions
          secondaryLabel="Cancelar"
          primaryLabel={null}
          secondaryAction={() => setNewFileDialogOpen(false)}
        />
        <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
              setSecretMode(newValue === 1);
              updateParams({
                path: `${newValue === 1 ? specialUploadPath : uploadPath}`,
                bucket: FILES_BUCKET,
              });
            }}
            aria-label="simple tabs example"
          >
            <Tab label="Archivos Generales" {...a11yProps(0)} />
            {allowedModuleSections.includes('VER ARCHIVOS RESTRINGIDOS') && (
              <Tab label="Archivos Privados" {...a11yProps(1)} />
            )}
          </Tabs>
        </AppBar>
        <Table
          title="Archivos de Elemento"
          data={elementFiles?.files.map((file) => ({
            fileName: file.key.split('/').pop(),
            fileKey: file.key,
          }))}
          columns={FILE_TABLE_HEADERS}
          actions={fileTableActions}
        />
      </Dialog>
      {/* ------------------------------------------------------------------------ */}
      {/* --- DELETE CONFIRM DIALOG ---------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}

      <Alert
        type={WARNING_TYPE}
        title="Borrar elemento de catalogo"
        content="Estas a punto de borrar un elemento de recursos humanos, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenDeleteDialog(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onBackdropPress={() => {
          setOpenDeleteDialog(false);
        }}
      />
    </>
  );

  const renderScreen = () => {
    if (!isLoading && !loading) {
      return (
        <>
          <Button
            onClick={() => downloadExcel()}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Descargar Excel
          </Button>
          {HumanResourcesTable}
        </>
      );
    } else {
      return <CircularProgress />;
    }
  };

  return <div className={styles.container}>{renderScreen()}</div>;
};

export default withNavigation(QuelityLibraryScreen);
