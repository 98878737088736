/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { getInitialValues } from './validation';
import Input from '../../../../Components/FormFields/FormikIconInput';
import Select from '../../../../Components/FormFields/FormikSelect';
import { AxiosContext } from '../../../../Contexts/AxiosContext/AxiosContext';
import { ProjectContext } from '../../../../Contexts/ProjectContext/ProjectContext';
// Icons
import { useStyles } from '../../../InventoryScreen/NewElementFormulary/materialStyles';
import { YES_NO_SELECT } from '../../../../Constants/dropdownsOptions';

function BackupFormulary(props) {
  const { setOpen, currentElement, refetch } = props;
  const styles = useStyles();
  const AxiosInstance = useContext(AxiosContext);
  const [projectState, setProjectState] = useContext(ProjectContext);

  const handlePost = async (values) => {
    const method = currentElement ? 'put' : 'post';
    const isParent = values?.isParent === 'SI';
    const conceptToPublish = { ...currentElement, ...values, isParent };
    const { data: newConcept } = await AxiosInstance[method](
      `/api/project/${projectState.id}/concept`,
      conceptToPublish,
    );
    console.log(newConcept);
    const { concepts } = projectState;

    const newConcepts =
      method === 'post'
        ? [...concepts, newConcept]
        : concepts.map((concept) =>
            concept.id === currentElement.id ? conceptToPublish : concept,
          );
    setProjectState({ ...projectState, concepts: newConcepts });
    refetch && refetch();
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentElement)}
        // validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          console.log(values);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, isSubmitting, values }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={6}>
                <Grid item xs={12} md={3}>
                  <Field name="code">
                    {(props) => (
                      <Input
                        formikName={'code'}
                        label={'Código de concepto (Cliente)'}
                        Icon={PostAddIcon}
                        props={props}
                        type="text"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field name="orderNumber">
                    {(props) => (
                      <Input
                        formikName={'orderNumber'}
                        label={'Orden'}
                        Icon={PostAddIcon}
                        props={props}
                        type="text"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field name="number">
                    {(props) => (
                      <Input
                        formikName={'number'}
                        label={'Número'}
                        Icon={PostAddIcon}
                        props={props}
                        type="text"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field name="parentNumber">
                    {(props) => (
                      <Input
                        formikName={'parentNumber'}
                        label={'Número de Padre'}
                        Icon={PostAddIcon}
                        props={props}
                        type="text"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="conceptName">
                    {(props) => (
                      <Input
                        formikName={'conceptName'}
                        label={'Nombre del concepto'}
                        Icon={PostAddIcon}
                        props={props}
                        type="text"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="isParent">
                    {(props) => (
                      <Select
                        formikName={'isParent'}
                        label={'Concepto Padre'}
                        Icon={PostAddIcon}
                        menuItemList={YES_NO_SELECT}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                {values?.isParent === 'SI' ? null : (
                  <>
                    <Grid item xs={12} md={4}>
                      <Field name="cost">
                        {(props) => (
                          <Input
                            formikName={'cost'}
                            label={'Costo'}
                            Icon={PostAddIcon}
                            props={props}
                            type="number"
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field name="quantity">
                        {(props) => (
                          <Input
                            formikName={'quantity'}
                            label={'Cantidad'}
                            Icon={PostAddIcon}
                            props={props}
                            type="number"
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field name="unitOfMeasure">
                        {(props) => (
                          <Input
                            formikName={'unitOfMeasure'}
                            label={'Unidad de medición'}
                            Icon={PostAddIcon}
                            props={props}
                            type="text"
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={12}>
                  <Field name="conceptDescription">
                    {(props) => (
                      <Input
                        formikName={'conceptDescription'}
                        label={'Descripción'}
                        Icon={PostAddIcon}
                        props={props}
                        type="text"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    {currentElement ? 'Guardar cambios' : 'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default BackupFormulary;
