import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

//ICONS
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ClearIcon from '@material-ui/icons/Clear';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import {
  DataContainer,
  CardTitle,
  ImportantText,
  TextTitle,
  StandardText,
  RegularTitle,
} from './DataCardEmployeeDataStyles';
import { normalizeTitleToUrl } from '../DataCardsUtils';
import { DataCardMainContainer, MoreInfoLink } from '../DataCardStyles';

const DataCardList = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { title = 'Empleado', employeeData: data = {} } = props;

  const handleSeeMore = () => {
    title && history.push(`${pathname}/${normalizeTitleToUrl(title)}`);
  };

  return (
    <DataCardMainContainer>
      <CardTitle>{title}</CardTitle>
      <DataContainer>
        <StandardText>
          <PersonOutlineIcon />
          &ensp;
          <TextTitle>Nombre: </TextTitle>
          <ImportantText>
            {data?.names} {data?.lastName1} {data?.lastName2}
          </ImportantText>
        </StandardText>

        <StandardText>
          <MailOutlineIcon />
          &ensp;
          <TextTitle>Correo: </TextTitle>
          <a href={`mailto:${data.email}`}>{data.email}</a>
        </StandardText>

        <StandardText>
          <PhoneIcon />
          &ensp;
          <TextTitle>Teléfono: </TextTitle>
          <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
        </StandardText>

        <StandardText>
          <AccountCircleIcon />
          &ensp;
          <TextTitle>Usuario: </TextTitle>
          <TextTitle>{data?.user?.username || 'No hay usuario asignado'}</TextTitle>
        </StandardText>

        <StandardText colorType={data?.assignedToProject === true}>
          {data?.assignedToProject === true ? (
            <>
              <DoneOutlineIcon color="inherit" />
              &nbsp;
              <RegularTitle positive={data?.assignedToProject}>SI</RegularTitle>
            </>
          ) : (
            <>
              <ClearIcon color="inherit" />
              &nbsp;
              <RegularTitle positive={data?.assignedToProject}>NO</RegularTitle>
            </>
          )}
          &nbsp; Asignado a Proyecto
        </StandardText>
      </DataContainer>
      <MoreInfoLink onClick={handleSeeMore}>Ver mas ...</MoreInfoLink>
    </DataCardMainContainer>
  );
};

export default DataCardList;
