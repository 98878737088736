import React from 'react';
import { TextField } from '@mui/material';
import { format, parse } from 'date-fns';
import { validateDateObject } from '../../../Constants/generalConstants';

const FormikDatePickerDesktop = ({ label, onChange, onBlur, value, error, ...otherProps }) => {
  return (
    <TextField
      label={label}
      type="date"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(event) => {
        if (event.target.value) {
          const date = parse(event.target.value, 'yyyy-MM-dd', new Date());
          onChange(date);
        }
      }}
      value={value ? format(validateDateObject(value), 'yyyy-MM-dd') : null}
      error={error}
      helperText={error}
      onBlur={onBlur}
      style={{ width: '100%' }}
      {...otherProps}
    />
  );
};

export default FormikDatePickerDesktop;
