import { useContext, useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const useMasterDataCatalogs = ({ entityList, skip }) => {
  const AxiosInstance = useContext(AxiosContext);
  const [catalogs, setCatalogs] = useState(null);

  const fetchConfiguration = {
    axiosInstance: AxiosInstance,
    initialUrl: `/api/masterData/list`,
    skip,
  };
  if (entityList) {
    fetchConfiguration.initialParams = { entityList };
  }
  const { data, updateParams, refetch, isLoading } = useFetch(fetchConfiguration);

  const updateEntityList = (entityList) => {
    updateParams({ entityList });
  };

  useEffect(() => {
    if (data) {
      const _catalogs = data.reduce((accumulator, masterDataElement) => {
        const { id, value, entity } = masterDataElement;
        const selectableOption = {
          itemValue: id,
          id,
          itemLabel: value,
        };

        accumulator[entity] = accumulator[entity]
          ? [...accumulator[entity], selectableOption]
          : [selectableOption];
        return accumulator;
      }, {});
      setCatalogs(_catalogs);
    }
  }, [data]);

  return { catalogs, updateEntityList, refetch, areCatalogsLoading: isLoading };
};
