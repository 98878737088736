import React, { createContext, useState, useEffect } from 'react';
import { createSetAndStoreFunction, loadStoredData } from './UserDataStorageUtils/userDataStorage';
const userContext = createContext([{}, () => {}]);

const UserContextProvider = (props) => {
  const [userState, setUserState] = useState({});

  useEffect(() => {
    const retrieveData = async () => {
      const storedData = await loadStoredData();
      setUserState(storedData);
    };
    retrieveData();
  }, [setUserState]);

  const setAndStorUserState = createSetAndStoreFunction(setUserState);

  return (
    <userContext.Provider value={[userState, setAndStorUserState]}>
      {props.children}
    </userContext.Provider>
  );
};

export { userContext, UserContextProvider };
