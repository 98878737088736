import { parse as parseDate, format as formatDate } from 'date-fns';
import { BACKEND_DATE_FORMAT } from '../../Constants/generalConstants';

export const PAID_ESTIMATION_STATUS = 'PAID';
export const UNPAID_ESTIMATION_STATUS = 'PAYMENT_PENDING';

const MONTHS = [
  'ENERO',
  'FEBRERO',
  'MARZO',
  'ABRIL',
  'MAYO',
  'JUNIO',
  'JULIO',
  'AGOSTO',
  'SEPTIEMBRE',
  'OCTUBRE',
  'NOVIEMBRE',
  'DICIEMBRE',
];

const parseBackendDate = (backendDateString) => {
  return parseDate(backendDateString, BACKEND_DATE_FORMAT, new Date());
};

const calcMonthsBeteewnTwoDates = (date1, date2) => {
  const dateObject1 = parseBackendDate(date1);
  const dateObject2 = parseBackendDate(date2);

  const months1 = dateObject1.getMonth() + 1 + dateObject1.getFullYear() * 12;
  const months2 = dateObject2.getMonth() + 1 + dateObject2.getFullYear() * 12;

  return Math.abs(months1 - months2);
};

const getHeaderFromDateObject = (dateObject) => {
  const month = dateObject.getMonth();
  const year = dateObject.getFullYear();
  return `${MONTHS[month]} - ${year}`;
};
const getHeaderFromDate = (date) => {
  const dateObject = parseBackendDate(date);
  return getHeaderFromDateObject(dateObject);
};

const createHeadersFromDates = (date, monthsBack) => {
  const dateObject = parseBackendDate(date);

  const headers = [];

  for (let i = 0; i < monthsBack; i++) {
    headers.push(getHeaderFromDateObject(dateObject));
    const startMonth = dateObject.getMonth();
    dateObject.setMonth(startMonth - 1);
  }

  return headers.reverse();
};

export const createHeaderArray = (costs) => {
  const datesTimes = costs.map((cost) => parseBackendDate(cost.date)).map((date) => date.getTime());
  const minTime = Math.min(...datesTimes, new Date().getTime());
  const maxTime = Math.max(...datesTimes, new Date().getTime());

  const minIndex = datesTimes.findIndex((date) => date === minTime);
  const maxIndex = datesTimes.findIndex((date) => date === maxTime);

  const minString = costs[minIndex]?.date || formatDate(new Date(), BACKEND_DATE_FORMAT);
  const maxString = costs[maxIndex]?.date || formatDate(new Date(), BACKEND_DATE_FORMAT);
  const monthsBetweenDates = calcMonthsBeteewnTwoDates(minString, maxString);
  // { title: "Concepto", field: "concept"},

  const monthsHeaders = createHeadersFromDates(
    maxString,
    monthsBetweenDates > 12 ? monthsBetweenDates : 12,
  ).map((monthHeader) => ({ title: monthHeader, field: monthHeader }));
  return [
    { title: 'Concepto', field: 'concept' },
    ...monthsHeaders,
    { title: 'Total', field: 'total' },
  ];
}; // [<MONTH> - <YEAR>, <MONTH> - <YEAR>, <MONTH> - <YEAR>, <MONTH> - <YEAR>]

const createCeroRow = (headers) => {
  const ceroRow = headers
    .filter((header) => !['concept', 'total'].includes(header.field))
    .map((header) => header.title)
    .reduce((accumulator, currentMonth) => {
      accumulator[currentMonth] = 0;
      return accumulator;
    }, {});
  return ceroRow;
};

const getTotalPerRow = (row) => {
  const total = Object.keys(row)
    .filter((key) => !['total', 'concept'].includes(key))
    .reduce((accumulator, key) => {
      return accumulator + row[key];
    }, 0);
  return total;
};

export const getTotalPerMonth = (costs) => {
  const costsByMonth = (costs || []).reduce((accumulator, currentCost) => {
    const { date } = currentCost;
    const key = getHeaderFromDate(date);
    accumulator[key] = (accumulator[key] ?? 0) + currentCost.cost;
    return accumulator;
  }, {});

  const headers = createHeaderArray(costs);
  const ceroRow = createCeroRow(headers);

  const total = getTotalPerRow(costsByMonth);

  return {
    ...ceroRow,
    ...costsByMonth,
    concept: 'Totales por mes',
    total,
  };
};

export const getCostsRows = (costs) => {
  const concepts = costs
    .reduce((accumulator, cost) => {
      const { concept } = cost;
      return accumulator.includes(concept) ? accumulator : [...accumulator, concept];
    }, [])
    .map((concept) => ({ concept }));

  concepts.forEach((conceptObject, conceptIndex) => {
    const { concept } = conceptObject;
    const conceptElements = costs.filter((cost) => cost.concept === concept);
    conceptElements.forEach((conceptElement) => {
      const { cost, date } = conceptElement;
      const column = getHeaderFromDate(date);
      concepts[conceptIndex][column] = (concepts[conceptIndex][column] ?? 0) + cost;
    });
  });

  const finalRows = concepts.map((concept) => {
    const total = getTotalPerRow(concept);
    const headers = createHeaderArray(costs);
    const ceroRow = createCeroRow(headers);
    const finalRow = { ...ceroRow, ...concept, total };
    return finalRow;
  }, []);

  return finalRows;
};

export const getAccumulatedMonthlyExpense = (costs) => {
  const headers = createHeaderArray(costs).filter(
    (header) => !['concept', 'total'].includes(header.field),
  );
  const monthlyTotals = getTotalPerMonth(costs);
  for (let i = 1; i < headers.length; i++) {
    const currentMonth = headers[i].field;
    const prevMonth = headers[i - 1].field;
    monthlyTotals[currentMonth] = monthlyTotals[currentMonth] + monthlyTotals[prevMonth];
  }
  monthlyTotals.concept = 'Saldo acumulado gasto';
  return monthlyTotals;
};

// getMilestonesPerMonth
/**
 * Separar los milestones por mes
 * calcular el costo monetario a partir de los porcentajes y los costos de los conceptos.
 * acomodar los totales por mes
 */

const calculateCostsFromMilestone = (milestone) => {
  const {
    percentage,
    concept: { cost, quantity },
  } = milestone;

  const milestoneCost = cost * (percentage / 100) * quantity;
  return milestoneCost;
};

export const getMilestonesPerMonth = (milestones, costs) => {
  const arrangedMilestones = milestones.reduce((accumulator, current) => {
    const header = getHeaderFromDate(current.dueDate);
    accumulator[header] = (accumulator[header] ?? 0) + calculateCostsFromMilestone(current);
    return accumulator;
  }, {});

  const headers = createHeaderArray(costs);
  const ceroRow = createCeroRow(headers);
  let total = 0;

  headers.forEach(({ field }) => {
    const scheduledAmount = arrangedMilestones[field];
    ceroRow[field] = scheduledAmount ?? 0;
    if (!['concept', 'total'].includes(field)) {
      total += scheduledAmount ?? 0;
    }
  });
  const result = { ...ceroRow, concept: 'Monto programada sin IVA', total };
  return result;
};
/**
 * Get awaiting payment estimations
 */

export const getUnpaidEstimations = (estimations, costs) => {
  const unpaidEstimations = estimations.filter(
    (estimation) => estimation.estimationStatus === UNPAID_ESTIMATION_STATUS,
  );
  const headers = createHeaderArray(costs);
  const ceroRow = createCeroRow(headers);
  const arrangedEstimations = unpaidEstimations.reduce((accumulator, currentEstimation) => {
    const { creationDate, estimationTotal } = currentEstimation;
    const dateKey = getHeaderFromDate(creationDate);
    accumulator[dateKey] = (accumulator[dateKey] ?? 0) + estimationTotal;
    return accumulator;
  }, {});

  const total = unpaidEstimations.reduce((accumulator, currentEstimation) => {
    return accumulator + currentEstimation.estimationTotal;
  }, 0);

  return {
    ...ceroRow,
    ...arrangedEstimations,
    total,
    concept: 'Monto estimado sin IVA',
  };
};

export const getPaidEstimations = (estimations, costs) => {
  const paidEstimations = estimations.filter(
    (estimation) => estimation.estimationStatus === PAID_ESTIMATION_STATUS,
  );
  const headers = createHeaderArray(costs);
  const ceroRow = createCeroRow(headers);
  const arrangedEstimations = paidEstimations.reduce((accumulator, currentEstimation) => {
    const { creationDate, estimationTotal } = currentEstimation;
    const dateKey = getHeaderFromDate(creationDate);
    accumulator[dateKey] = (accumulator[dateKey] ?? 0) + estimationTotal;
    return accumulator;
  }, {});

  const total = paidEstimations.reduce((accumulator, currentEstimation) => {
    return accumulator + currentEstimation.estimationTotal;
  }, 0);

  return {
    ...ceroRow,
    ...arrangedEstimations,
    total,
    concept: 'Monto cobrado sin IVA',
  };
};

export const getAccumulatedMonthlyPayments = (estimations, costs) => {
  const paidEstimations = estimations.filter(
    (estimation) => estimation.estimationStatus === PAID_ESTIMATION_STATUS,
  );
  const headers = createHeaderArray(costs);
  const ceroRow = createCeroRow(headers);

  const arrangedEstimations = paidEstimations.reduce((accumulator, currentEstimation) => {
    const { creationDate, estimationTotal } = currentEstimation;
    const dateKey = getHeaderFromDate(creationDate);
    accumulator[dateKey] = (accumulator[dateKey] ?? 0) + estimationTotal;
    return accumulator;
  }, {});

  const row = { ...ceroRow, ...arrangedEstimations };
  let total = 0;

  const monthHeaders = headers.filter((header) => !['concept', 'total'].includes(header.field));
  for (let i = 1; i < monthHeaders.length; i++) {
    const { field: currentField } = monthHeaders[i];
    const { field: pastField } = monthHeaders[i - 1];
    row[currentField] = row[currentField] + row[pastField];
    total = total < row[currentField] ? row[currentField] : total;
  }
  return { ...row, concept: 'Saldo acumulado ingreso', total };
};

export const getAccumulatedDifferences = (estimations, costs) => {
  const accumulatedPayments = getAccumulatedMonthlyPayments(estimations, costs);
  const accumulatedCosts = getAccumulatedMonthlyExpense(costs);

  const monthHeaders = Object.keys(accumulatedPayments).filter(
    (header) => !['concept', 'total'].includes(header),
  );

  let total = Number.NEGATIVE_INFINITY;
  monthHeaders.forEach((header) => {
    accumulatedPayments[header] = accumulatedPayments[header] - accumulatedCosts[header];
    total = total < accumulatedPayments[header] ? accumulatedPayments[header] : total;
  });

  return {
    ...accumulatedPayments,
    concept: 'Acumulado (Cobrado-Gastado)',
    total,
  };
};

export const getDifferences = (estimations, costs) => {
  const monthlyCosts = getTotalPerMonth(costs);
  const monthlyPayments = getPaidEstimations(estimations, costs);

  const monthHeaders = Object.keys(monthlyCosts).filter(
    (header) => !['concept', 'total'].includes(header),
  );

  let total = 0;
  monthHeaders.forEach((header) => {
    monthlyPayments[header] = monthlyPayments[header] - monthlyCosts[header];
    total = total + monthlyPayments[header];
  });

  return { ...monthlyPayments, concept: 'Diferencia (Cobrado-Gastado)', total };
};
