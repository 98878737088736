import React from 'react';
import { useHistory } from 'react-router-dom';
import { CardTitle, DataContainer } from './DataCardProfileDetailsStyles';
import { DataCardMainContainer, MoreInfoLink } from '../DataCardStyles';
import Table from '../../Table';

const DataCardList = (props) => {
  const history = useHistory();
  const { profile } = props;
  const { grants = [], id = '' } = profile || {};

  const handleSeeMore = () => {
    history.push(`/permisos/${id}`);
  };

  const TABLE_HEADERS = [
    { title: 'Nombre', field: 'name' },
    { title: 'Modulo', field: 'module' },
  ];

  const mapGrants = (leGrants = []) => {
    const generalModules = leGrants.reduce((accumulator, current) => {
      return accumulator.map(({ name }) => name).includes(current.module)
        ? accumulator
        : [...accumulator, { name: current.module, module: 'GENERAL' }];
    }, []);

    return [...leGrants, ...generalModules];
  };

  return (
    <DataCardMainContainer>
      <CardTitle>{profile?.name || 'No hay perfil asignado'}</CardTitle>
      {profile ? (
        <>
          <DataContainer>
            <Table
              data={mapGrants(grants)}
              columns={TABLE_HEADERS}
              options={{ pageSize: 5 }}
              // actions={actions}
              parentChildData={(row, rows) => rows.find((a) => a.name === row.module)}
            />
          </DataContainer>
          <MoreInfoLink onClick={handleSeeMore}>Ver mas ...</MoreInfoLink>
        </>
      ) : null}
    </DataCardMainContainer>
  );
};

export default DataCardList;
