import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  user: yup.string().required('Campo Obligatorio'),
});

export const getInitialValues = (currentElement = {}) => {
  const { user } = currentElement || {};
  return {
    user: user || '',
  };
};
