import { Button, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { FakeButton } from './../../../QualityProfilesScreen/QualityProfilesScreenStyles';
import Table from '../../../../Components/Table';
import axios from '../../../../Utils/AxiosS3Instance';
import { deleteFile } from '../../../../Utils/s3Utils';

const EstimationDocuments = ({
  documentFiles,
  invoiceFiles,
  setDocumentFiles,
  setInvoiceFiles,
  refetchInvoices,
  refetchDocuments,
  onClose,
  onDocument,
  onInvoice,
}) => {
  const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];
  const s3Bucket = 'roshare-estimations-files';

  const fileTableActions = [
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        console.log(rowElement);
        const { fileName, category, key } = rowElement;
        if (category == 'document') {
          setDocumentFiles(documentFiles.filter((doc) => doc.file.name !== fileName));
        } else if (category == 'invoice') {
          setInvoiceFiles(invoiceFiles.filter((doc) => doc.file.name !== fileName));
        }
        if (key) {
          deleteFile(key, s3Bucket, () => {
            // setDeleteOpenAlert(false);
            refetchInvoices();
            refetchDocuments();
          });
        }
      },
    },
    (rowData) => {
      console.log(rowData);
      if (!rowData.key) {
        return {
          icon: () => <CircularProgress />,
        };
      }
      return {
        icon: 'cloud',
        tooltip: 'descargar',
        iconProps: {
          color: 'primary',
        },
        onClick: async (event, rowElement) => {
          console.log(rowElement);
          const { key } = rowElement;
          const signedUrl = (await axios.get(`/s3-object/url?key=${key}&bucket=${s3Bucket}`)).data;

          window.open(signedUrl, '_blank');
        },
      };
    },
  ];

  const invoiceDocumentAction = {
    icon: () => <FakeButton style={{ padding: '10px' }}>Cargar archivo</FakeButton>,
    tooltip: 'Cargar archivo',
    onClick: () => {
      onInvoice && onInvoice();
    },
    isFreeAction: true,
  };
  const normalDocumentAction = {
    icon: () => <FakeButton style={{ padding: '10px' }}>Cargar archivo</FakeButton>,
    tooltip: 'Cargar archivo',
    onClick: () => {
      onDocument && onDocument();
    },
    isFreeAction: true,
  };

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={6}>
          <Table
            title="Documentos"
            data={documentFiles?.map(({ file, meta, key }) => {
              return {
                key,
                fileName: file.name,
                category: 'document',
                ...meta,
              };
            })}
            columns={FILE_TABLE_HEADERS}
            actions={[...fileTableActions, normalDocumentAction]}
            options={{
              pageSize: 5,
              pageSizeOptions: [5, 10, 15, 25],
              search: false,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Table
            title="Facturas"
            data={invoiceFiles?.map(({ file, _meta, key }) => {
              console.log(_meta);
              return {
                key,
                fileName: file.name,
                category: 'invoice',
              };
            })}
            columns={FILE_TABLE_HEADERS}
            actions={[...fileTableActions, invoiceDocumentAction]}
            options={{
              pageSize: 5,
              pageSizeOptions: [5, 10, 15, 25],
              search: false,
            }}
          />
        </Grid>
        {onClose && (
          <Grid item>
            <Button onClick={onClose} variant="outlined" color="secondary" type="submit">
              Cerrar
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EstimationDocuments;
