/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
// MUI
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
// CSS in React
// Internal components
// Hooks
//Material UI
import Button from '@material-ui/core/Button';
// Icons
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { useStyles } from './ForgotPasswordStyles';
import { validationSchema, getInitialValues } from './validation';

import * as Constants from './../../../Constants/urls';
import * as CognitoErrors from './../../../Constants/cognitoErrorCodes';
import Input from '../../../Components/FormFields/FormikIconInputOutlined';

export default function ForgotPasswordForm({ setCloseDialog }) {
  const styles = useStyles();

  const [codeReceived, setCodeReceived] = useState(false);

  const invokeForgotPassword = (formFields) => {
    axios
      .post(
        `${Constants.API_ENDPOINT}/auth/forgot-password`,
        {
          username: formFields.email,
        },
        {
          headers: {
            deviceType: 'WEB',
            latitude: 1,
            longitude: 1,
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setCodeReceived(true);
      })
      .catch((error) => {
        alert('Error al solicitar código de verificación ');
        console.error(error?.response);
      });
  };

  const invokeConfirmForgotPassword = (formFields) => {
    axios
      .post(
        `${Constants.API_ENDPOINT}/auth/confirm-forgot-password`,
        {
          username: formFields.email,
          newPassword: formFields.newPassword,
          resetCode: formFields.resetCode,
        },
        {
          headers: {
            deviceType: 'WEB',
            latitude: 1,
            longitude: 1,
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setCodeReceived(false);
        setCloseDialog(true);
      })
      .catch((error) => {
        console.log(error?.response?.data);
        if (error?.response?.data?.errorCode === CognitoErrors.INVALID_RESET_CODE) {
          alert('Código de verificación incorrecto');
        } else alert('Error al restablecer contraseña');
      });
  };
  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
          setSubmitting(true);
          if (codeReceived) {
            await invokeConfirmForgotPassword(values);
            if (!codeReceived) {
              resetForm();
            }
          } else {
            invokeForgotPassword(values);
            console.log(values);
            setErrors({});
          }
          setSubmitting(false);
        }}
        validationSchema={validationSchema(codeReceived)}
      >
        {({ handleSubmit }) => {
          return (
            <Form className={styles.formulary} onSubmit={handleSubmit}>
              <Divider className={styles.divider} />
              <Grid
                container
                spacing={2}
                alignItems="flex-end"
                justify="space-around"
                className={styles.commonDataContainer}
              >
                <Grid item xs={12}>
                  <Field name="email">
                    {(props) => (
                      <Input formikName="email" label="Correo Electronico" props={props} />
                    )}
                  </Field>
                </Grid>

                {codeReceived && (
                  <>
                    <Grid item xs={12}>
                      <Field name="resetCode">
                        {(props) => (
                          <Input
                            formikName="resetCode"
                            label="Código de Verificiación"
                            props={props}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="newPassword">
                        {(props) => (
                          <Input
                            formikName="newPassword"
                            label="Nueva contraseña"
                            type="text"
                            props={props}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="passwordConfirmation">
                        {(props) => (
                          <Input
                            formikName="passwordConfirmation"
                            label="Confirmar Nueva contraseña"
                            type="text"
                            props={props}
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider className={styles.divider} />

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => setCodeReceived(true)} color="primary">
                  Ya tengo un código
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: '1rem' }}
                  type="submit"
                >
                  Aceptar
                </Button>

                <Button
                  onClick={setCloseDialog}
                  endIcon={<CloseOutlined color="secondary" />}
                  variant="outlined"
                  color="primary"
                  style={{ margin: '1rem' }}
                >
                  Cancelar
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
