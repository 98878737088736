import React, { useEffect, useContext, useState } from 'react';
// Commons components

// Constants
// import { masterData } from './../../Constants';
// CSS in JS
// import { useStyles } from './MasterDataStyles';
// Hooks
import { useFetch } from './../../Hooks/useFetch';
// Screen components
import MasterDataTable from './MasterDataTable/MasterDataTable';
// import ButtonPad from "./ButtonPad/ButtonPad";
import ButtonPad from './../../Components/ButtonPad/ButtonPad';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';

const MasterData = () => {
  const [masterData, setMasterData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [type, setType] = useState(null);

  const AxiosInstance = useContext(AxiosContext);
  const { data: dbData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/masterData/list',
  });

  useEffect(() => {
    console.log(dbData);
    if (dbData) {
      setMasterData(dbData);
    }
  }, [dbData]);

  useEffect(() => {
    type
      ? setTableData(type ? masterData.filter((e) => e.entity === type) : null)
      : setTableData(null);
  }, [masterData, type]);

  const deleteLocalData = (element) => {
    setMasterData((prevState) => prevState.filter((e) => e.id !== element.id));
  };

  const addLocalData = (element) => {
    setMasterData((prevState) => [...prevState, element]);
  };

  const updateLocalData = (element) => {
    setMasterData((prevState) => prevState.map((e) => (e.id !== element.id ? e : element)));
  };

  const getTypes = (data = []) => {
    const result = (data || []).reduce(
      (previous, current) =>
        previous.includes(current.entity) ? previous : [...previous, current.entity],
      [],
    );
    return result.map((type) => {
      return {
        label: type,
        icon: null,
      };
    });
  };

  const selectType = (type) => {
    setType(type?.label || null);
  };

  const masterDataTable = (
    <>
      <MasterDataTable
        data={tableData}
        selectType={selectType}
        elementName={type}
        deleteLocalData={deleteLocalData}
        addLocalData={addLocalData}
        updateLocalData={updateLocalData}
      />
    </>
  );

  const buttonPad = (
    <ButtonPad title="Valores de catálogos" types={getTypes(masterData)} selectType={selectType} />
  );

  return tableData ? masterDataTable : buttonPad;
};

export default withNavigation(MasterData);
