import React from 'react';
import { useSnackbar } from 'notistack';
import getBrowserVersion from '../../Utils/getBrowserVersion';

function BrowserValidator() {
  const { enqueueSnackbar } = useSnackbar();
  const LATEST_SUPPORTED_CHROME_VERSION = '99.0.4844.83';

  React.useEffect(() => {
    const { browser, version } = getBrowserVersion();
    if (browser === 'Chrome') {
      const masterVersion = version.split('.')[0];
      const middleVersion = version.split('.')[2];
      const latestMasterVersion = LATEST_SUPPORTED_CHROME_VERSION.split('.')[0];
      const latestMiddleVersion = LATEST_SUPPORTED_CHROME_VERSION.split('.')[2];
      const errorMessage = `Te sugerimos actualizar tu versión de Chrome para el correcto funcionamiento del sistema. Mínimo ${LATEST_SUPPORTED_CHROME_VERSION}`;

      if (Number(masterVersion) < Number(latestMasterVersion)) {
        console.log(errorMessage);
        enqueueSnackbar(errorMessage, {
          variant: 'warning',
          preventDuplicate: true,
          persist: true,
        });
      } else if (
        Number(masterVersion) === Number(latestMasterVersion) &&
        Number(middleVersion) < Number(latestMiddleVersion)
      ) {
        console.log(errorMessage);
        enqueueSnackbar(errorMessage, {
          variant: 'warning',
          preventDuplicate: true,
        });
      }
    }
  }, []);

  return <></>;
}

export default BrowserValidator;
