import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Campo Obligatorio'),
});

export const getInitialValues = (currentElement = {}) => {
  const { name } = currentElement || {};
  return {
    name: name || '',
  };
};
