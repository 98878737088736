import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: 0;
`;

export const SideBarStyle = styled.div`
  display: inline-block;
  top: 10;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
`;

export const MapContainerStyle = styled.div`
  min-height: 250px;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
`;

export const CardTitle = styled.h1`
  font-size: 30px;
  margin: 20px;
  text-align: center;
  border-bottom: solid #707070 1px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid #f0f0f0 1px;
`;

export const ListText = styled.p`
  font-size: 20px;
  color: #707070;
  margin: 5px;
  cursor: pointer;
`;
