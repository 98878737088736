import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Campo obligatorio'),
});

export const getInitialValues = () => {
  return {
    name: '',
  };
};

export const preparePayload = () => {
  return {};
};
