import { CircularProgress } from '@material-ui/core';
import React from 'react';
import ExpiringDateIndicator from './components/ExpiringDateIndicator/ExpiringDateIndicator';
import {
  VehicleStateScreenMainContainer,
  VehicleStateScreenHeader,
  VehicleStateScreenTitle,
  VehicleStateTableContainer,
} from './VehicleStateScreen.styles';
import Table from '../../Components/Table';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { useVehicles } from '../../Hooks/useVehicles';

const VehicleStateScreen = () => {
  const HEADERS = [
    { title: 'ID', field: 'stockId' },
    { title: 'Marca', field: 'brand.value' },
    { title: 'Modelo', field: 'model.value' },
    { title: 'Placas', field: 'plate' },
    {
      title: 'Exp. de Poliza',
      field: 'vehicleRecord.insuranceExpirationDate',
      render: (rowData) => (
        <ExpiringDateIndicator currentDate={rowData?.vehicleRecord?.insuranceExpirationDate} />
      ),
    },
    {
      title: 'Exp. tarjeta de circ.',
      field: 'vehicleRecord.circulationCardExpirationDate',
      render: (rowData) => (
        <ExpiringDateIndicator
          currentDate={rowData?.vehicleRecord?.circulationCardExpirationDate}
        />
      ),
    },
    {
      title: 'Próxima Verificación',
      field: 'vehicleRecord.nextVerificationDate',
      render: (rowData) => (
        <ExpiringDateIndicator currentDate={rowData?.vehicleRecord?.nextVerificationDate} />
      ),
    },
  ];

  const { vehicles } = useVehicles();

  const actions = [
    {
      icon: 'visibility',
      tooltip: 'ver detalles',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {},
    },
  ];

  return (
    <VehicleStateScreenMainContainer>
      <VehicleStateScreenHeader>
        <VehicleStateScreenTitle>Reporte de flota vehicular</VehicleStateScreenTitle>
      </VehicleStateScreenHeader>
      <VehicleStateTableContainer>
        {vehicles?.length ? (
          <Table
            title="Detalle de vehículos"
            data={vehicles || []}
            columns={HEADERS}
            actions={actions}
            options={{
              filtering: true,
            }}
          />
        ) : (
          <CircularProgress />
        )}
      </VehicleStateTableContainer>
    </VehicleStateScreenMainContainer>
  );
};

export default withNavigation(VehicleStateScreen);
