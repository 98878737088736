import React, { useContext } from 'react';
import MapComponent from './Components/MapComponent/MapComponent';
import { ShoppingMapContainer } from './ShoppingMap.styled';
import { MapContainer } from './Components/MapComponent/MapComponent.styled';
import SearchForm from './Components/SearchForm/SearchForm';
import { CartStateProvider } from './Store/CartState';
import MapButtons from './Components/MapButtons/MapButtons';
import CartModal from './Components/CartModal/CartModal';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { userContext } from '../../Contexts/UserContext/UserContext';

const ShoppingMap = () => {
  const [userState] = useContext(userContext);
  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};

  const permisos = grants
    .filter((grant) => grant.module === 'CARRITO DE COMPRAS')
    .find((grant) => grant.name === 'CREAR CARRITO');

  return (
    <ShoppingMapContainer>
      <CartStateProvider>
        <MapContainer>
          <MapComponent />
          <SearchForm />
          {!!permisos && <MapButtons />}
          <CartModal />
        </MapContainer>
      </CartStateProvider>
    </ShoppingMapContainer>
  );
};

export default withNavigation(ShoppingMap);
