import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DataCardMainContainer, MoreInfoLink } from './../DataCardStyles';
import {
  AmountContainer,
  AmountText,
  DataContainer,
  IconContainer,
  CardTitle,
} from './DataCardAmountStyles';
import { normalizeTitleToUrl } from './../DataCardsUtils';

const DataCardAmaunt = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { amount, title, Icon, customHandleSeeMoreClick } = props;

  const handleSeeMoreClick = () => {
    history.push(`${pathname}/${normalizeTitleToUrl(title)}`);
  };

  return (
    <DataCardMainContainer>
      <CardTitle>{title}</CardTitle>
      <DataContainer>
        {!Number.isNaN(Number(amount)) && (
          <AmountContainer>
            <AmountText>{amount}</AmountText>
          </AmountContainer>
        )}
        {Icon && (
          <IconContainer>
            <Icon fontSize="inherit" color="primary" />
          </IconContainer>
        )}
      </DataContainer>
      <MoreInfoLink onClick={customHandleSeeMoreClick || handleSeeMoreClick}>
        Ver más...
      </MoreInfoLink>
    </DataCardMainContainer>
  );
};

export default DataCardAmaunt;
