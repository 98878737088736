import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Campo obligatorio'),
  username: yup.string().email('Escriba un correo válido').required('Campo obligatorio'),
});

export const getInitialValues = (user) => {
  return {
    name: user?.name || '',
    username: user?.username || '',
    profile: user?.profileObject?.id || '',
  };
};

export const preparePayload = () => {
  return {};
};
