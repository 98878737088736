/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
// Icons
import ScatterPlot from '@material-ui/icons/ScatterPlot';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { useStyles } from './materialStyles';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
import { getInitialValues, validationSchema } from './validation';
import FormikAutocomplete from './../../../Components/AutoComplete/FormikAutocomplete';
import { mapValues } from './postMapper';
import { useMasterDataCatalogs } from '../../../Hooks/useMasterdataCatalogs';
import { useProjectsForCatalogs } from '../../../Hooks/useProjectsForCatalogs';
import { useUsersForCatalogs } from '../../../Hooks/useUsersForCatalogs';
import Input from '../../../Components/FormFields/FormikIconInput';

const BackupFormulary = (props) => {
  const { setOpen, currentElement, refetchInventory } = props;
  const styles = useStyles();
  const AxiosInstance = useContext(AxiosContext);
  const [originalProjectId] = useState(
    currentElement?.project ? currentElement?.project.id : undefined,
  );

  const { catalogs } = useMasterDataCatalogs({
    entityList: ['Puestos', 'Areas', 'Titulos'],
  });
  const projectsInPlatform = useProjectsForCatalogs();
  const usersInPlatform = useUsersForCatalogs();

  const handlePost = async (values) => {
    const payload = mapValues(values, currentElement);
    const method = currentElement ? 'put' : 'post';
    const { projects } = payload;
    const deleteInventoryFromProject = !projects?.length;

    if (method === 'put') {
      payload.id = currentElement.id;
    }

    console.log(payload);
    const result = await AxiosInstance[method]('/api/employee', payload);

    if (deleteInventoryFromProject && originalProjectId) {
      await AxiosInstance.delete(`/api/project/${originalProjectId}/employee/${result.data.id}`);
    }

    if (!deleteInventoryFromProject && projects[0].id !== originalProjectId) {
      if (originalProjectId) {
        await AxiosInstance.delete(`/api/project/${originalProjectId}/employee/${result.data.id}`);
      }
      const { id: projectId } = projects[0];
      await AxiosInstance.post(`/api/project/${projectId}/employee/${result.data.id}`);
    }

    refetchInventory();
  };

  return !catalogs ? null : (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentElement)}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, isSubmitting, setFieldValue, setFieldTouched }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={1}>
                <Grid item xs={12} md={4}>
                  <Field name="names">
                    {(props) => (
                      <Input
                        formikName={'names'}
                        label={'Nombre(s)'}
                        Icon={PermIdentityIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="lastName1">
                    {(props) => (
                      <Input
                        formikName={'lastName1'}
                        label={'Apellido Paterno'}
                        Icon={PermIdentityIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="lastName2">
                    {(props) => (
                      <Input
                        formikName={'lastName2'}
                        label={'Apellido Materno'}
                        Icon={PermIdentityIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="phoneNumber">
                    {(props) => (
                      <Input
                        formikName={'phoneNumber'}
                        label={'Teléfono'}
                        Icon={PhoneIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="email">
                    {(props) => (
                      <Input
                        type="email"
                        formikName={'email'}
                        label={'Email'}
                        Icon={EmailIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="curp">
                    {(props) => (
                      <Input formikName={'curp'} label={'CURP'} Icon={ScatterPlot} {...props} />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="rfc">
                    {(props) => (
                      <Input formikName={'rfc'} label={'RFC'} Icon={ScatterPlot} {...props} />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="socialSecurityNumber">
                    {(props) => (
                      <Input
                        formikName={'socialSecurityNumber'}
                        label={'# Seguro Social'}
                        Icon={ScatterPlot}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="company">
                    {(props) => (
                      <Input
                        formikName={'company'}
                        label={'Empresas'}
                        Icon={ApartmentIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="professionalLicense">
                    {(props) => (
                      <Input
                        formikName={'professionalLicense'}
                        label={'Cédula Profesional'}
                        Icon={ScatterPlot}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Field name="degree.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Título / Profesión'}
                        options={catalogs['Titulos']}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Field name="position.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Puesto'}
                        options={catalogs['Puestos']}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="area.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Area'}
                        options={catalogs['Areas']}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="jobStartDate">
                    {(props) => (
                      <Input
                        formikName={'jobStartDate'}
                        label={'Fecha de Ingreso'}
                        Icon={CalendarTodayIcon}
                        {...props}
                        type="date"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="bankName">
                    {(props) => (
                      <Input
                        formikName={'bankName'}
                        label={'Institución Bancaria'}
                        Icon={AccountBalanceIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="bankAccountNumber">
                    {(props) => (
                      <Input
                        formikName={'bankAccountNumber'}
                        label={'# Cuenta Bancaria'}
                        Icon={AccountBalanceIcon}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Field name="user.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Usuario de plataforma'}
                        options={usersInPlatform}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Field name="project">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Proyecto'}
                        options={projectsInPlatform}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={12}></Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    {currentElement ? 'Guardar cambios' : 'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default BackupFormulary;
