import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: 0;
`;

export const CardTitle = styled.h1`
  font-size: 30px;
  margin: 20px;
  text-align: center;
  border-bottom: solid #707070 1px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid #f0f0f0 1px;
`;

export const ListText = styled.p`
  font-size: 20px;
  color: #707070;
  margin: 5px;
  cursor: pointer;
`;
export const ImportantText = styled.h4`
  font-size: 20px;
  color: 'black';
  cursor: pointer;
  margin: 5px;
  padding: 0px;
`;
export const StandardText = styled.div`
  /* font-size:20px;
    cursor: pointer;
    font-weight: normal;
    margin: 5px;
    padding: 0px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-bottom: solid 1px grey;
  ${(props) => {
    const color = `color: ${props.colorType}` === 'positive' ? 'green' : 'red';
    return props.colorType ? color : '';
  }};
`;

export const TextTitle = styled.h4`
  font-weight: normal;
  display: inline;
  font-size: 20px;
  cursor: pointer;
  font-weight: normal;
  margin: 5px;
  padding: 0px;
`;

export const RegularTitle = styled.h4`
  color: ${(props) => (props.positive ? 'green' : 'red')};
  display: inline;
  margin: 0px;
  padding: 0px;
`;
