import { Button, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useHistory } from 'react-router-dom';
import { getInitialValues, validationSchema } from './validation';
import { useStyles } from './materialStyles';
import Input from './../../../Components/FormFields/FormikIconInput';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
import Alert, { WARNING_TYPE } from '../../../Components/Alert/Alert';

const NewProfileFormulary = (props) => {
  const { grantsState, currentProfile } = props;
  const styles = useStyles();
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);
  const [noNameWarningVisibility, setNoNameWarningVisibility] = useState(false);

  const createPayload = (values) => {
    const { name } = values;
    const grants = Object.keys(grantsState).reduce(
      (accumulator, current) => [...accumulator, ...grantsState[current]],
      [{ id: 83 }],
    );
    const payload = {
      name,
      grants,
    };
    if (currentProfile) {
      payload.id = currentProfile.id;
    }
    return payload;
  };

  const handlePost = async (values) => {
    const method = !currentProfile || currentProfile === 'new' ? 'post' : 'put';
    await AxiosInstance[method]('/api/profile', createPayload(values));
    history.push('/perfiles');
  };

  return (
    <>
      <Grid container>
        <Formik
          initialValues={getInitialValues(currentProfile)}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            handlePost(values);
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
        >
          {({ isValid, isSubmitting }) => {
            return (
              <Form className={styles.formulary}>
                <Grid container alignItems="center" justify="space-between" spacing={6}>
                  <Grid item xs>
                    <Field name="name">
                      {(props) => (
                        <Input
                          formikName={'name'}
                          label={'Nombre del perfil'}
                          Icon={PostAddIcon}
                          props={props}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        history.push('/perfiles');
                      }}
                      disabled={isSubmitting}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setNoNameWarningVisibility(!isValid);
                      }}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {currentProfile ? 'Actualizar' : 'Guardar'}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        {/* ------------------------------------------------------------------------ */}
        {/* --- NO NAME WARNING ---------------------------------------------- */}
        {/* ------------------------------------------------------------------------ */}

        <Alert
          type={WARNING_TYPE}
          title="Imposible crear perfil sin nombre"
          content="Estas intentando crear un perfil sin nombre, esto no es posible"
          actions={[
            {
              text: 'OK',
              onClick: () => {
                setNoNameWarningVisibility(false);
              },
            },
          ]}
          open={noNameWarningVisibility}
          onClose={() => setNoNameWarningVisibility(false)}
          onBackdropPress={() => {
            setNoNameWarningVisibility(false);
          }}
        />
      </Grid>
    </>
  );
};

export default NewProfileFormulary;
