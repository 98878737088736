import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import { useLocation, useHistory } from 'react-router';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { getInitialValues } from './validation';

// Icons
import { useStyles } from './materialStyles';
import Select from './../../../../Components/FormFields/FormikSelect';

Date.prototype.getWeek = function () {
  let date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  let week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
  );
};

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

function NewWeekReportTable(props) {
  const { setOpen } = props;

  const [dropdownYearOptions, setDrowdownYearOptions] = useState([]);

  const styles = useStyles();
  const location = useLocation();
  const history = useHistory();

  const { pathname } = location;

  const getPreviousMonday = (date) => {
    let day = date.getDay();
    if (date.getDay() == 0) {
      date.setDate(date.getDate() - 7);
    } else {
      date.setDate(date.getDate() - (day - 1));
    }
    return date;
  };

  const twoDigitDate = (day) => {
    if (day < 10) return `0${day}`;
    else return day;
  };

  useEffect(() => {
    let currDate = new Date(); //today
    currDate.setMonth(currDate.getMonth() - 1); //minus 1 month
    currDate = getPreviousMonday(currDate); //get Monday of that week

    let temp = [];
    for (let week = 0; week < 6; week++) {
      let item = {};
      item['itemLabel'] = `Semana ${twoDigitDate(currDate.getWeek())} => ${
        monthNames[currDate.getMonth()]
      }   Lun ${twoDigitDate(currDate.getDate())} - Vier `;
      currDate.setDate(currDate.getDate() + 4); //toFriday
      item['itemLabel'] += twoDigitDate(currDate.getDate());
      item['itemValue'] = `${currDate.getWeek()}_${currDate.getFullYear()}`;
      currDate.setDate(currDate.getDate() + 3); //nextMonday
      temp.push(item);
    }
    setDrowdownYearOptions(temp);
  }, []);

  const handlePost = (values) => {
    const { weekOfTheYear } = values;
    history.push(`${pathname}/${weekOfTheYear}`);
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues()}
        // validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          handlePost(values);
          setSubmitting(false);
        }}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={5}>
                <Grid item xs={12}>
                  <Field name="weekOfTheYear">
                    {(props) => (
                      <Select
                        formikName={'weekOfTheYear'}
                        label={'Semana'}
                        Icon={DateRangeIcon}
                        menuItemList={dropdownYearOptions}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default NewWeekReportTable;
