export const getInitialValues = (currentElement) => {
  return {
    code: currentElement?.code || '',
    conceptName: currentElement?.conceptName || '',
    cost: currentElement?.cost || '',
    quantity: currentElement?.quantity || '',
    unitOfMeasure: currentElement?.unitOfMeasure || '',
    orderNumber: currentElement?.orderNumber || '',
    number: currentElement?.number || '',
    parentNumber: currentElement?.parentNumber || '',
    classification: currentElement?.classification || '',
    conceptDescription: currentElement?.conceptDescription || '',
    isParent: currentElement?.isParent ? 'SI' : 'NO',
  };
};
