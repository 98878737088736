import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;

  margin: 20px;
  margin-top: 0;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 22px;
  border-bottom: solid #707070 1px;
`;
export const CardTitle = styled.h1`
  font-size: 20px;
  padding-bottom: 5px;
  margin: 20px;
  text-align: flex-start;
  border-bottom: solid #707070 1px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  /* flex: 1 1 calc(100vw/6); */
  flex: 1 0 20%;
  margin: 5px;
  flex-direction: row;
  max-width: 23%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid #f0f0f0 1px;
  border-right: solid #f0f0f0 1px;
`;

export const ListText = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: #707070;
  margin: 5px;
  cursor: pointer;
`;
