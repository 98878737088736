import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  CardButtonsContainer,
  CardTitle,
  PropLabel,
  PropPairContainer,
  PropValue,
  StockResumeCardContainer,
} from './StockResumeCard.styled';
import { WAREHOUSE_COORDINATES } from '../../../../Constants/warehouseCoordinates';
import { useCartStateContext } from '../../Store/CartState';
const StockResumeCard = () => {
  const { filter, availableStock, addStockToCart, updateFilter, resetFilters } =
    useCartStateContext();
  const [cardStock, setCardStock] = useState(null);

  useEffect(() => {
    const { stockId } = filter || {};
    setCardStock(availableStock.find((stock) => stock.stockId === stockId));
  }, [filter.stockId]);

  const {
    equipment,
    description,
    responsible: responsibleRaw,
    status: statusRaw,
    projects,
    warehouse: warehouseId,
  } = cardStock || {};

  const { names, lastName1, lastName2 } = responsibleRaw || {};
  const responsible =
    names || lastName1 || lastName2
      ? `${names} ${lastName1} ${lastName2}`
      : 'Sin responsable asignado';
  const status = statusRaw?.value || 'Información faltante';
  const project = projects?.length ? projects[0].projectName : 'Sin proyecto asignado';
  const warehouseObject = WAREHOUSE_COORDINATES.find((wh) => wh.itemValue === warehouseId);
  const getWarehouseLabel = () => {
    if (warehouseObject) {
      const { itemLabel } = warehouseObject;
      return itemLabel;
    } else if (warehouseId === 877) {
      return 'Fuera de almacen';
    } else {
      return 'Almacen no asignado';
    }
  };

  const stockCard = (
    <StockResumeCardContainer>
      <CardTitle>Resumen de Elemento</CardTitle>
      <PropPairContainer>
        <PropLabel>Equipo:</PropLabel>
        <PropValue>{equipment}</PropValue>
      </PropPairContainer>
      <PropPairContainer>
        <PropLabel>Descripción:</PropLabel>
        <PropValue>{description}</PropValue>
      </PropPairContainer>
      <PropPairContainer>
        <PropLabel>Responsable:</PropLabel>
        <PropValue>{responsible}</PropValue>
      </PropPairContainer>
      <PropPairContainer>
        <PropLabel>Status:</PropLabel>
        <PropValue>{status}</PropValue>
      </PropPairContainer>
      <PropPairContainer>
        <PropLabel>Proyecto:</PropLabel>
        <PropValue>{project}</PropValue>
      </PropPairContainer>
      <PropPairContainer>
        <PropLabel>Almacen:</PropLabel>
        <PropValue>{getWarehouseLabel()}</PropValue>
      </PropPairContainer>
      <CardButtonsContainer>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            updateFilter({
              stockId: null,
            });
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            addStockToCart(cardStock);
            resetFilters();
          }}
        >
          Agregar
        </Button>
      </CardButtonsContainer>
    </StockResumeCardContainer>
  );

  return cardStock ? stockCard : null;
};

export default StockResumeCard;
