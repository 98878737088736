import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.light,
  },
  iconError: {
    color: theme.palette.error.main,
  },
  iconSecondary: {
    color: theme.palette.secondary.main,
  },
  menu: {
    width: 200,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  centerTexField: {
    textAlign: 'center',
  },
}));
