import styled from 'styled-components';

export const GraphContainer = styled.div`
  width: 90%;
  height: 60%;
  max-height: 60%;
  max-width: 90%;
  min-height: 67%;
  margin-left: 2%;
  min-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
