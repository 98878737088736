import { Menu, MenuItem, makeStyles, IconButton } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import EditIcon from '@material-ui/icons/Edit';
import { LaboratoryScreenMainContainer } from './LaboratoryScreenStyles';
import AddressFormulary from './AddressFormulary/Formulary';
import Table from '../../Components/Table';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import GenericDialog from '../../Containers/GenericDialog';

export const LAB_TEST_STATUS = {
  ASSIGNED: 'Asignado',
  COMPLETED: 'Completado',
  CREATED: 'Creado',
  IN_PROGRESS: 'En progreso',
  IN_TRANSIT: 'En tránsito',
  RECEIVED: 'Recibido',
};

const useStyles = makeStyles(() => ({
  container: {},
  button: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  fakeButton: {
    backgroundColor: '#3f4ab5',
    fontSize: '15px',
    fontWeight: 200,
    color: 'white',
    borderRadius: '5px',
    padding: '10px',
    margin: 0,
  },
}));

const LaboratoryScreen = () => {
  const history = useHistory();

  const classes = useStyles();

  const AxiosInstance = useContext(AxiosContext);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addresDialogStatus, setAddresDialogStatus] = useState(false);
  const [selectedLabTest, setSelectedLabTest] = useState(null);

  const handleEditClick = (event, rowData) => {
    setAnchorEl({ [rowData.orderNumber]: event.currentTarget });
  };

  const handleGeneralDataEdit = (rowData, event) => {
    event.stopPropagation();
    history.push(`/laboratorio/${rowData.orderNumber}?action=edit`);
  };

  const handleAddressDataEdit = (rowData, event) => {
    event.stopPropagation();
    setAddresDialogStatus(true);
    setSelectedLabTest(rowData);
  };

  const handleEditClose = () => {
    setAnchorEl(null);
  };
  const TABLE_HEADERS = [
    { field: 'orderNumber', title: 'Folio' },
    { field: 'client', title: 'Cliente' },
    { field: 'project', title: 'Proyecto' },
    { field: 'deliveryAddress', title: 'Dirección' },
    { field: 'responsible', title: 'Responsable' },
    { field: 'paymentStatus', title: 'Estado de Pago' },
    { field: 'labTestOrderStatus', title: 'Estado' },
  ];

  const mapLabTestToTable = (labTest) => {
    const { client, project, paymentReceived, labTestOrderStatus } = labTest;
    return {
      ...labTest,
      client: client?.clientName || '',
      project: project?.projectName || '',
      paymentReceived: paymentReceived ? 'Pagado' : 'Pendiente de pago',
      labTestOrderStatus: LAB_TEST_STATUS[labTestOrderStatus],
    };
  };

  const { data: labTestOrders, refetch: refetchLabtestOrders } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/lab-test-order/list`,
  });

  const actions = [
    {
      icon: () => {
        return (
          <div className={classes.fakeButton} variant="contained" color="primary">
            Nuevo Registro
          </div>
        );
      },
      tooltip: 'Nuevo Registro',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        history.push('/laboratorio/new');
      },
      isFreeAction: true,
      // hidden: !allowedModuleSections.includes("EDITAR"),
    },
    (rowData) => ({
      icon: () => {
        return (
          <div>
            <IconButton
              aria-controls={`simple-menu-${rowData.orderNumber}`}
              aria-haspopup="true"
              onClick={(event) => handleEditClick(event, rowData)}
            >
              <EditIcon fontSize="default" />
            </IconButton>
            <Menu
              id={`simple-menu-${rowData.orderNumber}`}
              anchorEl={anchorEl && anchorEl[rowData.orderNumber]}
              keepMounted
              open={Boolean(anchorEl && anchorEl[rowData.orderNumber])}
              onClose={handleEditClose}
            >
              <MenuItem onClick={(event) => handleGeneralDataEdit(rowData, event)}>
                Editar Datos Generales
              </MenuItem>
              <MenuItem onClick={(event) => handleAddressDataEdit(rowData, event)}>
                Editar datos de envío
              </MenuItem>
              <MenuItem onClick={handleEditClose}>Editar pruebas de laboratorio</MenuItem>
            </Menu>
          </div>
        );
      },
      tooltip: 'editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {},
      // hidden: !allowedModuleSections.includes("EDITAR"),
    }),
    {
      icon: 'folder',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        // const path = `${normalizePath("RECURSOS_HUMANOS")}/${curp}/`;
        // const specialPath = `${normalizePath("RECURSOS_HUMANOS")}/${curp}_secret/`;
        // setUploadPath(path);
        // setSpecialUploadPath(specialPath)
        // updateParams({ path: `${path}`, bucket: FILES_BUCKET });
        // setNewFileDialogOpen(true);
      },
      // hidden: !allowedModuleSections.includes("VER ARCHIVOS"),
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setElementToDelete(rowElement);
      },
      // hidden: !allowedModuleSections.includes("EDITAR"),
    },
    {
      icon: 'visibility',
      tooltip: 'Ver detalles de la orden de laboratorio',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        history.push(`/laboratorio/${rowElement.orderNumber}`);
      },
    },
  ];

  const handleDelete = async () => {
    console.log(elementToDelete);
    const { orderNumber } = elementToDelete;
    await AxiosInstance.delete(`/api/lab-test-order/${orderNumber}`);
    // refetch();
    setElementToDelete(null);
    refetchLabtestOrders();
  };

  return (
    <>
      <LaboratoryScreenMainContainer>
        <Table
          title={`Laboratorio`}
          data={(labTestOrders || []).map(mapLabTestToTable)}
          columns={TABLE_HEADERS}
          actions={actions}
        />
      </LaboratoryScreenMainContainer>
      <Alert
        type={WARNING_TYPE}
        title="Borrar elemento de catalogo"
        content="Estas a punto de borrar una orden de laboratorio, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setElementToDelete(null);
            },
          },
        ]}
        open={!!elementToDelete}
        onClose={() => setElementToDelete(null)}
        onBackdropPress={() => {
          setElementToDelete(null);
        }}
      />
      <GenericDialog
        openDialog={addresDialogStatus}
        setCloseDialog={() => setAddresDialogStatus(false)}
        dialogTitle="Actualizar dirección de envio"
      >
        <AddressFormulary labTest={selectedLabTest} setOpen={setAddresDialogStatus} />
      </GenericDialog>
    </>
  );
};

export default LaboratoryScreen;
