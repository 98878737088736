export const INVENTARIOS = 'INVENTARIOS';
export const PROYECTOS = 'PROYECTOS';
export const CLIENTES = 'CLIENTES';
export const RECURSOS_HUMANOS = 'RECURSOS HUMANOS';
export const DATOS_CATALOGOS = 'DATOS CATALOGOS';
export const ANUNCIOS = 'ANUNCIOS';
export const USUARIOS = 'USUARIOS';
export const COBROS = 'COBROS';
export const REGISTRO_DESCARGAS = 'REGISTRO DESCARGAS';
export const HISTORICOS = 'HISTORICOS';
export const DASHBOARD = 'DASHBOARD';
export const CALIDAD = 'BIBLIOTECA DE CALIDAD';
export const COSTOS = 'COSTOS';
export const PERFILES = 'PERFILES';
export const PERFILES_CALIDAD = 'SISTEMAS DE GESTIÓN';
export const CARRITO_DE_COMPRAS = 'CARRITO DE COMPRAS';
export const SOLICITUDES_COSTOS = 'SOLICITUDES DE FONDOS';

export const EDITAR_CONCEPTOS = 'EDIT_CONCEPTS';
export const EDITAR_ESTIMACIONES = 'EDIT_ESTIMATIONS';
export const EDITAR_EVIDENCIAS = 'EDIT_EVIDENCES';
export const EDITAR_RH = 'EDIT_HR';
export const EDITAR_PROGRAMA = 'EDIT_PROGRAM';
export const EDITAR_INVENTARIOS = 'EDIT_STOCKS';
export const EDITAR_PERMISOS = 'EDIT_PERMISSIONS';
export const LEER_CONCEPTOS = 'READ_CONCEPTS';
export const LEER_ESTIMACIONES = 'READ_ESTIMATIONS';
export const LEER_EVIDENCIAS = 'READ_EVIDENCES';
export const LEER_RH = 'READ_HR';
export const LEER_PROGRAMA = 'READ_PROGRAM';
export const LEER_INVENTARIOS = 'READ_STOCKS';
export const LEER_PERMISOS = 'READ_PERMISSIONS';
export const READ_PROJECT_FILES = 'READ_PROJECT_FILES';
export const EDIT_PROJECT_FILES = 'EDIT_PROJECT_FILES';
export const REQUEST_PROJECT_FUNDS = 'REQUEST_PROJECT_FUNDS';
export const ACCEDER_EXPEDIENTE = 'ACCESS_HISTORIC';

// id: 47
// module: "COBROS"
// name: "ACCESO"

export const PROJECT_GRANTS = [
  {
    flag: EDITAR_CONCEPTOS,
    name: 'Editar conceptos',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá editar los Conceptos para este proyecto, permiso de lectura necesario.',
  },
  {
    flag: ACCEDER_EXPEDIENTE,
    name: 'Leer expediente',
    module: 'Proyectos',
    description: 'El usuario puede leer el expediente histórico de un proyecto',
  },
  {
    flag: EDITAR_ESTIMACIONES,
    name: 'Editar estimaciones',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá editar las Estimaciones para este proyecto, permiso de lectura necesario.',
  },
  {
    flag: EDITAR_EVIDENCIAS,
    name: 'Editar evidencias',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá editar las Evidencias para este proyecto, permiso de lectura necesario.',
  },
  {
    flag: EDITAR_RH,
    name: 'Editar recursos humanos',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá editar los Recursos Humanos para este proyecto, permiso de lectura necesario.',
  },
  {
    flag: EDITAR_PROGRAMA,
    name: 'Editar programa',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá editar los Programas para este proyecto, permiso de lectura necesario.',
  },
  {
    flag: EDITAR_INVENTARIOS,
    name: 'Editar inventarios',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá editar los Inventarios para este proyecto, permiso de lectura necesario.',
  },
  {
    flag: EDITAR_PERMISOS,
    name: 'Editar permisos',
    module: 'Proyectos',
  },
  {
    flag: LEER_CONCEPTOS,
    name: 'Leer conceptos',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá leer los Conceptos de este proyecto, no otorga permisos de edición.',
  },
  {
    flag: LEER_ESTIMACIONES,
    name: 'Leer estimaciones',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá leer las Estimaciones de este proyecto, no otorga permisos de edición.',
  },
  {
    flag: LEER_EVIDENCIAS,
    name: 'Leer evidencias',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá leer las Evidencias de este proyecto, no otorga permisos de edición.',
  },
  {
    flag: LEER_RH,
    name: 'Leer recursos humanos',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá leer los Recursos Humanos de este proyecto, no otorga permisos de edición.',
  },
  {
    flag: LEER_PROGRAMA,
    name: 'Leer programa',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá leer los Programas de este proyecto, no otorga permisos de edición.',
  },
  {
    flag: LEER_INVENTARIOS,
    name: 'Leer inventarios',
    module: 'Proyectos',
    description:
      'El usuario con este permiso podrá leer los Inventarios de este proyecto, no otorga permisos de edición.',
  },
  {
    flag: LEER_PERMISOS,
    name: 'Leer permisos',
    module: 'Proyectos',
  },
  {
    flag: READ_PROJECT_FILES,
    name: 'Ver archivos en la nube',
    module: 'Proyectos',
    description: 'El usuario con este permiso podrá ver los archivos en la nube',
  },
  {
    flag: EDIT_PROJECT_FILES,
    name: 'Editar archivos en la nube',
    module: 'Proyectos',
    description: 'El usuario con este permiso podrá editar los archivos en la nube.',
  },
  {
    flag: REQUEST_PROJECT_FUNDS,
    name: 'Crear solicitudes de fondos',
    module: 'Proyectos',
    description: 'El usuario con este permiso podrá crear solicitudes de gastos a central.',
  },
];
