import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  formulary: {
    width: '100%',
  },
  divider: {
    margin: '2rem 0',
  },
  commonDataContainer: {
    marginTop: '1rem',
  },
  dimensionsTitle: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
}));
