import { Button, Grid } from '@material-ui/core';
import React from 'react';
import s3Axios from './../../../Utils/AxiosS3Instance';
import CustomDropzone from '../../../Components/DropZone/Dropzone';
import Table from '../../../Components/Table';
import { useFetch } from '../../../Hooks/useFetch';

const NewPaymentDocument = (props) => {
  const { currentEstimation = {}, currentProject = {}, setOpen } = props;
  const { id: projectId } = currentProject;
  const { id: estimationId } = currentEstimation;
  const FILES_BUCKET = 'roshare-evidences-files';
  const folderPath = `project${projectId}/estimation${estimationId}/`;

  const { data: estimationCurrentFiles, refetch: refetchFiles } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: folderPath
      ? { path: folderPath, bucket: FILES_BUCKET }
      : { bucket: FILES_BUCKET },
  });

  const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];

  const getFile = async (s3DownloadKey) => {
    const signedUrl = (
      await s3Axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`)
    ).data;

    window.open(signedUrl, '_blank');
    //win.focus();
  };

  const handleFileDelete = () => {};

  const fileTableActions = [
    {
      icon: 'cloud_download',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${folderPath}${fileName}`;
        getFile(key);
      },
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${folderPath}${fileName}`;
        handleFileDelete(key);
      },
    },
  ];

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <CustomDropzone
          path={folderPath}
          bucket={FILES_BUCKET}
          closeDialog={() => console.log(false)}
          refetch={refetchFiles}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button onClick={() => setOpen && setOpen(false)} variant="contained" color="primary">
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Table
          title="Archivos de Elemento"
          data={estimationCurrentFiles?.files.map((file) => ({
            fileName: file.key.split('/').pop(),
          }))}
          columns={FILE_TABLE_HEADERS}
          actions={fileTableActions}
        />
      </Grid>
    </Grid>
  );
};

export default NewPaymentDocument;
