import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: solid 1px black;
  border-radius: 5px;
`;

export const StatusIndicatorContainer = styled.div`
  background-color: #f0f0f0;
  border: solid 1px #a0a0a0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
`;

export const StatusIndicatorCircle = styled.div`
  background-color: ${(props) => props.warningLevel};
  border-radius: 100%;
  border: solid 1px black;
  width: 25px;
  height: 25px;
`;

export const TimeWarningText = styled.p`
  font-weight: 600;
`;
