import React, { useEffect, useState, useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import {
  useStyles,
  MainContainer,
  ProfileHeaderContainer,
  ProfilesTitle,
  FormContainer,
} from './PermisosScreenStyles';
import NewPermitForm from './NewProfileFormulary';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import DataCardGrantSelection from '../../Components/DataCards/DataCardGrantSelection';

export const GIVE_GRANT_TYPE = 'GIVE_GRANT_TYPE';
export const REMOVE_GRANT_TYPE = 'REMOVE_GRANT_TYPE';
export const FIRST_ASSIGNMENT_TYPE = 'FIRST_ASSIGNMENT_TYPE';
export const GIVE_MODULE_ACCESS_TYPE = 'GIVE_MODULE_ACCESS_TYPE';
export const REMOVE_MODULE_ACCESS_TYPE = 'REMOVE_MODULE_ACCESS_TYPE';
export const PROFILE_LOAD_TYPE = 'PROFILE_LOAD_TYPE';

const PermisosScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  const itemDescription = [
    {
      key: 1,
      desc: 'Permite al usuario ver los inventarios de los equipos de Computo. No otorga permisos de edición.',
    },
    {
      key: 2,
      desc: 'Permite al usuario editar los inventarios de los equipos de Computo. Requiere otorgar permisos de lectura.',
    },
    {
      key: 3,
      desc: 'Permite al usuario ver los equipos de Oficina en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 4,
      desc: 'Permite al usuario editar los equipos de Oficina en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 5,
      desc: 'Permite al usuario ver los Mobiliarios en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 6,
      desc: 'Permite al usuario editar los Mobiliarios en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 7,
      desc: 'Permite al usuario ver la Auscultación de Infraestructura en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 8,
      desc: 'Permite al usuario editar la Auscultación de Infraestructura en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 9,
      desc: 'Permite al usuario ver Ingeniería de Tránsito en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 10,
      desc: 'Permite al usuario editar Ingeniería de Tránsito en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 11,
      desc: 'Permite al usuario ver la Auscultación de Estructura en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 12,
      desc: 'Permite al usuario editar la Auscultación de Estructura en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 13,
      desc: 'Permite al usuario ver Geología, Geofísica y Georadar en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 14,
      desc: 'Permite al usuario editar Geología, Geofísica y Georadar en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 15,
      desc: 'Permite al usuario ver Geotecnia en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 16,
      desc: 'Permite al usuario editar Geotecnia en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 17,
      desc: 'Permite al usuario ver la Topografía en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 18,
      desc: 'Permite al usuario editar la Topografía en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 19,
      desc: 'Permite al usuario ver Drones en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 20,
      desc: 'Permite al usuario editar Drones en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 21,
      desc: 'Permite al usuario ver el Laboratorio en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 22,
      desc: 'Permite al usuario editar el Laboratorio en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 23,
      desc: 'Permite al usuario ver la Maquinaria Pesada y Especial en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 24,
      desc: 'Permite al usuario editar la Maquinaria Pesada y Especial en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 25,
      desc: 'Permite al usuario ver la Maquinaria Ligera en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 26,
      desc: 'Permite al usuario editar la Maquinaria Ligera en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 27,
      desc: 'Permite al usuario ver la Herramienta Menor en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 28,
      desc: 'Permite al usuario editar la Herramienta Menor en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 29,
      desc: 'Permite al usuario ver Vehículos en Inventarios. No otorga permisos de edición.',
    },
    {
      key: 30,
      desc: 'Permite al usuario editar Vehículos en Inventarios. Requiere otorgar permisos de lectura.',
    },
    {
      key: 31,
      desc: 'Permite al usuario ver Conceptos en Proyectos. No otorga permisos de edición.',
    },
    {
      key: 32,
      desc: 'Permite al usuario editar Conceptos en Proyectos. Requiere otorgar permisos de lectura.',
    },
    {
      key: 33,
      desc: 'Permite al usuario ver Evidencias en Proyectos. No otorga permisos de edición.',
    },
    {
      key: 34,
      desc: 'Permite al usuario editar Evidencias en Proyectos. Requiere otorgar permisos de lectura.',
    },
    {
      key: 35,
      desc: 'Permite al usuario ver Estimaciones en Proyectos. No otorga permisos de edición.',
    },
    {
      key: 36,
      desc: 'Permite al usuario editar Estimaciones en Proyectos. Requiere otorgar permisos de lectura.',
    },
    {
      key: 37,
      desc: 'Permite al usuario ver Programas en Proyectos. No otorga permisos de edición.',
    },
    {
      key: 38,
      desc: 'Permite al usuario editar Programas en Proyectos. Requiere otorgar permisos de lectura.',
    },
    {
      key: 39,
      desc: 'Permite al usuario ver Inventarios en Proyectos. No otorga permisos de edición.',
    },
    {
      key: 40,
      desc: 'Permite al usuario editar Inventarios en Proyectos. Requiere otorgar permisos de lectura.',
    },
    {
      key: 41,
      desc: 'Permite al usuario ver Humanos en Proyectos. No otorga permisos de edición.',
    },
    {
      key: 42,
      desc: 'Permite al usuario editar Humanos en Proyectos. Requiere otorgar permisos de lectura.',
    },
    { key: 43, desc: 'Permite al usuario ver en Recursos Humanos. No otorga permisos de edición.' },
    {
      key: 44,
      desc: 'Permite al usuario editar en Recursos Humanos. Requiere otorgar permisos de lectura.',
    },
    { key: 45, desc: 'Habilita, al usuario, el acceso al módulo de Datos Catálogos' },
    { key: 46, desc: 'Habilita, al usuario, el acceso al módulo de Usuarios' },
    { key: 47, desc: 'Habilita, al usuario, el acceso al módulo de Cobros' },
    { key: 48, desc: 'Permite al usuario ver el Registro de Descargas.' },
    { key: 49, desc: 'Permite al usuario Descargar el Registro de Descargas' },
    { key: 50, desc: 'Permite al usuario Crear Proyectos en el módulo de Históricos' },
    { key: 51, desc: 'Permite al usuario Cargar Archivos en el módulo de Históricos' },
    { key: 52, desc: 'Permite al usuario Descargar Archivos en el módulo de Históricos' },
    { key: 53, desc: 'Permite al usuario Eliminar Archivos en el módulo de Históricos' },
    { key: 54, desc: 'Permite al usuario Crear una nueva Carpeta en el módulo de Calidad' },
    { key: 55, desc: 'Permite al usuario Cargar Archivos en el módulo de Calidad' },
    { key: 56, desc: 'Permite al usuario Descargar en el módulo de Calidad' },
    { key: 57, desc: 'Permite al usuario Eliminar Archivos en el módulo de Calidad' },
    { key: 58, desc: 'Habilita, al usuario, el acceso al módulo de Perfiles' },
    { key: 59, desc: 'Permite al usuario ver Archivos de Recursos Humanos' },
    { key: 60, desc: 'Permite al usuario ver Archivos de Recursos Humanos' },
    { key: 61, desc: 'Permite al usuario ver Archivos Restringidos de Recursos Humanos' },
    { key: 62, desc: 'Permite al usuario ver Todos los Proyectos. No otorga permisos de Edición' },
    {
      key: 63,
      desc: 'Permite al usuario Editar los Proyectos. Requiere tener permiso para ver los Proyectos.',
    },
  ];

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------
  let { profile } = useParams();
  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [arrangedGrants, setArrangedGrants] = useState({});

  const [grantsState, grantsDispatch] = useReducer((state, action) => {
    const { targetGrant = {}, type, targetModule } = action;
    const { module } = targetGrant;
    let grantsSubSet;
    switch (type) {
      case GIVE_GRANT_TYPE:
        const currentGrants = state[module] || [];
        grantsSubSet = currentGrants.map(({ id }) => id).includes(targetGrant.id)
          ? state[module]
          : [...currentGrants, targetGrant];
        return { ...state, [module]: grantsSubSet };
      case REMOVE_GRANT_TYPE:
        grantsSubSet = state[module].filter(({ id }) => id !== targetGrant.id);
        return { ...state, [module]: grantsSubSet };
      case FIRST_ASSIGNMENT_TYPE:
        const { allModules, userGrants } = action;
        const freshGrants = Object.keys(allModules).reduce((accumulator, current) => {
          return { ...accumulator, [current]: [] };
        }, {});
        return userGrants ? userGrants : freshGrants;
      case PROFILE_LOAD_TYPE:
        const { arrangedProfile } = action;
        return arrangedProfile;
      case GIVE_MODULE_ACCESS_TYPE:
        return { ...state, [targetModule]: [...arrangedGrants[targetModule]] };
      case REMOVE_MODULE_ACCESS_TYPE:
        return { ...state, [targetModule]: [] };
      default:
        throw new Error();
    }
  }, {});
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { data: allGrants, isLoading } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/grant/list',
  });
  const { data: currentProfile, isLoading: isLoadingProfile } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/profile/${profile}`,
    skip: !profile || profile === 'new',
  });
  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  useEffect(() => {
    const arrangeGrants = (grantsList) =>
      grantsList.reduce((prev, curr) => {
        if (Object.keys(prev).includes(curr.module)) {
          prev[curr.module].push(curr);
        } else {
          prev[curr.module] = [curr];
        }
        return prev;
      }, {});

    if (allGrants?.length && !currentProfile) {
      const allGrantsWithDescription = allGrants.map((grant) => {
        const description = itemDescription.find((descItem) => {
          return grant.id === descItem.key;
        });
        const grantWithDescription = { ...grant };
        if (description) {
          grantWithDescription.description = description.desc;
        }
        return grantWithDescription;
      });
      const allModules = arrangeGrants(allGrantsWithDescription);
      setArrangedGrants(allModules);
      grantsDispatch({ type: FIRST_ASSIGNMENT_TYPE, allModules });
    } else if (currentProfile) {
      const { grants: profileGrants } = currentProfile;
      const arrangedProfile = arrangeGrants(profileGrants);
      grantsDispatch({ type: PROFILE_LOAD_TYPE, arrangedProfile });
    }
  }, [allGrants, currentProfile]);

  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------

  const allGrantsTable = (
    <>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
        {Object.keys(arrangedGrants)
          .filter((platformModule) => platformModule !== 'MASTER')
          .map((platformModule, index) => (
            <Grid key={index} item xs={12} md={12}>
              <DataCardGrantSelection
                title={platformModule}
                grantsCatalog={arrangedGrants[platformModule]}
                grantsModuleState={grantsState[platformModule]}
                grantsDispatch={grantsDispatch}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );

  const renderScreen = () => {
    if (!isLoading) {
      return allGrantsTable;
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <div className={styles.container}>
      <MainContainer>
        <ProfileHeaderContainer>
          <ProfilesTitle>Permisos de la plataforma</ProfilesTitle>
          <FormContainer>
            {!isLoadingProfile ? (
              <NewPermitForm grantsState={grantsState} currentProfile={currentProfile} />
            ) : null}
          </FormContainer>
        </ProfileHeaderContainer>
        {renderScreen()}
      </MainContainer>
    </div>
  );
};

export default withNavigation(PermisosScreen);
