import { Grid } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStyles } from './UserDashboardScreenStyles';
import DataCardProfileDetails from './../../Components/DataCards/DataCardProfileDetails';
import DataCardEmployeeData from './../../Components/DataCards/DataCardEmployeeData';
import DataCardMap from './../../Components/DataCards/DataCardMap';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { userContext } from '../../Contexts/UserContext/UserContext';

const UserDashboardScreen = () => {
  const styles = useStyles();

  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);

  let { employeeId } = useParams();
  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === 'PERFILES')
    .map((grant) => grant.name);

  const { data: employeeData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/employee/${employeeId || ''}`,
  });

  const { data: userData, updateUrl: updateUserUrl } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/user/${employeeData?.user?.id || ''}`,
    skip: !employeeData?.user?.id,
  });

  useEffect(() => {
    console.log('employeeData', employeeData);
    if (employeeData?.user?.id) {
      updateUserUrl(`api/user/${employeeData?.user?.id}`);
    }
  }, [employeeData, updateUserUrl]);

  return (
    <div className={styles.container}>
      <Grid container justify="flex-start" align="center" direction="row" spacing={6}>
        <Grid item xs={12}>
          <h1>Datos del usuario {employeeId}</h1>
        </Grid>
        <Grid item lg={allowedModuleSections.includes('ACCESO') ? 4 : 6} md={6} xs={12}>
          <DataCardEmployeeData employeeData={employeeData || {}} />
        </Grid>
        {allowedModuleSections.includes('ACCESO') && (
          <Grid item lg={4} md={6} xs={12}>
            <DataCardProfileDetails profile={userData?.profile} />
          </Grid>
        )}
        <Grid item lg={allowedModuleSections.includes('ACCESO') ? 4 : 6} md={6} xs={12}>
          <DataCardMap />
        </Grid>
      </Grid>
    </div>
  );
};

export default withNavigation(UserDashboardScreen);
