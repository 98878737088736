import { useState, useEffect } from 'react';
import axios from '../Utils/AxiosS3Instance';

export const useFetch = (argParams) => {
  const {
    initialUrl,
    initialParams = {},
    skip,
    initialBody,
    method = 'get',
    axiosInstance = axios,
  } = argParams;

  const [url, updateUrl] = useState(initialUrl);
  const [params, updateParams] = useState(initialParams);
  const [body, updateBody] = useState(initialBody);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [refetchIndex, setRefetchIndex] = useState(0);
  const queryString = Object.keys(params)
    .map((key) => {
      if (!Array.isArray(params[key])) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      } else {
        const newQuery = params[key]
          .map((keyItem) => `${encodeURIComponent(key)}=${encodeURIComponent(keyItem)}`)
          .join('&');
        return newQuery;
      }
    })
    .join('&');
  const refetch = () => {
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (skip) return;
      setIsLoading(true);
      try {
        const response = await axiosInstance[method](
          `${url}${queryString ? '?' : ''}${queryString}`,
          body,
        );
        const result = response.data;
        setData(result);
      } catch (err) {
        setHasError(true);
        setErrorMessage(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url, params, refetchIndex, body]);
  return {
    data,
    isLoading,
    hasError,
    errorMessage,
    updateUrl,
    updateParams,
    refetch,
    updateBody,
  };
};
