import React, { useState, useContext } from 'react';
import { Button, Divider, Grid, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import RoshareLogo from './../../assets/Logo.png';
import {
  LogInMainCard,
  LogInMainContainer,
  InputsContainer,
  LogoContainer,
  useStyles,
} from './NewPasswordStyles';
import Input from './../../Components/FormFields/FormikIconInputOutlined';
import { getInitialValues, validationSchema } from './validations';

// IMPORTS DE FORMULARIO DE FORGOT PASSWORD
import Dialog from './../../Containers/GenericDialog';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import { API_ENDPOINT } from '../../Constants/urls';
import { userContext } from '../../Contexts/UserContext/UserContext';
import { EXCEED_OF_ATTEMPS, NO_NAVIGATOR_ERROR } from '../../Contexts/GpsContext/GpsContext';

const NewPassWordLogin = () => {
  const [loading, setLoading] = useState(false);
  const [openFPDialog, setOpenFPDialog] = useState(false);
  const [userState, setUserState] = useContext(userContext);
  const [settingNewPassword, setSettingNewPassword] = useState(false);

  const styles = useStyles();

  const handleOpenFPDialog = () => {
    setOpenFPDialog(true);
  };

  const handleLogIn = async (values) => {
    try {
      //------------------------------------------------------------------------------------
      const tempUserState = { ...userState };
      const { username, password, newPassword } = values;
      //------------------------------------------------------------------------------------

      setLoading(true);
      axios.interceptors.response.use(
        (r) => r,
        (e) => {
          const { errorCode = '' } = e?.response?.data || {};
          if (errorCode && errorCode.includes('auth')) {
            throw new Error(errorCode);
          }
          throw e;
        },
      );

      const loginBody = {
        username: username.replace(/ /g, ''),
        password,
      };

      if (settingNewPassword) {
        loginBody.newPassword = newPassword;
      }

      const rawResult = await axios.post(`${API_ENDPOINT}/auth/login`, loginBody, {
        headers: {
          deviceType: 'WEB',
          latitude: 1,
          longitude: 1,
          'Content-Type': 'application/json',
        },
      });

      //------------------------------------------------------------------------------------
      const loginResult = rawResult.data;
      Object.keys(loginResult).forEach((k) => {
        tempUserState[k] = loginResult[k];
      });

      if (tempUserState.accessToken && tempUserState.refreshToken && tempUserState.sessionId) {
        setLoading(false);
        tempUserState.userToken = true;
        const { data: userDetails } = await axios.get(`${API_ENDPOINT}/api/user/me`, {
          headers: {
            Authorization: `${tempUserState.tokenType} ${tempUserState.idToken}`,
            deviceType: 'WEB',
            latitude: '0',
            longitude: '0',
            sessionId: tempUserState.sessionId,
            username: tempUserState.username,
          },
        });
        tempUserState.userDetails = userDetails;
        setUserState(tempUserState);
      } else {
        throw new Error('Login Data Error');
      }
    } catch (e) {
      setLoading(false);
      switch (e.message) {
        case 'Network Error':
          alert('Verifica tu conexión a internet');
          break;
        case 'auth01':
          alert('Usuario o contraseña incorrectos');
          break;
        case 'auth02':
          setSettingNewPassword(true);
          alert('Bienvenido, antes de continuar debe cambiar su password');
          break;
        case EXCEED_OF_ATTEMPS:
          alert(
            'Su dispositivo presenta errores en la ubicación GPS, no se puede iniciar sesión sin valores  GPS, Err: EXCEED_OF_ATTEMPS',
          );
          break;
        case NO_NAVIGATOR_ERROR:
          alert(
            'Su dispositivo presenta errores en la ubicación GPS, no se puede iniciar sesión sin valores  GPS, Err: NO_NAVIGATION_ERROR',
          );
          break;
        default:
          alert('Servicio no disponible.');
          console.log(e);
          break;
      }
    }
  };

  return (
    <LogInMainContainer>
      <LogInMainCard>
        <LogoContainer>
          <img src={RoshareLogo} alt="rocher-logo" />
        </LogoContainer>
        <InputsContainer>
          <Formik
            initialValues={getInitialValues()}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              handleLogIn(values);
              setSubmitting(false);
            }}
            validationSchema={validationSchema(settingNewPassword)}
          >
            {({ handleSubmit }) => {
              return (
                <Form className={styles.formulary} onSubmit={handleSubmit}>
                  <Divider className={styles.divider} />
                  <Grid
                    container
                    spacing={2}
                    alignItems="flex-end"
                    justify="space-around"
                    className={styles.commonDataContainer}
                  >
                    {/* newPassword: "",
                                    password: "",
                                    passwordConfirmation: "",
                                    username: "" */}
                    <Grid item xs={12} md={12}>
                      <Field name={`username`}>
                        {(props) => (
                          <Input
                            formikName={`username`}
                            label={`Nombre de usuario`}
                            // Icon={CameraRear}
                            props={props}
                            secondaryIconColor
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name={`password`}>
                        {(props) => (
                          <Input
                            formikName={`password`}
                            label={settingNewPassword ? `Contraseña predeterminado` : 'Contraseña'}
                            // Icon={CameraRear}
                            props={props}
                            type="password"
                            secondaryIconColor
                          />
                        )}
                      </Field>
                    </Grid>
                    {settingNewPassword && (
                      <>
                        <Grid item xs={12} md={6}>
                          <Field name={`newPassword`}>
                            {(props) => (
                              <Input
                                formikName={`newPassword`}
                                label={`Nueva contraseña`}
                                // Icon={CameraRear}
                                type="password"
                                props={props}
                                secondaryIconColor
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field name={`passwordConfirmation`}>
                            {(props) => (
                              <Input
                                formikName={`passwordConfirmation`}
                                label={`Confirmación de nuevo password`}
                                // Icon={CameraRear}
                                type="password"
                                props={props}
                                secondaryIconColor
                              />
                            )}
                          </Field>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} md={12}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleOpenFPDialog} color="primary">
                          Olvidé mi contraseña
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Divider className={styles.divider} />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {!loading ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ margin: '1rem' }}
                        type="submit"
                      >
                        Iniciar sesión
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>

          <Dialog openDialog={openFPDialog} dialogTitle={`Restablecer Contraseña`} maxWidth="sm">
            <ForgotPasswordForm setCloseDialog={() => setOpenFPDialog(false)}></ForgotPasswordForm>
          </Dialog>
        </InputsContainer>
      </LogInMainCard>
    </LogInMainContainer>
  );
};

export default NewPassWordLogin;
