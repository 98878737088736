import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { userContext } from '../../Contexts/UserContext/UserContext';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    overflow: 'visible',
    marginBottom: '20px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loader: {
    margin: 'auto',
  },
  ulStyle: {
    marginTop: 0,
  },
  buttonsColumn: {
    padding: '15px',
  },
  documentsContainer: {
    borderLeft: '1px solid #CACACA',
    padding: '10px',
  },
  mainContainer: {
    // padding: '10px'
  },
}));

export default function DocumentsAvailable() {
  const classes = useStyles();
  const [userState] = useContext(userContext);
  const history = useHistory();

  //TO DO: IMPORTAR ESTA FUNCION DEL MERGE DE OCTAVIO
  const arrangeDocuments = (documents) => {
    const arranged = documents.reduce((prev, curr) => {
      const { folder: rawFolder } = curr;
      const { value: folder } = rawFolder || {};
      if (!folder) {
        return prev;
      }
      prev[folder] = prev[folder] ? [...prev[folder], curr] : [curr];
      return prev;
    }, {});
    return arranged;
  };

  const [documents, setDocuments] = useState(undefined);

  useEffect(() => {
    if (userState?.userDetails?.qualityDocumentAccessList) {
      setDocuments(arrangeDocuments(userState.userDetails.qualityDocumentAccessList));
    }
  }, [userState]);

  const cardContentComponent = (docs) => (
    <>
      <Grid container direction="row" className={classes.mainContainer}>
        <Grid item xs={5} md={4} container direction="column" className={classes.buttonsColumn}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => history.push('/documentos-calidad')}
          >
            Sistemas de Gestión
          </Button>
        </Grid>
        <Grid
          item
          xs={7}
          md={8}
          container
          direction="column"
          className={classes.documentsContainer}
        >
          {documentsListComponent(docs)}
        </Grid>
      </Grid>
    </>
  );

  const documentsListComponent = (docs) => {
    return Object.keys(docs).map((curr) => (
      <>
        <Grid container direction="row">
          <Grid item xs={12} container direction="row">
            <Typography color="primary" style={{ fontWeight: 'bold' }}>
              {curr}
            </Typography>
          </Grid>
          <ul className={classes.ulStyle}>
            {docs[curr].map((doc, index) => (
              <li key={index}>
                <Typography color="primary">{`${doc.code} - ${doc.name}`}</Typography>
              </li>
            ))}
          </ul>
        </Grid>
      </>
    ));
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Mi perfil" />
      {!documents ? (
        <CircularProgress size={60} className={classes.loader} />
      ) : (
        cardContentComponent(documents)
      )}
    </Card>
  );
}
