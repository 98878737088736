import * as yup from 'yup';

export const getInitialValues = (approvedAmount) => {
  return {
    totalAprobado: `${approvedAmount || ''}`,
  };
};

export const validationSchema = yup.object().shape({
  totalAprobado: yup
    .number()
    .positive('Debe de ser un valor mayor a 0')
    .required('Campo obligatorio'),
});
