import { AppBar, Button, Divider, Tab, Tabs } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  LabWorksDetailsMainContainer,
  LabWorkDataCard,
  LabWorkDataContainer,
  WorkOrderCard,
  LabWorkFilesTableContainer,
  OrderTitle,
  OrderTextLine,
  WorkOrderTableTitle,
} from './LabWorkDetailsStyles';
import Dialog from './../../Containers/GenericDialog/index';
import Table from '../../Components/Table';
import { normalizePath } from '../../Utils/normalizePath';
import DropZone from '../../Components/DropZone';
import { userContext } from '../../Contexts/UserContext/UserContext';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { LAB_TEST_STATUS } from '../LaboratoryDashboard/LaboratoryDashboard';

const FILES_BUCKET = 'roshare-laboratory';

const LabWorkDetails = () => {
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);
  const { labTestId } = useParams();
  const [userState] = useContext(userContext);
  const [uploadPath, setUploadPath] = useState(
    `${normalizePath('evidencias_laboratorio')}/${labTestId}/cliente/`,
  );
  const [secretMode, setSecretMode] = useState(
    `${normalizePath('evidencias_laboratorio')}/${labTestId}/interno/`,
  );
  const [newFileDialog, setNewFileDialogOpen] = useState(false);
  const [specialUploadPath, setSpecialUploadPath] = useState('');
  const [tabValue, setTabValue] = useState(0);

  const FILES_TABLE_HEADERS = [
    { title: 'Nombre', field: 'name' },
    { title: 'Estudio/Metodo', field: 'studyMethod' },
    { title: 'Status', field: 'status' },
    { title: 'Última actualización', field: 'lastUpdate' },
  ];
  const TASKS_TABLE_HEADERS = [{ title: 'Estudio/Metodo', field: 'studyMethod' }];

  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === 'RECURSOS HUMANOS')
    .map((grant) => grant.name);

  const {
    data: elementFiles,
    updateParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: uploadPath ? { path: uploadPath } : undefined,
  });

  const { data: labTestOrder, updateUrl: updateGetLabTestOrderUrl } = useFetch({
    skip: !labTestId,
    initialUrl: `/api/lab-test-order${labTestId ? `/${labTestId}` : ''}`,
    axiosInstance: AxiosInstance,
  });

  useEffect(() => {
    if (labTestId) {
      updateGetLabTestOrderUrl(`/api/lab-test-order${labTestId ? `/${labTestId}` : ''}`);
    }
  }, [labTestId]);

  const files_actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Nuevo Archivo
          </Button>
        );
      },
      tooltip: 'New Record',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        const path = `${normalizePath('evidencias_laboratorio')}/${labTestId}/cliente/`;
        const specialPath = `${normalizePath('evidencias_laboratorio')}/${labTestId}/interno/`;
        setUploadPath(path);
        setSpecialUploadPath(specialPath);
        updateParams({ path: `${path}`, bucket: FILES_BUCKET });
        setNewFileDialogOpen(true);
      },
      isFreeAction: true,
      // hidden: !allowedModuleSections.includes("VER ARCHIVOS"),
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Regresar a lista
          </Button>
        );
      },
      tooltip: 'New Record',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        history.push('/laboratorio');
      },
      isFreeAction: true,
      // hidden: !allowedModuleSections.includes("VER ARCHIVOS"),
    },
  ];

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      {labTestOrder ? (
        <LabWorksDetailsMainContainer>
          <LabWorkDataContainer>
            <LabWorkDataCard>
              <OrderTitle>Trabajo de laboratorio número: {labTestId}</OrderTitle>
              <Divider />
              <OrderTextLine>Cliente: {labTestOrder?.client?.clientName}</OrderTextLine>
              <OrderTextLine>Responsable: {labTestOrder?.responsible}</OrderTextLine>
              <OrderTextLine>
                Status: {labTestOrder?.paymentStatus ? 'Pagado' : 'No Pagado'}
              </OrderTextLine>
              <OrderTextLine>Proyecto: {labTestOrder?.project?.projectName}</OrderTextLine>
              <OrderTextLine>
                Estado: {LAB_TEST_STATUS[labTestOrder?.labTestOrderStatus]}
              </OrderTextLine>
            </LabWorkDataCard>
            <WorkOrderCard>
              <Table
                title={<WorkOrderTableTitle>Orden de Trabajo</WorkOrderTableTitle>}
                data={[]}
                columns={TASKS_TABLE_HEADERS}
                components={{}}
                options={{
                  pageSize: 5,
                  search: false,
                  headerStyle: {
                    fontSize: '12px',
                    margin: '0',
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
                // actions={actions}
                style={{
                  flex: 1,
                }}
              />
            </WorkOrderCard>
          </LabWorkDataContainer>
          <LabWorkFilesTableContainer>
            <AppBar position="static">
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                  setSecretMode(newValue === 1);
                  updateParams({
                    path: `${newValue === 1 ? specialUploadPath : uploadPath}`,
                    bucket: FILES_BUCKET,
                  });
                }}
                aria-label="simple tabs example"
              >
                <Tab label="Archivos Generales" {...a11yProps(0)} />
                {allowedModuleSections.includes('VER ARCHIVOS CLIENTE') && (
                  <Tab label="Archivos Cliente" {...a11yProps(1)} />
                )}
              </Tabs>
            </AppBar>
            <Table
              title={`Archivos`}
              data={elementFiles?.files.map((file) => ({
                fileName: file.key.split('/').pop(),
              }))}
              columns={FILES_TABLE_HEADERS}
              actions={files_actions}
            />
          </LabWorkFilesTableContainer>
        </LabWorksDetailsMainContainer>
      ) : null}
      <Dialog
        openDialog={newFileDialog}
        dialogTitle={`${!secretMode ? 'Carpeta Cliente' : 'Carpeta interna'}`}
        maxWidth="md"
      >
        <DropZone
          path={secretMode ? specialUploadPath : uploadPath}
          closeDialog={() => setNewFileDialogOpen(false)}
          refetch={() => {
            console.log('refetch');
            refetchFiles();
          }}
          bucket={FILES_BUCKET}
        />
      </Dialog>
    </>
  );
};

export default LabWorkDetails;
