import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Newsletter from '../../Components/DashboardCards/Newsletter';
import DocumentsAvailable from '../../Components/DashboardCards/DocumentsAvailable';
import ProjectsAvailable from '../../Components/DashboardCards/ProjectsAvailable';

const HomeScreen = () => {
  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 112px)">
      <Grid container spacing={2} alignItems="stretch" style={{ flex: 1, maxHeight: '100%' }}>
        <Grid item style={{ display: 'flex', flexDirection: 'column', maxHeight: '100%' }} md={8}>
          <Newsletter />
        </Grid>
        <Grid item md={4}>
          <Box display="flex" flexDirection="column" height="calc(100vh - 112px)" overflow="hidden">
            <Box flex={1} display="flex" flexDirection="column" height={'50%'} overflow="scroll">
              <DocumentsAvailable />
            </Box>
            <Box flex={1} display="flex" flexDirection="column" overflow={'scroll'}>
              <ProjectsAvailable />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withNavigation(HomeScreen);
