import { parse as parseDate, format as stringifyDate } from 'date-fns';
import { BACKEND_DATE_FORMAT } from '../../../../Constants/generalConstants';

export const getInitialValues = (currentElement) => {
  console.log(currentElement);
  const { concept, percentage, dueDate, comment } = currentElement || {};

  const dueDateFormat = dueDate && parseDate(dueDate, BACKEND_DATE_FORMAT, new Date());
  const newFormatDate = dueDateFormat && stringifyDate(dueDateFormat, 'yyyy-MM-dd'); //

  return {
    dueDate: newFormatDate || '',
    concept: concept?.id || '',
    percentage: percentage || 0,
    comment: comment || '',
  };
};

export const createFolderPath = (createdEvidence, currentProject) => {
  if (!createdEvidence || !currentProject) {
    return '';
  }
  const { id: projectId } = currentProject;
  const { id: evidenceId } = createdEvidence;

  return `project${projectId}/evidence${evidenceId}/`;
};
