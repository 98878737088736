import { inventoryRouteList } from '../../InventoryScreen/InventoryData';

export const useInventoryTypeOptions = () => {
  return inventoryRouteList.map((element) => {
    return {
      itemLabel: element.elementName,
      itemValue: element.stockType,
    };
  });
};
