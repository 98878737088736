import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  container: {},
  button: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
}));

export const TableContainerWithScrollBars = styled.div`
  //overflow-x: scroll;
  width: 100%;
  max-width: 100%;
`;
