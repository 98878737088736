import * as yup from 'yup';
import { safetyObjectCheck } from '../../VehicleStateScreen/utils/getWarningLevel';

export const CREATING_NEW_QUALITY_FILE = 'CREATING_NEW_QUALITY_FILE';
export const EDITING_NEW_QUALITY_FILE = 'EDITING_NEW_QUALITY_FILE';

export const getValidationSchema = (formAction) => {
  const basicValidationSchema = {
    code: yup
      .string()
      .required('Campo Obligatorio')
      .matches(
        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-]+$/,
        'Error, código no puede llevar, ni espacios, ni acentos, ni signos distintos a guión',
      ),
    name: yup.string().required('Campo Obligatorio'),
    revision: yup.number().required('Campo Obligatorio'),
    revisionDate: yup.date().required('Campo Obligatorio'),
    status: yup.object().shape({
      id: yup.number().required('Campo Obligatorio').typeError('Campo Obligatorio'),
    }),
    location: yup.object().shape({
      id: yup.number().required('Campo Obligatorio').typeError('Campo Obligatorio'),
    }),
    folder: yup.object().shape({
      id: yup.number().required('Campo Obligatorio').typeError('Campo Obligatorio'),
    }),
    operationGroup: yup.object().shape({
      id: yup.number().required('Campo Obligatorio').typeError('Campo Obligatorio'),
    }),
    file: yup.string().required('Archivo requerido'),
  };

  if (formAction === EDITING_NEW_QUALITY_FILE) {
    basicValidationSchema.file = yup.boolean().required('');
  }

  return yup.object().shape(basicValidationSchema);
};

export const validationSchema = yup.object().shape({
  code: yup.string().required('Campo Obligatorio'),
  name: yup.string().required('Campo Obligatorio'),
  revision: yup.number().required('Campo Obligatorio'),
  revisionDate: yup.date().required('Campo Obligatorio'),
  status: yup.string().required('Campo Obligatorio'),
  location: yup.string().required('Campo Obligatorio'),
  folder: yup.string().required('Campo Obligatorio'),
  operationGroup: yup.string().required('Campo Obligatorio'),
});

export const getInitialValues = (currentElement = {}) => {
  const {
    code = '',
    name = '',
    revision = 0,
    revisionDate = new Date(),
    status = {
      id: null,
    },
    location = {
      id: null,
    },
    folder = {
      id: null,
    },
    operationGroup = {
      id: null,
    },
  } = currentElement || {};

  return {
    code,
    name,
    revision,
    revisionDate: safetyObjectCheck(revisionDate),
    status,
    location,
    folder,
    operationGroup,
  };
};
