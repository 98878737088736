import { AppBar, Button, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Dialog from './../../../Containers/GenericDialog';
import NewWeekFormulary from './NewWeekFormulary';
import Table from '../../../Components/Table';
import { COSTOS } from '../../../Constants/grantsModules';
import { useModuleGrants } from '../../../Hooks/useUsersGrants';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const WeeksTable = (props) => {
  const { arrangedData, value, setValue } = props;
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const [newReportModal, setNewReportModal] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TABLE_HEADERS = [
    { title: 'Número de semana', field: 'weekOfTheYear' },
    { title: 'Costo Total', field: 'totalCost' },
  ];

  const { allowedModuleSections: moduleGrants } = useModuleGrants(COSTOS);

  const getWeeksCost = (weeks) => {
    return weeks.map((week, weekOfTheYear) => {
      const totalCost = week.reduce((accumulator, cost) => accumulator + cost.cost, 0);

      return { totalCost, weekOfTheYear };
    });
  };

  const getTableData = (type) => getWeeksCost(arrangedData[type] || []).filter((week) => !!week);

  return (
    <>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Costos Directos" {...a11yProps(0)} />
          <Tab label="Costos Indirectos" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <Table
        title={`Reportes semanales`}
        data={getTableData(value ? 'INDIRECT' : 'DIRECT')}
        columns={TABLE_HEADERS}
        actions={[
          {
            icon: 'visibilityIcon',
            tooltip: 'Detalles',
            iconProps: {
              color: 'secondary',
            },
            onClick: (event, rowElement) => {
              history.push(`${pathname}/${rowElement.weekOfTheYear}`);
            },
          },
          {
            icon: () => {
              return (
                <Button variant="contained" color="primary">
                  Crear nuevo reporte
                </Button>
              );
            },
            tooltip: 'Create',
            iconProps: {
              color: 'secondary',
            },
            onClick: () => {
              setNewReportModal(true);
            },
            isFreeAction: true,
            hidden: !moduleGrants.includes('AGREGAR Y EDITAR'),
          },
          {
            icon: () => {
              return (
                <Button variant="contained" color="primary">
                  Regresar
                </Button>
              );
            },
            tooltip: 'Create',
            iconProps: {
              color: 'secondary',
            },
            onClick: () => {
              history.goBack();
            },
            isFreeAction: true,
          },
        ]}
      />
      <Dialog
        openDialog={newReportModal}
        dialogTitle={`Crear Nuevo Reporte - Costo ${value === 0 ? 'Directo' : 'Indirecto'}`}
        maxWidth="md"
      >
        <NewWeekFormulary mode={value} setOpen={setNewReportModal} />
      </Dialog>
    </>
  );
};

export default WeeksTable;
