/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import PersonIcon from '@material-ui/icons/Person';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { validationSchema, getInitialValues } from './validation';
import { useStyles } from './materialStyles';
import { useFetch } from '../../../Hooks/useFetch';
import Select from '../../../Components/FormFields/FormikSelect';

// Icons
import Input from '../../../Components/FormFields/FormikIconInput';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';

const NewUserFormulary = (props) => {
  const { setOpen, refetch, selectedUser } = props;
  const styles = useStyles();
  const AxiosInstance = useContext(AxiosContext);

  const { data: profilesElements } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: 'api/profile/list',
  });

  const createPayload = (values) => {
    values.profile =
      profilesElements?.find((element) => Number(element.id) === Number(values.profile)) || {};
    return values;
  };

  const handleNewUser = async (values) => {
    const data = values.profile ? createPayload(values) : values;
    const body = selectedUser ? { ...data, id: selectedUser.id } : data;
    await AxiosInstance[selectedUser ? 'put' : 'post']('/api/user', body);
    await refetch();
    setOpen(false);
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(selectedUser)}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleNewUser(values);
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={6}>
                <Grid item xs={12} md={6}>
                  <Field name="name">
                    {(props) => (
                      <Input formikName={'name'} label={'Nombre'} Icon={PersonIcon} props={props} />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field name="username">
                    {(props) => (
                      <Input
                        formikName={'username'}
                        label={'Correo electrónico'}
                        Icon={AlternateEmailIcon}
                        type="email"
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Field name="profile">
                    {(props) => (
                      <Select
                        formikName={'profile'}
                        label={'Perfiles de Usuario'}
                        Icon={AlternateEmailIcon}
                        props={props}
                        menuItemList={
                          profilesElements
                            ? profilesElements.map((element) => ({
                                itemLabel: element.name,
                                itemValue: `${element.id}`,
                              }))
                            : []
                        }
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    {selectedUser ? 'Guardar' : 'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default NewUserFormulary;
