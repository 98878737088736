import React, { useContext, useEffect, useState } from 'react';
// import { withNavigation } from '../Containers/AppNavigation/AppNavigation';
import { useHistory, useParams } from 'react-router-dom';
import { withNavigation } from './../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from './../../Contexts/AxiosContext/AxiosContext';
import Table from './../../Components/Table';
import { useFetch } from './../../Hooks/useFetch';
import { useInventoryHistoryStyles } from './InventoryHistoryDetailsStyles';

const InventoryHistoryDetails = () => {
  const classes = useInventoryHistoryStyles();
  const history = useHistory();
  const { inventoryId } = useParams();
  const axiosInstance = useContext(AxiosContext);
  const [log, setLog] = useState(null);

  const { data } = useFetch({
    axiosInstance,
    initialUrl: '/api/audit/events',
    initialParams: {
      modifiedRecordId: inventoryId,
      modifiedTable: 'STOCK',
    },
  });
  const { data: inventoryData } = useFetch({
    axiosInstance,
    initialUrl: `/api/stock/${inventoryId}`,
  });

  const TABLE_HEADERS = [
    { title: 'Tipo de evento', field: 'event' },
    { title: 'Autor del cambio', field: 'user.name' },
    { title: 'Cambios', field: 'changes' },
  ];

  const dictionary = {
    amaac: 'AMAAC',
    assignedToProject: 'Asigando a proyecto',
    brand: 'Marca',
    classification: 'Clasificación',
    description: 'Descripción',
    ema: 'EMA',
    equipment: 'Equipo',
    group: 'Grupo',
    id: 'ID',
    model: 'Modelo',
    nickname: 'Apodo',
    plate: 'Placa',
    projects: 'Proyectos',
    responsible: 'Responsable',
    serialNumber: 'Número de serie',
    status: 'Status',
    stockId: 'Id de inventario',
    stockType: 'Tipo de inventario',
    warehouse: 'Almacén',
    vehicleRecord: 'Registros del Vehículo',
  };

  const vehicleRecordDictionary = {
    circulationCardExpirationDate: 'Expiración de tarjeta de circulación',
    circulationCardIssueDate: 'Expedición de tarjeta de circulación',
    circulationCardNumber: 'Número de tarjeta de circulación',
    clause: 'Clausula del seguro',
    coverage: 'Covertura del seguro',
    endorsement: 'Endoso del seguro',
    insuranceExpirationDate: 'Fecha de expiración del seguro',
    insuranceProvider: 'Proveedor del seguro',
    lastVerificationDate: 'Fecha última verificación',
    nextVerificationDate: 'Fecha próxima verificación',
    policyDate: 'Fecha de póliza',
    policyIndex: 'Index de póliza',
    policyNumber: 'Número de póliza',
    verificationSticker: 'Calcomanía de verificación',
  };

  const defineChange = (params) => {
    const { variableChange, newValue } = params;
    switch (variableChange) {
      case 'vehicleRecord':
        if (typeof newValue === 'object' && newValue) {
          const keys = Object.keys(newValue)
            .filter((key) => key !== 'id')
            .map((key) => vehicleRecordDictionary[key])
            .join(', ');
          return keys ? `Cambios en registros de vehiculos, campos: ${keys}` : null;
        }
        return 'Cambio general';
      case 'responsible':
        if (typeof newValue === 'object') {
          const { lastName1, lastName2, names } = newValue;
          return `${names} ${lastName1} ${lastName2}`;
        } else if (!newValue) {
          return 'Sin responsable';
        }
        return 'Cambio general';
      case 'projects':
        if (newValue) {
          const projectIndex = Object.keys(newValue)[0];
          if (projectIndex === 0 || projectIndex) {
            const project = newValue[projectIndex];
            return `${project.projectName} - ${project.projectId}`;
          }
        }
        return newValue;
      default:
        return newValue;
    }
  };

  const createChanges = (changes) => {
    const result = Object.keys(changes || {}).reduce((accumulator, current) => {
      const definedChange = defineChange({
        newValue: changes[current],
        variableChange: current,
      });
      if (!definedChange) {
        return '';
      }
      return `${accumulator}${dictionary[current]}: ${definedChange}`;
    }, '');

    return result;
  };

  const mapData = (data) => {
    return data
      .map((element) => {
        const cambios = createChanges(element.changes);
        return cambios ? { ...element, changes: cambios } : null;
      })
      .filter((element) => element);
  };

  const compareObjects = (object1, object2) => {
    if (!object1 || !object2) {
      if (!object1 && !object2) {
        return null;
      } else if (!object1) {
        return object2;
      } else {
        return 'Deleted';
      }
    }
    const attributes2 = Object.keys(object2);
    const result = {};
    attributes2.forEach((attr) => {
      if (attr.toLowerCase().includes('date')) {
        return null;
      }
      if (typeof object1[attr] === 'object' && typeof object2[attr] === 'object') {
        const nestedResult = compareObjects(object1[attr], object2[attr]);
        if (nestedResult) {
          result[attr] = nestedResult;
        }
      } else {
        if (object1[attr] !== object2[attr]) {
          result[attr] = object2[attr];
        }
      }
    });
    return Object.keys(result).length ? result : null;
  };

  useEffect(() => {
    if (data && inventoryData && !log) {
      const mappedData = data.map((element) => {
        return { ...element, previousValue: JSON.parse(element.previousValue) };
      });
      const changes = mappedData.map((logElement, index) => {
        const newerValues = index - 1 >= 0 ? mappedData[index - 1].previousValue : inventoryData;
        const { previousValue } = logElement;
        const _changes = compareObjects(previousValue, newerValues);
        return { ...logElement, changes: _changes };
      });
      setLog(changes);
    }
  }, [data, inventoryData, compareObjects]);

  const actions = [
    {
      icon: () => <p className={classes.fakeButton}>REGRESAR</p>,
      onClick: () => {
        history.goBack();
      },
      isFreeAction: true,
    },
  ];

  return (
    <Table
      title={`Registro de cambios, Inventario: ${inventoryData?.stockId || ''}`}
      data={mapData(log || [])}
      columns={TABLE_HEADERS}
      actions={actions}
    />
  );
};

export default withNavigation(InventoryHistoryDetails);
