import * as yup from 'yup';

export const validationSchema = (updatingPassword) => {
  const yupObject = {
    // countryIdent: yup.string().required('This field is required'),
    // landingFacilityType: yup.string().required('This field is required'),
    // country: yup.string().required('This field is required'),
    password: yup.string().required('La contraseña es un campo requerido'),
    username: yup
      .string()
      .required('El usuario es un campo requerido')
      .email(
        'El usuario debe ser un correo electrónico válido. Verifica no estar colocando espacios de más',
      ),
  };
  if (updatingPassword) {
    yupObject.newPassword = yup
      .string()
      .matches(/\w*[a-z]\w*/, 'La contraseña debe de tener mínimo una letra minúscula')
      .matches(/\w*[A-Z]\w*/, 'La contraseña debe de tener mínimo una letra mayúscula')
      .matches(/\d/, 'La contraseña debe de tener un número')
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'La contraseña debe de tener un caracter especial')
      .min(8, ({ min }) => `La contraseña debe de tener mínimo ${min} caracteres`)
      .required('La contraseña es un campo requerido');

    // .matches(
    //     "^(((?=.*[a-z])(?=.*[A-Z]))((?=.*[a-z])(?=.*[0-9]))((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})",
    //     "La nueva contraseña debe contener mínimo 8 caractéres, una mayúscula, una minúscula, un número y un caracter especial"
    // ).matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, "Password must have a special character").required("La nueva contraseña debe contener mínimo 8 caractéres, una mayúscula, una minúscula, un número y un caracter especial");

    yupObject.passwordConfirmation = yup
      .string()
      .required('Las contraseñas deben ser iguales')
      .oneOf([yup.ref('newPassword'), null], 'Las contraseñas deben ser iguales');
  }

  return yup.object(yupObject);
};

export const getInitialValues = () => {
  let initialValues = {
    newPassword: '',
    password: '',
    passwordConfirmation: '',
    username: '',
  };

  return initialValues;
};
