import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
// Icons
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { useStyles } from './materialStyles';
import Input from '../../../Components/FormFields/FormikIconInput';

function NewFolderForm(props) {
  const { setOpen, createFolder } = props;
  const styles = useStyles();

  const handlePost = async (values) => {
    createFolder && createFolder(values);
  };

  return (
    <Grid container>
      <Formik
        initialValues={{}}
        // validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.table(values);
          setSubmitting(true);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={6}>
                <Grid item xs={12} md={12}>
                  <Field name="folderName">
                    {(props) => (
                      <Input
                        formikName={'folderName'}
                        label={'Nombre(s)'}
                        Icon={PermIdentityIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    {'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default NewFolderForm;
