import styled from 'styled-components';

export const CsvUploadMainContainer = styled.div`
  width: calc(100vw-15px);
  height: calc(100vh-15px);
  background-color: #f0f0f0;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const DemoButton = styled.div`
  padding: 15px;
  cursor: pointer;
  background: #f0f0f0;
  border-radius: 10px;
  border: solid black 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ProgressDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px;
  align-items: center;
  justify-content: center;
`;

export const ProgressText = styled.p`
  font-size: 20px;
  margin: 10px;
  padding: 10px;
`;
