import React from 'react';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';

const ErrorScreen = (props) => {
  const { notgranted } = props;

  const noGrantMessage = <h1>No tienes permiso para acceder a esta ruta del sistema</h1>;
  const grongRoute = <h1>La ruta que estas buscando no existe</h1>;

  return <div>{notgranted ? noGrantMessage : grongRoute}</div>;
};

export default withNavigation(ErrorScreen);
