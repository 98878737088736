import { Button, CircularProgress, Grid, Paper } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from './../../Components/Table';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from '../../Hooks/useFetch';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import { userContext } from '../../Contexts/UserContext/UserContext';

const ProfilesScreen = () => {
  //---------------------------------------------------------------------------
  // USER DATA AND GRANTS
  //---------------------------------------------------------------------------
  const [userState] = useContext(userContext);
  const { grants = [] } = userState?.userDetails?.profile || {};

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const history = useHistory();

  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [deleteWarningVisibility, setDeletingWarningVisibility] = useState(false);
  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { data: profilesElements, refetch: refetchProfiles } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: 'api/profile/list',
  });

  const [masterState, setMasterState] = useState(false);
  const [locking, setLocking] = useState(false);
  const [emergencyCloseWarningState, setEmergencyCloseWarningState] = useState(false);

  useEffect(() => {
    if (profilesElements?.length) {
      const notMasterProfile = profilesElements?.filter((profile) => profile.id !== 10) || [];
      const sampleProfile = notMasterProfile.length ? notMasterProfile[0] : {};

      const { grants = [] } = sampleProfile;
      const currentMasterState = grants.map((grant) => `${grant.id}`).includes('83');
      setMasterState(currentMasterState);
    }
  }, [profilesElements]);
  //---------------------------------------------------------------------------
  // HANDLE EMERGENCY CLOSE
  //---------------------------------------------------------------------------

  const handleEmergencyClose = async () => {
    console.log(profilesElements);
    const newProfiles = profilesElements.map((profile) => {
      if (profile.id === 10) {
        return profile;
      }
      const { grants: mapGrants } = profile;
      const addOrRemove = mapGrants.map((g) => `${g.id}`).includes('83');
      const newGrants = addOrRemove
        ? mapGrants.filter((grant) => grant.name !== 'MASTER')
        : [...mapGrants, { id: 83 }];
      return { ...profile, grants: newGrants };
    });

    setLocking(true);
    for (let i = 0; i < newProfiles.length; i++) {
      await AxiosInstance.put('/api/profile', newProfiles[i]);
    }
    refetchProfiles();
    setLocking(false);
    setEmergencyCloseWarningState(false);
  };
  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------

  const mapElement = (element) => {
    return element;
  };

  //---------------------------------------------------------------------------
  // Table Config
  //---------------------------------------------------------------------------

  const TABLE_HEADERS = [{ title: 'Nombre del Perfil', field: 'name' }];

  const tableActions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Nuevo Perfil
          </Button>
        );
      },
      tooltip: 'Crear nuevo perfil de usuario',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        history.push('/permisos/new');
      },
      isFreeAction: true,
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Cierre de emergencia
          </Button>
        );
      },
      tooltip: 'Cierre de emergencia',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setEmergencyCloseWarningState(true);
      },
      isFreeAction: true,
      hidden: !grants.map((grant) => grant.name).includes('CIERRE DE EMERGENCIA'),
    },
    {
      icon: 'edit',
      tooltop: 'Editar perfil de usuario',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { id } = rowElement;
        history.push(`/permisos/${id}`);
      },
    },
    (rowElement) => ({
      icon: 'delete',
      tooltop: 'Eliminar perfil de usuario',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setProfileToDelete(rowElement);
        setDeletingWarningVisibility(true);
      },
      hidden: rowElement.id === 10,
    }),
  ];

  const handleDelete = async () => {
    const { id } = profileToDelete;
    await AxiosInstance.delete(`/api/profile/${id}`);
    refetchProfiles();
    setDeletingWarningVisibility(false);
  };

  return (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Paper style={{ padding: 10, color: !masterState ? 'red' : 'black' }}>
            <h1>
              Actualmente el sistema se encuentra en:{' '}
              {masterState ? 'FUNCIONAMIENTO REGULAR' : 'CIERRE DE EMERGENCIA'}
            </h1>
          </Paper>
        </Grid>
        <Grid item>
          <Table
            title="Perfiles de usuario"
            data={(profilesElements || []).map(mapElement)}
            columns={TABLE_HEADERS}
            actions={tableActions}
          />
        </Grid>
      </Grid>
      {/* ------------------------------------------------------------------------ */}
      {/* --- DELETING PROFILE WARNING ---------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}

      <Alert
        type={WARNING_TYPE}
        title="Eliminar perfil de usuario"
        content="Estas intentando eliminar un perfil de usuario, esta acción es irreversible, ¿estás seguro?"
        actions={[
          {
            text: 'ELIMINAR',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'CANCELAR',
            onClick: () => {
              setDeletingWarningVisibility(false);
            },
          },
        ]}
        open={deleteWarningVisibility}
        onClose={() => setDeletingWarningVisibility(false)}
        onBackdropPress={() => {
          setDeletingWarningVisibility(false);
        }}
      />
      {/* ------------------------------------------------------------------------ */}
      {/* --- EMERGENCY CLOSE ACTIVATION ---------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}

      <Alert
        type={WARNING_TYPE}
        title="CIERRE DE EMERGENCIA"
        content={
          locking ? (
            <CircularProgress />
          ) : (
            `Actualmente el sistema se encuentra en: ${
              masterState ? 'FUNCIONAMIENTO REGULAR' : 'CIERRE DE EMERGENCIA'
            }, desea cambiar a: ${!masterState ? 'FUNCIONAMIENTO REGULAR' : 'CIERRE DE EMERGENCIA'}`
          )
        }
        actions={[
          {
            text: masterState ? 'APLICAR' : 'REACTIVAR',
            onClick: () => {
              handleEmergencyClose();
            },
          },
          {
            text: 'CANCELAR',
            onClick: () => {
              setEmergencyCloseWarningState(false);
            },
          },
        ]}
        open={emergencyCloseWarningState}
        onClose={() => setEmergencyCloseWarningState(false)}
        onBackdropPress={() => {
          setEmergencyCloseWarningState(false);
        }}
      />
    </>
  );
};

export default withNavigation(ProfilesScreen);
