import { Grid, Typography, Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import React, { useContext, useReducer } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';

import { GLOBAL_TABLE_HEADERS, FILE_TABLE_HEADERS, FILES_BUCKET, ITEM_HEIGHT } from './utils';
import { useStyles } from './InventoryScreenStyles';
import { excelInventory } from './../../Utils/documentsCreator';
import { mapElement } from './tableMapper';
import CsvDropzone from './../../Components/CsvDropZone';
import { normalizePath } from './../../Utils/normalizePath';
import DropZone from './../../Components/DropZone';
import { inventoryRouteList } from './InventoryData';
import ColumnEditFormulary from './ColumnEditFormulary/Formulary';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { userContext } from '../../Contexts/UserContext/UserContext';
import { useFetch } from '../../Hooks/useFetch';
import Dialog from '../../Containers/GenericDialog';
import DialogActions from '../../Containers/GenericDialog/DialogActions';
import Table from '../../Components/Table';
import { deleteFile } from '../../Utils/s3Utils';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';

const initialState = {
  screenState: 'idle',
  cvsDialogState: 'close',
  fileDialogState: 'close',
  deleteDialogState: 'close',
  columnDialogState: 'close',
  rowData: null,
  uploadPath: null,
  userCanEdit: false,
  tableHeaders: {
    inventoryType: true,
    clasification: true,
    inventoryNumber: true,
    equipment: true,
    description: true,
    brand: true,
    model: true,
    serialNumber: true,
    status: false,
    responsable: true,
    nickname: false,
    set: false,
    EMA: false,
    AMAAC: false,
    warehouse: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'IS_LOADING':
      return { ...state, screenState: 'loading' };
    case 'IS_IDLE':
      return { ...state, screenState: 'idle' };
    case 'HAS_ERROR':
      return { ...state, screenState: 'error' };
    case 'OPEN_CVS_DIALOG':
      return { ...state, cvsDialogState: 'open' };
    case 'CLOSE_CVS_DIALOG':
      return { ...state, cvsDialogState: 'close' };
    case 'SET_CURRENT_ROW_DATA':
      return { ...state, rowData: action.payload };
    case 'CLEAN_CURRENT_ROW_DATA':
      return { ...state, rowData: null };
    case 'SET_UPLOAD_PATH':
      return { ...state, uploadPath: null };
    case 'OPEN_FILE_DIALOG':
      return { ...state, fileDialogState: 'open' };
    case 'CLOSE_FILE_DIALOG':
      return { ...state, fileDialogState: 'close' };
    case 'OPEN_DELETE_DIALOG':
      return { ...state, deleteDialogState: 'open' };
    case 'CLOSE_DELETE_DIALOG':
      return { ...state, deleteDialogState: 'close' };
    case 'USER_CAN_EDIT':
      return { ...state, userCanEdit: action.payload };
    case 'OPEN_COLUMN_DIALOG':
      return { ...state, columnDialogState: 'open' };
    case 'CLOSE_COLUMN_DIALOG':
      return { ...state, columnDialogState: 'close' };
    case 'UPDATE_TABLE_HEADERS':
      return { ...state, tableHeaders: { ...action.payload } };
    default:
      throw new Error();
  }
}

const InventoryTable = () => {
  const styles = useStyles();
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === 'INVENTARIOS')
    .map((grant) => grant.name);

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    dispatch({ type: 'SET_CURRENT_ROW_DATA', payload: rowData });
    dispatch({
      type: 'USER_CAN_EDIT',
      payload: allowedModuleSections?.includes(
        inventoryRouteList.find((type) => type.stockType === rowData.inventoryType)?.canEditProfile,
      ),
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch({ type: 'CLEAN_CURRENT_ROW_DATA' });
  };

  const handleChangeColumnFields = (newFields) => {
    dispatch({ type: 'UPDATE_TABLE_HEADERS', payload: newFields });
    dispatch({ type: 'CLOSE_COLUMN_DIALOG' });
  };

  const { data: inventoryElements, refetch: elementsRefetch } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/stock/list`,
  });

  const {
    data: elementFiles,
    updateParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: state.uploadPath ? { path: state.uploadPath } : undefined,
    skip: !state.uploadPath,
  });

  const handleFileDelete = (key) => {
    deleteFile(key, FILES_BUCKET, () => {
      refetchFiles();
    });
  };

  const downloadStockExcel = async () => {
    try {
      dispatch({ type: 'IS_LOADING' });
      const response = await AxiosInstance.get(`/api/stock/list`);
      if (response.status === 200) {
        const records = response?.data;
        await excelInventory(records, `Inventario`);
      }
    } catch (error) {
      dispatch({ type: 'HAS_ERROR' });
    } finally {
      dispatch({ type: 'IS_IDLE' });
    }
  };

  const handleEdit = () => {
    history.push(`/inventarios/editar/${state.rowData.id}`);
    handleClose();
  };

  const getFile = async (s3DownloadKey) => {
    const signedUrl = (
      await AxiosInstance.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`)
    ).data;

    window.open(signedUrl, '_blank');
  };

  const handleFileUpload = async () => {
    const { inventoryType, inventoryNumber } = state.rowData;
    const path = `${normalizePath(inventoryType.replace(/ /g, ''))}/${inventoryNumber}/`;
    dispatch({ type: 'SET_UPLOAD_PATH', payload: path });
    updateParams({ path: `${path}`, bucket: FILES_BUCKET });
    dispatch({ type: 'OPEN_FILE_DIALOG' });
    handleClose();
  };

  const handleDeleteInDialog = async () => {
    await AxiosInstance.delete(
      `/api/stock/${state?.rowData?.id || state?.rowData?.inventoryNumber}`,
    );
    elementsRefetch();
    dispatch({ type: 'CLEAN_CURRENT_ROW_DATA' });
    dispatch({ type: 'CLOSE_DELETE_DIALOG' });
  };

  const handleDelete = () => {
    dispatch({ type: 'OPEN_DELETE_DIALOG' });
    setAnchorEl(null);
  };

  const handleView = () => {
    history.push(`/inventarios/logs/${state.rowData.id}`);
    handleClose();
  };

  const actions = [
    {
      icon: (props) => <MoreVertIcon {...props} />,
      tooltip: 'Opciones',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowData) => {
        handleClick(event, rowData);
      },
    },
    {
      icon: () => {
        return <p className={styles.fakeButton}>NUEVO REGISTRO</p>;
      },
      tooltip: 'Nuevo Registro',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        history.push(`/inventarios/nuevo`);
      },
      isFreeAction: true,
    },
    {
      icon: () => {
        return <p className={styles.fakeButton}>Cargar CSV</p>;
      },
      tooltip: 'Cargar CSV',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        dispatch({ type: 'OPEN_CVS_DIALOG' });
      },
      isFreeAction: true,
    },
    {
      icon: SettingsIcon,
      tooltip: 'Add / Remove Columns',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => dispatch({ type: 'OPEN_COLUMN_DIALOG' }),
      isFreeAction: true,
    },
  ];

  const fileTableActions = [
    {
      icon: 'cloud_download',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${state.uploadPath}/${fileName}`;
        getFile(key);
      },
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${state.uploadPath}/${fileName}`;
        handleFileDelete(key);
      },
    },
  ];

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {state.userCanEdit ? (
          <>
            <MenuItem onClick={handleEdit}>Editar</MenuItem>
            <MenuItem onClick={handleDelete}>Borrar</MenuItem>
            <MenuItem onClick={handleFileUpload}>Cargar un archivo</MenuItem>
            <MenuItem onClick={handleView}>Ver detalles</MenuItem>
          </>
        ) : (
          <MenuItem onClick={handleClose}>Este usuario no puede editar este tipo de item</MenuItem>
        )}
      </Menu>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h4">Consulta de inventarios</Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => downloadStockExcel()}
            variant="contained"
            color="primary"
            disabled={state.screenState === 'loading' || !inventoryElements}
            sx={{ marginRight: '1rem' }}
          >
            Descargar Excel
          </Button>
        </Grid>
        {inventoryElements?.length > 0 ? (
          <Grid item>
            <Table
              title={`Inventario`}
              data={(inventoryElements || []).map((element) => mapElement(element))}
              columns={GLOBAL_TABLE_HEADERS.filter((header) => state.tableHeaders[header.field])}
              actions={actions}
              options={{
                filtering: true,
                search: false,
                paging: true,
                tableLayout: 'auto',
                rowStyle: {
                  overflowWrap: 'break-word',
                },
                exportButton: true,
              }}
            />
          </Grid>
        ) : (
          <Grid item container style={{ marginTop: '2rem' }}>
            <CircularProgress size={'4rem'} />
          </Grid>
        )}
        <Dialog
          openDialog={state.cvsDialogState === 'open'}
          dialogTitle={'Cargar datos desde CSV'}
          maxWidth="md"
        >
          <CsvDropzone
            apimethod="post"
            apimode="multiple"
            onClose={() => {
              dispatch({ type: 'CLOSE_CVS_DIALOG' });
            }}
          />
          <DialogActions
            secondaryLabel="Cancelar"
            primaryLabel={null}
            secondaryAction={() => dispatch({ type: 'CLOSE_CVS_DIALOG' })}
          />
        </Dialog>
        <Dialog
          openDialog={state.fileDialogState === 'open'}
          dialogTitle={'Subir archivos'}
          maxWidth="lg"
        >
          <DropZone
            path={state.uploadPath}
            closeDialog={() => dispatch({ type: 'CLOSE_FILE_DIALOG' })}
            bucket={FILES_BUCKET}
          />
          <DialogActions
            secondaryLabel="Cancelar"
            primaryLabel={null}
            secondaryAction={() => dispatch({ type: 'CLOSE_FILE_DIALOG' })}
          />
          <Table
            title="Archivos de Elemento"
            data={elementFiles?.files.map((file) => ({
              fileName: file.key.split('/').pop(),
            }))}
            columns={FILE_TABLE_HEADERS}
            actions={fileTableActions}
          />
        </Dialog>
        <Dialog
          openDialog={state.columnDialogState === 'open'}
          dialogTitle={'Configuración de Columnas'}
          maxWidth="md"
        >
          <ColumnEditFormulary
            setOpen={() => dispatch({ type: 'OPEN_COLUMN_DIALOG' })}
            setClose={() => dispatch({ type: 'CLOSE_COLUMN_DIALOG' })}
            currentFilters={state.tableHeaders}
            setColumnFields={handleChangeColumnFields}
          />
        </Dialog>
        <Alert
          type={WARNING_TYPE}
          title="Borrar elemento de catalogo"
          content="Estas a punto de borrar un elemento del inventario, recuerda que esta acción es irreversible."
          actions={[
            {
              text: 'Eliminar',
              onClick: () => {
                handleDeleteInDialog();
              },
            },
            {
              text: 'Cancelar',
              onClick: () => dispatch({ type: 'CLOSE_DELETE_DIALOG' }),
            },
          ]}
          open={state.deleteDialogState === 'open'}
          onClose={() => dispatch({ type: 'CLOSE_DELETE_DIALOG' })}
          onBackdropPress={() => dispatch({ type: 'CLOSE_DELETE_DIALOG' })}
        />
      </Grid>
    </>
  );
};

export default withNavigation(InventoryTable);
