import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { ProgressDataContainer, ProgressText } from './DropZoneStyles';
const ProgressData = (props) => {
  const { progressValue } = props;

  const getProgressText = (value) => {
    return value < 1 ? `%${(Number(value) * 100).toFixed(2)}` : 'Actualización terminada';
  };

  return (
    <ProgressDataContainer>
      {progressValue < 1 ? <CircularProgress color="primary" /> : null}
      <ProgressText>{getProgressText(progressValue)}</ProgressText>
    </ProgressDataContainer>
  );
};

export default ProgressData;
