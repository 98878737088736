import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import ScatterPlot from '@material-ui/icons/ScatterPlot';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { getInitialValues } from './validation';
import Input from '../../../../Components/FormFields/FormikIconInput';
import Select from '../../../../Components/FormFields/FormikSelect';
import { AxiosContext } from '../../../../Contexts/AxiosContext/AxiosContext';
import { ProjectContext } from '../../../../Contexts/ProjectContext/ProjectContext';
// Icons
import { useStyles } from '../../../InventoryScreen/NewElementFormulary/materialStyles';

function BackupFormulary(props) {
  const { setOpen, currentElement } = props;
  const styles = useStyles();

  const AxiosInstance = useContext(AxiosContext);
  const [projectState, setProjectState] = useContext(ProjectContext);

  //----- Catalog data ---------------------------------------------------------------
  const [projectConcepts, setProjectConcepts] = useState([]);
  //------------------------------------------------------------------------------
  useEffect(() => {
    const { concepts } = projectState;
    setProjectConcepts(
      concepts.map(({ code, conceptName, id }) => {
        return { itemLabel: `${code} - ${conceptName}`, itemValue: `${id}` };
      }),
    );
  }, [projectState]);

  //------------------------------------------------------------------------------

  const parseCurrentElement = (currElement) => {
    if (!currElement) {
      return {};
    }
    const {
      approved: approvedString,
      estimable: estimableString,
      usedForEstimation: usedForEstimationString,
    } = currElement;

    const booleanValues = {
      approved: approvedString === 'SI',
      estimable: estimableString === 'SI',
      usedForEstimation: usedForEstimationString === 'SI',
    };

    return { ...currElement, ...booleanValues };
  };

  const mapValues = (values) => {
    const { concept, comment, percentage, dueDate } = values;

    const result = {
      concept: {
        id: Number(concept),
      },
      comment,
      percentage,
      dueDate,
    };

    // haciendo put
    if (currentElement) {
      result.id = currentElement.id;
    }
    return result;
  };

  const handlePost = async (values) => {
    const method = currentElement ? 'put' : 'post';
    const milestoneToPublish = {
      ...parseCurrentElement(currentElement),
      ...mapValues(values),
    };

    const { data: newMilestone } = await AxiosInstance[method](
      `/api/project/${projectState.id}/milestone`,
      milestoneToPublish,
    );
    const { milestones } = projectState;

    const newMilestones =
      method === 'post'
        ? [...milestones, newMilestone]
        : milestones.map((milestone) =>
            milestone.id === currentElement.id ? milestoneToPublish : milestone,
          );

    setProjectState({ ...projectState, milestones: newMilestones });
  };

  const creationForm = (
    <Formik
      initialValues={getInitialValues(currentElement)}
      // validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        console.log(values);
        await handlePost(values);
        resetForm();
        setSubmitting(false);
        setOpen(false);
      }}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <Form className={styles.formulary}>
            <Grid container alignItems="center" alignContent="center" spacing={6}>
              <Grid item xs={12} md={4}>
                <Field name="concept">
                  {(props) => (
                    <Select
                      formikName={'concept'}
                      label={'Concepto'}
                      menuItemList={projectConcepts}
                      props={props}
                      Icon={ScatterPlot}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="percentage">
                  {(props) => (
                    <Input
                      formikName={'percentage'}
                      label={'Porcentaje de avance'}
                      Icon={PostAddIcon}
                      props={props}
                      type="number"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="dueDate">
                  {(props) => (
                    <Input
                      formikName={'dueDate'}
                      label={'Fecha esperada'}
                      Icon={CalendarTodayIcon}
                      props={props}
                      type="date"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                <Field name="comment">
                  {(props) => (
                    <Input
                      formikName={'comment'}
                      label={'Comentario'}
                      Icon={PostAddIcon}
                      props={props}
                      type="text"
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container alignItems="center" alignContent="center" justify="center" spacing={6}>
              <Grid item xs={12} md={3} className={styles.button}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    if (!isValid) {
                      alert('La información ingresada no es correcta o está incompleta');
                    }
                  }}
                >
                  {currentElement ? 'Guardar cambios' : 'Crear'}
                </Button>
              </Grid>
              <Grid item xs={12} md={3} className={styles.button}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
  return creationForm;
  // return uploadingFiles;
}

export default BackupFormulary;
