import styled from 'styled-components';
import { Card } from '@material-ui/core';

export const LabWorksDetailsMainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LabWorkDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const WorkOrderCard = styled.div`
  margin: 5px;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const LabWorkDataCard = styled(Card)`
  margin: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;
export const LabWorkFilesTableContainer = styled.div`
  margin: 5px;
  padding: 0;
  flex: 4;
`;

export const OrderTitle = styled.h3`
  margin: 0;
  font-weight: 400;
`;

export const OrderTextLine = styled.p`
  font-weight: 300;
  margin: 1px;
`;

export const WorkOrderTableTitle = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 15px;
  margin: 0;
`;
