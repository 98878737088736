/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { format } from 'date-fns';
import { getInitialValues, validationSchema } from './validation';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
// Icons
import { useStyles } from './materialStyles';
import Input from '../../../Components/FormFields/FormikIconInput';
import SimpleFileUpload from '../../../Components/SimpleFileUpload';
import { cerosPrefix, normalizePath } from '../../../Utils/normalizePath';
import { postNewFile } from '../../../Utils/s3Utils';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';
import { safetyObjectCheck } from '../../VehicleStateScreen/utils/getWarningLevel';
const FILES_BUCKET = 'roshare-qualitylibrary';

function BackupFormulary(props) {
  const { setOpen, currentElement, refetchDocuments } = props;
  const styles = useStyles();

  const AxiosInstance = useContext(AxiosContext);

  const [newDocumentFile, setNewDocumentFile] = useState(null);

  const handlePost = async (values) => {
    try {
      const { futureRevision, revisionDate: revisionDateObject } = values;
      const { code } = currentElement;
      const fileExtension = newDocumentFile?.name?.split('.').pop();
      const path = normalizePath(`${code}/`);
      const fileName = `${code}_${cerosPrefix(futureRevision, 4)}.${fileExtension}`;
      await postNewFile(newDocumentFile, fileName, path, {
        customBucket: FILES_BUCKET,
      });
      const putUrl = `/api/quality-document/${code}/revision/${futureRevision}`;
      const revisionDate = format(safetyObjectCheck(revisionDateObject), BACKEND_DATE_FORMAT);
      const updatePayload = { revisionDate, code };
      await AxiosInstance.put(putUrl, updatePayload);
      await AxiosInstance.put('/api/quality-document', {
        ...currentElement,
        revisionDate,
      });
    } catch (error) {
      console.log(error);
    } finally {
      refetchDocuments && (await refetchDocuments());
      setOpen();
    }
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentElement)}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, isSubmitting, setFieldValue }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={6}>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="revision">
                    {(props) => (
                      <Input
                        formikName={'revision'}
                        label={'Revisión actual'}
                        Icon={PostAddIcon}
                        {...props}
                        type="number"
                        disabled
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="futureRevision">
                    {(props) => (
                      <Input
                        formikName={'futureRevision'}
                        label={'Revisión futura'}
                        Icon={PostAddIcon}
                        {...props}
                        type="number"
                        disabled
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="revisionDate">
                    {(props) => (
                      <Input
                        formikName={'revisionDate'}
                        label={'Fecha de revisión actualizada'}
                        {...props}
                        type="date"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="file">
                    {(props) => (
                      <SimpleFileUpload
                        setFieldValue={setFieldValue}
                        fieldName={props.field.name}
                        setDocument={setNewDocumentFile}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={4} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    {currentElement ? 'Guardar cambios' : 'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default BackupFormulary;
