import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useStyles } from './EvidenciasScreenStyles';
import ColumnEditFormulary from './ColumnEditFormulary/Formulary';
import NewEvidenceFormulary from './NewElementFormulary/Formulary';
import { userContext } from './../../../Contexts/UserContext/UserContext';
import { withNavigation } from '../../../Containers/AppNavigation/AppNavigation';
import Dialog from '../../../Containers/GenericDialog';
import Table from '../../../Components/Table';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import Alert, { WARNING_TYPE } from '../../../Components/Alert/Alert';
import { ProjectContext } from '../../../Contexts/ProjectContext/ProjectContext';
import { ProjectMainTitle } from '../../SingleProjectScreen/SingleProjectScreenStyles';

const EvidenciasScreen = (props) => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const AxiosInstance = useContext(AxiosContext);
  const [projectState, setProjectState] = useContext(ProjectContext);
  const [userState] = useContext(userContext);

  //---------------------------------------------------------------------------
  const history = useHistory();
  let location = useLocation();
  const { pathname } = location;
  const { estimationMode } = props;
  const estimationId = parseInt(pathname.slice(-1));
  const overwriteEvidenciasData = projectState?.estimations?.filter((element) => {
    return element.id === estimationId;
  })[0]?.evidences;

  //---------------------------------------------------------------------------
  // useState hooks
  //--------------------------------------------------------------------------- setOpenSaveDialog
  const [formularyDialogOpen, setFormularyDialogOpen] = useState(false);
  const [newElementModalState, setNewElementModalState] = useState(false);
  const [evidenceToEdit, setEvidenceToEdit] = useState(null);
  const [evidenceToDelete, setEvidenceToDelete] = useState(null);
  const [modalFileMode, setModalFileMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingWarning, setDeletingWarning] = useState(false);
  const [userProjectGrants, setUserProjectGrants] = useState({});
  const [columnTableState, setColumnTableState] = useState({
    inventoryType: true,
    clasification: true,
    inventoryNumber: true,
    equipment: true,
    description: true,
    brand: false,
    model: false,
    serialNumber: false,
    status: false,
    responsable: false,
    project: false,
    nickname: false,
    set: false,
    EMA: false,
    AMAAC: false,
  });

  //---------------------------------------------------------------------------
  // CONSTANTS
  //---------------------------------------------------------------------------
  const dateFilter = new URLSearchParams(window.location.search).get('fecha');
  const { userDetails = {} } = userState || {};

  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useFetch hooks
  // //---------------------------------------------------------------------------

  const handleDelete = async () => {
    try {
      await AxiosInstance.delete(`/api/project/${projectState.id}/evidence/${evidenceToDelete.id}`);
      const { evidences } = projectState;
      setProjectState({
        ...projectState,
        evidences: evidences.filter((evidence) => evidence.id !== evidenceToDelete.id),
      });
    } catch (error) {
      console.log(error.message);
      setDeletingWarning(true);
    } finally {
      setEvidenceToDelete(null);
      setOpenDeleteDialog(false);
    }
  };
  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (projectState) {
      const { projectGrants } = projectState;

      let tempGrants = {};
      (projectGrants || []).forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });
      setUserProjectGrants(tempGrants);
    }
  }, [projectState]);

  //---------------------------------------------------------------------------
  const mapElement = (element) => {
    const {
      approved,
      author: { name: author },
      concept,
      description,
      estimable,
      quantity,
      title,
      usedForEstimation,
      id,
      creationDate,
    } = element;
    const _element = {
      approved: approved ? 'SI' : 'NO',
      author,
      concept: concept?.conceptName || 'N/A',
      conceptObject: concept,
      description,
      estimable: estimable ? 'SI' : 'NO',
      quantity: quantity || 'N/A',
      title,
      usedForEstimation: usedForEstimation ? 'SI' : 'NO',
      id,
      creationDate: creationDate?.substring(0, creationDate.length >= 10 ? 10 : 0),
    };
    return _element;
  };

  const filterByDate = (element) => {
    if (dateFilter) return dateFilter === element.creationDate;
    return true;
  };

  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // COMMON Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'Titulo', field: 'title' },
    { title: 'Concepto', field: 'concept' },
    { title: 'Cantidad', field: 'quantity' },
    { title: 'Autor', field: 'author' },
    { title: 'Descripción', field: 'description' },
    { title: 'Aprobada', field: 'approved' },
    { title: 'Estimable', field: 'estimable' },
    { title: 'Usado en estimación', field: 'usedForEstimation' },
    { title: 'Fecha', field: 'creationDate' },
  ];

  //---------------------------------------------------------------------------
  // MAIN Table Actions
  //---------------------------------------------------------------------------

  const actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            {'Agregar Evidencia'}
          </Button>
        );
      },
      tooltip: 'Button',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setEvidenceToEdit(null);
        setNewElementModalState(true);
      },
      isFreeAction: true,
      hidden: !userProjectGrants?.EDIT_EVIDENCES?.includes(userDetails?.username),
    },
    {
      icon: SettingsIcon,
      tooltip: 'Add / Remove Columns',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setModalFileMode(false);
        setFormularyDialogOpen(true);
      },
      isFreeAction: true,
    },
    {
      icon: 'edit',
      tooltip: 'Editar evidencia',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        console.log(event, rowElement);
        setEvidenceToEdit(rowElement);
        setNewElementModalState(true);
        setModalFileMode(false);
      },
      hidden: !userProjectGrants?.EDIT_EVIDENCES?.includes(userDetails?.username),
    },
    {
      icon: 'folder',
      tooltip: 'Administrar archivos de evidencia',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        console.log(event, rowElement);
        setEvidenceToEdit(rowElement);
        setNewElementModalState(true);
        setModalFileMode(true);
      },
      hidden: !userProjectGrants?.EDIT_EVIDENCES?.includes(userDetails?.username),
    },
    {
      icon: 'delete',
      tooltip: 'Eliminar evidencias',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        console.log(event, rowElement);
        setEvidenceToDelete(rowElement);
        setOpenDeleteDialog(true);
      },
      hidden: !userProjectGrants?.EDIT_EVIDENCES?.includes(userDetails?.username),
    },
  ];

  const estimationDetailsAction = [
    {
      icon: 'folder',
      tooltip: 'Administrar archivos de evidencia',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        console.log(event, rowElement);
        setEvidenceToEdit(rowElement);
        setNewElementModalState(true);
        setModalFileMode(true);
      },
      hidden: !userProjectGrants?.EDIT_EVIDENCES?.includes(userDetails?.username),
    },
  ];

  const MainEvidenciasTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid container direction="row" justify="flex-starte" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              {'REGRESAR <<'}
            </Button>
          </Grid>
          <Grid item>
            <ProjectMainTitle>
              {`${projectState?.projectId || ''} - ${projectState?.projectName || ''} - ${
                projectState?.contractKey || ''
              }` || 'Proyecto'}
            </ProjectMainTitle>
          </Grid>
        </Grid>
        <Grid item>
          <Table
            title={dateFilter ? `Evidencias  -  ${dateFilter}` : `Evidencias`}
            data={
              estimationMode
                ? overwriteEvidenciasData?.map(mapElement) || []
                : projectState?.evidences.map(mapElement).filter(filterByDate) || []
            }
            columns={TABLE_HEADERS}
            actions={estimationMode ? estimationDetailsAction : actions}
            options={{
              filtering: true,
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        openDialog={formularyDialogOpen}
        dialogTitle={'Configuración de Columnas'}
        maxWidth="md"
      >
        <ColumnEditFormulary
          setOpen={setFormularyDialogOpen}
          currentFilter={columnTableState}
          columnFields={columnTableState}
          setColumnFields={setColumnTableState}
        />
      </Dialog>
      <Dialog
        openDialog={newElementModalState}
        dialogTitle={evidenceToEdit ? 'Editar Evidencia' : 'Nueva Evidencia'}
        maxWidth="md"
      >
        <NewEvidenceFormulary
          setOpen={setNewElementModalState}
          currentElement={evidenceToEdit}
          modalFileMode={modalFileMode}
        />
      </Dialog>
      <Alert
        type={WARNING_TYPE}
        title="Borrar evidencia del proyecto"
        content="Estas a punto de borrar una evidencia del proyecto, recuerda que esta acción es irreversible y puede impactar otras áreas del sistema."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenDeleteDialog(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onBackdropPress={() => {
          setOpenDeleteDialog(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Imposible Borrar"
        content="Esta evidencia no ha podido ser eliminada, es probable que sea referenciada por otro elemento del sistema."
        actions={[
          {
            text: 'OK',
            onClick: () => {
              setDeletingWarning(false);
            },
          },
        ]}
        open={deletingWarning}
        onClose={() => setDeletingWarning(false)}
        onBackdropPress={() => {
          setDeletingWarning(false);
        }}
      />
    </>
  );

  const renderScreen = () => {
    return MainEvidenciasTable;
  };

  return <div className={styles.container}>{renderScreen()}</div>;
};

export default withNavigation(EvidenciasScreen);
