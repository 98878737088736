export const inventoryRouteList = [
  {
    elementName: 'Equipo de Cómputo',
    routePath: '/inventarios/equipo_de_computo',
    apiPath: '/api/stock/equipo-computo',
    entityType: 'Clasificaciones Equipo de Cómputo',
    brandEntityType: 'Marcas de Equipo de Cómputo',
    modelEntityType: 'Modelos de Equipo de Cómputo',
    stockType: 'EQUIPO_COMPUTO',
    canViewProfile: 'VER EQUIPO DE COMPUTO',
    canEditProfile: 'EDITAR EQUIPO DE COMPUTO',
  },
  {
    elementName: 'Equipo de Oficina',
    routePath: '/inventarios/equipo_de_oficina',
    apiPath: '/api/stock/equipo-oficina',
    entityType: 'Clasificaciones de Equipo de Oficina',
    brandEntityType: 'Marcas de Equipo de Oficina',
    modelEntityType: 'Modelos de Equipo de Oficina',
    stockType: 'EQUIPO_OFICINA',
    canViewProfile: 'VER EQUIPO DE OFICINA',
    canEditProfile: 'EDITAR EQUIPO DE OFICINA',
  },
  {
    elementName: 'Mobiliario',
    routePath: '/inventarios/mobiliario',
    apiPath: '/api/stock/mobiliario',
    entityType: 'Clasificaciones de Mobiliario',
    brandEntityType: 'Marcas de Mobiliario',
    modelEntityType: 'Modelos de Mobiliario',
    stockType: 'MOBILIARIO',
    canViewProfile: 'VER MOBILIARIO',
    canEditProfile: 'EDITAR MOBILIARIO',
  },
  {
    elementName: 'Auscultación de Infraestructura',
    routePath: '/inventarios/auscultacion_de_infraestructura',
    apiPath: '/api/stock/auscultacion',
    entityType: 'Clasificaciones de Auscultación de Infraestructura',
    brandEntityType: 'Marcas de Auscultación de Infraestructura',
    modelEntityType: 'Modelos de Auscultación de Infraestructura',
    stockType: 'AUSCULTACION',
    canViewProfile: 'VER AUSCULTACION DE INFRAESTRUCTURA',
    canEditProfile: 'EDITAR AUSCULTACION DE INFRAESTRUCTURA',
  },
  {
    elementName: 'Ingeniería de Tránsito',
    routePath: '/inventarios/ingenieria_de_transito',
    apiPath: '/api/stock/ingenieria-transito',
    entityType: 'Clasificaciones de Ingeniería de Tránsito',
    brandEntityType: 'Marcas de Ingeniería de Tránsito',
    modelEntityType: 'Modelos de Ingeniería de Tránsito',
    stockType: 'INGENIERIA_TRANSITO',
    canViewProfile: 'VER INGENIERIA DE TRANSITO',
    canEditProfile: 'EDITAR INGENIERIA DE TRANSITO',
  },
  {
    elementName: 'Auscultación de la Estructura',
    routePath: '/inventarios/auscultacion_de_la_estructura',
    apiPath: '/api/stock/auscultacion-estructura',
    entityType: 'Clasificaciones de Auscultación de la Estructura',
    brandEntityType: 'Marcas de Auscultación de la Estructura',
    modelEntityType: 'Modelos de Auscultación de la Estructura',
    stockType: 'AUSCULTACION_ESTRUCTURA',
    canViewProfile: 'VER AUSCULTACION DE LA ESTRUCTURA',
    canEditProfile: 'EDITAR AUSCULTACION DE LA ESTRUCTURA',
  },
  {
    elementName: 'Geología Geofísica y Georadar',
    routePath: '/inventarios/geologia_geofisica_y_georadar',
    apiPath: '/api/stock/geofisica',
    entityType: 'Clasificaciones de Geología Geofísica y Georadar',
    brandEntityType: 'Marcas de Geología Geofísica y Georadar',
    modelEntityType: 'Modelos de Geología Geofísica y Georadar',
    stockType: 'GEOFISICA',
    canViewProfile: 'VER GEOLOGIA GEOFISICA Y GEORADAR',
    canEditProfile: 'EDITAR GEOLOGIA GEOFISICA Y GEORADAR',
  },
  {
    elementName: 'Geotecnia',
    routePath: '/inventarios/geotecnia',
    apiPath: '/api/stock/geotecnia',
    entityType: 'Clasificaciones de Geotecnia',
    brandEntityType: 'Marcas de Geotecnia',
    modelEntityType: 'Modelos de Geotecnia',
    stockType: 'GEOTECNIA',
    canViewProfile: 'VER GEOTECNIA',
    canEditProfile: 'EDITAR GEOTECNIA',
  },
  {
    elementName: 'Topografía',
    routePath: '/inventarios/topografia',
    apiPath: '/api/stock/topografia',
    entityType: 'Clasificaciones de Topografía',
    brandEntityType: 'Marcas de Topografía',
    modelEntityType: 'Modelos de Topografía',
    stockType: 'TOPOGRAFIA',
    canViewProfile: 'VER TOPOGRAFIA',
    canEditProfile: 'EDITAR TOPOGRAFIA',
  },
  {
    elementName: 'Drones',
    routePath: '/inventarios/drones',
    apiPath: '/api/stock/drones',
    entityType: 'Clasificaciones de Drones',
    brandEntityType: 'Marcas de Drones',
    modelEntityType: 'Modelos de Drones',
    stockType: 'DRONES',
    canViewProfile: 'VER DRONES',
    canEditProfile: 'EDITAR DRONES',
  },
  {
    elementName: 'Laboratorio',
    routePath: '/inventarios/laboratorio',
    apiPath: '/api/stock/laboratorio',
    entityType: 'Clasificaciones de Laboratorio',
    brandEntityType: 'Marcas de Laboratorio',
    modelEntityType: 'Modelos de Laboratorio',
    stockType: 'LABORATORIO',
    canViewProfile: 'VER LABORATORIO',
    canEditProfile: 'EDITAR LABORATORIO',
  },
  {
    elementName: 'Maquinaria Pesada y/o Especial',
    routePath: '/inventarios/maquinaria_pesada_y_o_especial',
    apiPath: '/api/stock/maquinaria-pesada',
    entityType: 'Clasificaciones de Maquinaria Pesada y/o Especial',
    brandEntityType: 'Marcas de Maquinaria Pesada y/o Especial',
    modelEntityType: 'Modelos de Maquinaria Pesada y/o Especial',
    stockType: 'MAQUINARIA_PESADA',
    canViewProfile: 'VER MAQUINARIA PESADA Y ESPECIAL',
    canEditProfile: 'EDITAR MAQUINARIA PESADA Y ESPECIAL',
  },
  {
    elementName: 'Maquinaria Ligera',
    routePath: '/inventarios/maquinaria_ligera',
    apiPath: '/api/stock/maquinaria',
    entityType: 'Clasificaciones de Maquinaria Ligera',
    brandEntityType: 'Marcas de Maquinaria Ligera',
    modelEntityType: 'Modelos de Maquinaria Ligera',
    stockType: 'MAQUINARIA',
    canViewProfile: 'VER MAQUINARIA LIGERA',
    canEditProfile: 'EDITAR MAQUINARIA LIGERA',
  },
  {
    elementName: 'Herramienta Menor',
    routePath: '/inventarios/herramienta_menor',
    apiPath: '/api/stock/herramientas',
    entityType: 'Clasificaciones de Herramienta Menor',
    brandEntityType: 'Marcas de Herramienta Menor',
    modelEntityType: 'Modelos de Herramienta Menor',
    stockType: 'HERRAMIENTAS',
    canViewProfile: 'VER HERRAMIENTA MENOR',
    canEditProfile: 'EDITAR HERRAMIENTA MENOR',
  },
  {
    elementName: 'Vehículos',
    routePath: '/inventarios/vehiculos',
    apiPath: '/api/stock/vehiculos',
    entityType: 'Clasificaciones de Vehículos',
    brandEntityType: 'Marcas de Vehículos',
    modelEntityType: 'Modelos de Vehículos',
    stockType: 'VEHICULOS',
    canViewProfile: 'VER VEHICULOS',
    canEditProfile: 'EDITAR VEHICULOS',
  },
  {
    elementName: 'Equipo de Seguridad',
    routePath: '/inventarios/equipo-de-seguridad',
    entityType: 'Clasificaciones de equipo de seguridad',
    brandEntityType: 'Marcas de equipo de seguridad',
    modelEntityType: 'Modelos de equipo de seguridad',
    stockType: 'EQUIPO_DE_SEGURIDAD',
    canViewProfile: 'VER EQUIPOS DE SEGURIDAD',
    canEditProfile: 'EDITAR EQUIPOS DE SEGURIDAD',
  },
];

export const URL =
  'https://api-qa.ro-share.com/api/stock/list?stockType=EQUIPO_COMPUTO&stockType=EQUIPO_OFICINA&stockType=MOBILIARIO&stockType=AUSCULTACION&stockType=INGENIERIA_TRANSITO&stockType=AUSCULTACION_ESTRUCTURA&stockType=GEOFISICA&stockType=GEOTECNIA&stockType=TOPOGRAFIA&stockType=DRONES&stockType=LABORATORIO&stockType=MAQUINARIA_PESADA&stockType=MAQUINARIA&stockType=HERRAMIENTAS&stockType=VEHICULOS';
