import React from 'react';
import MaterialTable from 'material-table';
import { format } from 'date-fns';
import { BACKEND_DATE_FORMAT } from '../../../../Constants/generalConstants';
import { ESTIMATION_STATUS_DICTIONARY } from '../DependencyFeedbackDialog/DependencyFeedbackDialog';

const EstimationLog = ({ estimation }) => {
  const { comments } = estimation || {};
  let parsedComments;
  try {
    parsedComments = JSON.parse(comments);
  } catch (error) {
    parsedComments = [];
  }

  const TABLE_HEADERS = [
    {
      title: 'Fecha',
      field: 'timeStamp',
      render: ({ timeStamp }) => {
        return format(new Date(timeStamp), BACKEND_DATE_FORMAT);
      },
    },
    { title: 'Comentario', field: 'comment' },
    {
      title: 'Estado',
      field: 'state',
      render: ({ state }) => {
        return ESTIMATION_STATUS_DICTIONARY[state];
      },
    },
    { title: 'Autor', field: 'author' },
  ];

  return comments ? (
    <MaterialTable
      title="Historia de la estimación"
      columns={TABLE_HEADERS}
      data={parsedComments}
      options={{ search: false }}
    />
  ) : null;
};

export default EstimationLog;
