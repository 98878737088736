import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Cancel';
import SuccessIcon from '@material-ui/icons/Check';

function getDataFromType(type) {
  let icon;
  let titleFallback;

  if (type === 'warning') {
    icon = (
      <WarningIcon
        style={{
          width: '50px',
          height: '50px',
          margin: 'auto',
          color: '#ffd400',
        }}
      />
    );
    titleFallback = 'Warning';
  } else if (type === 'error') {
    icon = (
      <ErrorIcon
        color="#D09F53"
        style={{
          width: '50px',
          height: '50px',
          margin: 'auto',
          color: '#c40505',
        }}
      />
    );
    titleFallback = 'Error';
  } else if (type === 'success') {
    icon = (
      <SuccessIcon
        color="#D09F53"
        style={{
          width: '50px',
          height: '50px',
          margin: 'auto',
          color: '#0a9b00',
        }}
      />
    );
    titleFallback = 'Success';
  } else {
    titleFallback = 'Alert';
  }
  return { icon, titleFallback };
}

/**
  type: "warning" | "error" | "success" | "none",
  title: string,
  content: string | React.Node,
  actions: Array<{
    text: string,
    onClick: function,
    style: "cancel"
  }>,
  open: boolean,
  onClose: function,
  onBackdropPress: function
**/

export const WARNING_TYPE = 'warning';
export const ERROR_TYPE = 'error';
export const SUCCESS_TYPE = 'success';

export default function Alert(props) {
  const { type = 'none', title, content, actions, open, onClose, onBackdropPress } = props;
  const { icon, titleFallback } = getDataFromType(type);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={onBackdropPress}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      style={{ padding: '50px' }}
    >
      <DialogTitle id="alert-dialog-title">{title || titleFallback}</DialogTitle>
      <DialogContent
        style={{
          fontSize: '20px',
          justifyContent: 'center',
          textAlign: 'center',
          paddingTop: '30px',
          paddingBottom: '30px',
        }}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>{icon}</div>
        {content}
      </DialogContent>
      {actions && (
        <DialogActions>
          {actions.map((action, idx) => (
            <React.Fragment key={idx}>
              <Button onClick={action.onClick} style={{ ...action.style } || {}}>
                {action.text || ''}
              </Button>
              {idx !== actions.length - 1 ? <div style={{ width: '10px' }}></div> : null}
            </React.Fragment>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
}
