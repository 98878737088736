import * as yup from 'yup';
import { parse as parseDate, format as formatDate } from 'date-fns';
import { BACKEND_DATE_FORMAT } from '../../../../Constants/generalConstants';

export const validationSchema = yup.object().shape({
  concept: yup.string().required('Campo obligatorio'),
  cost: yup.number().required('Campo obligatorio'),
  date: yup.date().required('Campo obligatorio'),
});

export const getInitialValues = (currentCost) => {
  const { concept, cost, date } = currentCost ?? {};

  // 19/08/2021

  const initialValues = {
    concept,
    cost,
  };

  if (date) {
    initialValues.date = formatDate(parseDate(date, BACKEND_DATE_FORMAT, new Date()), 'yyyy-MM-dd');
  }
  return initialValues;
};
