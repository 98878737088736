import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import DropZone from './../../../Components/DropZone';
import DialogActions from './../../../Containers/GenericDialog/DialogActions';
import axios from './../../../Utils/AxiosS3Instance';

import NewWeekReportFormulary from './NewWeekReportFormulary';
import Dialog from './../../../Containers/GenericDialog';
import { normalizePath } from './../../../Utils/normalizePath';
import { useFetch } from '../../../Hooks/useFetch';
import { deleteFile } from '../../../Utils/s3Utils';
import Table from '../../../Components/Table';
import { COSTOS } from '../../../Constants/grantsModules';
import { useModuleGrants } from '../../../Hooks/useUsersGrants';

const FILES_BUCKET = 'roshare-costs-files';
const WeekReportTable = (props) => {
  const history = useHistory();
  let { week } = useParams();
  const { arrangedData = [], value = 0, refetchProjectData } = props;
  const [tableData, setTableData] = useState([]);
  const [newReportModal, setNewReportModal] = useState(false);
  const [newAttachmentModal, setNewAttachmentModal] = useState(false);
  const [uploadPath, setUploadPath] = useState('');
  const [costToEdit, setCostToEdit] = useState(null);
  const [autocompleteCostConceptSuggestions, setAutocompleteCostConceptsSuggestions] = useState([]);
  const { allowedModuleSections: moduleGrants } = useModuleGrants(COSTOS);

  useEffect(() => {
    const arrangedCosts = value === 0 ? arrangedData['DIRECT'] : arrangedData['INDIRECT'];
    if (arrangedCosts) {
      const finalItem = arrangedCosts
        .reduce((accumulator, weekCosts) => {
          return [...accumulator, ...weekCosts];
        }, [])
        .filter((element) => {
          return element != null && parseInt(element.week) === parseInt(week);
        });

      const currentConcepts = finalItem
        .map((item) => item.concept)
        .reduce(
          (accumulator, currentCostConcept) =>
            accumulator.includes(currentCostConcept)
              ? accumulator
              : [...accumulator, currentCostConcept],
          [],
        );
      setAutocompleteCostConceptsSuggestions(currentConcepts);
      setTableData(finalItem);
    }
  }, [arrangedData]);

  const TABLE_HEADERS = [
    { title: 'Concepto', field: 'concept' },
    { title: 'Cost', field: 'cost' },
    { title: 'Fecha', field: 'date' },
  ];

  const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];

  const TABLE_ACTIONS = [
    {
      icon: 'folder',
      tooltip: 'Detalles',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { id } = rowElement;
        const path = `${normalizePath(value ? 'INDIRECT' : 'DIRECT')}_${id}/`;
        setUploadPath(path);
        updateParams({ path: path, bucket: FILES_BUCKET });
        setNewAttachmentModal(true);
      },
    },
    {
      icon: 'edit',
      tooltip: 'Editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setCostToEdit(rowElement);
        setNewReportModal(true);
      },
      hidden: !moduleGrants.includes('AGREGAR Y EDITAR'),
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Agregar elemento
          </Button>
        );
      },
      tooltip: 'Create',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setNewReportModal(true);
      },
      isFreeAction: true,
      hidden: !moduleGrants.includes('AGREGAR Y EDITAR'),
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Regresar
          </Button>
        );
      },
      tooltip: 'Create',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        history.goBack();
      },
      isFreeAction: true,
    },
  ];

  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------
  const getFile = async (s3DownloadKey) => {
    const signedUrl = (
      await axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`)
    ).data;

    window.open(signedUrl, '_blank');
    //win.focus();
  };

  const handleFileDelete = (key) => {
    deleteFile(key, FILES_BUCKET, () => {
      // setDeleteOpenAlert(false);
      refetchFiles();
    });
  };
  //---------------------------------------------------------------------------
  // Table Actions
  //---------------------------------------------------------------------------

  const {
    data: elementFiles,
    updateParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: uploadPath ? { path: uploadPath } : undefined,
  });

  const fileTableActions = [
    {
      icon: 'cloud_download',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${uploadPath}${fileName}`;
        getFile(key);
      },
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${uploadPath}${fileName}`;
        handleFileDelete(key);
      },
      hidden: !moduleGrants.includes('AGREGAR Y EDITAR'),
    },
  ];

  return (
    <>
      <Table
        title={`Gastos ${value === 0 ? 'directos' : 'indirectos'} semana ${week}`}
        data={tableData}
        columns={TABLE_HEADERS}
        actions={TABLE_ACTIONS}
      />
      <Dialog
        openDialog={newReportModal}
        dialogTitle={`Crear Nuevo Reporte - Gasto ${value === 0 ? 'Directo' : 'Indirecto'}`}
        maxWidth="md"
      >
        <NewWeekReportFormulary
          mode={value}
          setOpen={setNewReportModal}
          refetchProjectData={refetchProjectData}
          currentCost={costToEdit}
          autocompleteCostConceptSuggestions={autocompleteCostConceptSuggestions}
        />
      </Dialog>
      <Dialog openDialog={newAttachmentModal} dialogTitle={'Subir archivos'} maxWidth="md">
        {moduleGrants.includes('AGREGAR Y EDITAR') && (
          <DropZone
            path={uploadPath}
            closeDialog={() => setNewAttachmentModal(false)}
            refetch={() => {
              console.log('');
            }}
            bucket={FILES_BUCKET}
          />
        )}
        <DialogActions
          secondaryLabel="Cancelar"
          primaryLabel={null}
          secondaryAction={() => setNewAttachmentModal(false)}
        />
        <Table
          title="Archivos de Elemento"
          data={elementFiles?.files.map((file) => ({
            fileName: file.key.split('/').pop(),
          }))}
          columns={FILE_TABLE_HEADERS}
          actions={fileTableActions}
        />
      </Dialog>
    </>
  );
};

export default WeekReportTable;
