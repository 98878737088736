import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useStyles, TableContainerWithScrollBars } from './RecursosHumanosScreenStyles';
import ColumnEditFormulary from './ColumnEditFormulary/Formulary';
import { ProjectContext } from './../../../Contexts/ProjectContext/ProjectContext';
import { userContext } from './../../../Contexts/UserContext/UserContext';
import { withNavigation } from '../../../Containers/AppNavigation/AppNavigation';
import Dialog from '../../../Containers/GenericDialog';
import Table from '../../../Components/Table';
import { useFetch } from '../../../Hooks/useFetch';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import Alert from '../../../Components/Alert/Alert';
import { ProjectMainTitle } from '../../SingleProjectScreen/SingleProjectScreenStyles';

const RecursosHumanosScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const history = useHistory();
  const AxiosInstance = useContext(AxiosContext);
  const [projectState, setProjectState] = useContext(ProjectContext);
  const [userState] = useContext(userContext);

  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useState hooks
  //--------------------------------------------------------------------------- setOpenSaveDialog
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [tableComparisonView, setTableComparisonView] = useState(false);
  const [formularyDialogOpen, setFormularyDialogOpen] = useState(false);
  const [userProjectGrants, setUserProjectGrants] = useState({});
  const [columnTableState, setColumnTableState] = useState({
    position: true,
    names: true,
    lastName1: true,
    lastName2: true,
    area: true,
  });

  //---------------------------------------------------------------------------
  const { userDetails = {} } = userState || {};

  //---------------------------------------------------------------------------
  // useFetch hooks
  // //---------------------------------------------------------------------------
  const { data: allEmployees, refetch: refetchEmployees } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/employee/list',
  });
  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (allEmployees) {
      console.log(allEmployees);
    }
  }, [allEmployees]);

  useEffect(() => {
    if (projectState) {
      const { projectGrants = [] } = projectState;

      let tempGrants = {};
      projectGrants.forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });
      setUserProjectGrants(tempGrants);
    }
  }, [projectState]);

  //---------------------------------------------------------------------------
  const mapElement = (element) => {
    const {
      area,
      assignedToProject,
      bankAccountNumber,
      bankName,
      curp,
      degree,
      email,
      id,
      jobStartDate,
      lastName1,
      lastName2,
      names,
      phoneNumber,
      position,
      professionalLicense,
      rfc,
      socialSecurityNumber,
      user,
    } = element;
    const _element = {
      area: area?.value || '',
      areaObject: area,
      assignedToProject,
      bankAccountNumber,
      bankName,
      curp,
      degree: degree?.value || '',
      degreeObject: degree,
      email,
      id,
      jobStartDate,
      lastName1,
      lastName2,
      names,
      fullName: `${names} ${lastName1} ${lastName2}`,
      phoneNumber,
      position: position?.value || '',
      positionObject: position,
      professionalLicense,
      rfc,
      socialSecurityNumber,
      user,
    };
    return _element;
  };

  const filterColumns = (column) => {
    return columnTableState[column.field];
  };
  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // COMMON Table Actions
  //---------------------------------------------------------------------------
  const TABLE_HEADERS = [
    { title: 'Puesto', field: 'position' },
    { title: 'Nombre(s)', field: 'names' },
    { title: 'Apellido Paterno', field: 'lastName1' },
    { title: 'Apellido Materno', field: 'lastName2' },
    { title: 'Área', field: 'area' },
    { title: 'Teléfono', field: 'phoneNumber' },
    { title: 'Email', field: 'email' },
    { title: 'Título / Profesión', field: 'degree' },
    { title: 'CURP', field: 'curp' },
    { title: 'RFC', field: 'rfc' },
    { title: 'Fecha de Ingreso', field: 'jobStartDate' },
    { title: 'Institución Bancaria', field: 'bankName' },
    { title: '# Cuenta Bancaria', field: 'bankAccountNumber' },
    { title: 'Cédula Profesional', field: 'professionalLicense' },
    { title: '# Seguro Social', field: 'socialSecurityNumber' },
    { title: 'Empresas', field: 'company' },
  ];

  //---------------------------------------------------------------------------
  // MAIN Table Actions
  //---------------------------------------------------------------------------
  const columnConfigurationIcon = {
    icon: SettingsIcon,
    tooltip: 'Add / Remove Columns',
    iconProps: {
      color: 'secondary',
    },
    onClick: () => {
      setFormularyDialogOpen(true);
    },
    isFreeAction: true,
  };

  const actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            {tableComparisonView ? 'Regresar' : 'Editar Empleados'}
          </Button>
        );
      },
      tooltip: 'Update Employees',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setTableComparisonView(!tableComparisonView);
      },
      isFreeAction: true,
      hidden: !userProjectGrants?.EDIT_HR?.includes(userDetails?.username),
    },
    {
      icon: 'delete',
      tooltop: 'Eliminar Empleado del proyecto',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        const { id: employeeId } = rowElement;
        const { id: projectId } = projectState;

        const { data: newProjectState } = await AxiosInstance.delete(
          `/api/project/${projectId}/employee/${employeeId}`,
        );
        console.log('removing employee', newProjectState);
        setProjectState(newProjectState);
        refetchEmployees();
      },
      hidden: !tableComparisonView || !userProjectGrants?.EDIT_HR?.includes(userDetails?.username),
    },
  ];

  if (tableComparisonView === false) actions.push(columnConfigurationIcon);

  const MainRecursosHumanosTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        {!tableComparisonView && (
          <Grid container direction="row" justify="flex-starte" alignItems="center" spacing={2}>
            {!tableComparisonView && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {'REGRESAR <<'}
                </Button>
              </Grid>
            )}
            {!tableComparisonView && (
              <Grid item>
                <ProjectMainTitle>
                  {`${projectState?.projectId || ''} - ${projectState?.projectName || ''} - ${
                    projectState?.contractKey || ''
                  }` || 'Proyecto'}
                </ProjectMainTitle>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item>
          <Table
            title={tableComparisonView ? 'R.H. Asignados' : `Recursos Humanos Asignados`}
            data={(projectState?.employees || []).map(mapElement)}
            columns={TABLE_HEADERS.filter(filterColumns)}
            actions={actions}
          />
        </Grid>
      </Grid>
      <Dialog
        openDialog={formularyDialogOpen}
        dialogTitle={'Configuración de Columnas'}
        maxWidth="md"
      >
        <ColumnEditFormulary
          setOpen={setFormularyDialogOpen}
          currentFilter={columnTableState}
          columnFields={columnTableState}
          setColumnFields={setColumnTableState}
        />
      </Dialog>
    </>
  );

  //---------------------------------------------------------------------------
  // SECONDARY Table Actions
  //---------------------------------------------------------------------------
  const secondaryTableActions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Guardar
          </Button>
        );
      },
      tooltip: 'Save',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setOpenSaveDialog(true);
      },
      isFreeAction: true,
    },
    {
      icon: 'add',
      tooltip: 'Agregar empleado a proyecto',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        const { id: employeeId } = rowElement;
        const { id: projectId } = projectState;
        console.log('agregando empleado a proyecto', rowElement);
        const { data: newProjectState } = await AxiosInstance.post(
          `/api/project/${projectId}/employee/${employeeId}`,
        );
        setProjectState(newProjectState);
        refetchEmployees();
      },
    },
  ];

  const SecondaryRecursosHumanosTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Table
            title={`R.H. No Asignados`}
            data={(allEmployees || [])
              .filter((employee) => !employee?.assignedToProject)
              .map(mapElement)}
            columns={TABLE_HEADERS.filter(filterColumns)}
            actions={secondaryTableActions}
          />
        </Grid>
      </Grid>
      <Alert
        type={'none'}
        title="Guardar"
        content="Estas seguro de querer guardar tus cambios?"
        actions={[
          {
            text: 'Guardar',
            onClick: () => {
              setTableComparisonView(false);
              setOpenSaveDialog(false);
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenSaveDialog(false);
            },
          },
        ]}
        open={openSaveDialog}
        onClose={() => setOpenSaveDialog(false)}
        onBackdropPress={() => {
          setOpenSaveDialog(false);
        }}
      />
    </>
  );

  const renderScreen = () => {
    let tablesView = (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TableContainerWithScrollBars>
            {SecondaryRecursosHumanosTable}
          </TableContainerWithScrollBars>
        </Grid>
        <Grid item xs={6}>
          <TableContainerWithScrollBars>{MainRecursosHumanosTable}</TableContainerWithScrollBars>
        </Grid>
      </Grid>
    );

    return tableComparisonView ? tablesView : MainRecursosHumanosTable;
  };

  return <div className={styles.container}>{renderScreen()}</div>;
};

export default withNavigation(RecursosHumanosScreen);
