import React, { useContext, useReducer, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, MenuItem, Select } from '@material-ui/core';
import {
  useStyles,
  ProfilesTitle,
  MainContainer,
  ProfileHeaderContainer,
  FormContainer,
  SelectContainer,
  SubtitleTitle,
} from './QualityProfileEditScreenStyles';
import NewPermitForm from './NewProfileForm';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import DataCardGrantSelection from '../../Components/DataCards/DataCardGrantSelection';

export const GIVE_GRANT_TYPE = 'GIVE_GRANT_TYPE';
export const REMOVE_GRANT_TYPE = 'REMOVE_GRANT_TYPE';
export const FIRST_ASSIGNMENT_TYPE = 'FIRST_ASSIGNMENT_TYPE';
export const GIVE_MODULE_ACCESS_TYPE = 'GIVE_MODULE_ACCESS_TYPE';
export const REMOVE_MODULE_ACCESS_TYPE = 'REMOVE_MODULE_ACCESS_TYPE';
export const PROFILE_LOAD_TYPE = 'PROFILE_LOAD_TYPE';

const QualityProfileEditScreen = () => {
  const styles = useStyles();
  const [arrangedDocuments, setArrangedDocuments] = useState({});

  let { profile, userId } = useParams();
  const AxiosInstance = useContext(AxiosContext);
  const [userCurrentProfile, setUserCurrentProfile] = useState(null);
  const [qaDocProfiles, setQaDocProfiles] = useState([]);
  const [dynamicProfileId, setDynamicProfileId] = useState();

  const [grantsState, grantsDispatch] = useReducer((state, action) => {
    const { targetGrant = {}, type, targetModule } = action;
    const { value: module } = targetGrant?.folder || {};
    let grantsSubSet;
    switch (type) {
      case GIVE_GRANT_TYPE:
        const currentGrants = state[module] || [];
        grantsSubSet = currentGrants.map(({ code }) => code).includes(targetGrant.code)
          ? state[module]
          : [...currentGrants, targetGrant];
        return { ...state, [module]: grantsSubSet };
      case REMOVE_GRANT_TYPE:
        grantsSubSet = state[module].filter(({ code }) => code !== targetGrant.code);
        return { ...state, [module]: grantsSubSet };
      case FIRST_ASSIGNMENT_TYPE:
        const { allModules, userGrants } = action;
        const freshGrants = Object.keys(allModules).reduce((accumulator, current) => {
          return { ...accumulator, [current]: [] };
        }, {});
        return userGrants ? userGrants : freshGrants;
      case PROFILE_LOAD_TYPE:
        const { arrangedProfile } = action;
        return arrangedProfile;
      case GIVE_MODULE_ACCESS_TYPE:
        return { ...state, [targetModule]: [...arrangedDocuments[targetModule]] };
      case REMOVE_MODULE_ACCESS_TYPE:
        return { ...state, [targetModule]: [] };
      default:
        throw new Error();
    }
  }, {});

  const { data: allDocuments, isLoading } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/quality-document/list',
  });

  const { data: currentDocProfile, isLoading: isLoadingProfile } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/qd-access-template/${profile}`,
    skip: !profile || profile === 'nuevo',
  });

  const { data: allQaDocsProfiles } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/qd-access-template/list`,
  });

  useEffect(() => {
    if (allQaDocsProfiles) {
      const selectArray = allQaDocsProfiles.map((profile) => ({
        itemValue: profile.id,
        itemLabel: profile.name,
      }));
      setQaDocProfiles(selectArray);
    }
  }, [allQaDocsProfiles]);

  const { data: currentUserProfile } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/user/${userId}`,
    skip: !userId && userId !== 0,
  });
  // const {data: currentProfile,isLoading:isLoadingProfile} = useFetch({
  //   axiosInstance: AxiosInstance,
  //   initialUrl: `/api/profile/${profile}`,
  //   skip: !profile || profile === 'nuevo'
  // })

  const arrangeDocuments = (documents) => {
    const arranged = documents.reduce((prev, curr) => {
      if (!curr?.folder?.value) {
        return prev;
      }
      const {
        folder: { value: folder },
      } = curr;
      prev[folder] = prev[folder] ? [...prev[folder], curr] : [curr];
      return prev;
    }, {});
    return arranged;
  };

  useEffect(() => {
    if (allDocuments?.length && !currentDocProfile) {
      const aDoc = arrangeDocuments(allDocuments);
      setArrangedDocuments(aDoc);
      grantsDispatch({ type: FIRST_ASSIGNMENT_TYPE, allModules: aDoc });
    } else if (currentDocProfile) {
      const { qualityDocuments } = currentDocProfile;
      const arrangedDocuments = arrangeDocuments(qualityDocuments);
      grantsDispatch({ type: PROFILE_LOAD_TYPE, arrangedProfile: arrangedDocuments });
    }
  }, [allDocuments, currentDocProfile]);

  useEffect(() => {
    if (currentUserProfile) {
      const { qualityDocumentAccessList, name } = currentUserProfile;
      const arrangedDocuments = arrangeDocuments(qualityDocumentAccessList);
      grantsDispatch({ type: PROFILE_LOAD_TYPE, arrangedProfile: arrangedDocuments });
      setUserCurrentProfile({ qualityDocuments: qualityDocumentAccessList, name });
    }
  }, [currentUserProfile]);

  const handleChange = (event) => {
    console.log(event.target.value);
    setDynamicProfileId(event.target.value);
  };

  const allDocumentsTable = (
    <>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
        {Object.keys(arrangedDocuments).map((documentFolder, index) => (
          <Grid item xs={12} md={12} key={`${documentFolder}${index}`}>
            <DataCardGrantSelection
              title={documentFolder}
              grantsCatalog={arrangedDocuments[documentFolder]}
              grantsModuleState={grantsState[documentFolder]}
              grantsDispatch={grantsDispatch}
              referenceAttribute="code"
            />
          </Grid>
        ))}
      </Grid>
    </>
  );

  const getForm = () => {
    const show = userId ? !!userCurrentProfile : !isLoadingProfile;
    return show ? (
      <NewPermitForm
        grantsState={grantsState}
        platformProfileId={currentUserProfile?.profile?.id}
        userId={userId}
        currentProfile={userId ? userCurrentProfile : currentDocProfile}
        cancelUrl={userId ? '/usuarios' : '/perfiles-calidad'}
      />
    ) : null;
  };

  return (
    <div className={styles.container}>
      <MainContainer>
        <ProfileHeaderContainer>
          <ProfilesTitle>Perfiles calidad</ProfilesTitle>
          <FormContainer>{getForm()}</FormContainer>
        </ProfileHeaderContainer>
        <ProfileHeaderContainer>
          <SubtitleTitle>Si lo desea, puede elegir una plantilla</SubtitleTitle>
          <SelectContainer>
            <Select
              labelId="perfil-dinamico"
              id="perfil-dinamico"
              value={dynamicProfileId}
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              {qaDocProfiles.map((menuItem, key) => {
                return (
                  <MenuItem key={key} value={menuItem.itemValue}>
                    {menuItem.itemLabel}
                  </MenuItem>
                );
              })}
            </Select>
          </SelectContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const profileToApply = allQaDocsProfiles.find(
                (profile) => dynamicProfileId === profile.id,
              );
              console.log(profileToApply, dynamicProfileId);
              const { qualityDocuments } = profileToApply;
              const arrangedDocs = arrangeDocuments(qualityDocuments);
              grantsDispatch({ type: PROFILE_LOAD_TYPE, arrangedProfile: arrangedDocs });
            }}
          >
            Aplicar plantilla
          </Button>
        </ProfileHeaderContainer>
        {isLoading ? <></> : allDocumentsTable}
      </MainContainer>
    </div>
  );
};

export default withNavigation(QualityProfileEditScreen);
