export const CREATING_TASK = 'CREATING_TASKS';
export const UPDATING_TASK = 'UPDATING_TASK';
export const VIEWING_TASK = 'VIEWING_TASK';

export const TASK_COMPLETED = 'COMPLETED';
export const TASK_ASSIGNED = 'ASSIGNED';
export const TASK_UNASSIGNED = 'UNASSIGNED';
export const TASK_CANCELED = 'CANCELED';
export const TASK_CLOSED = 'CLOSED';
export const TASK_STATUS = [
  { key: 'CANCEL', label: 'Cancelada' },
  { key: TASK_ASSIGNED, label: 'Asignada' },
  { key: TASK_COMPLETED, label: 'Completada' },
  { key: TASK_CANCELED, label: 'Cancelada' },
  { key: TASK_CLOSED, label: 'Cerrada' },
];
export const TASK_STATUS_TRANSLATE = {
  [TASK_ASSIGNED]: 'Asignada',
  [TASK_COMPLETED]: 'Completada',
  [TASK_CANCELED]: 'Cancelada',
  [TASK_CLOSED]: 'Cerrada',
};
export const TASK_STATUS_SUPERVISOR = [
  { key: TASK_ASSIGNED, label: 'Asignada' },
  { key: TASK_CANCELED, label: 'Cancelada' },
  { key: TASK_CLOSED, label: 'Cerrada' },
];
export const TASK_STATUS_EMPLOYEE = [
  { key: 'CANCEL', label: 'Cancelada' },
  { key: TASK_COMPLETED, label: 'Completada' },
  { key: TASK_ASSIGNED, label: 'Asignada' },
];
export const FCM_TOKEN_STORAGE_KEY = 'fcmToken';
export const ROSHARE_USER_DATA = 'rud';
export const USER_COOKIES_KEYS = 'uck';
export const PROJECT_CONTEXT_KEYS = 'project_contexts_keys';
export const PROJECT_CONTEXT_KEY = 'project_context';
