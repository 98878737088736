import React, { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, CircularProgress, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { useModuleGrants } from './../../Hooks/useUsersGrants';
import QuoteRequestFormulary from './BulkEditFormulary/Formulary';
import { useStyles } from '../CostSupervisionScreen/CostSupervisionScreenStyles';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import Dialog from '../../Containers/GenericDialog';
import { SOLICITUDES_COSTOS } from '../../Constants/grantsModules';
import {
  FUNDREQUEST_DICTIONARY,
  FUNDREQUEST_STATUS,
  useFundRequests,
} from '../../Hooks/useFundRequests';
import { useProjectById } from '../../Hooks/useProjectById';
import { FakeButton } from '../QualityProfilesScreen/QualityProfilesScreenStyles';
import { ROSHARE_GRANTS } from '../../.gen/grantsAndModules.generated';

const CostSupervisionEditionScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const history = useHistory();
  let { projectId, requestId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [elementToDelete, setElementToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [bulkEditingMode, setBulkEditingMode] = useState(false);
  const [viewContactData, setViewContactData] = useState(false);
  //---------------------------------------------------------------------------

  const { canIdoThis: canIdoThisSolicitudesDeCostos } = useModuleGrants(SOLICITUDES_COSTOS);

  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { areFundRequestsLoading, refetchFundRequests, fundRequest } = useFundRequests({
    filters: {
      projectIdFilter: projectId,
    },
    requestId,
  });

  const { project } = useProjectById(projectId);

  //---------------------------------------------------------------------------
  // Table Actions
  //---------------------------------------------------------------------------

  const actions = [
    {
      icon: 'grading',
      tooltip: 'Aprobar solicitudes',
      iconProps: {
        color: 'secondary',
      },
      label: 'Aprobar solicitudes',
      isFreeAction: true,
      onClick: () => {
        setBulkEditingMode(true);
      },
      disabled: !Array.isArray(fundRequest?.projectCosts) || !fundRequest?.projectCosts?.length,
      hidden: !canIdoThisSolicitudesDeCostos(ROSHARE_GRANTS.SOLICITUDES_DE_FONDOS.APROBAR_MONTOS),
    },
  ];

  const getTotalSolicitado = () => {
    return `$ ${(fundRequest?.projectCosts || [])
      .reduce((acc, curr) => {
        return acc + Number(curr.requestedAmount) || 0;
      }, 0)
      .toFixed(2)}`;
  };

  const getTotalAprobado = () => {
    return `$ ${(fundRequest?.projectCosts || [])
      .reduce((acc, curr) => {
        return acc + Number(curr.approvedAmount) || 0;
      }, 0)
      .toFixed(2)}`;
  };

  const TABLE_HEADERS = [
    {
      title: 'Fecha de solicitud',
      field: 'createDate',
      render: () => <p>{fundRequest.createDate}</p>,
      editComponent: () => <p>-</p>,
    },
    {
      title: 'Concepto',
      field: 'concept',
    },
    {
      title: `Total solicitado: ${getTotalSolicitado()}`,
      field: 'requestedAmount',
      type: 'numeric',
      render: (rowData) => <p>${(rowData?.requestedAmount || 0).toFixed(2)}</p>,
    },
    {
      title: `Total aprobado: ${getTotalAprobado()}`,
      field: 'approvedAmount',
      editable: canIdoThisSolicitudesDeCostos(ROSHARE_GRANTS.SOLICITUDES_DE_FONDOS.APROBAR_MONTOS)
        ? undefined
        : 'never',
      type: 'numeric',
      render: (rowData) => <p>${(rowData?.approvedAmount || 0).toFixed(2)}</p>,
    },
  ];

  const handleDelete = async () => {
    await AxiosInstance.delete(
      `/api/stock/${elementToDelete.id || elementToDelete.inventoryNumber}`,
    );
    //refetch();
    setOpenDeleteDialog(false);
    setElementToDelete(null);
  };

  const getStatusChangeButtonText = () => {
    switch (fundRequest?.status) {
      case FUNDREQUEST_STATUS.DRAFT:
        return 'Solicitar Gastos';
      case FUNDREQUEST_STATUS.REQUESTED:
        return 'Aprobar fondos';
      case FUNDREQUEST_STATUS.APPROVED:
        return 'Aplicar Pago';
      default:
        return FUNDREQUEST_STATUS.DRAFT;
    }
  };

  const getShowButton = () => {
    switch (fundRequest?.status) {
      case FUNDREQUEST_STATUS.DRAFT:
        return true;
      case FUNDREQUEST_STATUS.REQUESTED:
      case FUNDREQUEST_STATUS.APPROVED:
        return canIdoThisSolicitudesDeCostos(ROSHARE_GRANTS.SOLICITUDES_DE_FONDOS.APROBAR_MONTOS);
      default:
        return false;
    }
  };

  const handleStateChange = async () => {
    const getNextStatus = () => {
      switch (fundRequest?.status) {
        case FUNDREQUEST_STATUS.DRAFT:
          return FUNDREQUEST_STATUS.REQUESTED;
        case FUNDREQUEST_STATUS.REQUESTED:
          return FUNDREQUEST_STATUS.APPROVED;
        case FUNDREQUEST_STATUS.APPROVED:
          return FUNDREQUEST_STATUS.CLOSED;
        default:
          return FUNDREQUEST_STATUS.DRAFT;
      }
    };
    const getNotificationText = () => {
      switch (fundRequest?.status) {
        case FUNDREQUEST_STATUS.DRAFT:
          return 'Fondos solicitados exitosamente';
        case FUNDREQUEST_STATUS.REQUESTED:
          return 'Fondos aprobados exitosamente';
        case FUNDREQUEST_STATUS.APPROVED:
          return 'Solicitud transferida a pagada exitosamente';
        default:
          return 'Operación exitosa';
      }
    };

    try {
      await AxiosInstance.put(`/api/project/${projectId}/fund-request`, {
        ...fundRequest,
        status: getNextStatus(),
      });
      enqueueSnackbar(getNotificationText(), {
        variant: 'success',
        preventDuplicate: true,
      });
      refetchFundRequests();
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error, contactar soporte', {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const tableHeader = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <h3>Proyecto: {project?.projectName}</h3>
      {canIdoThisSolicitudesDeCostos(ROSHARE_GRANTS.SOLICITUDES_DE_FONDOS.APROBAR_MONTOS) && (
        <h3
          style={{
            marginLeft: '3rem',
          }}
          variant="contained"
          aria-hidden="true"
          onClick={() => setViewContactData(true)}
        >
          Solicitante: {fundRequest?.requesterName}
        </h3>
      )}
      <h3
        style={{
          marginLeft: '3rem',
        }}
      >
        Solicitud: {fundRequest?.id}
      </h3>
      <h3
        style={{
          marginLeft: '3rem',
        }}
      >
        Status: {FUNDREQUEST_DICTIONARY[fundRequest?.status || 'DRAFT']}
      </h3>
      <div
        style={{
          marginLeft: '3rem',
        }}
      >
        <Button variant="contained" onClick={() => history.goBack()}>
          Regresar
        </Button>
      </div>
      <div
        style={{
          marginLeft: '3rem',
        }}
      >
        {getShowButton() && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleStateChange}
            // disabled={fundRequest.id}
          >
            {getStatusChangeButtonText()}
          </Button>
        )}
      </div>
    </div>
  );

  const listaDeSolicitudesDePresupuesto = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <MaterialTable
            toolbar
            title={tableHeader}
            data={fundRequest?.projectCosts || []}
            columns={TABLE_HEADERS}
            isLoading={areFundRequestsLoading || !fundRequest}
            icons={{
              Add: (props) => (
                <FakeButton {...props} style={{ padding: '5px' }}>
                  <Typography variant="body1">Agregar Concepto</Typography>
                </FakeButton>
              ),
            }}
            editable={
              fundRequest?.status === FUNDREQUEST_STATUS.DRAFT
                ? {
                    onRowAdd: (newData) =>
                      new Promise((resolve) => {
                        const newCost = {
                          concept: newData.concept,
                          requestedAmount: newData.requestedAmount,
                          type: 'DIRECT',
                        };
                        const url = `/api/project/${projectId}/${requestId}/cost`;
                        AxiosInstance.post(url, newCost).then(() => {
                          refetchFundRequests();
                          resolve();
                        });
                      }),
                    onRowUpdate: (newData) =>
                      new Promise((resolve, reject) => {
                        AxiosInstance.put(`/api/project/${projectId}/${requestId}/cost`, newData)
                          .then(() => {
                            refetchFundRequests();
                            resolve();
                          })
                          .catch(reject);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        AxiosInstance.delete(
                          `/api/project/${projectId}/${requestId}/cost/${oldData.id}`,
                        )
                          .then(() => {
                            resolve();
                            refetchFundRequests();
                          })
                          .catch(reject);
                      }),
                  }
                : undefined
            }
            actions={actions}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: [10, 15, 25],
              sorting: true,
              filtering: false,
              search: false,
              paging: true,
            }}
          />
        </Grid>
      </Grid>
      {/* ------------------------------------------------------------------------ */}
      {/* --- DELETE CONFIRM DIALOG ---------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}
      <Alert
        type={WARNING_TYPE}
        title="Borrar solicitud de presupuesto"
        content="Estas a punto de borrar una solicitud de presupuesto, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenDeleteDialog(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onBackdropPress={() => {
          setOpenDeleteDialog(false);
        }}
      />
      {/* BULK Approval dialog */}
      <Dialog openDialog={bulkEditingMode} dialogTitle={'Aprobar solicitudes'} maxWidth="md">
        <QuoteRequestFormulary
          setOpen={setBulkEditingMode}
          AxiosInstance={AxiosInstance}
          fundRequest={fundRequest}
          refetchFundRequests={refetchFundRequests}
        />
      </Dialog>
      <Alert
        // type={SUCCESS_TYPE}
        title="Datos del contacto"
        content={
          <>
            <h1>hola</h1>
          </>
        }
        actions={[
          {
            text: 'Cerrar',
            onClick: () => {
              setViewContactData(false);
            },
          },
        ]}
        open={viewContactData}
        onClose={() => setViewContactData(false)}
        onBackdropPress={() => {
          setViewContactData(false);
        }}
      />
    </>
  );

  const renderScreen = () => {
    if (Array.isArray(fundRequest?.projectCosts)) {
      return <>{listaDeSolicitudesDePresupuesto}</>;
    } else {
      return <CircularProgress />;
    }
  };

  return <div className={styles.container}>{renderScreen()}</div>;
};

export default withNavigation(CostSupervisionEditionScreen);
