import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  input: {
    textTransform: 'uppercase',
  },
  icon: {
    color: theme.palette.primary.light,
  },
  iconSecondary: {
    color: theme.palette.secondary.light,
  },
  iconError: {
    color: theme.palette.error.main,
  },
  textField: {
    textTransform: 'uppercase',
    color: 'red',
  },
}));
