import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import { useParams } from 'react-router';
import { format as formatDate } from 'date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Icons
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { TextField } from '@material-ui/core';
import { useStyles } from './materialStyles';
import { AxiosContext } from './../../../../Contexts/AxiosContext/AxiosContext';
import { getInitialValues, validationSchema } from './validation';
import Input from './../../../../Components/FormFields/FormikIconInput';
import { BACKEND_DATE_FORMAT } from '../../../../Constants/generalConstants';
import { safetyObjectCheck } from '../../../VehicleStateScreen/utils/getWarningLevel';

function NewWeekReportTable(props) {
  const {
    setOpen,
    mode = 0,
    refetchProjectData,
    currentCost,
    autocompleteCostConceptSuggestions,
  } = props;
  const AxiosInstance = useContext(AxiosContext);

  const [currWeek, setCurrWeek] = useState(0);
  const [currYear, setCurrYear] = useState(0);

  const styles = useStyles();
  let { week, project } = useParams();

  useEffect(() => {
    let yearIndex = week.indexOf('_');
    if (yearIndex === -1) {
      setCurrWeek(week);
      let date = new Date();
      setCurrYear(date.getFullYear());
    } else {
      setCurrWeek(week.substring(0, yearIndex));
      setCurrYear(week.substring(yearIndex + 1));
    }
  }, []);

  const mapValues = (values) => {
    const { concept, cost, date: dateObject } = values;

    const date = dateObject
      ? formatDate(safetyObjectCheck(dateObject), BACKEND_DATE_FORMAT)
      : undefined;

    const result = {
      concept: concept,
      cost: parseInt(cost),
      projectId: parseInt(project),
      type: mode === 0 ? 'DIRECT' : 'INDIRECT',
      week: parseInt(currWeek),
      year: currYear,
      date,
    };
    return result;
  };

  const handlePost = async (values) => {
    const payload = mapValues(values);
    await AxiosInstance['post'](`/api/project/${project}/cost`, payload);
    setOpen(false);
    refetchProjectData();
    // history.goBack();
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentCost)}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          handlePost(values);
          setSubmitting(false);
        }}
      >
        {({ isValid, isSubmitting, setFieldValue, errors }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={5}>
                <Grid item xs={12}>
                  <Field name="concept">
                    {(props) => (
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        onChange={(event, newValue) => {
                          setFieldValue('concept', newValue);
                        }}
                        options={autocompleteCostConceptSuggestions.map((option) => option)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Concepto"
                            id="concept"
                            value={props?.field?.value}
                            margin="normal"
                            variant="outlined"
                            onChange={(event) => setFieldValue('concept', event.target.value)}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="cost">
                    {(props) => (
                      <Input
                        formikName={'cost'}
                        label={'Costo'}
                        Icon={AttachMoneyIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="date">
                    {(props) => (
                      <Input
                        formikName={'date'}
                        label={'Fecha'}
                        Icon={AttachMoneyIcon}
                        type="date"
                        setFieldValue={setFieldValue}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        console.log(errors);
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default NewWeekReportTable;
