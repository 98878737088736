import { useContext, useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const NO_USER = 'NO_USER';

export const useEmployeesForCatalogs = (params) => {
  const { project } = params || {};
  const AxiosInstance = useContext(AxiosContext);
  const [options, setOptions] = useState([]);

  const { data: employeesInPlatform } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/employee/list`,
  });

  const mapEmployee = (user) => {
    const { names, lastName1, lastName2 } = user;
    const orderName = `${lastName1} ${lastName2}, ${names}`;
    return { itemLabel: orderName, itemValue: `${user.id}`, orderName };
  };

  const filterEmployee = (user) => {
    const { projects } = user;
    const isInProject = projects.map(({ id }) => `${id}`).includes(`${project}`);
    return !project || isInProject;
  };

  const noUser = { itemLabel: 'N/A', itemValue: '-1' };

  useEffect(() => {
    if (employeesInPlatform) {
      const _selectableOptions = employeesInPlatform.filter(filterEmployee).map(mapEmployee);
      _selectableOptions.sort((itemA, itemB) => (itemA.orderName > itemB.orderName ? 1 : -1));
      setOptions([noUser, ..._selectableOptions]);
    }
  }, [employeesInPlatform]);

  return options;
};
