import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const drawerWidth = 290;
const closedDrawerWidth = 70;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerStyle: {
    backgroundColor: '#263A57',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    transition: 'all 0.1s linear',
    marginLeft: drawerWidth,
    backgroundColor: '#263A57',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  appBarWide: {
    transition: 'all 0.1s linear',
    backgroundColor: '#263A57',
    marginLeft: closedDrawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${closedDrawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  backButton: {
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: 20,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    transition: 'all 0.1s linear',
    width: drawerWidth,
    backgroundColor: '#263A57',
  },
  closedDrawerPaper: {
    transition: 'all 0.1s linear',
    width: closedDrawerWidth,
    backgroundColor: '#263A57',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  navigationArrows: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
}));

export const StyledMainBlock = styled.main`
  max-width: calc(100% - ${(props) => (props.fullMenuView ? drawerWidth : closedDrawerWidth)}px);
  margin-left: ${(props) => (props.fullMenuView ? drawerWidth : closedDrawerWidth)}px;
`;
