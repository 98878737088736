import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  container: {},
}));

export const MainProjectsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const TitleProjectElement = styled.div`
  display: inline-block;
  width: 50%;
`;
export const TitleProjectButtons = styled.div`
  display: inline-block;
  width: 50%;
`;
