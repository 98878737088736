import React, { useContext } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { API_ENDPOINT } from './../../Constants/urls';
import { userContext } from '../UserContext/UserContext';
import { AppContext } from '../AppContext/AppContext';
import { GpsContext } from '../GpsContext/GpsContext';

export const getAxiosInstance = (contexts, setters) => {
  const roshareAxios = axios.create({
    baseURL: API_ENDPOINT,
  });

  const getHeaders = () => {
    const { userState, gpsState } = contexts;
    const headers = {
      Authorization: `${userState.tokenType} ${userState.idToken}`,
      deviceType: 'WEB',
      latitude: `${gpsState.latitude}`,
      longitude: `${gpsState.longitude}`,
      sessionId: userState.sessionId,
      username: userState.username,
    };
    return headers;
  };

  const getRefreshHeaders = () => {
    const { userState, gpsState } = contexts;
    const headers = {
      deviceType: 'WEB',
      latitude: `${gpsState.latitude}`,
      longitude: `${gpsState.longitude}`,
      sessionId: userState.sessionId,
      username: userState.username,
      'Content-Type': 'application/json',
    };
    return headers;
  };

  roshareAxios.interceptors.request.use((req) => {
    if (!(req.headers.latitude && req.headers.longitude)) {
      setters.setGpsState();
    }
    if (req.url === '/auth/refresh') {
      req.headers = { ...req.headers, ...getRefreshHeaders(contexts) };
    } else {
      req.headers = { ...req.headers, ...getHeaders(contexts) };
    }
    return req;
  });

  roshareAxios.interceptors.response.use(
    (r) => r,
    (error) => {
      const originalRequest = error.config;
      const { userState, appState } = contexts;
      const { refreshToken } = userState;
      const { setUserState, setAppState } = setters;

      Sentry.captureException(error);

      if (originalRequest.url === '/auth/refresh') {
        // throw new axios.Cancel('Operation canceled by the user.');
        throw error;
      }

      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (error.response && error.response.data && error.response.data.errorCode) {
          switch (error.response.data.errorCode) {
            case 'api401':
              setAppState({ ...appState, closeSession: true });
              setUserState(null);
              throw error;
            case 'auth04':
              originalRequest._retry = true;
              return roshareAxios
                .post('/auth/refresh', { refreshToken })
                .then((newTokenResponse) => {
                  const { idToken, sessionId, accessToken } = newTokenResponse.data;
                  setUserState({ ...userState, idToken, sessionId, accessToken });
                  return roshareAxios(originalRequest);
                });
            default:
              throw error;
          }
        }
      }
      // throw new axios.Cancel('Operation canceled by the user. unknown');
      throw error;
    },
  );

  return roshareAxios;
};

export const AxiosContext = React.createContext(null);

export const AxiosContextProvider = (props) => {
  const [userState, setUserState] = useContext(userContext);
  const [appState, setAppState] = useContext(AppContext);
  const [gpsState, setGpsState] = useContext(GpsContext);

  Sentry.configureScope((scope) => scope.setUser({ email: userState?.username }).setLevel('Error'));

  const axiosInstance = getAxiosInstance(
    { userState, appState, gpsState },
    { setUserState, setAppState, setGpsState },
  );

  return <AxiosContext.Provider value={axiosInstance}>{props.children}</AxiosContext.Provider>;
};
