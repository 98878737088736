import React, { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { CircularProgress, InputAdornment } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone-uploader';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { parse } from 'date-fns';
import { initialValues, validationSchema } from './validation';
import { MainContainer, EstimationHeader, EstimationScreenTitle, FormContainer } from './styled';
import axios from '../../../../Utils/AxiosS3Instance';

import { withNavigation } from '../../../../Containers/AppNavigation/AppNavigation';
import Input from '../../../../Components/FormFields/FormikIconInput';
import { AxiosContext } from '../../../../Contexts/AxiosContext/AxiosContext';
import Dialog from '../../../../Containers/GenericDialog';
import { useFetch } from '../../../../Hooks/useFetch';
import { BACKEND_DATE_FORMAT } from '../../../../Constants/generalConstants';
import EstimationLog from '../EstimationLog/EstimationLog';
import { AWAITING_DEPENDENCY_APPROVAL } from '../DependencyFeedbackDialog/DependencyFeedbackDialog';
import { userContext } from '../../../../Contexts/UserContext/UserContext';
import EstimationDetails from '../EstimationDetails/EstimationDetails';
import EstimationDocuments from '../EstimationDocuments/EstimationDocuments';

const CreateEstimationForm = () => {
  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const history = useHistory();
  const { project: projectId, estimationId: urlEstimationId } = useParams();

  const AxiosInstance = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();

  //---------------------------------------------------------------------------
  // STATE FOR MODALS
  //---------------------------------------------------------------------------
  const [documentModalIsOpen, setDocumentModalIsOpen] = useState(false);
  const [invoiceModalIsOpen, setInvoiceModalIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(!urlEstimationId);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const [estimationId, setEstimationId] = useState(urlEstimationId);

  const [userState] = React.useContext(userContext);

  //---------------------------------------------------------------------------
  // CONSTANTS FOR THE FILE
  //---------------------------------------------------------------------------

  const s3Bucket = 'roshare-estimations-files';
  // const s3Bucket = 'roshare-respaldo';
  const urlCreateEstimation = `/api/project/${projectId}/estimation`;

  const {
    data: estimation,
    isLoading,
    refetch: refetchEstimation,
  } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/estimation/${estimationId}`,
    skip: !urlEstimationId,
  });

  const { data: invoiceFilesS3, refetch: refetchInvoices } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: {
      bucket: s3Bucket,
      path: `${projectId}/estimaciones/${estimationId}/factura/`,
    },
    skip: !urlEstimationId,
  });

  const { data: estimationFiles, refetch: refetchDocuments } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: {
      bucket: s3Bucket,
      path: `${projectId}/estimaciones/${estimationId}/archivos/`,
    },
    skip: !urlEstimationId,
  });

  React.useEffect(() => {
    if (invoiceFilesS3) {
      console.log(invoiceFilesS3);
      setInvoiceFiles(
        (invoiceFilesS3?.files || []).map((file) => {
          return { key: file.key, file: { name: file.key.split('/').pop(), category: 'invoice' } };
        }),
      );
    }
    if (estimationFiles) {
      setDocumentFiles(
        (estimationFiles?.files || []).map((file) => {
          return { key: file.key, file: { name: file.key.split('/').pop(), category: 'document' } };
        }),
      );
    }
  }, [invoiceFilesS3, estimationFiles]);

  const { creationDate: creationDateString, ...estimationRest } = estimation || {};

  const creationDate = creationDateString
    ? parse(creationDateString, BACKEND_DATE_FORMAT, new Date())
    : new Date();

  const handleUploadFiles = async (path, bucket, estimationId, file) => {
    const params = {
      bucket: bucket,
      key: `${path}${file.name}`,
      fileType: file.type,
    };
    const url = `https://x3v5fdavwf.execute-api.us-east-1.amazonaws.com/beta?${queryString.stringify(
      params,
    )}`;
    const { data: postUrl } = await axios.get(url);
    const headers = {
      'Content-Type': params.filetype,
    };
    await axios
      .put(postUrl, file, { headers: headers })
      .then(() => {
        enqueueSnackbar('Archivo cargado correctamente.', { variant: 'success', persist: false });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error', persist: false });
      });
  };

  const handlePost = async (
    { estimationTotal, convenio, contractNumber, period, comments, id },
    formikHelpers,
  ) => {
    const payload = {
      comments: comments || '[]',
      estimationTotal,
      convenio,
      contractNumber,
      period,
    };
    if (urlEstimationId) {
      payload.id = id;
    } else {
      const newComments = [];
      newComments.push({
        timeStamp: new Date().toISOString(),
        comment: 'Estimación creada',
        state: AWAITING_DEPENDENCY_APPROVAL,
        author: userState.userDetails.name,
      });
      payload.comments = JSON.stringify(newComments);
    }

    try {
      const { data: creationData } = await AxiosInstance[urlEstimationId ? 'put' : 'post'](
        urlCreateEstimation,
        payload,
      );
      const { id } = creationData;

      setEstimationId(id);

      const newEvidencePath = `${projectId}/estimaciones/${id}/archivos/`;
      const newInvoicePath = `${projectId}/estimaciones/${id}/factura/`;
      const documentPromises = documentFiles.map(({ file }) => {
        return handleUploadFiles(newEvidencePath, s3Bucket, id, file);
      });

      const invoicesPromises = invoiceFiles.map(({ file }) => {
        return handleUploadFiles(newInvoicePath, s3Bucket, id, file);
      });

      await Promise.all([...documentPromises, ...invoicesPromises]);

      if (!urlEstimationId) {
        history.push(`/proyectos/${projectId}/estimaciones/${id}`);
      }
      enqueueSnackbar(`Estimación ${urlEstimationId ? 'actualizada' : 'creada'} correctamente.`, {
        variant: 'success',
        persist: false,
      });
      if (urlEstimationId) {
        refetchEstimation();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error', persist: false });
    } finally {
      formikHelpers.setSubmitting(false);
      setEditMode(false);
    }
  };

  const handleFileUpdate = async ({ documents, invoices }) => {
    const newEvidencePath = `${projectId}/estimaciones/${estimationId}/archivos/`;
    const newInvoicePath = `${projectId}/estimaciones/${estimationId}/factura/`;
    const documentPromises = (documents || documentFiles)
      .filter(({ key }) => !key)
      .map(({ file }) => {
        return handleUploadFiles(newEvidencePath, s3Bucket, estimationId, file);
      });

    const invoicesPromises = (invoices || invoiceFiles)
      .filter(({ key }) => !key)
      .map(({ file }) => {
        return handleUploadFiles(newInvoicePath, s3Bucket, estimationId, file);
      });

    await Promise.all([...documentPromises, ...invoicesPromises]);

    refetchDocuments();
    refetchInvoices();
  };

  return !urlEstimationId || (!isLoading && estimation) ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <MainContainer>
          <EstimationHeader>
            <EstimationScreenTitle>
              {urlEstimationId ? 'Detalles de estimación' : 'Nueva Estimación'}
            </EstimationScreenTitle>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/proyectos/${projectId}/estimaciones`)}
            >
              REGRESAR
            </Button>
          </EstimationHeader>
          {!editMode ? (
            <EstimationDetails
              estimation={{
                ...initialValues,
                ...(estimationRest || {}),
                creationDate,
              }}
              onEdit={() => setEditMode(true)}
            />
          ) : (
            <Formik
              initialValues={{
                ...initialValues,
                ...(estimationRest || {}),
                creationDate,
              }}
              onSubmit={async (values, formikHelpers) => {
                await handlePost(values, formikHelpers);
              }}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <>
                    <FormContainer onSubmit={handleSubmit}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <Field name="estimationTotal">
                            {(props) => (
                              <Input
                                formikName={'estimationTotal'}
                                label="Monto"
                                type="number"
                                disabled={
                                  urlEstimationId &&
                                  !['AWAITING_DEPENDENCY_APPROVAL', 'DEPENDENCY_REJECTED'].includes(
                                    estimation?.estimationStatus,
                                  )
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ),
                                }}
                                step="any"
                                {...props}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field name="creationDate">
                            {(props) => (
                              <Input
                                formikName={'creationDate'}
                                label="Fecha de creación"
                                type="date"
                                value={props.form.initialValues.creationDate}
                                disabled={true}
                                {...props}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field name="contractNumber">
                            {(props) => (
                              <Input
                                disabled={!!urlEstimationId}
                                formikName={'contractNumber'}
                                label="Número de Estimación"
                                {...props}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field name="period">
                            {(props) => (
                              <Input
                                disabled={!!urlEstimationId}
                                formikName={'period'}
                                label="Periodo"
                                {...props}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field name="convenio">
                            {(props) => (
                              <Input
                                formikName={'convenio'}
                                disabled={!!urlEstimationId}
                                label="Contrato o Convenio al que pertenece"
                                {...props}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} style={{ marginTop: '35px' }}>
                        {isSubmitting ? (
                          <Grid item>
                            <CircularProgress />
                          </Grid>
                        ) : (
                          (!urlEstimationId ||
                            ['AWAITING_DEPENDENCY_APPROVAL', 'DEPENDENCY_REJECTED'].includes(
                              estimation?.estimationStatus,
                            )) && (
                            <Grid item>
                              <Button variant="outlined" color="primary" type="submit">
                                {urlEstimationId ? 'Actualizar' : 'Crear'} Estimación
                              </Button>
                            </Grid>
                          )
                        )}
                        {!isSubmitting && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => setEditMode(false)}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </FormContainer>
                  </>
                );
              }}
            </Formik>
          )}
          <Dialog openDialog={documentModalIsOpen} dialogTitle="Cargar Documento" maxWidth="md">
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Dropzone
                  onSubmit={(values) => {
                    const newFiles = values.map((val) => {
                      const { file, meta } = val;
                      return {
                        file,
                        meta,
                      };
                    });
                    setDocumentFiles((prev) => [...prev, ...newFiles]);
                    if (urlEstimationId) {
                      handleFileUpdate({
                        documents: newFiles,
                      });
                    }
                    setDocumentModalIsOpen(false);
                  }}
                  submitButtonContent={'Guardar'}
                  inputContent={'Haz click o arrastra un archivo para subirlo a la nube'}
                  inputWithFilesContent={'Agregar más archivos'}
                  multiple={false}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => setDocumentModalIsOpen(false)}
                      variant="outlined"
                      color="secondary"
                      type="submit"
                    >
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
          <Dialog openDialog={invoiceModalIsOpen} dialogTitle="Cargar Factura" maxWidth="md">
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Dropzone
                  onSubmit={(values) => {
                    const newFiles = values.map((val) => {
                      const { file, meta } = val;
                      return {
                        file,
                        meta,
                      };
                    });
                    setInvoiceFiles((prev) => [...prev, ...newFiles]);
                    if (urlEstimationId) {
                      handleFileUpdate({
                        invoices: newFiles,
                      });
                    }
                    setInvoiceModalIsOpen(false);
                  }}
                  submitButtonContent={'Guardar'}
                  inputContent={'Haz click o arrastra un archivo para subirlo a la nube'}
                  inputWithFilesContent={'Agregar más archivos'}
                  multiple={false}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => setInvoiceModalIsOpen(false)}
                      variant="outlined"
                      color="secondary"
                      type="submit"
                    >
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        </MainContainer>
      </Grid>
      <Grid item xs={12}>
        {estimation && <EstimationLog estimation={estimation} />}
      </Grid>
      <Grid item xs={12}>
        {estimation && (
          <EstimationDocuments
            documentFiles={documentFiles}
            invoiceFiles={invoiceFiles}
            refetchDocuments={refetchDocuments}
            refetchInvoices={refetchInvoices}
            setDocumentFiles={setDocumentFiles}
            setInvoiceFiles={setInvoiceFiles}
            onDocument={() => setDocumentModalIsOpen(true)}
            onInvoice={() => setInvoiceModalIsOpen(true)}
          />
        )}
      </Grid>
    </Grid>
  ) : null;
};

export default withNavigation(CreateEstimationForm);
