import styled from 'styled-components';
import { Card } from '@material-ui/core';

export const LaboratoryScreenMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const LaboratoryHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const LaboratoryHeaderCard = styled(Card)`
  width: 100%;
  padding: 10px;
  margin-right: 10px;
`;
