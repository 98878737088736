import React, { useContext, useState } from 'react';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useHistory, useLocation } from 'react-router-dom';
import { parse as parseDate } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { normalizeTitleToUrl } from './../DataCardsUtils';
import ProgressBar from '../../ProgressBar';
import {
  DataCardMainContainer,
  ProyectTitle,
  MoreInfoLink,
  HeaderContainer,
  OptionsButtonContainer,
} from '../DataCardStyles';
import Alert, { WARNING_TYPE } from '../../Alert/Alert';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';

const DataCard = (props) => {
  // amount: "1.2344321E7"
  // classification: "PUERTOS/ADUANAS"
  // contractKey: "asdf1234"
  // endDate: "31/10/2020"
  // id: 1
  // moneyProgress: null
  // projectId: "6"
  // projectStatus: null
  // projectType: "SUPERVISIÓN"
  // startDate: "15/10/2020"
  // totalPaid: null
  const {
    project: {
      projectId,
      projectName,
      amount,
      totalPaid,
      startDate: startDateString,
      endDate: endDateString,
      id: dataBaseId,
    },
    setProjectToEdit,
    setEditDialogOpen,
    editProfile,
    deleteProfile,
    refetch,
  } = props;
  const history = useHistory();
  let location = useLocation();
  const { pathname } = location;

  // Calculo para saber cual es el avance monetario del proyecto.
  const moneyProgressBar = [
    {
      color: '#3f51b5',
      percentage:
        Number(totalPaid || 0) / Number(amount) > 1 ? 1 : Number(totalPaid || 0) / Number(amount),
    },
  ];
  // Cálculo para saber cual es el avance temporal
  const startDate = parseDate(startDateString, BACKEND_DATE_FORMAT, new Date()).getTime();
  const endDate = parseDate(endDateString, BACKEND_DATE_FORMAT, new Date()).getTime();
  const toDay = new Date().getTime();

  const totalDays = Math.floor((endDate - startDate) / (24 * 3600000));
  const currentDays = Math.floor((toDay - startDate) / (24 * 3600000));
  const timeProgressValue = currentDays / totalDays > 1 ? 1 : currentDays / totalDays;

  const timeProgress = [
    {
      color: '#3f51b5',
      percentage: timeProgressValue < 0 ? 0 : timeProgressValue,
    },
  ];

  // FUTURE FEATURE
  // const goalsProgress = [
  //   {
  //     color: "#3f51b5",
  //     percentage: Math.random(),
  //   },
  // ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const AxiosInstance = useContext(AxiosContext);
  const open = Boolean(anchorEl);

  const handleSeeMoreClick = () => {
    history.push(`${pathname}/${normalizeTitleToUrl(`${dataBaseId}`)}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (callback) => {
    callback && callback();
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  const options = [];

  if (editProfile) {
    options.push({
      label: 'Editar',
      callback: () => {
        setProjectToEdit && setProjectToEdit(props.project);
        setEditDialogOpen && setEditDialogOpen(true);
      },
    });
  }

  if (deleteProfile) {
    options.push({
      label: 'Eliminar',
      callback: () => {
        setProjectToDelete && setProjectToDelete(props.project);
        setOpenDeleteDialog && setOpenDeleteDialog(true);
      },
    });
  }

  const handleDelete = async () => {
    await AxiosInstance.delete(`/api/project/${projectToDelete.id}`);
    setProjectToDelete(null);
    setOpenDeleteDialog(false);
    refetch && refetch();
  };

  return (
    <>
      <DataCardMainContainer>
        <HeaderContainer>
          <ProyectTitle>{`Proyecto ${projectId} ${projectName || ''}`}</ProyectTitle>
          {options.length ? (
            <OptionsButtonContainer>
              <div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={() => handleClose(null)}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  {options.map(({ label, callback }) => (
                    <MenuItem key={label} onClick={() => handleClose(callback)}>
                      {label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </OptionsButtonContainer>
          ) : (
            <></>
          )}
        </HeaderContainer>
        <ProgressBar sections={moneyProgressBar} Icon={MonetizationOnIcon} />
        <ProgressBar sections={timeProgress} Icon={AccessTimeIcon} />
        {/* <ProgressBar sections={goalsProgress} Icon={FormatListBulletedIcon} /> */}
        <MoreInfoLink onClick={handleSeeMoreClick}>Ver más...</MoreInfoLink>
      </DataCardMainContainer>
      <Alert
        type={WARNING_TYPE}
        title="Borrar elemento de catalogo"
        content="Estas a punto de borrar un elemento, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenDeleteDialog(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onBackdropPress={() => {
          setOpenDeleteDialog(false);
        }}
      />
    </>
  );
};

export default DataCard;
