import { Container, Divider, makeStyles } from '@material-ui/core';
import React from 'react';
// Icons
import { withNavigation } from './../../Containers/AppNavigation/AppNavigation';
import StockElementForm from './Components/StockElementForm';
import { useUrlData } from './hooks/useUrlData';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    border: 'solid 1px #D0D0D0',
    borderRadius: 5,
  },
  formTitle: {
    marginTop: '0',
    marginBottom: '20px',
  },
  formContainer: {
    marginTop: '20px',
  },
}));

const NewStockElementScreen = () => {
  const classes = useStyles();
  const isNewElement = window.location.href.includes('nuevo');
  const { stockType } = useUrlData();
  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
        <h1 className={classes.formTitle}>
          {isNewElement ? 'Nuevo elemento' : 'Editar elemento'} de inventario: {stockType}
        </h1>
        <Divider />
        <div className={classes.formContainer}>
          <StockElementForm />
        </div>
      </div>
    </Container>
  );
};

export default withNavigation(NewStockElementScreen);
