import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import { format } from 'date-fns';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FormikAutocomplete from './../../../Components/AutoComplete/FormikAutocomplete';
import {
  CREATING_NEW_QUALITY_FILE,
  EDITING_NEW_QUALITY_FILE,
  getInitialValues,
  getValidationSchema,
} from './validation';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from './../../../Hooks/useFetch';
// Icons
import { useStyles } from './materialStyles';
import { cerosPrefix, normalizePath } from './../../../Utils/normalizePath';
import SimpleFileUpload from '../../../Components/SimpleFileUpload';
import { postNewFile } from '../../../Utils/s3Utils';
import Input from '../../../Components/FormFields/FormikIconInput';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';
import { safetyObjectCheck } from '../../VehicleStateScreen/utils/getWarningLevel';

const ESTADOS_DE_DOCUMENTOS_DE_CALIDAD = 'Estados de documentos de calidad';
const GRUPOS_DE_OPERACION = 'Grupos de operación de documentos de calidad';
const FOLDERS_DE_CALIDAD = 'Folders de calidad';
const UBICACION_DISTRIBUCION = 'Ubicación / Distribución de Calidad';
const FILES_BUCKET = 'roshare-qualitylibrary';

function BackupFormulary(props) {
  const { setOpen, currentElement, refetchDocuments } = props;
  const styles = useStyles();

  const AxiosInstance = useContext(AxiosContext);

  const { data: masterData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/masterData/list',
    initialParams: {
      entityList: [
        ESTADOS_DE_DOCUMENTOS_DE_CALIDAD,
        GRUPOS_DE_OPERACION,
        FOLDERS_DE_CALIDAD,
        UBICACION_DISTRIBUCION,
      ],
    },
  });

  const getSelectElementsFromMasterData = (entityName) => {
    const resultado = (masterData || [])
      .filter((element) => element.entity === entityName)
      .map((element) => ({
        itemLabel: element.value,
        itemValue: element.id,
      }));
    return resultado;
  };

  const [newDocumentFile, setNewDocumentFile] = useState(null);

  const mapPayload = (payload) => {
    const { revisionDate: revisionDateObject } = payload;
    const revisionDate = format(safetyObjectCheck(revisionDateObject), BACKEND_DATE_FORMAT);
    const newPayload = {
      ...payload,
      revisionDate,
    };
    return newPayload;
  };

  const handlePost = async (values) => {
    const method = currentElement ? 'put' : 'post';
    const payload = mapPayload(values);
    if (currentElement) {
      payload.id = currentElement.id;
    } else {
      const fileExtension = newDocumentFile?.name?.split('.').pop();
      const path = normalizePath(`${values.code}/`);
      const fileName = `${values.code}_${cerosPrefix(values.revision, 4)}.${fileExtension}`;
      await postNewFile(newDocumentFile, fileName, path, {
        customBucket: FILES_BUCKET,
      });
    }
    await AxiosInstance[method]('/api/quality-document', payload);
    await refetchDocuments();
    setOpen();
  };

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentElement)}
        validationSchema={getValidationSchema(
          currentElement ? EDITING_NEW_QUALITY_FILE : CREATING_NEW_QUALITY_FILE,
        )}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handlePost(values);
          resetForm();
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, isSubmitting, values, setFieldValue, setFieldTouched, errors }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Field name="code">
                    {(props) => (
                      <Input
                        formikName={'code'}
                        label={'Código'}
                        Icon={PostAddIcon}
                        props={props}
                        disabled={currentElement}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="name">
                    {(props) => (
                      <Input
                        formikName={'name'}
                        label={'Nombre del documento'}
                        Icon={PostAddIcon}
                        props={props}
                      />
                    )}
                  </Field>
                </Grid>
                {!currentElement ? (
                  <Grid item xs={12} md={6}>
                    <Field name="revision">
                      {(props) => (
                        <Input
                          formikName={'revision'}
                          label={'Estado y Revisión'}
                          Icon={PostAddIcon}
                          props={props}
                          type="number"
                        />
                      )}
                    </Field>
                  </Grid>
                ) : (
                  <></>
                )}
                {!currentElement ? (
                  <Grid item xs={12} md={6}>
                    <Field name="revisionDate">
                      {(props) => (
                        <Input
                          formikName={'revisionDate'}
                          label={'Fecha de revisión actualizada'}
                          type="date"
                          props={props}
                        />
                      )}
                    </Field>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12} md={6}>
                  <Field name="status.id">
                    {(props) => (
                      <FormikAutocomplete
                        label="Estado del documento"
                        options={getSelectElementsFromMasterData(ESTADOS_DE_DOCUMENTOS_DE_CALIDAD)}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="location.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Ubicación / Distribución'}
                        options={getSelectElementsFromMasterData(UBICACION_DISTRIBUCION)}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="folder.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Folder'}
                        options={getSelectElementsFromMasterData(FOLDERS_DE_CALIDAD)}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="operationGroup.id">
                    {(props) => (
                      <FormikAutocomplete
                        label={'Grupo de operación'}
                        options={getSelectElementsFromMasterData(GRUPOS_DE_OPERACION)}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    )}
                  </Field>
                </Grid>
                {!currentElement ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Field name="file">
                      {(props) => (
                        <SimpleFileUpload
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          setDocument={setNewDocumentFile}
                          {...props}
                        />
                      )}
                    </Field>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        console.log(errors);
                        console.log(values);
                      }
                    }}
                  >
                    {currentElement ? 'Guardar cambios' : 'Crear'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default BackupFormulary;
