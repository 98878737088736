import React, { useContext, createContext, useReducer, useEffect } from 'react';
import {
  ShoppingCartInitialState,
  stateFunctions,
  shoppingCartReducer,
  ADD_STOCK_TO_CART,
  REMOVE_STOCK_FROM_CART,
  CLEAN_CART,
  UPDATE_FILTER,
  LOAD_STOCK,
  LOAD_PROJECTS,
  LOAD_WAREHOUSES,
  SET_SELECTED_PROJECT,
  REMOVE_SELECTED_PROJECT,
  SET_SELECTED_WAREHOUSE,
  REMOVE_SELECTED_WAREHOUSE,
  SET_TARGET_PROJECT,
  REMOVE_TARGET_PROJECT,
  SET_FILTERED_PROJECTS,
  SET_FILTERED_WAREHOUSES,
  RESET_FILTERS,
  SET_MODAL_VISIBILITY,
  LOAD_EMPLOYEES,
  SET_SEARCH_TARGET,
  RH_TARGET,
  STOCK_TARGET,
  ADD_EMPLOYEE_TO_CART,
} from './Reducer';
import { useAxiosInstance } from '../../../Hooks/useAxiosInstace';
import { WAREHOUSE_COORDINATES } from '../../../Constants/warehouseCoordinates';
import { useFetch } from '../../../Hooks/useFetch';

export const CartStateContext = createContext({
  ...ShoppingCartInitialState,
  ...stateFunctions,
});

export const CartStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(shoppingCartReducer, ShoppingCartInitialState);

  const AxiosInstance = useAxiosInstance();

  //------------------------------------------------------------
  // Loading projects from platform.
  //------------------------------------------------------------
  const { data: projectsInPlatform } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/list`,
    skip: !!state?.projects?.length,
  });

  useEffect(() => {
    if (projectsInPlatform?.length) {
      loadProjects(projectsInPlatform.filter((project) => project.latitude && project.longitude));
    }
  }, [projectsInPlatform]);

  //------------------------------------------------------------
  //------------------------------------------------------------
  // Loading stocks from platform
  //------------------------------------------------------------
  const { data: stockInPlatform } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/stock/list`,
    skip: !!state?.availableStock?.length,
  });

  useEffect(() => {
    if (stockInPlatform?.length) {
      loadStock(stockInPlatform.filter((stock) => !!stock.projects?.length || !!stock.warehouse));
    }
  }, [stockInPlatform]);
  //------------------------------------------------------------
  //------------------------------------------------------------
  // Loading stocks from platform
  //------------------------------------------------------------
  const { data: employeesInPlatform } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/employee/list`,
    skip: !!state?.availableEmployees?.length,
  });

  useEffect(() => {
    if (employeesInPlatform?.length) {
      loadEmployees(employeesInPlatform);
    }
  }, [employeesInPlatform]);
  //------------------------------------------------------------

  //------------------------------------------------------------
  // Loading warehouses from platform.
  //------------------------------------------------------------
  useEffect(() => {
    loadWarehouses(WAREHOUSE_COORDINATES);
  }, []);
  //------------------------------------------------------------
  // Filter change handler
  //------------------------------------------------------------
  const isTargetProject = (project) => {
    return project?.id === state?.targetProject?.id;
  };
  useEffect(() => {
    const { projects, warehouses, filter, availableStock, availableEmployees, searchTarget } =
      state;

    const filteredProjects = projects
      .filter((project) => {
        const { project: filterProject } = filter;
        if (isTargetProject(project)) {
          return true;
        }
        if (!filterProject) {
          return true;
        } else {
          const { id: filterProjectId } = filterProject;
          return project.id === filterProjectId;
        }
      })
      .filter((project) => {
        if (isTargetProject(project)) {
          return true;
        }
        const { stockType } = filter;
        if (!stockType || searchTarget === RH_TARGET) {
          return true;
        }
        const selectedTypeStock = availableStock.filter((stock) => {
          return stock.stockType === stockType;
        });

        return !!selectedTypeStock.find((stockElement) => {
          if (stockElement.projects?.length) {
            return stockElement.projects[0].id === project.id;
          }
        });
      })
      .filter((project) => {
        if (isTargetProject(project)) {
          return true;
        }
        const { stockId } = filter;
        if (!stockId || searchTarget === RH_TARGET) {
          return true;
        }
        const stock = state.availableStock.find((stockElement) => stockElement.stockId === stockId);
        return project.id === stock?.projects[0]?.id;
      })
      .filter((project) => {
        if (isTargetProject(project)) {
          return true;
        }
        const { employeeTitle } = filter;
        if (!employeeTitle || searchTarget === STOCK_TARGET) {
          return true;
        } else {
          const matchEmployees = availableEmployees
            .filter((employee) => employee?.degree?.id === employeeTitle)
            .map((employee) => employee.projects)
            .reduce((accumulator, current) => [...accumulator, ...current])
            .map((project) => project.id);
          return matchEmployees.includes(project.id);
        }
      })
      .filter((project) => {
        if (isTargetProject(project)) {
          return true;
        }
        const { employeeId } = filter;
        if (!employeeId || searchTarget === STOCK_TARGET) {
          return true;
        } else {
          const matchEmployees = availableEmployees
            .find((employee) => employee.id === employeeId)
            .projects.map((project) => project.id);
          return matchEmployees.includes(project.id);
        }
      });

    const filteredWarehouses = warehouses
      .filter(() => {
        const { project: filterProject } = filter;
        return !filterProject;
      })
      .filter((warehouse) => {
        const { stockType } = filter;
        if (!stockType) {
          return true;
        }
        const selectedTypeStock = availableStock.filter((stock) => {
          return stock.stockType === stockType;
        });

        return !!selectedTypeStock.find((stockElement) => {
          if (stockElement.warehouse) {
            return Number(stockElement.warehouse) === Number(warehouse.itemValue);
          }
        });
      })
      .filter((warehouse) => {
        const { stockId } = filter;
        if (!stockId) {
          return true;
        }

        const stock = state.availableStock.find((stockElement) => stockElement.stockId === stockId);
        return Number(warehouse.itemValue) === Number(stock?.warehouse?.id);
      })
      .filter(() => searchTarget === STOCK_TARGET);

    setFilteredProjects(filteredProjects);
    setFilteredWarehouses(filteredWarehouses);
  }, [state.filter, state.projects, state.warehouses, state.searchTarget]);
  //------------------------------------------------------------
  useEffect(() => {
    console.log(state.cart);
  }, [state.cart]);

  // stateFunctions.addStockToCart
  const addStockToCart = (newStock) => {
    dispatch({
      type: ADD_STOCK_TO_CART,
      payload: {
        newStock,
      },
    });
  };

  const addEmployeeToCart = (newEmployee) => {
    dispatch({
      type: ADD_EMPLOYEE_TO_CART,
      payload: {
        newEmployee,
      },
    });
  };

  // stateFunctions.removeStockFromCart()
  const removeStockFromCart = (stockToRemove) => {
    dispatch({
      type: REMOVE_STOCK_FROM_CART,
      payload: {
        stockToRemove,
      },
    });
  };

  const cleanCart = () => {
    dispatch({
      type: CLEAN_CART,
    });
  };

  const updateFilter = (newFilter) => {
    dispatch({
      type: UPDATE_FILTER,
      payload: {
        newFilter,
      },
    });
  };

  const loadStock = (stock) => {
    dispatch({
      type: LOAD_STOCK,
      payload: {
        stock,
      },
    });
  };

  const loadProjects = (projects) => {
    dispatch({
      type: LOAD_PROJECTS,
      payload: {
        projects,
      },
    });
  };

  const loadWarehouses = (warehouses) => {
    dispatch({
      type: LOAD_WAREHOUSES,
      payload: {
        warehouses,
      },
    });
  };

  const setSelectedProject = (selectedProject) => {
    dispatch({
      type: SET_SELECTED_PROJECT,
      payload: {
        selectedProject,
      },
    });
  };

  const removeSelectedProject = () => {
    dispatch({
      type: REMOVE_SELECTED_PROJECT,
    });
  };

  const setSelectedWarehouse = (selectedWarehouse) => {
    dispatch({
      type: SET_SELECTED_WAREHOUSE,
      payload: {
        selectedWarehouse,
      },
    });
  };

  const removeSelectedWarehouse = () => {
    dispatch({
      type: REMOVE_SELECTED_WAREHOUSE,
    });
  };

  const setTargetProject = (selectedTargetProject) => {
    const newTargetProject = state?.projects?.find(
      (project) => project.id === selectedTargetProject,
    );

    dispatch({
      type: SET_TARGET_PROJECT,
      payload: {
        targetProject: newTargetProject,
      },
    });
  };
  const removeTargetProject = () => {
    dispatch({
      type: REMOVE_TARGET_PROJECT,
    });
  };

  const setFilteredProjects = (filteredProjects) => {
    dispatch({
      type: SET_FILTERED_PROJECTS,
      payload: {
        filteredProjects,
      },
    });
  };

  const setFilteredWarehouses = (filteredWarehouses) => {
    dispatch({
      type: SET_FILTERED_WAREHOUSES,
      payload: {
        filteredWarehouses,
      },
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
    });
  };

  const setModalVisibility = (modalVisibility) => {
    dispatch({
      type: SET_MODAL_VISIBILITY,
      payload: {
        modalVisibility,
      },
    });
  };

  const loadEmployees = (availableEmployees) => {
    dispatch({
      type: LOAD_EMPLOYEES,
      payload: {
        availableEmployees,
      },
    });
  };

  const setSearchTarget = (searchTarget) => {
    dispatch({
      type: SET_SEARCH_TARGET,
      payload: {
        searchTarget,
      },
    });
  };

  return (
    <CartStateContext.Provider
      value={{
        ...state,
        addStockToCart,
        addEmployeeToCart,
        removeStockFromCart,
        cleanCart,
        updateFilter,
        loadStock,
        loadProjects,
        loadWarehouses,
        setSelectedProject,
        removeSelectedProject,
        setSelectedWarehouse,
        removeSelectedWarehouse,
        setTargetProject,
        removeTargetProject,
        setFilteredProjects,
        setFilteredWarehouses,
        resetFilters,
        setModalVisibility,
        loadEmployees,
        setSearchTarget,
      }}
    >
      {children}
    </CartStateContext.Provider>
  );
};

export const useCartStateContext = () => useContext(CartStateContext);

export const withNavigation = (WrappedComponent) => {
  const ResultComponent = (props) => {
    return (
      <CartStateProvider {...props}>
        <WrappedComponent {...props} />
      </CartStateProvider>
    );
  };

  return ResultComponent;
};
