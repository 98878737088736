export const mapElement = (element) => {
  const {
    stockId,
    equipment,
    description,
    brand,
    model,
    serialNumber,
    status,
    responsible,
    nickname,
    warehouse,
    ema,
    amaac,
    classification,
    group,
    id,
    projects,
    stockType,
  } = element;
  const _element = {
    inventoryType: stockType,
    clasification: classification?.value,
    clasificationObject: classification,
    warehouse: warehouse?.value,
    warehouseObject: warehouse,
    inventoryNumber: stockId,
    equipment: equipment,
    description: description,
    brand: brand?.value,
    model: model?.value || '',
    brandObject: brand,
    modelObject: model,
    serialNumber: serialNumber,
    status: status?.value || '',
    statusObject: status,
    responsable: responsible?.name || '',
    responsableObject: responsible,
    projectObject: projects ? projects[0] : null,
    project: projects ? (projects[0] || {}).projectName : '',
    nickname: nickname,
    set: group?.value,
    setObject: group,
    EMA: ema,
    AMAAC: amaac,
    id,
    projects,
  };

  if (responsible) {
    const { lastName1, lastName2, names } = responsible;
    _element.responsable = `${names} ${lastName1} ${lastName2}`;
  } else {
    _element.responsable = 'Sin asignar';
  }

  return _element;
};
