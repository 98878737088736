import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import Table from '../../../Components/Table';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from '../../../Hooks/useFetch';

const ProjectsTable = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const AxiosInstance = useContext(AxiosContext);
  const { data: allProjects } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/project/list',
  });
  const TABLE_HEADERS = [
    { title: 'Proyecto', field: 'projectAlias' },
    { title: 'Costo Total', field: 'totalPaid' },
  ];

  const getRows = () => {
    return (allProjects || []).map((project) => {
      return { ...project, projectAlias: `${project.contractKey} - ${project.projectName || ''}` };
    });
  };

  return (
    <Table
      title={`Costos por proyectos`}
      data={getRows()}
      columns={TABLE_HEADERS}
      actions={[
        {
          icon: 'visibilityIcon',
          tooltip: 'Detalles',
          iconProps: {
            color: 'secondary',
          },
          onClick: (event, rowElement) => {
            history.push(`${pathname}/${rowElement.id}`);
          },
        },
      ]}
    />
  );
};

export default ProjectsTable;
