import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import {
  DataContainer,
  CardTitle,
  ListItemContainer,
  ListText,
  CardHeaderContainer,
} from './DataCardGrantSelectionStyles';
import { DataCardMainContainer } from '../DataCardStyles';
import {
  GIVE_GRANT_TYPE,
  GIVE_MODULE_ACCESS_TYPE,
  REMOVE_GRANT_TYPE,
  REMOVE_MODULE_ACCESS_TYPE,
} from '../../../Screens/PermisosScreen/PermisosScreen';

const DataCardList = (props) => {
  const {
    title,
    grantsCatalog = [],
    grantsModuleState = [],
    grantsDispatch,
    referenceAttribute = 'id',
  } = props;

  const handleChange = (grant, event) => {
    grantsDispatch({
      type: event.target.checked ? GIVE_GRANT_TYPE : REMOVE_GRANT_TYPE,
      targetGrant: grant,
    });
  };

  const handleGeneralChange = (event) => {
    grantsDispatch({
      type: event.target.checked ? GIVE_MODULE_ACCESS_TYPE : REMOVE_MODULE_ACCESS_TYPE,
      targetModule: title,
    });
  };

  const calcGeneralCheckboxState = () => {
    const { length: catalogLength } = grantsCatalog;
    const { length: moduleStateLength } = grantsModuleState;

    const indeterminate = !!moduleStateLength && moduleStateLength !== catalogLength;
    const checked = catalogLength === moduleStateLength || indeterminate;
    return { checked, indeterminate };
  };

  return (
    <DataCardMainContainer>
      <CardHeaderContainer>
        <Checkbox {...calcGeneralCheckboxState()} onChange={handleGeneralChange} />
        <CardTitle>{title}</CardTitle>
      </CardHeaderContainer>
      <DataContainer>
        {grantsCatalog.map((item, itemIndex) => {
          return (
            <ListItemContainer key={itemIndex}>
              <Checkbox
                checked={
                  !!grantsModuleState.find(
                    (grantModule) => item[referenceAttribute] === grantModule[referenceAttribute],
                  )
                }
                onChange={(event) => handleChange(item, event)}
              />
              <Tooltip placement="top" title={item?.description || ''}>
                <ListText
                // onClick={() => {
                //   handleItemClick();
                // }}
                >
                  {item.name}
                </ListText>
              </Tooltip>
            </ListItemContainer>
          );
        })}
      </DataContainer>
      {/* <MoreInfoLink onClick={handleSeeMore}>Ver mas ...</MoreInfoLink> */}
    </DataCardMainContainer>
  );
};

export default DataCardList;
