import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useStyles } from './ConceptosScreenStyles';
import ColumnEditFormulary from './ColumnEditFormulary/Formulary';
import NewConceptFormulary from './NewElementFormulary/Formulary';
import { AxiosContext } from './../../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from './../../../Hooks/useFetch';
import CsvDropzone from './../../../Components/CsvDropZone';
import { userContext } from './../../../Contexts/UserContext/UserContext';
import { csvConceptMap } from './csvMap';
import DialogActions from '../../../Containers/GenericDialog/DialogActions';
import { ProjectMainTitle } from '../../SingleProjectScreen/SingleProjectScreenStyles';
import Alert, { WARNING_TYPE } from '../../../Components/Alert/Alert';
import Table from '../../../Components/Table';
import Dialog from '../../../Containers/GenericDialog';
import { withNavigation } from '../../../Containers/AppNavigation/AppNavigation';
import { ProjectContext } from '../../../Contexts/ProjectContext/ProjectContext';
import { API_ENDPOINT } from '../../../Constants/urls';

const ConceptosScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const history = useHistory();
  //---------------------------------------------------------------------------
  const [projectState, setProjectState] = useContext(ProjectContext);
  const [userState] = useContext(userContext);
  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------
  // useState hooks
  //--------------------------------------------------------------------------- setOpenSaveDialog
  const [apiUrl, setApiUrl] = useState('');
  const [formularyDialogOpen, setFormularyDialogOpen] = useState(false);
  const [conceptToDelete, setConceptToDelete] = useState(null);
  const [conceptToEdit, setConceptToEdit] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingWarning, setDeletingWarning] = useState(false);
  const [columnTableState, setColumnTableState] = useState({
    inventoryType: true,
    clasification: true,
    inventoryNumber: true,
    equipment: true,
    description: true,
    brand: false,
    model: false,
    serialNumber: false,
    status: false,
    responsable: false,
    project: false,
    nickname: false,
    set: false,
    EMA: false,
    AMAAC: false,
  });

  const [newElementModalState, setNewElementModalState] = useState(false);
  const [csvModalState, setCsvModalState] = useState(false);
  const [userProjectGrants, setUserProjectGrants] = useState({});

  const { userDetails = {} } = userState || {};
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { data: projectDetails, refetch: updateProjectState } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${projectState?.id}`,
    skip: !projectState?.id,
  });
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (projectState?.id) {
      console.log(projectState);
      setApiUrl(`${API_ENDPOINT}/api/project/${projectState?.id}/concept`);
    }
  }, [projectState]);
  useEffect(() => {
    if (projectDetails) {
      console.log(projectDetails);
      setProjectState(projectDetails);

      const { projectGrants } = projectDetails;
      let tempGrants = {};
      projectGrants.forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });
      setUserProjectGrants(tempGrants);
    }
  }, [projectDetails, setProjectState]);

  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // COMMON Table Actions
  //---------------------------------------------------------------------------
  // code: "Codigo Concepto"
  // conceptName: "Concepto Updated"
  // cost: 10
  // id: 1
  // quantity: 15
  // tableData: {id: 0}
  // total: 150
  // unitOfMeasure: "ml"
  const TABLE_HEADERS = [
    { title: 'Codigo de Concepto', field: 'code' },
    { title: 'Codigo del Sistema', field: 'number' },
    { title: 'Nombre', field: 'conceptName' },
    { title: 'Descripción', field: 'conceptDescription' },
    { title: 'Costo', field: 'cost' },
    { title: 'Cantidad Realizada', field: 'quantity' },
    { title: 'Total', field: 'total' },
    { title: 'Unidad de medida', field: 'unitOfMeasure' },
  ];

  //---------------------------------------------------------------------------
  // MAIN Table Actions
  //---------------------------------------------------------------------------

  const actions = [
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            {'Agregar Concepto'}
          </Button>
        );
      },
      tooltip: 'Button',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setConceptToDelete(null);
        setNewElementModalState(true);
      },
      isFreeAction: true,
      hidden: !userProjectGrants?.EDIT_CONCEPTS?.includes(userDetails?.username),
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            {'Cargar desde CSV'}
          </Button>
        );
      },
      tooltip: 'Button',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setCsvModalState(true);
      },
      isFreeAction: true,
      hidden: !userProjectGrants?.EDIT_CONCEPTS?.includes(userDetails?.username),
    },
    {
      icon: SettingsIcon,
      tooltip: 'Add / Remove Columns',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setFormularyDialogOpen(true);
      },
      isFreeAction: true,
    },
    {
      icon: 'delete',
      tooltip: 'Eliminar Concepto',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setConceptToDelete(rowElement);
        setOpenDeleteDialog(true);
      },
      hidden: !userProjectGrants?.EDIT_CONCEPTS?.includes(userDetails?.username),
    },
    {
      icon: 'edit',
      tooltip: 'Editar Concepto',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setConceptToEdit(rowElement);
        setNewElementModalState(true);
      },
      hidden: !userProjectGrants?.EDIT_CONCEPTS?.includes(userDetails?.username),
    },
  ];

  const handleDelete = async () => {
    try {
      await AxiosInstance.delete(`api/project/${projectState.id}/concept/${conceptToDelete.id}`);
      const { concepts } = projectState;
      updateProjectState();
      setProjectState({
        ...projectState,
        concepts: concepts.filter((concept) => concept.id !== conceptToDelete.id),
      });
    } catch (error) {
      setDeletingWarning(true);
    } finally {
      setOpenDeleteDialog(false);
      setConceptToDelete(null);
    }
  };

  const MainEstimacionesTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid container direction="row" justify="flex-starte" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              {'REGRESAR <<'}
            </Button>
          </Grid>
          <Grid item>
            <ProjectMainTitle>
              {`${projectState?.projectId || ''} - ${projectState?.projectName || ''} - ${
                projectState?.contractKey || ''
              }` || 'Proyecto'}
            </ProjectMainTitle>
          </Grid>
        </Grid>
        <Grid item>
          <Table
            title={`Conceptos`}
            data={
              projectState?.concepts?.filter(
                ({ number, parentNumber }) => number && parentNumber,
              ) || []
            }
            columns={TABLE_HEADERS}
            actions={actions}
            parentChildData={(row, rows) => rows.find((a) => a.number === row.parentNumber)}
          />
        </Grid>
      </Grid>
      <Dialog
        openDialog={formularyDialogOpen}
        dialogTitle={'Configuración de Columnas'}
        maxWidth="md"
      >
        <ColumnEditFormulary
          setOpen={setFormularyDialogOpen}
          currentFilter={columnTableState}
          columnFields={columnTableState}
          setColumnFields={setColumnTableState}
        />
      </Dialog>
      <Dialog openDialog={newElementModalState} dialogTitle={'Nuevo Concepto'} maxWidth="lg">
        <NewConceptFormulary
          setOpen={setNewElementModalState}
          currentElement={conceptToEdit}
          refetch={updateProjectState}
        />
      </Dialog>
      <Dialog openDialog={csvModalState} dialogTitle={'Carga CSV'} maxWidth="md">
        <CsvDropzone
          apimethod="post"
          apimode="multiple"
          map={csvConceptMap}
          headers={{
            Authorization: `${userState.tokenType} ${userState.idToken}`,
            deviceType: 'WEB',
            latitude: '0',
            longitude: '0',
            sessionId: userState.sessionId,
            username: userState.username,
          }}
          apiurl={apiUrl}
          onClose={() => {
            updateProjectState();
            setCsvModalState(false);
          }}
        />
        <DialogActions
          secondaryLabel="Cancelar"
          primaryLabel={null}
          secondaryAction={() => {
            updateProjectState();
            setCsvModalState(false);
          }}
        />
      </Dialog>
      <Alert
        type={WARNING_TYPE}
        title="Borrar concepto del proyecto"
        content="Estas a punto de borrar un concepto del proyecto, recuerda que esta acción es irreversible y puede impactar otras áreas del sistema."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenDeleteDialog(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onBackdropPress={() => {
          setOpenDeleteDialog(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Imposible Borrar"
        content="Este concepto no ha podido ser eliminado, es probable que sea referenciada por otro elemento del sistema."
        actions={[
          {
            text: 'OK',
            onClick: () => {
              setDeletingWarning(false);
            },
          },
        ]}
        open={deletingWarning}
        onClose={() => setDeletingWarning(false)}
        onBackdropPress={() => {
          setDeletingWarning(false);
        }}
      />
    </>
  );

  const renderScreen = () => {
    return MainEstimacionesTable;
  };

  return <div className={styles.container}>{renderScreen()}</div>;
};

export default withNavigation(ConceptosScreen);
