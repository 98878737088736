import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useStyles } from './FormikIconInputOutlinedStyle';

function FormikIconInputOutlinedDisplay({
  formikName,
  label,
  Icon,
  props,
  type,
  disabled,
  multiline,
  rows,
  secondary,
  secondaryIconColor,
  customOnBlur,
  redIcon,
  format,
}) {
  const { field, form } = props;

  const { handleBlur, touched, errors, setFieldValue } = form;
  const { value } = field;
  const style = useStyles();

  const error = touched[formikName] && errors[formikName];

  return (
    <TextField
      id={formikName}
      label={
        format
          ? `${label}: (${value ? parseInt(value).toLocaleString('es-MX', format) : '$0.00'})` || ''
          : label || ''
      }
      className={style.textField}
      value={value}
      onChange={(event) => {
        // const newValue = fieldValuesToUpperCase(event.target.value, type);
        setFieldValue(field.name, event.target.value);
        // handleChange(event);
      }}
      onBlur={(event) => {
        const blurFunction = customOnBlur || handleBlur;
        //---- Codigo para hacer todo mayusculas :D
        // const newValue = fieldValuesToUpperCase(event.target.value, type);
        setFieldValue(field.name, event.target.value);
        blurFunction(event);
      }}
      type={type}
      size="small"
      InputProps={{
        name: formikName,
        id: formikName,
        endAdornment: Icon && (
          <InputAdornment position="end">
            <Icon
              className={
                error || redIcon
                  ? style.iconError
                  : secondaryIconColor
                  ? style.iconSecondary
                  : style.icon
              }
              style={{ fontSize: 20 }}
            />
          </InputAdornment>
        ),
      }}
      helperText={error}
      variant="outlined"
      disabled={disabled}
      fullWidth
      multiline={multiline}
      rows={rows}
      error={error ? true : false}
      color={secondary ? 'secondary' : 'primary'}
    />
  );
}

export default FormikIconInputOutlinedDisplay;
