import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Dialog from './../../Containers/GenericDialog';
import FolderComponent from './FolderComponent/FolderComponent';
import FoldersContainer from './FolderContainer/FolderContainer';
import {
  ButtonsContainer,
  HeaderContainer,
  HeaderTitle,
  PCSHeaderContainer,
  ProjectCloudScreenMainContainer,
} from './ProjectCloudScreenStyles';
import FileComponent from './FileComponent/FileComponent';
import NewFolderForm from './NewFolderForm/NewFolderForm';
import Dropzone from '../../Components/DropZone';
import { useFetch } from '../../Hooks/useFetch';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import s3AxiosInstance from '../../Utils/AxiosS3Instance';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { userContext } from '../../Contexts/UserContext/UserContext';

const FILES_BUCKET = 'respaldo-roshare';

const ProjectHistoricCloudScreen = () => {
  const [newFolderDialog, setNewFolderDialogOpen] = React.useState(false);
  const [uploadPath, setUploadPath] = React.useState([]);
  const history = useHistory();
  const axiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);
  const { userDetails = {} } = userState || {};

  const { projectId } = useParams();

  const { data: projectData } = useFetch({
    axiosInstance: axiosInstance,
    initialUrl: `/api/project/${projectId}`,
    skip: !projectId,
  });

  const { projectGrants } = projectData || {};

  let userGrants = {};
  projectGrants?.forEach((grant) => {
    if (!(grant?.grantName in userGrants)) userGrants[grant?.grantName] = [];
    userGrants[grant.grantName].push(grant?.user?.username);
  });

  const {
    data: elementFiles,
    updateParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: { bucket: FILES_BUCKET, path: `${projectId}/` },
    skip: !projectId,
  });

  const createFolder = async (params) => {
    const { folderName, path } = params;
    const bodyForm = new FormData();
    bodyForm.append('bucket', FILES_BUCKET);
    bodyForm.append('path', path || '');
    bodyForm.append('name', folderName);
    bodyForm.append('isFolder', true);

    await s3AxiosInstance.post('/s3-object', bodyForm);
    refetchFiles();
    setNewFolderDialogOpen(false);
  };

  const goBack = () => {
    setUploadPath((prev) => {
      const newArray = [...prev];
      newArray.pop();

      return newArray;
    });
  };

  const goProjectHome = () => {
    history.push('/historicos');
  };

  React.useEffect(() => {
    updateParams({
      bucket: FILES_BUCKET,
      path: uploadPath.join('/'),
    });
  }, [uploadPath]);

  React.useEffect(() => {
    const doesFolderExist = async (args) => {
      const { path, folder } = args || {};
      const url = `/list-all-objects?bucket=${FILES_BUCKET}${path ? `&path=${path}` : ''}`;
      const payload = await s3AxiosInstance.get(url);
      const { folders } = payload.data;
      const folderExists = !!folders.find(({ key }) => `${folder}/` === key);
      if (!folderExists) {
        createFolder({ folderName: folder });
      }
      return folderExists;
    };
    const initProjectHistory = async () => {
      await doesFolderExist({
        folder: projectId,
        path: '/',
      });
      setUploadPath((prev) => [...prev, projectId]);
    };

    if (projectId) {
      initProjectHistory();
    }
  }, [projectId]);

  return userGrants?.ACCESS_HISTORIC?.includes(userDetails?.username) ? (
    <>
      <ProjectCloudScreenMainContainer>
        <HeaderContainer>
          <ButtonsContainer style={{ flex: 1 }}>
            <Button onClick={uploadPath?.length > 1 ? goBack : goProjectHome}>Regresar</Button>
            <Button onClick={() => setNewFolderDialogOpen(true)}>Crear folder</Button>
          </ButtonsContainer>

          <div style={{ flex: 3 }}>
            <Dropzone
              path={`${uploadPath.join('/')}/`}
              refetch={() => {
                console.log('refetch');
                refetchFiles();
              }}
              bucket={FILES_BUCKET}
            />
          </div>
        </HeaderContainer>
        <PCSHeaderContainer>
          <HeaderTitle>{`Históricos de proyecto: ${projectData?.projectName || ''}`}</HeaderTitle>
        </PCSHeaderContainer>
        <HeaderTitle>
          {uploadPath.join('/').replace(/\/\//g, '/').replace(projectId, projectData?.projectName)}
        </HeaderTitle>
        <FoldersContainer>
          {(elementFiles?.folders || []).map((folder, folderIndex) => {
            return (
              <FolderComponent
                addToPath={(fName) => setUploadPath((prev) => [...prev, fName])}
                folderName={folder.key}
                key={`${folderIndex}_${folder.key}`}
                bucket={FILES_BUCKET}
                refetchFiles={refetchFiles}
              />
            );
          })}
          {(elementFiles?.files || []).map((folder, folderIndex) => {
            return (
              <FileComponent
                deleteEnabled={true}
                refetchFiles={refetchFiles}
                bucket={FILES_BUCKET}
                folderName={folder.key}
                key={`${folderIndex}_${folder.key}`}
              />
            );
          })}
        </FoldersContainer>
      </ProjectCloudScreenMainContainer>
      <Dialog openDialog={newFolderDialog} dialogTitle={`Crear nuevo folder`} maxWidth="md">
        <NewFolderForm
          setOpen={setNewFolderDialogOpen}
          refetchFolders={refetchFiles}
          createFolder={(values) => createFolder({ ...values, path: uploadPath.join('/') })}
        />
      </Dialog>
    </>
  ) : (
    <h1>No tienes permiso para acceder a este expediente</h1>
  );
};

export default withNavigation(ProjectHistoricCloudScreen);
