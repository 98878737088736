import * as yup from 'yup';

const basicValidationShape = {
  estimationTotal: yup
    .number()
    .positive('El valor debe ser positivo')
    .required('Campo obligatorio'),
  comments: yup.string(),
};

export const validationSchema = yup.object().shape(basicValidationShape);

export const initialValues = {
  creationDate: new Date(),
  estimationTotal: 0,
  comments: '',
  period: '',
  contractNumber: '',
  convenio: '',
};
