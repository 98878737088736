import React, { useState } from 'react';

const NO_NAVIGATOR_ERROR = 'NO_NAV';
const EXCEED_OF_ATTEMPS = 'EXCEED_OF_ATTEMPS';

const GpsContext = React.createContext([{}, () => {}]);

const GpsContextProvider = (props) => {
  const [gpsState, setGpsState] = useState({ latitude: 1, longitude: 1 });
  const [retries, setRetries] = useState(0);
  const [requesting, setRequesting] = useState(false);

  const getPosition = (position) => {
    const { latitude, longitude } = position.coords;
    const newValues = { latitude, longitude };
    setGpsState(newValues);
    return newValues;
  };

  const refreshGPS = () => {
    return new Promise((res) => {
      const success = (position) => {
        const values = getPosition(position);
        setRetries(0);
        setRequesting(false);
        res(values);
      };

      const error = (err) => {
        console.log(err);
        setGpsState({ latitude: 1, longitude: 1 });
        setRequesting(false);
        // getLocation();
      };

      const getLocation = () => {
        if (retries < 3) {
          setRequesting(true);
          setRetries(retries + 1);
          navigator.geolocation.getCurrentPosition(success, error, {
            maximumAge: 60000,
            timeout: 5000,
            enableHighAccuracy: true,
          });
        } else {
          setRetries(0);
          // rej(new Error(EXCEED_OF_ATTEMPS));
          setGpsState({ latitude: 1, longitude: 1 });
          res({ latitude: 1, longitude: 1 });
        }
      };

      if (navigator.geolocation) {
        getLocation();
      } else {
        setGpsState({ latitude: 1, longitude: 1 });
        res({ latitude: 1, longitude: 1 });
        // rej(new Error(NO_NAVIGATOR_ERROR));
      }
    });
  };

  !requesting &&
    !gpsState?.latitude &&
    !gpsState.longitude &&
    refreshGPS().catch((err) => {
      console.log(err);
      setGpsState({ latitude: 1, longitude: 1 });
    });

  return <GpsContext.Provider value={[gpsState, refreshGPS]}>{props.children}</GpsContext.Provider>;
};

export { GpsContext, GpsContextProvider, EXCEED_OF_ATTEMPS, NO_NAVIGATOR_ERROR };
