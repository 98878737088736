export const GLOBAL_TABLE_HEADERS = [
  { title: 'Tipo de Inventario', field: 'inventoryType' },
  { title: 'Clasificación', field: 'clasification' },
  { title: '# de Inventario', field: 'inventoryNumber' },
  { title: 'Equipo', field: 'equipment' },
  {
    title: 'Descripción',
    field: 'description',
    cellStyle: {
      width: '100px',
      minWidth: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    headerStyle: {
      width: '100px',
      minWidth: '400px',
    },
  },
  { title: 'Marca', field: 'brand' },
  { title: 'Modelo', field: 'model' },
  { title: '# Serie', field: 'serialNumber' },
  { title: 'Status', field: 'status' },
  { title: 'Responsable', field: 'responsable' },
  {
    title: 'Proyecto',
    field: 'project',
    cellStyle: {
      width: '250px',
      minWidth: '250px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    headerStyle: {
      width: '250px',
      minWidth: '250px',
    },
  },
  { title: 'Apodo', field: 'nickname' },
  { title: 'Conjunto', field: 'set' },
  { title: 'EMA', field: 'EMA' },
  { title: 'AMAAC', field: 'AMAAC' },
  { title: 'Almacén', field: 'warehouse' },
];

export const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];

export const FILES_BUCKET = 'roshare-inventory';

export const ITEM_HEIGHT = 48;
