import { Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import FormikAutocomplete from '../../../Components/AutoComplete/FormikAutocomplete';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from '../../../Hooks/useFetch';
import { useMasterDataCatalogs } from '../../../Hooks/useMasterdataCatalogs';
import { useInventoryTypeOptions } from '../../NewStockElementScreen/hooks/useInventoryTypeOptions';
import { inventoryRouteList } from '../InventoryData';

const useStyles = makeStyles(() => ({
  chipContainer: {
    border: 'solid grey 1px',
    borderRadius: '5px',
    padding: '5px',
  },
}));

const InventorySearchForm = ({ onSearchChange, currentData }) => {
  const classes = useStyles();
  const axiosInstance = React.useContext(AxiosContext);
  const inventoryTypeOptions = useInventoryTypeOptions();
  const [stockFormConfiguration, setStockFormConfiguration] = React.useState(null);

  const { catalogs: masterDataCatalogs, updateEntityList: updateMasterDataParams } =
    useMasterDataCatalogs({
      entityList: [],
      skip: !stockFormConfiguration,
    });

  const [selectedInventoryTypes, setSelectedInventoryTypes] = React.useState([]);
  const [selectedInventoryClassification, setSelectedInventoryClassifications] = React.useState([]);
  const [classificationOptions, setClassificationOptions] = React.useState([]);

  const { data, updateParams } = useFetch({
    axiosInstance,
    initialUrl: '/api/stock/list',
    initialParams: {
      stockType: selectedInventoryTypes,
    },
    skip: !selectedInventoryTypes?.length,
  });

  React.useEffect(() => {
    updateParams({
      stockType: selectedInventoryTypes.map(({ itemValue }) => itemValue),
    });
  }, [selectedInventoryTypes, updateParams]);

  React.useEffect(() => {
    let tableData = [];
    if (data) {
      if (selectedInventoryClassification?.length) {
        tableData = data.filter(({ classification }) => {
          return selectedInventoryClassification
            .map(({ itemValue }) => itemValue)
            .includes(classification.id);
        });
      } else {
        tableData = data;
      }
    }
    if (currentData?.length !== tableData?.length) {
      onSearchChange && onSearchChange(tableData);
    }
  }, [data, selectedInventoryClassification, onSearchChange]);

  React.useEffect(() => {
    if (selectedInventoryTypes?.length === 1 && !stockFormConfiguration) {
      const newConfig = inventoryRouteList.find(
        (inventory) => inventory.stockType === selectedInventoryTypes[0].itemValue,
      );
      setStockFormConfiguration(newConfig);
      const { entityType } = newConfig;
      updateMasterDataParams([entityType]);
    } else if (
      selectedInventoryTypes?.length !== 1 &&
      stockFormConfiguration &&
      classificationOptions?.length
    ) {
      setStockFormConfiguration(null);
      setClassificationOptions([]);
      setSelectedInventoryClassifications([]);
    }
  }, [
    classificationOptions,
    selectedInventoryTypes,
    setStockFormConfiguration,
    stockFormConfiguration,
    updateMasterDataParams,
  ]);

  React.useEffect(() => {
    if (masterDataCatalogs && stockFormConfiguration) {
      const { entityType } = stockFormConfiguration;
      const classificationOptions = masterDataCatalogs[entityType];
      setClassificationOptions(classificationOptions);
    }
  }, [masterDataCatalogs, stockFormConfiguration]);

  return (
    <Formik initialValues={{}}>
      {({ setFieldValue, setFieldTouched }) => {
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={classificationOptions?.length ? 6 : 12}>
                <Grid container className={classes.chipContainer} spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {selectedInventoryTypes?.length
                        ? 'Tipos de inventarios seleccionados:'
                        : 'No se ha seleccionado ningun tipo de inventario'}
                    </Typography>
                  </Grid>
                  {selectedInventoryTypes.map((inventoryType, index) => {
                    return (
                      <Grid key={index} item>
                        <Chip
                          key={index}
                          label={inventoryType.itemLabel}
                          onDelete={() => {
                            setSelectedInventoryTypes((prevSelection) =>
                              prevSelection.filter(
                                ({ itemValue }) => itemValue !== inventoryType.itemValue,
                              ),
                            );
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              {classificationOptions?.length ? (
                <Grid item xs={6}>
                  <Grid container className={classes.chipContainer} spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {selectedInventoryTypes?.length
                          ? 'Clasificaciones seleccionadas:'
                          : 'No se ha seleccionado ninguna clasificación'}
                      </Typography>
                    </Grid>
                    {selectedInventoryClassification.map((inventoryClassification, index) => {
                      return (
                        <Grid key={index} item>
                          <Chip
                            key={index}
                            label={inventoryClassification.itemLabel}
                            onDelete={() => {
                              setSelectedInventoryClassifications((prevSelection) =>
                                prevSelection.filter(
                                  ({ itemValue }) =>
                                    itemValue !== inventoryClassification.itemValue,
                                ),
                              );
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={classificationOptions?.length ? 6 : 12}>
                <Field name="stockType">
                  {(props) => (
                    <FormikAutocomplete
                      options={inventoryTypeOptions}
                      label="Tipo de inventario"
                      customOnChange={(newValue) => {
                        newValue &&
                          setSelectedInventoryTypes((prevTypes) => [...prevTypes, newValue]);
                        setFieldValue(props.field.name, null);
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      {...props}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="stockClassification">
                  {(props) =>
                    classificationOptions?.length ? (
                      <FormikAutocomplete
                        options={classificationOptions}
                        label="Clasificación de inventario"
                        customOnChange={(newValue) => {
                          newValue &&
                            setSelectedInventoryClassifications((prevTypes) => [
                              ...prevTypes,
                              newValue,
                            ]);
                          setFieldValue(props.field.name, null);
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        {...props}
                      />
                    ) : null
                  }
                </Field>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InventorySearchForm;
