import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const RoshareCarousel = ({ imagelinks }) => {
  return (
    <Carousel dynamicHeight infiniteLoop interval={5} stopOnHover>
      {(imagelinks || []).map((image, key) => {
        return (
          <img src={image} key={key} alt={key} style={{ maxHeight: '80%', maxWidth: '80%' }} />
        );
      })}
    </Carousel>
  );
};

export default RoshareCarousel;
