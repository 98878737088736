import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: 0;
`;

export const CardTitle = styled.h1`
  font-size: 30px;
  margin: 20px;
  text-align: center;
  border-bottom: solid #707070 1px;
`;
