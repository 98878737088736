import {
  DEPENDENCY_REJECTED,
  AWAITING_DEPENDENCY_APPROVAL,
  AWAITING_INVOICE,
  DEPENDENCY_APPROVED,
  INVOICED,
  PAID,
  PAYMENT_PENDING,
  REJECTED,
} from '../DependencyFeedbackDialog/DependencyFeedbackDialog';

export const ALL_ESTIMATION_STATUS = [
  DEPENDENCY_REJECTED,
  AWAITING_DEPENDENCY_APPROVAL,
  AWAITING_INVOICE,
  DEPENDENCY_APPROVED,
  INVOICED,
  PAID,
  PAYMENT_PENDING,
  REJECTED,
];

export const getStatusChange = (currentStatus) => {
  switch (currentStatus) {
    case DEPENDENCY_REJECTED:
      return [];
    case AWAITING_DEPENDENCY_APPROVAL:
      return [AWAITING_INVOICE];
    case AWAITING_INVOICE:
      return [INVOICED];
    case DEPENDENCY_APPROVED:
      return [];
    case INVOICED:
      return [];
    case PAYMENT_PENDING:
      return [];
    case REJECTED:
      return [];
    default:
      return [];
  }
};
