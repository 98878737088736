import React, { useContext } from 'react';
import { useFetch } from './useFetch';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const useProjectById = (projectId) => {
  const AxiosInstance = useContext(AxiosContext);
  const [project, setProject] = React.useState(null);

  const { data, refetch: refetchProject } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${projectId}`,
    skip: !projectId,
  });

  React.useEffect(() => {
    if (data) {
      setProject(data);
    }
  }, [data]);

  return { project, refetchProject };
};
