import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  revisionDate: yup.string().required('Campo Obligatorio'),
  revision: yup.number().required('Campo Obligatorio'),
  file: yup
    .boolean()
    .required('')
    .oneOf([true], 'Debe de subir un archivo para poder actualizar la versión'),
});

export const getInitialValues = (currentElement = {}) => {
  const { revision, revisionDate, id } = currentElement || {};

  return {
    revision: `${revision}`,
    futureRevision: `${Number(revision) + 1}`,
    revisionDate,
    file: false,
    id,
  };
};
