import styled from 'styled-components';
export const FIRST_SECTION = 'first';
export const LAST_SECTION = 'last';

//------------------------------------------------------------------------
export const MainComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
//------------------------------------------------------------------------

//------------------------------------------------------------------------
export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: ${({ radius }) => radius || 100}px;
  margin: 10px;
  flex: 1;
`;
//------------------------------------------------------------------------

//------------------------------------------------------------------------
export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-right: 0;
`;
//------------------------------------------------------------------------
//------------------------------------------------------------------------
const getRadius = (kind, radius) => {
  switch (kind) {
    case FIRST_SECTION:
      return `${radius}px 0 0 ${radius}px`;
    case LAST_SECTION:
      return `0 ${radius}px ${radius}px 0`;
    default:
      return `0`;
  }
};
//------------------------------------------------------------------------

//------------------------------------------------------------------------
export const PercentageSection = styled.div`
  flex: ${(props) => props.percentage};
  background-color: ${(props) => props.color};
  height: 20px;
  border-radius: ${({ kind, radius }) => getRadius(kind, radius)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
//------------------------------------------------------------------------
//------------------------------------------------------------------------
export const SectionValue = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: white;
`;
//------------------------------------------------------------------------
