import React, { useState, useContext } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { useStyles } from '../MenuStyle';
import { userContext } from '../../../../Contexts/UserContext/UserContext';

const MenuListItem = (props) => {
  const { element, index, expandCondition, component } = props;
  const { subRouts } = element || {};
  const styles = useStyles();
  const history = useHistory();
  const [subMenuStatus, setSubmenuStatus] = useState(false);
  const [userState] = useContext(userContext);

  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedViewProfiles = grants.map((grant) => grant.name);

  const shouldExpand = () => {
    return expandCondition ? expandCondition() : subMenuStatus;
  };

  return (
    <>
      <ListItem
        button
        component={component}
        key={`${element.routeName}_${index}`}
        onClick={() => history.push(element.routePath)}
      >
        <ListItemIcon style={{ color: 'white' }}>
          <element.Icon />
        </ListItemIcon>
        <ListItemText primary={element.routeName} style={{ color: 'white' }} />
        {subRouts && !expandCondition && (
          <ListItemSecondaryAction style={{ color: 'white' }}>
            <IconButton
              color="inherit"
              aria-label="upload picture"
              component="span"
              onClick={() => setSubmenuStatus((p) => !p)}
            >
              {subMenuStatus ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {subRouts && (
        <Collapse in={shouldExpand()} timeout="auto" unmountOnExit>
          <List disablePadding>
            {subRouts &&
              subRouts
                .filter((element) => {
                  return (
                    !('canViewProfile' in element) ||
                    allowedViewProfiles.includes(element.canViewProfile)
                  );
                })
                .map(({ Icon, routeName, routePath }, indexin) => (
                  <ListItem
                    button
                    // component={Link}
                    to={routePath}
                    onClick={() => history.push(routePath)}
                    className={styles.nested}
                    selected={window.location.href.includes(routePath)}
                    key={`${indexin}-${routeName}`}
                  >
                    <ListItemIcon>
                      <Icon className={styles.icon} />
                    </ListItemIcon>
                    <ListItemText primary={routeName} />
                  </ListItem>
                ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default MenuListItem;
