/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';

import { useStyles } from './../MasterDataStyles';
//--------------------------------------------------------------------
// IMPORTS DE TABLA
import Table from './../../../Components/Table';
// IMPORTS DE FORMULARIO DE CREACION
import Dialog from './../../../Containers/GenericDialog';
import DialogFormulary from './../DialogFormulary';
// IMPORTS DE ALERTA DE ERROR
import Alert, { ERROR_TYPE, WARNING_TYPE } from './../../../Components/Alert/Alert';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
//--------------------------------------------------------------------

const MasterDataTable = (props) => {
  const { deleteLocalData, addLocalData, updateLocalData } = props;
  const styles = useStyles();
  //--- Properties ----------------------------------------------------
  const { tableTitle, elementName, data, selectType } = props;
  //--- useState hooks -------------------------------------------------
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [masterDataElement, setMasterDataElement] = useState(null);
  const [masterDataToDelete, setMasterDataToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [lastElementAlert, setLastElementAlert] = useState(false);
  // const [deleteResult, deleteMasterDataElement] = useAxios([]);
  const AxiosInstance = useContext(AxiosContext);
  //--- Table functions ------------------------------------------

  const handleEdit = (event, element) => {
    setOpenDialog(true);
    setMasterDataElement(element);
  };

  const handleDelete = async () => {
    if (data.length > 1) {
      await AxiosInstance.delete(`api/masterData/${masterDataToDelete.id}`);
      deleteLocalData(masterDataToDelete);
      setMasterDataToDelete(null);
    } else {
      setLastElementAlert(true);
    }
  };

  const handleAddElement = () => {
    setOpenDialog(true);
    setMasterDataElement(null);
  };

  // useEffect(() => {
  //   if (
  //     deleteResult.statusCode === 200 &&
  //     !deleteResult.loading &&
  //     masterDataToDelete
  //   ) {
  //     deleteLocalData(masterDataToDelete);
  //     setOpenDeleteDialog(false);
  //     setMasterDataToDelete(null);
  //     deleteResult.resetResult();
  //   }
  // }, [deleteResult, masterDataToDelete]);

  const actions = [
    {
      icon: 'edit',
      iconProps: {
        color: 'secondary',
      },
      tooltip: 'Edit Element',
      onClick: handleEdit,
    },
    {
      icon: 'delete',
      tooltip: 'Delete Element',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, mdElement) => {
        if (data.length > 1) {
          setMasterDataToDelete(mdElement);
          setOpenDeleteDialog(true);
        } else {
          setLastElementAlert(true);
        }
      },
    },
  ];

  //TABLE HEADERS
  const masterDataHeadersArray = [
    { title: 'ID', field: 'id' },
    { title: 'VALUE', field: 'value' },
    { title: 'ENTITY', field: 'entity' },
  ];

  return (
    <div>
      {/* ------------------------------------------------------------------------ */}
      {/* --- BUTTON TO ADD NEW ELEMENT ------------------------------------------ */}
      {/* ------------------------------------------------------------------------ */}

      <Button
        className={styles.button}
        color="primary"
        variant="contained"
        onClick={handleAddElement}
        style={{ margin: '0px', marginBottom: '20px' }}
      >
        Agregar nuevo elemento: {elementName || 'element'}
      </Button>
      <Button
        className={styles.button}
        color="primary"
        variant="contained"
        onClick={() => selectType()}
        style={{ margin: '0px', marginBottom: '20px', marginLeft: '20px' }}
      >
        Regresar
      </Button>

      {/* ------------------------------------------------------------------------ */}
      {/* --- TABLE OF ELEMENTS -------------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}

      <Table
        title={tableTitle || 'Tabla'}
        columns={masterDataHeadersArray}
        data={data || []}
        onRowClick={handleEdit}
        actions={actions}
      />

      {/* ------------------------------------------------------------------------ */}
      {/* --- CREATE AND UPDATE DIALOG ------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}

      <Dialog
        openDialog={openDialog}
        setCloseDialog={() => setOpenDialog(false)}
        dialogTitle={`Crear nuevo: ${elementName}`}
        maxWidth="sm"
      >
        <DialogFormulary
          setCloseDialog={() => setOpenDialog(false)}
          setOpenAlert={setOpenAlert}
          masterDataElement={masterDataElement}
          type={elementName}
          addLocalData={addLocalData}
          updateLocalData={updateLocalData}
        />
      </Dialog>

      {/* ------------------------------------------------------------------------ */}
      {/* --- ERROR DIALOG ------------------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}
      <Alert
        type={ERROR_TYPE}
        title="Error al intentar crear un elemento de master data"
        content="Status: ERROR"
        actions={[
          {
            text: 'OK',
            onClick: () => setOpenAlert(false),
          },
        ]}
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        onBackdropPress={() => {
          setOpenAlert(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Último elemento"
        content="Este es el último elemento del catálogo, no es posible eliminarlo manualmente, en caso de ser necesario contactar soporte técnico."
        actions={[
          {
            text: 'OK',
            onClick: () => setLastElementAlert(false),
          },
        ]}
        open={lastElementAlert}
        onClose={() => setLastElementAlert(false)}
        onBackdropPress={() => {
          setLastElementAlert(false);
        }}
      />

      {/* ------------------------------------------------------------------------ */}
      {/* --- DELETE CONFIRM DIALOG ---------------------------------------------- */}
      {/* ------------------------------------------------------------------------ */}

      <Alert
        type={WARNING_TYPE}
        title="Borrar elemento de catalogo"
        content="Estas a punto de borrar un elemento del catálogo, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
              setOpenDeleteDialog(false);
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setOpenDeleteDialog(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onBackdropPress={() => {
          setOpenDeleteDialog(false);
        }}
      />
    </div>
  );
};

export default MasterDataTable;
