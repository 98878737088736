import * as yup from 'yup';

export const validationSchema = (codeReceived) => {
  const yupObject = {
    email: yup
      .string()
      .required('Correo electronico es requerido')
      .email('Formato de correo electrónico inválido'),
  };
  if (codeReceived) {
    yupObject.resetCode = yup.string().required('Código de verificación es requerido');
    yupObject.newPassword = yup
      .string()
      .matches(/\w*[a-z]\w*/, 'La contraseña debe de tener mínimo una letra minúscula')
      .matches(/\w*[A-Z]\w*/, 'La contraseña debe de tener mínimo una letra mayúscula')
      .matches(/\d/, 'La contraseña debe de tener un número')
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'La contraseña debe de tener un caracter especial')
      .min(8, ({ min }) => `La contraseña debe de tener mínimo ${min} caracteres`)
      .required('La contraseña es un campo requerido');
    yupObject.passwordConfirmation = yup
      .string()
      .required('Las contraseñas deben ser iguales')
      .oneOf([yup.ref('newPassword'), null], 'Las contraseñas deben ser iguales');
  }
  return yup.object(yupObject);
};

export const getInitialValues = () => {
  let initialValues = {
    email: '',
    resetCode: '',
    newPassword: '',
  };

  return initialValues;
};
