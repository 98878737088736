import { PROJECT_CONTEXT_KEYS, PROJECT_CONTEXT_KEY } from '../../../Constants/tasksConstants';

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  window.localStorage.setItem(cname, cvalue);
  // document.cookie = newCookie;
};

const getCookie = (cname) => {
  return window.localStorage.getItem(cname);
};

const deleteCookie = (cname) => {
  window.localStorage.removeItem(cname);
};

const storeStateObject = (state) => {
  const keys = Object.keys(state);
  setCookie(PROJECT_CONTEXT_KEYS, JSON.stringify(keys), 1);
  keys.forEach((key) => {
    setCookie(`${PROJECT_CONTEXT_KEY}_${key}`, JSON.stringify({ value: state[key] }));
  });
};

const getStateObject = () => {
  try {
    const keysString = getCookie(PROJECT_CONTEXT_KEYS);
    const keys = JSON.parse(keysString);
    if (keys) {
      return keys.reduce((prev, curr) => {
        try {
          const valueString = getCookie(`${PROJECT_CONTEXT_KEY}_${curr}`);
          const { value } = JSON.parse(valueString);
          return { ...prev, [curr]: value };
        } catch (error) {
          return prev;
        }
      }, {});
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

const removeStateObject = () => {
  try {
    const keysString = getCookie(PROJECT_CONTEXT_KEYS);
    const keys = JSON.parse(keysString);
    if (keys) {
      keys.forEach((k) => deleteCookie(`${PROJECT_CONTEXT_KEY}_${k}`));
    }
    deleteCookie(PROJECT_CONTEXT_KEYS);
  } catch (error) {
    throw Error(error);
  }
};

const loadStoredData = () => {
  return new Promise((res, rej) => {
    try {
      const values = getStateObject();
      res(values);
    } catch (error) {
      console.log('There has been an error calling for the data in the first context call', error);
      rej(error);
    }
  });
};

const createSetAndStoreFunction = (setStateFunction) => {
  const result = (newvalue) => {
    if (newvalue) {
      try {
        storeStateObject(newvalue);
      } catch (error) {
        console.log(error);
      }
    } else {
      removeStateObject();
    }
    setStateFunction(newvalue);
  };
  return result;
};

export { loadStoredData, createSetAndStoreFunction };
