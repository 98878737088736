import MaterialTable from 'material-table';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getWeek, parse, startOfWeek, endOfWeek, format } from 'date-fns';
import { Box, Typography } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import { FUNDREQUEST_STATUS } from '../../../Hooks/useFundRequests';
import { userContext } from '../../../Contexts/UserContext/UserContext';
import { FakeButton } from '../../QualityProfilesScreen/QualityProfilesScreenStyles';

const FundrequestTable = ({ fundRequests, areFundRequestsLoading, refetchFundRequests }) => {
  const { projectId } = useParams();
  const history = useHistory();
  const AxiosInstance = React.useContext(AxiosContext);
  const [user] = React.useContext(userContext);
  const { enqueueSnackbar } = useSnackbar();

  const TABLE_HEADERS = [
    {
      title: 'Proyecto',
      field: 'projectName',
    },
    {
      title: 'Autor',
      field: 'requesterName',
      editComponent: (rowData) => <p>{rowData?.requesterName}</p>,
    },
    {
      title: 'Semana',
      field: 'week',
      customFilterAndSearch: (term, rowData) => {
        const { week, createDate } = rowData;
        const weekNumber =
          Number(week) || getWeek(parse(createDate, BACKEND_DATE_FORMAT, new Date()));
        return Number(term) === Number(weekNumber);
      },
      render: (rowData) => {
        const { createDate, week } = rowData;
        const createDateObject = parse(createDate, BACKEND_DATE_FORMAT, new Date());
        const startOfTheWeek = startOfWeek(createDateObject);
        const endOfTheWeek = endOfWeek(createDateObject);
        const weekNumber = `Semana ${week || getWeek(createDateObject)}`;
        const weekSpan = `${format(startOfTheWeek, BACKEND_DATE_FORMAT)} - ${format(
          endOfTheWeek,
          BACKEND_DATE_FORMAT,
        )}`;
        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography>{weekNumber}</Typography>
            <Typography>{weekSpan}</Typography>
          </Box>
        );
      },
    },
    { title: 'Fecha de solicitud', field: 'createDate' },
    {
      title: 'Status',
      field: 'status',
      lookup: {
        DRAFT: 'BORRADOR',
        REQUESTED: 'SOLICITADO',
        CANCELLED: 'CANCELADO',
        APPROVED: 'APROBADO',
        CLOSED: 'CERRADO',
      },
    },
    { title: 'Total solicitado', field: 'totalRequestedAmount' },
    { title: 'Total aprobado', field: 'totalApprovedAmount' },
  ];

  const actions = [
    {
      icon: 'visibility',
      tooltip: 'Ver detalles',
      iconProps: {
        color: 'secondary',
      },
      onClick: (_event, rowElement) => {
        history.push(`/proyectos/${rowElement.projectId}/solicitudes-costos/${rowElement.id}`);
      },
    },
    {
      icon: () => (
        <FakeButton>
          <Typography variant="h6">NUEVA SOLICITUD</Typography>
        </FakeButton>
      ),
      tooltip: 'Nueva solicitud',
      iconProps: {
        color: 'secondary',
      },
      onClick: async () => {
        try {
          const weekOfTheYear = getWeek(new Date());
          const creationResult = await AxiosInstance.post(
            `/api/project/${projectId}/fund-request`,
            {
              createDate: format(new Date(), BACKEND_DATE_FORMAT),
              projectId,
              status: FUNDREQUEST_STATUS.DRAFT,
              requesterName: user?.userDetails?.name || '',
              projectCosts: [],
              week: weekOfTheYear,
            },
          );
          enqueueSnackbar('Solicitud creada exitosamente', {
            variant: 'success',
            preventDuplicate: true,
          });
          history.push(`/proyectos/${projectId}/solicitudes-costos/${creationResult.data.id}`);
        } catch (error) {
          enqueueSnackbar('Error al crear solicitud, favor de contactar soporte', {
            variant: 'error',
            preventDuplicate: true,
          });
        }
      },
      isFreeAction: true,
      hidden: !projectId,
    },
    (rowElement) => ({
      icon: MonetizationOnIcon,
      tooltip: 'Marcar pagada',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (_event, rowElement) => {
        // FUNDREQUEST_STATUS.CLOSED
        const fundRequest = { ...rowElement };
        delete fundRequest.tableData;
        await AxiosInstance.put(`/api/project/${rowElement.projectId}/fund-request`, {
          ...fundRequest,
          status: FUNDREQUEST_STATUS.CLOSED,
        });
        enqueueSnackbar('Solicitud transferida a pagada exitosamente', {
          variant: 'success',
          preventDuplicate: true,
        });
        refetchFundRequests();
      },
      hidden: rowElement.status !== FUNDREQUEST_STATUS.APPROVED,
    }),
  ];

  const deleteFundRequest = async (oldData) => {
    try {
      const deleteUrl = `/api/project/${oldData.projectId}/fund-request/${oldData.id}`;
      await AxiosInstance.delete(deleteUrl);
      refetchFundRequests();
      enqueueSnackbar('Solicitud de fondos eliminada exitosamenet', {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (error) {
      enqueueSnackbar('Error al eliminar solicitud de costos, contactar soporte', {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return (
    <MaterialTable
      toolbar
      localization={{
        body: {
          editRow: {
            deleteText: '¿Estas seguro de eliminar este elemento?',
          },
        },
      }}
      editable={{
        isDeletable: (rowData) => rowData.status === FUNDREQUEST_STATUS.DRAFT,
        onRowDelete: deleteFundRequest,
      }}
      title={'Presupuestos Solicitados'}
      data={fundRequests}
      columns={TABLE_HEADERS}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 15, 25],
        sorting: true,
        filtering: true,
        search: false,
        paging: true,
      }}
      isLoading={areFundRequestsLoading}
    />
  );
};

export default FundrequestTable;
