import * as yup from 'yup';
import { VEHICULOS } from '../../../Hooks/useAssetData';
import { NO_PROJECT } from '../../../Hooks/useProjectsForCatalogs';

import { validateDateObject } from '../../../Constants/generalConstants';

yup.addMethod(yup.string, 'validateProject', function (args) {
  const { message } = args;

  return this.test('validateProject', message, function (value) {
    const { path, createError } = this;
    return Number(value) || value === NO_PROJECT || createError({ path, message });
  });
});

const basicValidationShape = {
  stockType: yup.string().required('Campo obligatorio'),
  classification: yup.object().shape({
    id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
  }),
  stockId: yup.string().required('Campo obligatorio'),
  equipment: yup.string().required('Campo obligatorio'),
  description: yup.string().required('Campo obligatorio'),
  brand: yup.object().shape({
    id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
  }),
  model: yup.object().shape({
    id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
  }),
  serialNumber: yup.string().nullable(),
  status: yup.object().shape({
    id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
  }),
  warehouse: yup.object().shape({
    id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
  }),
  responsible: yup
    .object()
    .shape({
      id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
    })
    .nullable(),
  group: yup.object().shape({
    id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
  }),
  ema: yup.string().required('Campo obligatorio'),
  amaac: yup.string().required('Campo obligatorio'),
  nickname: yup.string().typeError('Campo obligatorio').nullable(),
  assignedToProject: yup.boolean(),
  project: yup
    .string()
    .validateProject('Campo obligatorio')
    .required('Campo obligatorio')
    .typeError('Campo obligatorio'),
  lastCalibrationDate: null,
  calibrationProvider: null,
  calibrationExpirationDate: null,
  vehicleRecord: yup
    .object()
    .shape({
      id: yup.number().typeError('Campo obligatorio').nullable(),
    })
    .nullable(),
};

const vehicleRecordValidationShape = {
  vehicleRecord: yup.object().shape({
    insuranceProvider: yup
      .object()
      .shape({
        id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
      })
      .nullable(),
    coverage: yup
      .object()
      .shape({
        id: yup.number().required('Campo obligatorio').typeError('Campo obligatorio'),
      })
      .nullable(),
    clause: yup.string().nullable(),
    policyDate: yup.date().when('clause', {
      is: (val) => !!val === true,
      then: yup.date().required('Campo requerido').typeError('Campo requerido'),
      otherwise: yup.date().nullable(),
    }),
    insuranceExpirationDate: yup.date().when('clause', {
      is: (val) => !!val === true,
      then: yup.date().required('Campo requerido').typeError('Campo requerido'),
      otherwise: yup.date().nullable(),
    }),
    circulationCardExpirationDate: yup.date().when('circulationCardNumber', {
      is: (val) => !!val === true,
      then: yup.date().required('Campo requerido').typeError('Campo requerido'),
      otherwise: yup.date().nullable(),
    }),
    circulationCardIssueDate: yup.date().when('circulationCardNumber', {
      is: (val) => !!val === true,
      then: yup.date().required('Campo requerido').typeError('Campo requerido'),
      otherwise: yup.date().nullable(),
    }),
    circulationCardNumber: yup.string(),
    lastVerificationDate: yup.date().when('verificationSticker', {
      is: (val) => !!val === true,
      then: yup.date().required('Campo requerido').typeError('Campo requerido'),
      otherwise: yup.date().nullable(),
    }),
    nextVerificationDate: yup.date().when('verificationSticker', {
      is: (val) => !!val === true,
      then: yup.date().required('Campo requerido').typeError('Campo requerido'),
      otherwise: yup.date().nullable(),
    }),
    verificationSticker: yup.string().typeError('Campo obligatorio'),
  }),
  plate: yup.string().required('Campo obligatorio'),
};

export const validationSchema = yup.object().shape(basicValidationShape);

export const getValidationSchema = (stockType) => {
  switch (stockType) {
    case VEHICULOS:
      return yup.object().shape({ ...basicValidationShape, ...vehicleRecordValidationShape });
    default:
      return yup.object().shape(basicValidationShape);
  }
};

export const getInitialValues = (currentElement = {}, otherValues) => {
  const { tableConfiguration, projectId, stockType: otherStockType } = otherValues;

  const {
    stockType = 'EQUIPO_DE_COMPUTO',
    classification = { id: null },
    stockId = '',
    equipment = '',
    description = '',
    brand = { id: null },
    model = { id: null },
    serialNumber = '',
    status = { id: null },
    warehouse = { id: null },
    responsible = { id: null },
    group = { id: null },
    ema = 'NO',
    amaac = 'NO',
    nickname = '',
    plate = '',
    assignedToProject = false,
    projects = [],
    lastCalibrationDate = null,
    calibrationProvider = null,
    calibrationExpirationDate = null,
    vehicleRecord = {},
  } = currentElement || {};

  const initialValues = {
    stockType,
    classification,
    stockId,
    equipment,
    description,
    brand,
    model,
    serialNumber,
    status,
    warehouse,
    responsible,
    group,
    ema,
    amaac,
    nickname: nickname,
    plate,
    assignedToProject,
    projects,
    lastCalibrationDate,
    calibrationProvider,
    vehicleRecord,
    ...currentElement,
    calibrationExpirationDate: validateDateObject(calibrationExpirationDate),
  };

  if (tableConfiguration?.stockType) {
    initialValues.stockType = tableConfiguration?.stockType;
  }

  if (projectId) {
    initialValues.project = projectId;
  } else {
    initialValues.project = projects && projects[0] ? projects[0].id : NO_PROJECT;
  }

  if (initialValues.project !== NO_PROJECT) {
    initialValues.warehouse = { id: 877 };
  }

  if (stockType === 'VEHICULOS') {
    initialValues.plate = plate || '';
  }

  if (otherStockType) {
    initialValues.stockType = otherStockType;
    if (otherStockType === VEHICULOS) {
      const {
        insuranceProvider = { id: null },
        coverage = { id: null },
        clause = '',
        policyDate = null,
        insuranceExpirationDate = null,
        circulationCardExpirationDate = null,
        circulationCardIssueDate = null,
        circulationCardNumber = '',
        lastVerificationDate = null,
        nextVerificationDate = null,
        verificationSticker = '',
        endorsement = '',
        policyIndex = '',
      } = vehicleRecord || {};

      initialValues.vehicleRecord = {
        insuranceProvider,
        coverage,
        clause,
        policyDate: validateDateObject(policyDate),
        insuranceExpirationDate: validateDateObject(insuranceExpirationDate),
        circulationCardExpirationDate: validateDateObject(circulationCardExpirationDate),
        circulationCardIssueDate: validateDateObject(circulationCardIssueDate),
        lastVerificationDate: validateDateObject(lastVerificationDate),
        nextVerificationDate: validateDateObject(nextVerificationDate),
        circulationCardNumber,
        verificationSticker,
        endorsement,
        policyIndex,
      };
    }
  }

  return initialValues;
};
