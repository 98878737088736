import { Button, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import Alert, { SUCCESS_TYPE } from '../../Components/Alert/Alert';
import DataCardGrantSelection from '../../Components/DataCards/DataCardGrantSelection';
import { PROJECT_GRANTS } from '../../Constants/grantsModules';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { ProjectContext } from '../../Contexts/ProjectContext/ProjectContext';
import { useFetch } from '../../Hooks/useFetch';
import { userContext } from '../../Contexts/UserContext/UserContext';

export const GIVE_GRANT_TYPE = 'GIVE_GRANT_TYPE';
export const REMOVE_GRANT_TYPE = 'REMOVE_GRANT_TYPE';
export const FIRST_ASSIGNMENT_TYPE = 'FIRST_ASSIGNMENT_TYPE';
export const GIVE_MODULE_ACCESS_TYPE = 'GIVE_MODULE_ACCESS_TYPE';
export const REMOVE_MODULE_ACCESS_TYPE = 'REMOVE_MODULE_ACCESS_TYPE';
export const PROFILE_LOAD_TYPE = 'PROFILE_LOAD_TYPE';

const ProjectGrantsScreen = () => {
  let { username, project } = useParams();
  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [userProjectGrants, setUserProjectGrants] = useState({});

  const [projectState, setProjectState] = useContext(ProjectContext);

  const { data: projectData, refetch: refreshProjectData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${project}`,
  });

  const { projectGrants = [] } = projectState || {};
  const { userDetails = {} } = userState || {};
  const [generalAccessUser, setGeneralAccessUser] = useState(false);

  useEffect(() => {
    if (userDetails) {
      console.log(userDetails);
      const projectGrants = (userDetails?.profile?.grants || [])
        .filter((grant) => grant.module === 'PROYECTOS')
        .map((grant) => grant.name);
      const permiso = projectGrants.includes('ACCESO GLOBAL A PERMISOS DE PROYECTO');
      setGeneralAccessUser(permiso);
    }
  }, [userDetails]);

  useEffect(() => {
    if (projectData) {
      setProjectState(projectData);

      const { projectGrants } = projectState;

      let tempGrants = {};
      projectGrants.forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });
      setUserProjectGrants(tempGrants);
    }
  }, [projectData]);

  const getNameFromFlag = (flag) => {
    const grant = PROJECT_GRANTS.find((grant) => grant.flag === flag);
    return grant.name;
  };
  const getIdFromFlag = (flag) => {
    const grant = PROJECT_GRANTS.findIndex((grant) => grant.flag === flag);
    return grant;
  };

  const getUserGrants = () =>
    projectGrants
      .filter((grant) => grant.user.id === Number(username))
      .map((grant) => {
        return {
          name: getNameFromFlag(grant.grantName),
          module: PROJECT_GRANTS[0].module,
          id: getIdFromFlag(grant.grantName),
          flag: grant.grantName,
        };
      });

  const getUserData = () => {
    const userGrants = projectGrants.filter((grant) => grant.user.id === Number(username));
    const { user } = userGrants.pop() || {};
    return user;
  };

  const [grantsState, grantsDispatch] = useReducer((state, action) => {
    const { type, targetGrant } = action;
    switch (type) {
      case GIVE_GRANT_TYPE:
        return [...state, targetGrant];
      case REMOVE_GRANT_TYPE:
        return state.filter((grant) => grant.flag !== targetGrant.flag);
      case FIRST_ASSIGNMENT_TYPE:
        break;
      case GIVE_MODULE_ACCESS_TYPE:
        return PROJECT_GRANTS.map((grant, grantIndex) => ({ ...grant, id: grantIndex }));
      case REMOVE_MODULE_ACCESS_TYPE:
        return [];
      case PROFILE_LOAD_TYPE:
        break;
      default:
        break;
    }
  }, getUserGrants());

  const uploadPermits = async () => {
    const currentProjectGrants = projectGrants.filter(
      (grant) => grant.user.id === Number(username),
    );
    const removing = currentProjectGrants.filter((grant) => {
      const grantsNames = grantsState.map((grant) => grant.flag);
      return !grantsNames.includes(grant.grantName);
    });
    const adding = grantsState.filter((grant) => {
      const grantsName = currentProjectGrants.map((grant) => grant.grantName);
      return !grantsName.includes(grant.flag);
    });

    for (let i = 0; i < removing.length; i++) {
      const { id } = removing[i];
      await AxiosInstance.delete(`api/project/${project}/grant/${id}`);
    }

    for (let i = 0; i < adding.length; i++) {
      const { flag } = adding[i];
      console.log(flag);
      await AxiosInstance.post(`api/project/${project}/grant/`, {
        grantName: flag,
        user: {
          id: Number(username),
        },
      });
    }
    refreshProjectData();
    setOpenConfirmationDialog(true);
  };

  return (
    <>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
        <Grid>
          <h1>Permisos de {getUserData().name}</h1>
        </Grid>
        <DataCardGrantSelection
          title={PROJECT_GRANTS[0].module}
          grantsCatalog={PROJECT_GRANTS.map((grant, grantIndex) => ({ ...grant, id: grantIndex }))}
          grantsModuleState={grantsState}
          grantsDispatch={grantsDispatch}
        />
        <Grid item>
          <Grid container direction="row" justify="flex-end" spacing={3}>
            <Grid item>
              {userProjectGrants?.EDIT_PERMISSIONS?.includes(userDetails?.username) ||
              generalAccessUser ? (
                <Button variant="contained" color="primary" onClick={uploadPermits}>
                  Guardar
                </Button>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  window.history.back();
                }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Alert
        type={SUCCESS_TYPE}
        title="Operación exitosa"
        content="Se han actualizado los privilegios del usuario correctamente"
        actions={[
          {
            text: 'Finalizar',
            onClick: () => {
              window.history.back();
            },
          },
        ]}
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onBackdropPress={() => {
          setOpenConfirmationDialog(false);
        }}
      />
    </>
  );
};

export default withNavigation(ProjectGrantsScreen);
