import styled from 'styled-components';

export const DataCardMainContainer = styled.div`
  border: solid #f0f0f0 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const OptionsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ProyectTitle = styled.h1`
  font-size: 18px;
  font-weight: 300;
  color: #707070;
  text-align: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
`;

export const MoreInfoLink = styled.a`
  color: '#AAAAFF';
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: underline;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
`;
