import * as yup from 'yup';
import { NO_PROJECT } from '../../../Hooks/useProjectsForCatalogs';

export const validationSchema = yup.object().shape({
  AMAAC: yup.string().required('Campo Obligatorio'),
  EMA: yup.string().required('Campo Obligatorio'),
  brand: yup.string().required('Campo Obligatorio'),
  clasification: yup.string().required('Campo Obligatorio'),
  description: yup.string().required('Campo Obligatorio'),
  equipment: yup.string().required('Campo Obligatorio'),
  inventoryNumber: yup.string().required('Campo Obligatorio'),
  model: yup.string().required('Campo Obligatorio'),
  serialNumber: yup.string(),
  status: yup.string().required('Campo Obligatorio'),
});

export const getInitialValues = (currentElement = {}, otherValues) => {
  const { tableConfiguration, projectId } = otherValues;
  const {
    AMAAC,
    EMA,
    description,
    equipment,
    inventoryNumber,
    nickname,
    serialNumber,
    clasificationObject,
    brandObject,
    modelObject,
    statusObject,
    warehouseObject,
    responsableObject,
    setObject,
    inventoryType,
    projects,
  } = currentElement || {};

  const { stockType } = tableConfiguration || {};

  const initialValues = {
    AMAAC,
    EMA,
    brand: brandObject?.id,
    clasification: clasificationObject?.id,
    description,
    equipment,
    inventoryNumber,
    model: modelObject?.id || '',
    nickname,
    responsable: responsableObject?.id || -1,
    serialNumber,
    set: setObject?.id,
    warehouse: warehouseObject?.id || 877,
    status: statusObject?.id,
    inventoryType: stockType || inventoryType,
  };

  if (projectId) {
    initialValues.project = projectId;
  } else {
    initialValues.project = projects && projects[0] ? projects[0].id : NO_PROJECT;
  }

  if (stockType === 'VEHICULOS') {
    initialValues.plate = '';
  }

  return initialValues;
};
