import React, { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import {
  DataElementContainer,
  DescriptionContainer,
  MainDataContainer,
  SampleDetailContainer,
  SamplesDataContainer,
  CustomInput,
  ButtonContainer,
} from './NewLabworkScreenStyles';
import ItemListTable from '../../Components/ItemListTable/ItemListTable';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import Alert, { SUCCESS_TYPE, WARNING_TYPE } from '../../Components/Alert/Alert';

const useStyles = makeStyles(() => ({
  MainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  MainDataContainer: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  HeaderTitle: {
    fontWeight: '400',
    margin: '5px',
    padding: '5px',
  },
}));

const NewLabworkScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const AxiosInstance = useContext(AxiosContext);
  const [createdLabTestOrder, setCreatedLabTestOrder] = useState(null);
  const [successAlertState, setSuccessAlertState] = useState(false);
  const [creationErrorWarningStatus, setCreationErrorWarningStatus] = useState(false);
  const { labTestId } = useParams();
  const { search } = useLocation();
  const { action } = queryString.parse(search.replace(/\?/g, ''));

  const { data: clientData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/client/list`,
  });
  const { data: projectsData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/list`,
  });

  const {
    data: labTestOrder,
    loadingLabTestOrder,
    updateUrl: updateUrlLabtest,
  } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/lab-test-order/${labTestId}`,
    skip: !labTestId,
  });

  useEffect(() => {
    if (labTestId) {
      updateUrlLabtest(`/api/lab-test-order/${labTestId}?action=edit`);
    }
  }, [labTestId]);

  useEffect(() => {
    if (labTestOrder) {
      setCreatedLabTestOrder(labTestOrder);
    }
  }, [labTestOrder]);

  const formatClients = () => {
    return (clientData || [])
      .sort((a, b) => {
        if (a.clientName > b.clientName) {
          return 1;
        }
        if (b.clientName > a.clientName) {
          return -1;
        }
        return 0;
      })
      .map((client) => ({ itemLabel: client.clientName, itemValue: client.id }));
  };

  const formatProjects = () => {
    return (projectsData || [])
      .sort((a, b) => {
        if (a.projectName > b.projectName) {
          return 1;
        }
        if (a.projectName < b.projectName) {
          return -1;
        }
        return 0;
      })
      .map((project) => ({ itemLabel: project.projectName, itemValue: project.id }));
  };

  const handleCreateNewLabWork = async (values) => {
    try {
      const { itemValue: clientId } = values.client;
      const { itemValue: projectId } = values.project;
      const method = action === 'edit' ? 'put' : 'post';
      const payload = {
        ...values,
        client: { id: clientId },
        project: { id: projectId },
      };
      if (action === 'edit') {
        payload.orderNumber = createdLabTestOrder.orderNumber;
      }
      console.log(payload);
      const { data: postResult } = await AxiosInstance[method]('/api/lab-test-order', payload);
      setCreatedLabTestOrder(postResult);
      setSuccessAlertState(true);
    } catch {
      setCreationErrorWarningStatus(true);
    }
  };

  const getInitialValues = () => {
    const formatedClient = {
      itemValue: createdLabTestOrder?.client?.id,
      itemLabel: createdLabTestOrder?.client?.clientName,
    };
    const formatedProject = {
      itemValue: createdLabTestOrder?.project?.id,
      itemLabel: createdLabTestOrder?.project?.projectName,
    };
    const init = {
      client: createdLabTestOrder?.client ? formatedClient : null,
      project: createdLabTestOrder?.project ? formatedProject : null,
      description: createdLabTestOrder?.description || '',
    };
    return init;
  };

  return (
    <>
      <div className={classes.MainContainer}>
        <MainDataContainer>
          <h1 className={classes.HeaderTitle}>Nueva solicitud de laboratorio</h1>
        </MainDataContainer>
        <SamplesDataContainer>
          {labTestId === 'new' || (createdLabTestOrder && !loadingLabTestOrder) ? (
            <DescriptionContainer>
              <h3 className={classes.HeaderTitle}>Datos Generales:</h3>
              <Formik
                onSubmit={(values) => {
                  handleCreateNewLabWork(values);
                }}
                initialValues={getInitialValues()}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <Field name="client">
                      {(props) => (
                        <DataElementContainer>
                          <Autocomplete
                            id="client-select"
                            options={formatClients()}
                            getOptionLabel={(option) => option.itemLabel}
                            renderInput={(params) => (
                              <TextField {...params} label="Cliente" variant="outlined" />
                            )}
                            onChange={(event, value) => {
                              const { field } = props;
                              setFieldValue(field.name, value);
                            }}
                            value={values[props?.field?.name]}
                            // value={props?.field?.value?.id ? {itemValue: props?.field?.value?.id, itemLabel: }}
                            disabled={action === 'edit'}
                          />
                        </DataElementContainer>
                      )}
                    </Field>
                    <Field name="project">
                      {(props) => (
                        <DataElementContainer>
                          <Autocomplete
                            id="client-select"
                            options={formatProjects()}
                            getOptionLabel={(option) => option.itemLabel}
                            renderInput={(params) => (
                              <TextField {...params} label="Proyecto" variant="outlined" />
                            )}
                            onChange={(event, value) => {
                              const { field } = props;
                              setFieldValue(field.name, value);
                            }}
                            defaultValue={values[props?.field?.name]}
                            disabled={action === 'edit'}
                          />
                        </DataElementContainer>
                      )}
                    </Field>
                    <h3 className={classes.HeaderTitle}>Comentarios:</h3>
                    <Field name="description">
                      {(props) => (
                        <DataElementContainer flex={1}>
                          <CustomInput
                            placeholder="Describa los estudios y metodos deseados para aplicarse a las muestras"
                            rowsMax={20}
                            rows={20}
                            value={values[props?.field?.name]}
                            onChange={(event) => {
                              const newValue = event.target.value;
                              const {
                                field: { name },
                              } = props;
                              setFieldValue(name, newValue);
                            }}
                            defaultValue={values[props?.field?.name]}
                          />
                        </DataElementContainer>
                      )}
                    </Field>
                    <ButtonContainer>
                      <Button
                        style={{ margin: '5px' }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {action === 'edit' ? 'Editar' : 'Guardar'}
                      </Button>
                      <Button
                        style={{ margin: '5px' }}
                        variant="contained"
                        color="secondary"
                        onClick={() => history.push('/laboratorio')}
                      >
                        {action === 'edit' ? 'Regresar' : 'Cancelar'}
                      </Button>
                    </ButtonContainer>
                  </Form>
                )}
              </Formik>
            </DescriptionContainer>
          ) : (
            <></>
          )}
          {createdLabTestOrder ? (
            <SampleDetailContainer>
              <h3 className={classes.HeaderTitle}>Muestras:</h3>
              <ItemListTable createdLabTestOrder={createdLabTestOrder} />
            </SampleDetailContainer>
          ) : (
            <></>
          )}
        </SamplesDataContainer>
      </div>
      <Alert
        type={SUCCESS_TYPE}
        title={
          `El nuevo trabajo de laboratorio creado, FOLIO: ${createdLabTestOrder?.orderNumber}` || ''
        }
        content="Por favor procede a agregar las muestras"
        actions={[
          {
            text: 'OK',
            onClick: () => {
              setSuccessAlertState(false);
              history.push(`/laboratorio/${createdLabTestOrder?.orderNumber}?action=edit`);
            },
          },
        ]}
        open={successAlertState}
        onClose={() => setSuccessAlertState(false)}
        onBackdropPress={() => {
          setSuccessAlertState(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Error"
        content="Ha ocurrido un error intentando crear la orden de laboratorio, verifique la información o intente más tarde"
        actions={[
          {
            text: 'OK',
            onClick: () => {
              setCreationErrorWarningStatus(false);
            },
          },
        ]}
        open={creationErrorWarningStatus}
        onClose={() => setCreationErrorWarningStatus(false)}
        onBackdropPress={() => {
          setCreationErrorWarningStatus(false);
        }}
      />
    </>
  );
};

export default NewLabworkScreen;
