import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  margin-top: 0;
`;

export const IconContainer = styled.div`
  flex: 1;
  font-size: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AmountContainer = styled.div`
  flex: 1;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const AmountText = styled.h1`
  font-size: 80px;
  margin: 0;
`;
export const CardTitle = styled.h1`
  font-size: 30px;
  margin: 20px;
  text-align: center;
  border-bottom: solid #707070 1px;
`;
