/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import { getInitialValues } from './validation';
// Icons
import { useStyles } from './materialStyles';
import Checkbox from '../../../Components/FormFields/FormikCheckboxes';

function BackupFormulary({ setClose, currentFilters, setColumnFields }) {
  const styles = useStyles();

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentFilters)}
        onSubmit={async (values) => {
          setColumnFields(values);
        }}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center">
                <Grid item xs={12} md={4}>
                  <Field name="inventoryType">
                    {(props) => (
                      <Checkbox
                        formikName={'inventoryType'}
                        label={'Tipo de inventario'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="clasification">
                    {(props) => (
                      <Checkbox
                        formikName={'clasification'}
                        label={'Clasificación'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="inventoryNumber">
                    {(props) => (
                      <Checkbox
                        formikName={'inventoryNumber'}
                        label={'# de Inventario'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="project">
                    {(props) => (
                      <Checkbox formikName={'project'} label={'Proyecto'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="equipment">
                    {(props) => (
                      <Checkbox formikName={'equipment'} label={'Equipo'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="description">
                    {(props) => (
                      <Checkbox
                        formikName={'description'}
                        label={'Descripción'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="brand">
                    {(props) => (
                      <Checkbox formikName={'brand'} label={'Marca'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="model">
                    {(props) => (
                      <Checkbox formikName={'model'} label={'Modelo'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="serialNumber">
                    {(props) => (
                      <Checkbox
                        formikName={'serialNumber'}
                        label={'# Serie'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="warehouse">
                    {(props) => (
                      <Checkbox
                        formikName={'warehouse'}
                        label={'Almacén'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="status">
                    {(props) => (
                      <Checkbox formikName={'status'} label={'Status'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="responsable">
                    {(props) => (
                      <Checkbox
                        formikName={'responsable'}
                        label={'Responsable'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="nickname">
                    {(props) => (
                      <Checkbox formikName={'nickname'} label={'Apodo'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="set">
                    {(props) => (
                      <Checkbox formikName={'set'} label={'Conjunto'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="EMA">
                    {(props) => (
                      <Checkbox formikName={'EMA'} label={'EMA'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="AMAAC">
                    {(props) => (
                      <Checkbox formikName={'AMAAC'} label={'AMAAC'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={setClose}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default BackupFormulary;
