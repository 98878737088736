import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { userContext } from './../Contexts/UserContext/UserContext';
import QualityLibraryScreen from './QualityLibraryScreen';
import NewPassWordLogin from './NewPassword/NewPassword';
import UserCrudScreen from './UserCrudScreen/UserCrudScreen';
import DownloadLogScreen from './DownloadLogScreen/DownloadLogScreen';
import InventoryScreen from './InventoryScreen/InventoryTable';
import ProjectsScreen from './AllProjectsScreen/ProjectsScreen';
import SingleProjectScreen from './SingleProjectScreen/SingleProjectScreen';
import {
  ConceptosScreen,
  EstimacionesScreen,
  InventariosScreen,
  RecursosHumanosScreen,
  EvidenciasScreen,
} from './ProjectCatalogScreens';
import MasterDataScreen from './MasterDataScreen';
import HumanResourcesScreen from './HumanResourcesScreen/HumanResourcesScreen';
import PermisosScreen from './PermisosScreen';
import ProgramScreen from './ProjectCatalogScreens/ProgramScreen';
import PaymentScreen from './PaymentScreen/PaymentScreen';
import ProfilesScreen from './ProfilesScreen';
import QualityProfilesScreen from './QualityProfilesScreen';
import QualityProfilesEditScreen from './QualityProfileEditScreen';
import HomeScreen from './HomeScreen';
import GrantedRoute from './../Containers/GrantedRoute';
import UserDashboardScreen from './UserDashboardScreen/UserDashboardScreen';
import ErrorScreen from './ErrorScreen';
import ProjectUsersScreen from './ProjectUsersScreen/ProjectUsersScreen';
import ProjectGrantsScreen from './ProjectGrantsScreen/ProjectGrantsScreen';
import CostosScreen from './CostsScreen/CostsScreen';
import ClientsScreen from './ClientsScreen';
import LaboratoryScreen from './LaboratoryScreen/LaboratoryScreen';
import UserLogScreen from './UserLogScreen/UserLogScreen';
import QualityDocumentsScreen from './QualityDocumentsScreen/QualityDocumentsScreen';
// eslint-disable-next-line import/no-named-as-default
import ProjectResume from './ProjectResume/ProjectResume';
import ProjectCloudScreen from './ProjectCloudScreen';
import ShoppingMap from './ShoppingCartScreen/ShoppingMap';
import ShoppingMapHistory from './ShoppingMapHistory/ShoppingMapHistory';
import InventoryHistoryDetails from './InventoryHistoryDetails/InventoryHistoryDetails';
import NewStockElementScreen from './NewStockElementScreen/NewStockElementScreen';
import ShoppingCartOrderView from './ShoppingCartOrderView/ShoppingCartOrderView';
import VehicleStateScreen from './VehicleStateScreen/VehicleStateScreen';
import CostSupervisionScreen from './CostSupervisionScreen';
import CostSupervisionEditionScreen from './CostSupervisionEditionScreen';
import ProjectHistoricCloudScreen from './ProjectCloudScreen/ProjectHistoricCloudScreen';
import HistoricProjectScreen from './HistoricProjectsScreen/HistoricProjectScreen';
import StockCsvScreen from './StockCsvScreen/StockCsvScreen';
import LaboratoryReportScreen from './LaboratoryScreen/LaboratoryReportScreen';
import InventoryGeneralSearch from './InventoryScreen/InventoryGeneralSearch';
import HomeScreenConfig from './HomeScreenConfig/HomeScreenConfig';
import CreateEstimationForm from './ProjectCatalogScreens/EstimacionesScreen/CreateEstimationForm/CreateEstimationForm';
import ProjectPayments from './PaymentScreen/ProjectPayments';
import { ROSHARE_GRANTS } from '../.gen/grantsAndModules.generated';
import * as moduleGrants from '../Constants/grantsModules';

const AppRouter = () => {
  const [userState] = useContext(userContext);
  const { accessToken } = userState || {};

  const getSignedOutRoutes = () => {
    return (
      <>
        <Route path="/" component={NewPassWordLogin} />
      </>
    );
  };

  const getAppRoutes = () => {
    return (
      <>
        <Switch>
          <GrantedRoute
            path="/historicos/:projectId"
            component={ProjectHistoricCloudScreen}
            grantModule={ROSHARE_GRANTS.HISTORICOS.MAIN_MODULE}
          />
          <GrantedRoute
            path="/historicos"
            component={HistoricProjectScreen}
            grantModule={ROSHARE_GRANTS.HISTORICOS.MAIN_MODULE}
          />
          <GrantedRoute
            path="/biblioteca-calidad"
            component={QualityLibraryScreen}
            grantModule={ROSHARE_GRANTS.BIBLIOTECA_DE_CALIDAD.MAIN_MODULE}
          />
          <Route path="/documentos-calidad" component={QualityDocumentsScreen} />
          <GrantedRoute
            path="/master-data"
            component={MasterDataScreen}
            grantModule={ROSHARE_GRANTS.DATOS_CATALOGOS.MAIN_MODULE}
          />
          <GrantedRoute
            path="/registro-descargas"
            component={DownloadLogScreen}
            grantModule={moduleGrants.REGISTRO_DESCARGAS}
          />
          <GrantedRoute
            path="/inventarios/logs/:inventoryId"
            component={InventoryHistoryDetails}
            grantModule={moduleGrants.INVENTARIOS}
          />
          <GrantedRoute
            path="/inventarios/nuevo"
            component={NewStockElementScreen}
            grantModule={moduleGrants.INVENTARIOS}
          />
          <GrantedRoute
            path="/inventarios/editar/:stockId"
            component={NewStockElementScreen}
            grantModule={moduleGrants.INVENTARIOS}
          />
          <GrantedRoute
            path="/inventarios/detalle-vehiculos"
            component={VehicleStateScreen}
            grantModule={moduleGrants.INVENTARIOS}
          />
          <GrantedRoute
            path="/inventarios/carga-masiva"
            component={StockCsvScreen}
            grantModule={moduleGrants.INVENTARIOS}
          />
          <GrantedRoute
            path="/inventarios/general"
            component={InventoryGeneralSearch}
            grantModule={moduleGrants.INVENTARIOS}
            specificGrants={['VER CSV']}
          />
          <GrantedRoute
            path="/inventarios"
            component={InventoryScreen}
            grantModule={moduleGrants.INVENTARIOS}
          />
          <GrantedRoute
            path="/recursos-humanos"
            exact
            component={HumanResourcesScreen}
            grantModule={moduleGrants.RECURSOS_HUMANOS}
          />
          <GrantedRoute
            path="/recursos-humanos/:employeeId"
            component={UserDashboardScreen}
            grantModule={moduleGrants.RECURSOS_HUMANOS}
          />
          <GrantedRoute
            path="/proyectos"
            exact
            component={ProjectsScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/clientes"
            exact
            component={ClientsScreen}
            grantModule={moduleGrants.CLIENTES}
          />
          <GrantedRoute
            path="/cobros"
            exact
            component={PaymentScreen}
            grantModule={moduleGrants.COBROS}
          />
          <GrantedRoute
            path="/cobros/:project"
            exact
            component={ProjectPayments}
            grantModule={moduleGrants.COBROS}
          />
          <GrantedRoute
            path="/proyectos/:project"
            exact
            component={SingleProjectScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/conceptos"
            component={ConceptosScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <Route
            path="/proyectos/:project/estimaciones/crear"
            exact
            component={CreateEstimationForm}
          />
          <GrantedRoute
            path="/proyectos/:project/estimaciones/:estimationId"
            component={CreateEstimationForm}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/estimaciones"
            component={EstimacionesScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/inventarios/nuevo"
            component={NewStockElementScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/inventarios/editar/:stockId"
            component={NewStockElementScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/inventarios"
            component={InventariosScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/recursos-humanos"
            component={RecursosHumanosScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/evidencias"
            component={EvidenciasScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/programa"
            component={ProgramScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/usuarios-y-permisos"
            exact
            component={ProjectUsersScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/archivos-de-trabajo"
            exact
            component={ProjectCloudScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <GrantedRoute
            path="/proyectos/:project/usuarios-y-permisos/:username"
            component={ProjectGrantsScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <Route
            path="/proyectos/:projectId/solicitudes-costos/:requestId"
            component={CostSupervisionEditionScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <Route
            path="/proyectos/:projectId/solicitudes-costos"
            component={CostSupervisionScreen}
            grantModule={moduleGrants.PROYECTOS}
          />
          <Route
            path="/solicitudes-costos/:requestId"
            component={CostSupervisionEditionScreen}
            grantModule={moduleGrants.COSTOS}
          />
          <GrantedRoute
            path="/solicitudes-costos/"
            component={CostSupervisionScreen}
            grantModule={moduleGrants.SOLICITUDES_COSTOS}
            specificGrants={['APROBAR MONTOS']}
          />
          <GrantedRoute
            path="/sabana/:projectId"
            component={ProjectResume}
            grantModule={moduleGrants.PROYECTOS}
          />
          <Route path="/laboratorio" exact component={LaboratoryReportScreen} />
          <Route path="/laboratorio/:labTestId" exact component={LaboratoryScreen} />
          <GrantedRoute
            path="/carrito"
            exact
            component={ShoppingMap}
            grantModule={moduleGrants.CARRITO_DE_COMPRAS}
          />
          <GrantedRoute
            path="/historial-carrito"
            exact
            component={ShoppingMapHistory}
            grantModule={moduleGrants.CARRITO_DE_COMPRAS}
            specificGrants={['HISTORICOS']}
          />
          <GrantedRoute
            path="/historial-carrito/view"
            exact
            component={ShoppingCartOrderView}
            grantModule={moduleGrants.CARRITO_DE_COMPRAS}
            specificGrants={['HISTORICOS']}
          />
          <GrantedRoute
            path="/permisos/:profile"
            component={PermisosScreen}
            grantModule={moduleGrants.PERFILES}
          />
          <GrantedRoute
            path="/perfiles"
            component={ProfilesScreen}
            grantModule={moduleGrants.PERFILES}
          />
          <GrantedRoute
            path="/perfiles-calidad/:profile"
            component={QualityProfilesEditScreen}
            grantModule={moduleGrants.PERFILES}
          />
          <GrantedRoute
            path="/usuarios/qa-docs/:userId"
            component={QualityProfilesEditScreen}
            grantModule={moduleGrants.USUARIOS}
            specificGrants={['EDITAR SISTEMAS DE GESTION']}
          />
          <GrantedRoute
            path="/perfiles-calidad"
            component={QualityProfilesScreen}
            grantModule={moduleGrants.PERFILES}
          />
          <GrantedRoute
            path="/usuarios"
            exact
            component={UserCrudScreen}
            grantModule={moduleGrants.USUARIOS}
          />
          <GrantedRoute
            path="/usuarios/log/:userId"
            exact
            component={UserLogScreen}
            grantModule={moduleGrants.USUARIOS}
          />
          <GrantedRoute
            path="/costos"
            exact
            component={CostosScreen}
            grantModule={moduleGrants.COSTOS}
          />
          <GrantedRoute
            path="/costos/:project"
            exact
            component={CostosScreen}
            grantModule={moduleGrants.COSTOS}
          />
          <GrantedRoute
            path="/costos/:project/:week"
            exact
            component={CostosScreen}
            grantModule={moduleGrants.COSTOS}
          />
          <Route
            path="/anuncio-home"
            exact
            component={HomeScreenConfig}
            grantModule={moduleGrants.ANUNCIOS}
          />
          <Route path="/404" component={ErrorScreen} />
          <Route path="/" exact component={HomeScreen} />
          <Redirect to="/404" />
        </Switch>
      </>
    );
  };

  return (
    <div>
      <Router>
        <Switch>{accessToken ? getAppRoutes() : getSignedOutRoutes()}</Switch>
      </Router>
    </div>
  );
};

export default AppRouter;
