import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    flexDirection: 'column ',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
}));
