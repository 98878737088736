import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const SearchFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 10;
`;

export const SearchFormDiv = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 2.5px;
  > * {
    margin: 2.5px;
  }
  width: 25vw;
  margin-bottom: 5px;
`;

export const BasicSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 2.5px;
  }
`;
