import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import Dialog from './../../Containers/GenericDialog';
import FolderComponent from './FolderComponent/FolderComponent';
import FoldersContainer from './FolderContainer/FolderContainer';
import {
  ButtonsContainer,
  HeaderContainer,
  HeaderTitle,
  PCSHeaderContainer,
  ProjectCloudScreenMainContainer,
} from './ProjectCloudScreenStyles';
import axios from './../../Utils/AxiosS3Instance';
import FileComponent from './FileComponent/FileComponent';
import NewFolderForm from './NewFolderForm/NewFolderForm';
import Dropzone from '../../Components/DropZone';
import { useFetch } from '../../Hooks/useFetch';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { userContext } from '../../Contexts/UserContext/UserContext';

const FILES_BUCKET = 'roshare-projects-cloud';

const ProjectCloudScreen = () => {
  const axiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);
  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};

  const { project } = useParams();
  const history = useHistory();

  const [uploadPath, setUploadPath] = useState([project]);
  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [userProjectGrants, setUserProjectGrants] = useState(null);
  const [generalAccess, setGeneralAccess] = useState(false);

  const { data: projectData } = useFetch({
    initialUrl: `/api/project/${project}`,
    axiosInstance: axiosInstance,
  });

  useEffect(() => {
    if (projectData) {
      const { projectName, projectGrants } = projectData;
      setProjectName(projectName);

      const users = projectGrants.reduce((accumulator, currentGrant) => {
        if (!accumulator.includes(currentGrant.user.username)) {
          accumulator.push(currentGrant.user.username);
        }
        return accumulator;
      }, []);

      let tempGrants = {};
      projectGrants.forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });

      setUserProjectGrants(tempGrants);
      console.log(grants);
      const _generalAccess = grants
        .filter((grant) => grant.module === 'PROYECTOS')
        .map((grant) => grant.name)
        .includes('VER TODOS LOS PROYECTOS');
      setGeneralAccess(_generalAccess);
      console.log(users, tempGrants, _generalAccess);
    }
  }, [projectData]);

  const {
    data: elementFiles,
    updateParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: { bucket: FILES_BUCKET, path: `${project}/` },
  });

  const { data: baseFiles } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: { bucket: FILES_BUCKET },
  });

  useEffect(() => {
    const newParams = {
      bucket: FILES_BUCKET,
      path: `${uploadPath.join('/').replace(/\/\//g, '/')}/`,
    };
    updateParams(newParams);
  }, [uploadPath]);

  useEffect(() => {
    if (baseFiles) {
      const { folders } = baseFiles;
      const folderNames = folders.map((folder) => folder.key.replace(/\//g, '').toLowerCase());
      console.log(project);
      if (!folderNames.includes(project)) {
        const bodyForm = new FormData();
        bodyForm.append('bucket', FILES_BUCKET);
        bodyForm.append('name', project);
        bodyForm.append('isFolder', true);
        axios.post('/s3-object', bodyForm);
      }
    }
  }, [baseFiles]);

  const createFolder = async (params) => {
    const { folderName } = params;
    const bodyForm = new FormData();
    bodyForm.append('bucket', FILES_BUCKET);
    const path = uploadPath.join('/').replace(/\/\//g, '/');
    bodyForm.append('path', path);
    bodyForm.append('name', folderName);
    bodyForm.append('isFolder', true);

    await axios.post('/s3-object', bodyForm);
    refetchFiles();
    setNewFolderDialogOpen(false);
  };

  const goBack = () => {
    setUploadPath((prev) => {
      const newArray = [...prev];
      newArray.pop();

      return newArray;
    });
  };

  const goProjectHome = () => {
    history.push(`/proyectos/${project}`);
  };

  return (
    <>
      <ProjectCloudScreenMainContainer>
        <HeaderContainer>
          <ButtonsContainer style={{ flex: 1 }}>
            <Button onClick={uploadPath?.length > 1 ? goBack : goProjectHome}>Regresar</Button>
            {(userProjectGrants?.EDIT_PROJECT_FILES?.includes(userDetails?.username) ||
              generalAccess) && (
              <Button onClick={() => setNewFolderDialogOpen(true)}>Crear folder</Button>
            )}
          </ButtonsContainer>
          {(userProjectGrants?.EDIT_PROJECT_FILES?.includes(userDetails?.username) ||
            generalAccess) && (
            <div style={{ flex: 3 }}>
              <Dropzone
                path={`${uploadPath.join('/')}/`}
                refetch={() => {
                  console.log('refetch');
                  refetchFiles();
                }}
                bucket={FILES_BUCKET}
              />
            </div>
          )}
        </HeaderContainer>
        <PCSHeaderContainer>
          <HeaderTitle>{`Archivos del proyecto: ${projectName}`}</HeaderTitle>
        </PCSHeaderContainer>
        <HeaderTitle>{uploadPath.join('/').replace(/\/\//g, '/')}</HeaderTitle>
        <FoldersContainer>
          {(elementFiles?.folders || []).map((folder, folderIndex) => {
            return (
              <FolderComponent
                addToPath={(fName) => setUploadPath((prev) => [...prev, fName])}
                folderName={folder.key}
                key={`${folderIndex}_${folder.key}`}
                refetchFiles={refetchFiles}
                bucket={FILES_BUCKET}
              />
            );
          })}
          {(elementFiles?.files || []).map((folder, folderIndex) => {
            return (
              <FileComponent
                deleteEnabled={
                  userProjectGrants?.EDIT_PROJECT_FILES?.includes(userDetails?.username) ||
                  generalAccess
                }
                refetchFiles={refetchFiles}
                bucket={FILES_BUCKET}
                folderName={folder.key}
                key={`${folderIndex}_${folder.key}`}
              />
            );
          })}
        </FoldersContainer>
      </ProjectCloudScreenMainContainer>
      <Dialog openDialog={newFolderDialogOpen} dialogTitle={`Crear nuevo folder`} maxWidth="md">
        <NewFolderForm
          setOpen={setNewFolderDialogOpen}
          refetchFolders={refetchFiles}
          createFolder={createFolder}
        />
      </Dialog>
    </>
  );
};

export default withNavigation(ProjectCloudScreen);
