import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { format } from 'date-fns';
import { useFetch } from '../../Hooks/useFetch';
import { NEWSLETTER_DASHBOARD_BUCKET } from '../../Constants/urls';
import { downloadFile } from '../../Utils/s3Utils';
import { BACKEND_DATE_FORMAT } from '../../Constants/generalConstants';
import RoshareCarousel from '../RoshareCarousel/RoshareCarousel';

const useStyles = makeStyles(() => ({
  root: {
    // width: "100%",
    // height: "100%",
    // marginTop: '20px',
    // marginLeft: '20px'
    maxHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    // flex: 1,
    // '&:hover': {
    //   cursor: 'pointer',
    // },
  },
  image: {
    maxWidth: '100%',
    maxHeight: 'auto',
  },
  loader: {
    margin: 'auto',
  },
}));

export default function NewsletterCard() {
  const classes = useStyles();
  const { data, isLoading } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: {
      bucket: NEWSLETTER_DASHBOARD_BUCKET,
    },
  });
  const [imagelinks, setImageLinks] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [imagelinkLoading, setImageLinkLoading] = useState();
  const [intervalId, setIntervalId] = useState(null);
  const [preview, openPreview] = useState(false);

  useEffect(() => {
    const getImageLink = async () => {
      if (data && !!isLoading) {
        if (data?.files.length) {
          try {
            setImageLinkLoading(true);
            const links = await Promise.all(
              data.files.map((image) => {
                return downloadFile(image.key, NEWSLETTER_DASHBOARD_BUCKET);
              }),
            );
            setImageLinks(links);
          } catch (error) {
            console.error(error);
          } finally {
            setImageLinkLoading(false);
          }
        }
      }
    };

    getImageLink();
  }, [data, isLoading]);

  useEffect(() => {
    if (imagelinks?.length && !intervalId) {
      const newIntervalId = setInterval(() => {
        setImageIndex((prevIndex) => {
          return prevIndex + 1 >= imagelinks?.length ? 0 : prevIndex + 1;
        });
      }, 3000);
      setIntervalId(newIntervalId);
      return () => clearInterval(newIntervalId);
    }
  }, [imagelinks]);

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title="Avisos importantes"
          subheader={format(new Date(), BACKEND_DATE_FORMAT)}
        />
        {isLoading || imagelinkLoading ? (
          <CircularProgress size={60} className={classes.loader} />
        ) : (
          <div className={classes.media} aria-hidden="true">
            {/* <img src={imagelinks[imageIndex]} alt="admin pic" className={classes.image} /> */}
            <RoshareCarousel imagelinks={imagelinks} />
          </div>
        )}
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            Recuerda vivir los valores de la empresa en todo momento
          </Typography>
        </CardContent>
      </Card>
      <Dialog open={preview} onClose={() => openPreview(false)} maxWidth="xl">
        <DialogTitle id="customized-dialog-title" onClose={() => openPreview(false)}>
          Preview
        </DialogTitle>
        <DialogContent dividers>
          <img alt="preview" src={imagelinks[imageIndex]} />
        </DialogContent>
      </Dialog>
    </>
  );
}
