export const getInitialValues = (currentFilter) => {
  return {
    inventoryType: currentFilter?.inventoryType || false,
    clasification: currentFilter?.clasification || false,
    equipment: currentFilter?.equipment || false,
    inventoryNumber: currentFilter?.inventoryNumber || false,
    description: currentFilter?.description || false,
    brand: currentFilter?.brand || false,
    model: currentFilter?.model || false,
    serialNumber: currentFilter?.serialNumber || false,
    project: currentFilter?.project || false,
    responsable: currentFilter?.responsable || false,
    status: currentFilter?.status || false,
    nickname: currentFilter?.nickname || false,
    set: currentFilter?.set || false,
    EMA: currentFilter?.EMA || false,
    AMAAC: currentFilter?.AMAAC || false,
  };
};
