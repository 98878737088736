import React from 'react';
//Material UI
import Button from '@material-ui/core/Button';
// Icons
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';

function DialogActions({
  primaryAction,
  secondaryAction,
  primaryLabel = 'Save',
  secondaryLabel = 'Close',
}) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {primaryLabel && (
        <Button
          onClick={primaryAction}
          endIcon={primaryLabel === 'Delete' ? <Delete /> : <Save color="secondary" />}
          variant="contained"
          color="primary"
          style={{ margin: '1rem' }}
        >
          {primaryLabel}
        </Button>
      )}

      <Button
        onClick={secondaryAction}
        endIcon={<CloseOutlined color="secondary" />}
        variant="outlined"
        color="primary"
        style={{ margin: '1rem' }}
      >
        {secondaryLabel}
      </Button>
    </div>
  );
}

export default DialogActions;
