import React from 'react';
import { Hidden, Drawer } from '@material-ui/core';
import { useStyles } from './../AppNavigationStyles';
import Menu from './../Menu/Menu';

const ResponsiveDrawer = (props) => {
  const styles = useStyles();

  const { handleDrawerToggle, showDrawer, displayDesktopMenu, setDisplayDesktopMenu } = props;

  const desktop = (
    <Hidden smDown implementation="css">
      <Drawer
        classes={{
          paper: displayDesktopMenu ? styles.drawerPaper : styles.closedDrawerPaper,
        }}
        variant="permanent"
        open
      >
        <Menu
          // mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          displayDesktopMenu={displayDesktopMenu}
          setDisplayDesktopMenu={setDisplayDesktopMenu}
          // openUserMenu={setParentOfUserMenu}
        />
      </Drawer>
    </Hidden>
  );

  const mobile = (
    <Hidden mdUp implementation="css">
      <Drawer
        variant="temporary"
        open={showDrawer}
        onClose={handleDrawerToggle}
        //   classes={{
        //     paper: styles.drawerPaper,
        //   }}
      >
        {/* <Menu
        // mobileOpen={mobileOpen}
        // handleDrawerToggle={handleDrawerToggle}
        // openUserMenu={setParentOfUserMenu}
        /> */}
      </Drawer>
    </Hidden>
  );

  return (
    <nav className={styles.drawerStyle}>
      {mobile}
      {desktop}
    </nav>
  );
};
export default ResponsiveDrawer;
