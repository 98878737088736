import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { parse as parseDate, format as formatDate } from 'date-fns';
import ComputerIcon from '@material-ui/icons/Computer';
import PersonIcon from '@material-ui/icons/Person';
import FileIcon from '@material-ui/icons/Folder';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { ProjectMainTitle, MainSingleProjectContainer } from './SingleProjectScreenStyles';
import { withNavigation } from './../../Containers/AppNavigation/AppNavigation';
import DataCardAmount from './../../Components/DataCards/DataCardAmount';
import DataCardList from './../../Components/DataCards/DataCardList';
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
import { dummyProject } from './dummyData';
import { AxiosContext } from './../../Contexts/AxiosContext/AxiosContext';
import { ProjectContext } from './../../Contexts/ProjectContext/ProjectContext';
import { useFetch } from '../../Hooks/useFetch';
import { userContext } from '../../Contexts/UserContext/UserContext';
import Dialog from '../../Containers/GenericDialog';
import NewProjectFormulary from '../AllProjectsScreen/NewProjectFormulary/Formulary';
import { BACKEND_DATE_FORMAT } from '../../Constants/generalConstants';
import { ESTIMATION_STATUS_DICTIONARY } from '../ProjectCatalogScreens/EstimacionesScreen/DependencyFeedbackDialog/DependencyFeedbackDialog';

const SingleProjectScreen = () => {
  let { project } = useParams();
  const AxiosInstance = useContext(AxiosContext);
  const [, setProjectState] = useContext(ProjectContext);
  const [userState] = useContext(userContext);
  const [, setEvidencesCalendarData] = useState([]);
  const [, setProgramsCalendarData] = useState([]);
  const [, setTimeGraphData] = useState([]);
  const [, setMoneyProgressData] = useState([]);
  const [generalAccess, setGeneralAccess] = useState(false);
  const [userProjectGrants, setUserProjectGrants] = useState({});
  const [usersInProject, setUsersInProject] = useState(0);
  const singleProjectData = dummyProject;
  const history = useHistory();
  const { title: projectTitle } = singleProjectData;
  const { data: projectData, refetch: refreshProjectData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${project}`,
  });
  const { data } = useFetch({
    initialUrl: '/list-all-objects',
  });
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    concepts = [],
    employees = [],
    estimations = [],
    evidences = [],
    stocks = [],
  } = projectData || {};
  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === 'PROYECTOS')
    .map((grant) => grant.name);
  const { folders = [] } = data || {};

  const reformatDate = (date) => {
    if (!date) {
      return '';
    }
    const parsedDate = parseDate(date, BACKEND_DATE_FORMAT, new Date());
    const newStringDate = formatDate(parsedDate, 'yyyy-MM-dd');
    return newStringDate;
  };

  // const ClickedCalendarDate = (event, day) => {
  //   history.push(`${pathname}/evidencias/?fecha=${day.day}`);
  // };

  useEffect(() => {
    if (projectData) {
      projectData['title'] = projectTitle;
      console.log(projectData);
      setProjectState(projectData);
      const {
        evidences,
        startDate: startDateString,
        endDate: endDateString,
        estimations,
        amount,
        milestones,
        projectGrants,
      } = projectData;

      const users = projectGrants.reduce((accumulator, currentGrant) => {
        if (!accumulator.includes(currentGrant.user.username)) {
          accumulator.push(currentGrant.user.username);
        }
        return accumulator;
      }, []);

      setUsersInProject(users.length);

      let tempGrants = {};
      projectGrants.forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });
      setUserProjectGrants(tempGrants);
      const _generalAccess = grants
        .filter((grant) => grant.module === 'PROYECTOS')
        .map((grant) => grant.name)
        .includes('VER TODOS LOS PROYECTOS');
      setGeneralAccess(_generalAccess);
      const evidencesCalendar = evidences
        ?.map((evidence) => evidence?.creationDate?.split('T')[0])
        .filter((date) => !!date)
        .map((date) => ({ day: date, value: true }));
      console.log(evidencesCalendar);
      setEvidencesCalendarData(evidencesCalendar);

      const programCalendar = milestones
        ?.map((program) => reformatDate(program?.dueDate))
        .map((date) => ({ day: date, value: true }));
      setProgramsCalendarData(programCalendar);

      // Cálculo para saber cual es el avance temporal
      const startDate = parseDate(startDateString, BACKEND_DATE_FORMAT, new Date()).getTime();
      const endDate = parseDate(endDateString, BACKEND_DATE_FORMAT, new Date()).getTime();
      const toDay = new Date().getTime();

      const totalDays = Math.floor((endDate - startDate) / (24 * 3600000));
      const currentDays = Math.floor((toDay - startDate) / (24 * 3600000));

      const timeGraphData = {
        id: 'Tiempo',
        color: 'hsl(245, 70%, 50%)',
        data: [],
      };

      const timeChartData = [
        {
          id: 'Transcurrido',
          label: 'Transcurrido',
          value: ((currentDays * 100) / totalDays).toFixed(2),
          color: 'hsl(258, 70%, 50%)',
        },
        {
          id: 'Restante',
          label: 'Restante',
          value: (100 - (currentDays * 100) / totalDays).toFixed(2),
          color: 'hsl(122, 70%, 50%)',
        },
      ];

      const forIncrement = currentDays > 10 ? Math.floor(currentDays / 10) : 1;
      for (let i = 0; i < currentDays; i = i + forIncrement) {
        const day = formatDate(new Date(startDate + 24 * 3600000 * i), BACKEND_DATE_FORMAT);
        const newValue = {
          x: day,
          y: ((i * 100) / totalDays).toFixed(2),
        };
        timeGraphData.data.push(newValue);
      }
      console.log(timeGraphData);
      setTimeGraphData(timeChartData);
      // Cálculo para saber cual es el avance monetario
      // const estimationsGraphData = estimations.map(estimation => {
      //   const {} = estimation;
      // });
      const estimationsByDate = estimations.reduce((allEstimations, curr) => {
        const { creationDate } = curr;
        allEstimations[creationDate] = allEstimations[creationDate]
          ? [...allEstimations[creationDate], curr]
          : [curr];
        return allEstimations;
      }, {});
      const amountsPerDate = Object.keys(estimationsByDate).map((date) => {
        const estimationsInDate = estimationsByDate[date].reduce((total, curr) => {
          const { estimationTotal } = curr;
          return total + estimationTotal;
        }, 0);
        return {
          x: date,
          y: (estimationsInDate / amount) * 100,
        };
      });
      const acumulativeAmounts = amountsPerDate.map((subAmount, subAmountIndex, subAmountArray) => {
        if (subAmountIndex > 0) {
          const { y: prevY } = subAmountArray[subAmountIndex - 1];
          const { y: currY } = subAmount;

          return { ...subAmount, y: currY + prevY };
        } else {
          return subAmount;
        }
      });
      acumulativeAmounts.push({ x: endDateString, y: 100 });

      const moneyGraphData = {
        id: 'Tiempo',
        color: 'hsl(245, 70%, 50%)',
        data: acumulativeAmounts,
      };
      setMoneyProgressData([moneyGraphData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, setProjectState]);

  return (
    <MainSingleProjectContainer>
      <Grid container direction="row" justify="flex-starte" alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <Grid container direction="row" justify="flex-starte" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setProjectState(null);
                  history.push('/proyectos');
                }}
              >
                REGRESAR
              </Button>
            </Grid>
            <Grid item>
              <ProjectMainTitle>
                {`${projectData?.projectId || ''} - ${projectData?.projectName || ''} - ${
                  projectData?.contractKey || ''
                }` || 'Proyecto'}
              </ProjectMainTitle>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
            spacing={2}
          >
            {allowedModuleSections.includes('EDITAR PROYECTOS') && (
              <Grid item justify="flex-end" alignItems="flex-end" alignContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  EDITAR
                </Button>
              </Grid>
            )}
            {userProjectGrants?.ACCESS_HISTORIC?.includes(userDetails?.username) && (
              <Grid item justify="flex-end" alignItems="flex-end" alignContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push(`/historicos/${projectData.id}`);
                  }}
                >
                  Expediente
                </Button>
              </Grid>
            )}
            <Grid item justify="flex-end" alignItems="flex-end" alignContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push(`/sabana/${projectData.id}`);
                }}
              >
                Sabana
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
        {/* <Grid item xs={12} sm={6} md={4} lg={4}>
          <GraphCard
            title="Avance Financiero"
            bottomAxiss="Tiempo"
            leftAxiss="%"
            data={moneyProgressData}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <GraphCard
            title="Avance Físico"
            bottomAxiss="Tiempo"
            leftAxiss="%"
            data={timeGraphData}
            type={PIE_CHART}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <GraphCard
            title="Participación"
            type={CALENDAR_CHART}
            clickDate={ClickedCalendarDate}
            data={evidencesCalendarData}
            startDate={reformatDate(projectState?.startDate)}
            endDate={reformatDate(projectState?.endDate)}
          />
        </Grid> */}
        {(userProjectGrants?.REQUEST_PROJECT_FUNDS?.includes(userDetails?.username) ||
          generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DataCardList
              title="Conceptos"
              list={concepts.map((concept) => ({
                title: concept.conceptName,
                path: concept.id,
              }))}
            />
          </Grid>
        )}
        {(userProjectGrants?.READ_EVIDENCES?.includes(userDetails?.username) || generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DataCardList
              title="Evidencias"
              list={evidences.map((evidencia) => ({
                title: evidencia.title,
                path: evidencia.id,
              }))}
            />
          </Grid>
        )}
        {(userProjectGrants?.READ_ESTIMATIONS?.includes(userDetails?.username) ||
          generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DataCardList
              title="Estimaciones"
              list={estimations.map((estimacion) => {
                const { comments } = estimacion;
                let finalComment = comments;
                let parsedComments;
                try {
                  parsedComments = JSON.parse(comments);
                  if (Array.isArray(parsedComments)) {
                    if (parsedComments.length) {
                      const { comment, state } = parsedComments[parsedComments.length - 1];
                      finalComment = `${ESTIMATION_STATUS_DICTIONARY[state]}: ${
                        comment || 'Sin comentarios en el cambio de estado'
                      }`;
                    } else {
                      finalComment = 'Sin comentarios aún';
                    }
                  }
                } catch (error) {
                  finalComment = comments;
                }
                if (finalComment?.length > 50) {
                  finalComment = 'Comentario muy largo para mostrar, ver en detalles';
                }
                return {
                  title: finalComment,
                  path: estimacion.id,
                };
              })}
            />
          </Grid>
        )}
        {/* {(userProjectGrants?.READ_PROGRAM?.includes(userDetails?.username) || generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GraphCard
              title="Programa"
              type="calendar"
              data={programsCalendarData}
              startDate={reformatDate(projectState?.startDate)}
              endDate={reformatDate(projectState?.endDate)}
              seeMorePath="programa"
            />
          </Grid>
        )} */}
        {(userProjectGrants?.READ_STOCKS?.includes(userDetails?.username) || generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DataCardAmount title="Inventarios" amount={stocks.length} Icon={ComputerIcon} />
          </Grid>
        )}
        {(userProjectGrants?.READ_HR?.includes(userDetails?.username) || generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DataCardAmount title="Recursos Humanos" amount={employees.length} Icon={PersonIcon} />
          </Grid>
        )}
        {(userProjectGrants?.READ_PERMISSIONS?.includes(userDetails?.username) ||
          generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DataCardAmount title="Usuarios y permisos" amount={usersInProject} Icon={PersonIcon} />
          </Grid>
        )}
        {(userProjectGrants?.READ_PROJECT_FILES?.includes(userDetails?.username) ||
          generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DataCardAmount title="Archivos de trabajo" Icon={FileIcon} />
          </Grid>
        )}
        {(userProjectGrants?.REQUEST_PROJECT_FUNDS?.includes(userDetails?.username) ||
          generalAccess) && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DataCardAmount
              title="Solicitudes de Gastos"
              Icon={MonetizationOnIcon}
              customHandleSeeMoreClick={() => {
                history.push(`/proyectos/${project}/solicitudes-costos`);
              }}
            />
          </Grid>
        )}
      </Grid>
      <Dialog openDialog={dialogOpen} dialogTitle={'Editar Proyecto'} maxWidth="md">
        <NewProjectFormulary
          folders={folders}
          setOpen={setDialogOpen}
          currentElement={projectData}
          refetchProjects={refreshProjectData}
        />
      </Dialog>
    </MainSingleProjectContainer>
  );
};

export default withNavigation(SingleProjectScreen);
