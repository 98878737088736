import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';

const MyFullCalendar = (props) => {
  const { handleDayClick } = props;

  const handleEventClick = (values) => {
    handleDayClick && handleDayClick(values);
  };

  const { calendarEvents } = props;
  return (
    <FullCalendar
      plugins={[dayGridPlugin, listPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'prev,next',
      }}
      locale={esLocale}
      weekends={true}
      height="100%"
      themeSystem="bootstrap"
      timeZone="local"
      events={calendarEvents || []}
      eventClick={handleEventClick}
      dateClick={handleEventClick}
      selectable={true}
    />
  );
};

export default MyFullCalendar;
