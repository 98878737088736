import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { TextField } from '@material-ui/core';
import { getInitialValues, validationSchema } from './validation';
// Icons
import { useStyles } from './materialStyles';

function QuoteRequestFormulary(props) {
  const fieldRef = React.useRef('r');
  const { setOpen, fundRequest, AxiosInstance, refetchFundRequests } = props;
  const { projectName, projectCosts, projectId, id } = fundRequest || {};
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [currentRequest, setCurrentRequest] = React.useState(0);

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(
          projectCosts[currentRequest]?.approvedAmount ||
            projectCosts[currentRequest]?.requestedAmount,
        )}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setSubmitting(true);
          //Actualizar en el backend y Si la respuesta es correcta

          const { totalAprobado } = values;
          const approvedAmount = Number(totalAprobado);

          const newFundRequestCost = {
            ...projectCosts[currentRequest],
            approvedAmount,
          };
          try {
            if (approvedAmount !== projectCosts[currentRequest]?.approvedAmount) {
              await AxiosInstance.put(`/api/project/${projectId}/${id}/cost`, newFundRequestCost);
              enqueueSnackbar('Monto aprobado correctamente', {
                variant: 'success',
                preventDuplicate: true,
              });
              await refetchFundRequests();
            }
            if (currentRequest < projectCosts.length - 1) {
              await setFieldValue(
                'totalAprobado',
                projectCosts[currentRequest + 1]?.approvedAmount ||
                  projectCosts[currentRequest + 1]?.requestedAmount,
              );
              setCurrentRequest(currentRequest + 1);
              fieldRef.current.blur();
              fieldRef.current.focus();
            } else {
              setCurrentRequest(0);
              setOpen(false);
            }
          } catch (error) {
            enqueueSnackbar(
              'Ha ocurrido un error aprobando este monto, favor de contactar soporte',
              {
                variant: 'error',
                preventDuplicate: true,
              },
            );
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleChange, values, setFieldValue }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center">
                <Grid
                  item
                  xs={12}
                  container
                  style={{ marginTop: '1rem', display: 'flex', width: '100%' }}
                  justify="center"
                >
                  <h3 style={{ marginRight: '2rem' }}> Proyecto:</h3>
                  <h2> {projectName || 'Sin proyecto'}</h2>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  style={{ marginTop: '1rem', display: 'flex', width: '100%' }}
                  justify="center"
                >
                  <h3 style={{ marginRight: '1rem' }}>Concepto:</h3>
                  <h2>{projectCosts[currentRequest]?.concept || 'Sin concepto'}</h2>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  style={{ marginTop: '1rem', display: 'flex', width: '100%' }}
                  justify="center"
                >
                  <h3 style={{ marginRight: '1rem' }}>Total solicitado:</h3>
                  <h2>
                    $ {projectCosts[currentRequest]?.requestedAmount || 'Sin total solicitado'}
                  </h2>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  style={{ marginTop: '1rem', display: 'flex', width: '100%' }}
                  justify="center"
                >
                  <h3 style={{ marginRight: '1rem' }}>Aprobar: $</h3>
                  <Field name="totalAprobado">
                    {() => (
                      <TextField
                        id="totalAprobado"
                        name="totalAprobado"
                        label="Total aprobado"
                        type="number"
                        onFocus={(e) => e.target.select()}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        variant="outlined"
                        onChange={handleChange}
                        value={values.totalAprobado}
                        ref={fieldRef}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
                style={{ marginTop: '2rem', marginBottom: '2rem' }}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setCurrentRequest((curr) => (curr === 0 ? 0 : curr - 1));
                      setFieldValue(
                        'totalAprobado',
                        projectCosts[currentRequest === 0 ? 0 : currentRequest - 1]
                          ?.approvedAmount ||
                          projectCosts[currentRequest === 0 ? 0 : currentRequest - 1]
                            ?.requestedAmount,
                      );
                    }}
                  >
                    Anterior
                  </Button>
                </Grid>

                <Grid item xs={12} md={3} className={styles.button}>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                    {currentRequest < projectCosts.length - 1 ? 'Siguiente' : 'Finalizar'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default QuoteRequestFormulary;
