import { Divider, Grid } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import Input from '../../../../../Components/FormFields/FormikIconInput';

const CirculationCardForm = () => {
  return (
    <>
      <Grid item xs={12} md={12}>
        <h1>Tarjeta de circulación</h1>
      </Grid>
      <Grid item xs={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field name="vehicleRecord.circulationCardNumber">
          {(props) => (
            <Input
              label={'Número de tarjeta de circulación'}
              formikName="vehicleRecord.circulationCardNumber"
              {...props}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={4}>
        <Field name="vehicleRecord.circulationCardIssueDate">
          {(props) => (
            <Input
              label={'Fecha de expedición'}
              type="date"
              formikName="vehicleRecord.circulationCardIssueDate"
              {...props}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={4}>
        <Field name="vehicleRecord.circulationCardExpirationDate">
          {(props) => (
            <Input
              label={'Fecha de expiración'}
              type="date"
              formikName="vehicleRecord.circulationCardExpirationDate"
              {...props}
            />
          )}
        </Field>
      </Grid>
    </>
  );
};

export default CirculationCardForm;
