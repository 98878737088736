import React from 'react';
import { useHistory } from 'react-router-dom';
// MUI
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// Icons
import MenuIcon from '@material-ui/icons/Menu';
import Home from '@material-ui/icons/HomeOutlined';

// CSS in Js
import { useStyles } from './../AppNavigationStyles';
// Inner components
// Context
// import { currentRoutContext } from "../../Context";

const CustomAppBar = (props) => {
  const { handleDrawerToggle } = props;
  const state = {};
  const styles = useStyles();
  const history = useHistory();

  return (
    <AppBar
      position="fixed"
      className={props.displayDesktopMenu ? styles.appBar : styles.appBarWide}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
          className={styles.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => history.push('/')}
          className={styles.backButton}
        >
          <Home />
        </IconButton>

        <Typography variant="h6" color="inherit" noWrap>
          {state?.currentRout?.name || ''}
        </Typography>
        <div className={styles.grow} />
        {/* <div className={styles.navigationArrows}>
          <Tooltip title="Go to the previous section">
            <IconButton
              color="inherit"
              // onClick={() => history.push(state.currentRout.previousRout)}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Tooltip title="Go to the next section">
            <IconButton
              color="inherit"
              // onClick={() => history.push(state.currentRout.nextRout)}
            >
              <ArrowForward />
            </IconButton>
          </Tooltip>
        </div> */}
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
