import React from 'react';
//Material UI
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

/**
 * Generic default Dialog that for formulary
 *
 * @param {*} {
 *   openDialog: boolean,
 *   setCloseDialog: func,
 *   dialogTitle: string,
 *   contentChild: react element,
 *   buttonLabel: string,
 *   maxSize: number
 * }
 * @returns Dialog
 */
function GenericDialog({
  openDialog,
  setCloseDialog,
  dialogTitle,
  fullScreen,
  maxWidth,
  children,
}) {
  return (
    <Dialog
      open={openDialog}
      maxWidth={maxWidth || 'xl'}
      onClose={setCloseDialog}
      fullWidth
      fullScreen={fullScreen}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography>
        {<Typography variant="h4">{dialogTitle}</Typography>}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default GenericDialog;
