/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Formik, Field, Form } from 'formik';
// MUI
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
// CSS in React
// Internal components
// Hooks
//Material UI
import Button from '@material-ui/core/Button';
// Icons
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import Save from '@material-ui/icons/Save';
import Input from './../../../Components/FormFields/FormikIconInputOutlined';
import { useStyles } from './DialogFormularyStyles';
import { validationSchema, getInitialValues } from './validation';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';

export default function DialogFormulary({
  setCloseDialog,
  setOpenAlert,
  type,
  masterDataElement,
  addLocalData,
  updateLocalData,
}) {
  const styles = useStyles();

  const AxiosInstance = useContext(AxiosContext);

  const createMasterDataElement = async (url, method, body) => {
    console.log('creating');

    const { data } = body;

    if (method === 'put') {
      data.id = masterDataElement.id;
    }
    const result = await AxiosInstance[method](url, data);

    console.log(result);
    const { data: newElement } = result;

    method === 'put' ? updateLocalData(data) : addLocalData(newElement);
    setCloseDialog();
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues(masterDataElement || {})}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          try {
            // const _id =
            //   masterDataElement?.id ||
            //   `${type
            //     .replace(/[aeiou]/g, "")
            //     .toUpperCase()}_${values.value.replace(/[- ]/g, "_")}`;
            await createMasterDataElement('/api/masterData', masterDataElement ? 'put' : 'post', {
              data: { ...values, entity: type },
            });
          } catch (error) {
            Error(error);
            setOpenAlert(true);
          }
          setCloseDialog(false);
          resetForm();
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => {
          return (
            <Form className={styles.formulary} onSubmit={handleSubmit}>
              <Divider className={styles.divider} />
              <Grid
                container
                spacing={2}
                alignItems="flex-end"
                justify="space-around"
                className={styles.commonDataContainer}
              >
                <Grid item xs={12}>
                  <Field name="value">
                    {(props) => <Input formikName="value" label="VALUE" props={props} />}
                  </Field>
                </Grid>
              </Grid>
              <Divider className={styles.divider} />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  //onClick={submitForm}
                  endIcon={<Save color="#FFF" />}
                  variant="contained"
                  color="primary"
                  style={{ margin: '1rem' }}
                  type="submit"
                >
                  Guardar
                </Button>

                <Button
                  onClick={setCloseDialog}
                  endIcon={<CloseOutlined color="secondary" />}
                  variant="outlined"
                  color="primary"
                  style={{ margin: '1rem' }}
                >
                  Cancelar
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
