import styled from 'styled-components';

export const CostScreenMainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CostScreenTitle = styled.h1`
  margin: 0;
  font-weight: 300;
  margin-bottom: 10px;
  padding: 5px;
`;
