import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { parse as parseDate, format as formatDate } from 'date-fns';
import { useHistory } from 'react-router-dom';
import {
  ProgramActionsContainer,
  CalendarElementsContainer,
  ProgramScreenContainer,
  GraphContainer,
  TableContainer,
} from './ProgramScreenStyles';
import Dialog from './../../../Containers/GenericDialog';
import FullCalendar from './../../../Components/FullCalendar/FullCalendar';
import { withNavigation } from './../../../Containers/AppNavigation/AppNavigation';
import NewEventFormulary from './NewEventFormulary/NewEventFormulary';
import DataCardGraph from './../../../Components/DataCards/DataCardGraph';
import { ProjectMainTitle } from './../../SingleProjectScreen/SingleProjectScreenStyles';
import { userContext } from './../../../Contexts/UserContext/UserContext';
import {
  BAR_CHART,
  CALENDAR_CHART,
} from '../../../Components/DataCards/DataCardGraph/DataCardGraph';
import Table from '../../../Components/Table';
import { ProjectContext } from '../../../Contexts/ProjectContext/ProjectContext';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';

const ProgramScreen = () => {
  const history = useHistory();
  const [projectState] = useContext(ProjectContext);
  const [userState] = useContext(userContext);

  const [newEventModal, setNewEventModal] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [calendarEvents, setCalendarEvent] = useState([]);
  const [goalsChartData, setGoalsChartData] = useState([]);
  const [userProjectGrants, setUserProjectGrants] = useState({});
  const [conceptsCompletedQuantities, setConceptsCompletedCuantities] = useState([]);
  const [conceptsProgressPercentage, setConceptsProgressPercentage] = useState([]);
  const [calendarChartData, setCalendarChartData] = useState([]);
  const [tableHeight, setTableHeight] = useState(null);
  const [tableWidth, setTableWidth] = useState(null);
  const div = useCallback((node) => {
    if (node !== null) {
      setTableHeight(node.getBoundingClientRect().height);
      setTableWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const { userDetails = {} } = userState || {};

  const reformatDate = (date) => {
    if (!date) {
      return '';
    }
    const parsedDate = parseDate(date, BACKEND_DATE_FORMAT, new Date());
    const newStringDate = formatDate(parsedDate, 'yyyy-MM-dd');
    return newStringDate;
  };

  useEffect(() => {
    const { milestones, evidences, projectGrants } = projectState;

    // Mapping milestones for Full Calendar Components
    const newMilestonesDates = milestones.map((milestone) => {
      const {
        dueDate,
        concept: { code: conceptCode, conceptName },
        percentage,
      } = milestone;

      const dueDateObject = parseDate(dueDate, BACKEND_DATE_FORMAT, new Date());
      const formatedDueDate = dueDateObject.toISOString();

      return {
        title: `${percentage}% - ${conceptCode} - ${conceptName}`,
        conceptCode,
        start: formatedDueDate,
        end: formatedDueDate,
        backgroundColor: '#0B2650',
        textColor: '#FFFFFF',
      };
    });
    // Mapping milestones for Calendar Chart
    const newCalendarChartData = milestones.map((milestone) => {
      const { dueDate } = milestone;

      const dueDateObject = parseDate(dueDate, BACKEND_DATE_FORMAT, new Date());
      const formatedDueDate = formatDate(dueDateObject, 'yyyy-MM-dd');

      return {
        day: formatedDueDate,
        value: true,
      };
    });

    const conceptQuantities = evidences.reduce((accumulator, evidence) => {
      if (!evidence?.concept?.code || !evidence?.estimable) {
        return accumulator;
      }
      console.log(evidence);
      const {
        concept: { code },
        quantity,
      } = evidence;
      console.log(code, quantity);
      accumulator[code] = (accumulator[code] || 0) + quantity;
      return accumulator;
    }, {});

    let tempGrants = {};

    if (projectState) {
      projectGrants.forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });
    }

    setUserProjectGrants(tempGrants);
    setConceptsCompletedCuantities(conceptQuantities);
    setCalendarChartData(newCalendarChartData);
    setCalendarEvent(newMilestonesDates);
  }, [projectState]);

  useEffect(() => {
    const chartData = Object.keys(conceptsProgressPercentage).map((conceptCode) => {
      const values = conceptsProgressPercentage[conceptCode];
      const { concepts: conceptsFromState, milestones: milestonesFromState } = projectState;
      const targetConcept = conceptsFromState.find((concept) => concept.code === conceptCode) || {};
      const targetMilestone = milestonesFromState.find((milestone) => {
        const {
          concept: { id: conceptId },
          percentage: milestonePercentage,
        } = milestone;
        return conceptId === targetConcept.id && values.goalPercentage === milestonePercentage;
      });
      return {
        conceptCode,
        Restante: values.goalPercentage - values.actualPercentage,
        meta: values.goalPercentage,
        RestanteColor: 'hsl(16, 70%, 50%)',
        Actual: values.actualPercentage,
        ActualColor: 'hsl(176, 70%, 50%)',
        conceptDescription: targetConcept.conceptName,
        conceptObject: targetConcept,
        milestoneObject: targetMilestone,
      };
    });
    setGoalsChartData(chartData);
  }, [conceptsProgressPercentage, projectState]);

  useEffect(() => {
    if (goalsChartData.length) {
      console.log(goalsChartData);
    }
  }, [goalsChartData]);

  const handleDayClick = (values) => {
    const {
      event: { start: selectedDate },
    } = values;

    const conceptsFromDate = calendarEvents
      .filter((event) => event.start === selectedDate.toISOString())
      .map((event) => event.conceptCode)
      .map((conceptCode) => {
        const { concepts: allConcepts } = projectState;
        const conceptFromCatalog = allConcepts.find((concept) => concept.code === conceptCode);
        console.log(conceptFromCatalog);
        const { quantity: totalQuantity } = conceptFromCatalog;
        const percentageAdvancemente = (
          ((conceptsCompletedQuantities[conceptCode] || 0) * 100) /
          Number(totalQuantity)
        ).toFixed(2);

        return { conceptCode, percentage: percentageAdvancemente };
      })
      .reduce((accumulator, curr) => {
        accumulator[curr.conceptCode] = {
          actualPercentage: Number(curr.percentage),
        };
        return accumulator;
      }, {});
    Object.keys(conceptsFromDate).forEach((conceptCode) => {
      const { milestones } = projectState;
      const milestone = milestones.find((ms) => {
        const {
          concept: { code },
        } = ms;
        return code === conceptCode;
      });
      const { percentage } = milestone;
      conceptsFromDate[conceptCode].goalPercentage = percentage;
    });
    setConceptsProgressPercentage(conceptsFromDate);
  };

  // Actual: 1.6
  // ActualColor: "hsl(176, 70%, 50%)"
  // Restante: 10.4
  // RestanteColor: "hsl(16, 70%, 50%)"
  // conceptCode: "IDSJC23"
  const TABLE_HEADERS = [
    { title: 'Código del concepto', field: 'conceptCode' },
    { title: 'Nombre', field: 'conceptDescription' },
    { title: 'Porcentaje actual', field: 'Actual' },
    { title: 'Porcentaje esperado', field: 'meta' },
  ];

  const tableActions = [
    {
      icon: 'edit',
      tooltip: 'Editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        console.log(rowElement);
        const { milestoneObject } = rowElement;
        setEventToEdit(milestoneObject);
        setNewEventModal(true);
      },
      hidden: !userProjectGrants?.EDIT_PROGRAM?.includes(userDetails?.username),
    },
  ];

  return (
    <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
      <Grid container direction="row" justify="flex-starte" alignItems="center" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            {'REGRESAR <<'}
          </Button>
        </Grid>
        <Grid item>
          <ProjectMainTitle>
            {`${projectState?.projectId || ''} - ${projectState?.projectName || ''} - ${
              projectState?.contractKey || ''
            }` || 'Proyecto'}
          </ProjectMainTitle>
        </Grid>
      </Grid>
      <Grid item>
        <ProgramScreenContainer>
          {goalsChartData.length ? (
            <>
              <CalendarElementsContainer>
                <GraphContainer>
                  <DataCardGraph
                    title="Porcentaje de Concepto"
                    type={BAR_CHART}
                    data={goalsChartData}
                    clickDate={() => alert('click')}
                    closeButtonEnabled={true}
                    onClose={() => setGoalsChartData([])}
                  />
                </GraphContainer>
                <TableContainer ref={div}>
                  <Table
                    title={`Inventarios agregados a proyecto`}
                    data={goalsChartData || []}
                    columns={TABLE_HEADERS}
                    options={{
                      maxBodyHeight: `${tableHeight - 125}px`,
                      maxBodyWidth: `${tableWidth}px`,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 15, 25],
                    }}
                    actions={tableActions}
                  />
                </TableContainer>
              </CalendarElementsContainer>
            </>
          ) : null}
          <CalendarElementsContainer>
            <DataCardGraph
              title="Vista General"
              type={CALENDAR_CHART}
              data={calendarChartData}
              startDate={reformatDate(projectState?.startDate)}
              endDate={reformatDate(projectState?.endDate)}
            />
            <ProgramActionsContainer>
              {userProjectGrants?.EDIT_PROGRAM?.includes(userDetails?.username) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEventToEdit(null);
                    setNewEventModal(true);
                  }}
                >
                  Agregar evento al programa
                </Button>
              )}
            </ProgramActionsContainer>
            <FullCalendar calendarEvents={calendarEvents} handleDayClick={handleDayClick} />
            <Dialog
              openDialog={newEventModal}
              dialogTitle={'Nuevo Evento'}
              maxWidth="md"
              setCloseDialog={() => setNewEventModal(false)}
            >
              <NewEventFormulary setOpen={setNewEventModal} currentElement={eventToEdit} />
            </Dialog>
          </CalendarElementsContainer>
        </ProgramScreenContainer>
      </Grid>
    </Grid>
  );
};

export default withNavigation(ProgramScreen);
