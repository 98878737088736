import { format as formatDate } from 'date-fns';
import { NO_PROJECT } from '../../../Hooks/useProjectsForCatalogs';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';

const validateDate = (dateValue) => {
  if (dateValue) {
    console.log('validating: ', dateValue);
    if (typeof dateValue === 'string') {
      return dateValue;
    }

    return dateValue ? formatDate(dateValue, BACKEND_DATE_FORMAT) : undefined;
  }
};

export const mapValues = (values) => {
  const {
    project,
    responsible,
    plate,
    lastCalibrationDate: lastCalibrationDateObject,
    vehicleRecord = {},
    calibrationExpirationDate: calibrationExpirationDateObject,
    calibrationStatus,
  } = values;

  const {
    policyDate: policyDateObject,
    insuranceExpirationDate: insuranceExpirationDateObject,
    circulationCardExpirationDate: circulationCardExpirationDateObject,
    circulationCardIssueDate: circulationCardIssueDateObject,
    lastVerificationDate: lastVerificationDateObject,
    nextVerificationDate: nextVerificationDateObject,
  } = vehicleRecord || {};

  const policyDate = validateDate(policyDateObject);
  const insuranceExpirationDate = validateDate(insuranceExpirationDateObject);
  const circulationCardExpirationDate = validateDate(circulationCardExpirationDateObject);
  const circulationCardIssueDate = validateDate(circulationCardIssueDateObject);
  const lastVerificationDate = validateDate(lastVerificationDateObject);
  const nextVerificationDate = validateDate(nextVerificationDateObject);
  const lastCalibrationDate = validateDate(lastCalibrationDateObject);
  const calibrationExpirationDate = validateDate(calibrationExpirationDateObject);

  const result = {
    ...values,
    projects: project === NO_PROJECT ? [] : [{ id: project }],
    responsible: Number(responsible?.id) === -1 ? null : responsible,
    plate: plate || null,
    lastCalibrationDate,
    calibrationExpirationDate,
    calibrationStatus,
    vehicleRecord: {
      ...vehicleRecord,
      insuranceExpirationDate,
      circulationCardExpirationDate,
      circulationCardIssueDate,
      lastVerificationDate,
      nextVerificationDate,
      policyDate,
    },
  };
  delete result.project;
  return result;
};
