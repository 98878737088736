import styled from 'styled-components';
import { Form } from 'formik';

export const MainContainer = styled.div`
  min-width: 600px;
  border: solid 1px #d0d0d0;
  padding: 20px;
  border-radius: 5px;
`;

export const EstimationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  -webkit-box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
`;

export const EstimationScreenTitle = styled.h1`
  font-size: 40px;
  margin: 0;
`;
export const ButtonsContainer = styled.div`
  display: flex;
`;

export const FormContainer = styled(Form)`
  margin-top: 25px;
  width: 100%;
`;

export const FormLabel = styled.label`
  display: block;
  font-size: 24px;
`;
