// const {
//   ALPHABETICAL_AZ,
//   ALPHABETICAL_ZA,
//   LAST_UPDATED,
//   OLDEST_UPDATED,
//   sortingFunctions,
// } = require("./../utils/sortUtils");

//=========================================HISTORICO=======================================
//PAISES
export const PAISES = [
  { itemValue: 'mexico', itemLabel: 'MEXICO' },
  { itemValue: 'costa_rica', itemLabel: 'COSTA RICA' },
  { itemValue: 'chile', itemLabel: 'CHILE' },
  { itemValue: 'el_salvador', itemLabel: 'República de El Salvador'.toUpperCase() },
];

//TIPO CLIENTE
export const TIPO_CLIENTES = [
  { itemValue: 'publico', itemLabel: 'PUBLICO' },
  { itemValue: 'privado', itemLabel: 'PRIVADO' },
  // { itemValue: 'extranjero', itemLabel: 'EXTRANJERO' },
];

//SI O NO
export const YES_NO_SELECT = [
  { itemValue: 'SI', itemLabel: 'SI' },
  { itemValue: 'NO', itemLabel: 'NO' },
];

//CLIENTES
export const CLIENTES = [
  { itemValue: 'sct', itemLabel: 'SCT' },
  { itemValue: 'capufe', itemLabel: 'CAPUFE' },
  { itemValue: 'pemex', itemLabel: 'PEMEX' },
  { itemValue: 'CAPUFE', itemLabel: 'CAPUFE' },
  { itemValue: 'SCT', itemLabel: 'SCT' },
  { itemValue: 'CNA', itemLabel: 'CNA' },
  { itemValue: 'CFE', itemLabel: 'CFE' },
  { itemValue: 'BANOBRAS', itemLabel: 'BANOBRAS' },
  { itemValue: 'GAP', itemLabel: 'GAP' },
  { itemValue: 'AICM', itemLabel: 'AICM' },
  { itemValue: 'ASA', itemLabel: 'ASA' },
  { itemValue: 'CDI', itemLabel: 'CDI' },
  { itemValue: 'FIDEMICA', itemLabel: 'FIDEMICA' },
  { itemValue: 'ICA', itemLabel: 'ICA' },
  { itemValue: 'IMP', itemLabel: 'IMP' },
  { itemValue: 'MTI Nicaragua', itemLabel: 'MTI Nicaragua' },
  { itemValue: 'API Ensenada', itemLabel: 'API Ensenada' },
  { itemValue: 'API Manzanillo', itemLabel: 'API Manzanillo' },
  { itemValue: 'La Peninsular', itemLabel: 'La Peninsular' },
  { itemValue: 'API Puerto Madero', itemLabel: 'API Puerto Madero' },
  { itemValue: 'ID Las Truchas', itemLabel: 'ID Las Truchas' },
  { itemValue: 'TEPJF', itemLabel: 'TEPJF' },
  { itemValue: 'FERROMEX', itemLabel: 'FERROMEX' },
  { itemValue: 'CAED', itemLabel: 'CAED' },
  { itemValue: 'SACMEX', itemLabel: 'SACMEX' },
  { itemValue: 'CONIP', itemLabel: 'CONIP' },
  { itemValue: 'STC Metro', itemLabel: 'STC Metro' },
  { itemValue: 'API Puerto Guaymas', itemLabel: 'API Puerto Guaymas' },
  { itemValue: 'GDF', itemLabel: 'GDF' },
  { itemValue: 'API Altamira', itemLabel: 'API Altamira' },
  { itemValue: 'CFE', itemLabel: 'CFE' },
  { itemValue: 'API Lázaro Cárdenas', itemLabel: 'API Lázaro Cárdenas' },
  { itemValue: 'API Mazatlán', itemLabel: 'API Mazatlán' },
  { itemValue: 'CARSO', itemLabel: 'CARSO' },
];

// PROJECT STATUSES
export const PROJECT_STATUSES = [
  { itemValue: 'ACTIVE', itemLabel: 'ACTIVO' },
  { itemValue: 'CLOSED', itemLabel: 'CERRADO' },
];

//REGION
export const REGIONES = [
  { itemValue: 'norte', itemLabel: 'NORTE' },
  { itemValue: 'sur', itemLabel: 'SUR' },
  { itemValue: 'este', itemLabel: 'ESTE' },
  { itemValue: 'oeste', itemLabel: 'OESTE' },
];

//ESTADOS DE MEXICO
export const ESTADOS_MEXICO = [
  { itemValue: 'Aguascalientes', itemLabel: 'Aguascalientes' },
  { itemValue: 'Baja California', itemLabel: 'Baja California' },
  { itemValue: 'Baja California Sur', itemLabel: 'Baja California Sur' },
  { itemValue: 'Campeche', itemLabel: 'Campeche' },
  { itemValue: 'Coahuila de Zaragoza', itemLabel: 'Coahuila de Zaragoza' },
  { itemValue: 'Colima', itemLabel: 'Colima' },
  { itemValue: 'Chiapas', itemLabel: 'Chiapas' },
  { itemValue: 'Chihuahua', itemLabel: 'Chihuahua' },
  { itemValue: 'Distrito Federal', itemLabel: 'Distrito Federal' },
  { itemValue: 'Durango', itemLabel: 'Durango' },
  { itemValue: 'Guanajuato', itemLabel: 'Guanajuato' },
  { itemValue: 'Guerrero', itemLabel: 'Guerrero' },
  { itemValue: 'Hidalgo', itemLabel: 'Hidalgo' },
  { itemValue: 'Jalisco', itemLabel: 'Jalisco' },
  { itemValue: 'México', itemLabel: 'Estado de México' },
  { itemValue: 'Michoacán de Ocampo', itemLabel: 'Michoacán de Ocampo' },
  { itemValue: 'Morelos', itemLabel: 'Morelos' },
  { itemValue: 'Nayarit', itemLabel: 'Nayarit' },
  { itemValue: 'Nuevo León', itemLabel: 'Nuevo León' },
  { itemValue: 'Oaxaca', itemLabel: 'Oaxaca' },
  { itemValue: 'Puebla', itemLabel: 'Puebla' },
  { itemValue: 'Querétaro', itemLabel: 'Querétaro' },
  { itemValue: 'Quintana Roo', itemLabel: 'Quintana Roo' },
  { itemValue: 'San Luis Potosí', itemLabel: 'San Luis Potosí' },
  { itemValue: 'Sinaloa', itemLabel: 'Sinaloa' },
  { itemValue: 'Sonora', itemLabel: 'Sonora' },
  { itemValue: 'Tabasco', itemLabel: 'Tabasco' },
  { itemValue: 'Tamaulipas', itemLabel: 'Tamaulipas' },
  { itemValue: 'Tlaxcala', itemLabel: 'Tlaxcala' },
  {
    itemValue: 'Veracruz de Ignacio de la Llave',
    itemLabel: 'Veracruz de Ignacio de la Llave',
  },
  { itemValue: 'Yucatán', itemLabel: 'Yucatán' },
  { itemValue: 'Zacatecas', itemLabel: 'Zacatecas' },
];

//ESTADOS DE COSTA RICA
export const ESTADOS_COSTA_RICA = [
  { itemValue: 'San José', itemLabel: 'San José' },
  { itemValue: 'Alajuela', itemLabel: 'Alajuela' },
  { itemValue: 'Cartago', itemLabel: 'Cartago' },
  { itemValue: 'Heredia', itemLabel: 'Heredia' },
  { itemValue: 'Guanacaste', itemLabel: 'Guanacaste' },
  { itemValue: 'Puntarenas', itemLabel: 'Puntarenas' },
  { itemValue: 'Limón', itemLabel: 'Limón' },
];

//ESTADOS DE CHILE
export const ESTADOS_CHILE = [
  { itemValue: 'Tarapacá', itemLabel: 'Tarapacá' },
  { itemValue: 'Antofagasta', itemLabel: 'Antofagasta' },
  { itemValue: 'Atacama', itemLabel: 'Atacama' },
  { itemValue: 'Coquimbo', itemLabel: 'Coquimbo' },
  { itemValue: 'Valparaíso', itemLabel: 'Valparaíso' },
  { itemValue: 'O’Higgins', itemLabel: 'O’Higgins' },
  { itemValue: 'Maule', itemLabel: 'Maule' },
  { itemValue: 'Bío-Bío', itemLabel: 'Bío-Bío' },
  { itemValue: 'La Araucanía', itemLabel: 'La Araucanía' },
  { itemValue: 'Los Lagos', itemLabel: 'Los Lagos' },
  { itemValue: 'Aysén', itemLabel: 'Aysén' },
  { itemValue: 'Magallanes', itemLabel: 'Magallanes' },
  {
    itemValue: 'Metropolitana de Santiago',
    itemLabel: 'Metropolitana de Santiago',
  },
  { itemValue: 'Los Ríos', itemLabel: 'Los Ríos' },
  { itemValue: 'Arica y Parinacota', itemLabel: 'Arica y Parinacota' },
];
export const ESTADOS_EL_SALVADOR = [{ itemValue: 'San Salvador', itemLabel: 'San Salvador' }];

//TIPO TRABAJO
export const TIPO_TRABAJO = [
  { itemValue: 'supervisión', itemLabel: 'SUPERVISION' },
  { itemValue: 'proyecto', itemLabel: 'PROYECTO' },
  { itemValue: 'control_de_calidad', itemLabel: 'CONTROL DE CALIDAD' },
];

//CLASIFICACION DEL TRABAJO
export const CLASIFICACION_TRABAJO = [
  { itemValue: 'puertos/aduanas', itemLabel: 'PUERTOS/ADUANAS' },
  { itemValue: 'infraestructura', itemLabel: 'PROYECTOS DE INFRAESTRUCTURA' },
  { itemValue: 'desarrollo_social', itemLabel: 'DESARROLLO SOCIAL' },
  { itemValue: 'aeropuertos', itemLabel: 'AEROPUERTOS' },
  {
    itemValue: 'infraestructura_hiidraulica',
    itemLabel: 'INFRAESTRUCTURA HIDRAÚLICA',
  },
  { itemValue: 'auditorías', itemLabel: 'AUDITORÍAS' },
  { itemValue: 'carreteras', itemLabel: 'CARRETERAS' },
  { itemValue: 'capacitación', itemLabel: 'CAPACITACIÓN' },
  { itemValue: 'Ferrocarriles', itemLabel: 'FERROCARRILES' },
];

//MESES
export const MESES = [
  { itemValue: 'enero', itemLabel: 'ENERO' },
  { itemValue: 'febrero', itemLabel: 'FEBRERO' },
  { itemValue: 'marzo', itemLabel: 'MARZO' },
  { itemValue: 'abril', itemLabel: 'ABRIL' },
  { itemValue: 'mayo', itemLabel: 'MAYO' },
  { itemValue: 'junio', itemLabel: 'JUNIO' },
  { itemValue: 'julio', itemLabel: 'JULIO' },
  { itemValue: 'agosto', itemLabel: 'AGOSTO' },
  { itemValue: 'septiembre', itemLabel: 'SEPTIEMBRE' },
  { itemValue: 'octubre', itemLabel: 'OCTUBRE' },
  { itemValue: 'noviembre', itemLabel: 'NOVIEMBRE' },
  { itemValue: 'diciembre', itemLabel: 'DICIEMBRE' },
];

//AÑOS
export const ANIOS = [
  { itemValue: '2005', itemLabel: '2005' },
  { itemValue: '2006', itemLabel: '2006' },
  { itemValue: '2007', itemLabel: '2007' },
  { itemValue: '2008', itemLabel: '2008' },
  { itemValue: '2009', itemLabel: '2009' },
  { itemValue: '2010', itemLabel: '2010' },
  { itemValue: '2011', itemLabel: '2011' },
  { itemValue: '2012', itemLabel: '2012' },
  { itemValue: '2013', itemLabel: '2013' },
  { itemValue: '2014', itemLabel: '2014' },
  { itemValue: '2015', itemLabel: '2015' },
  { itemValue: '2016', itemLabel: '2016' },
  { itemValue: '2017', itemLabel: '2017' },
  { itemValue: '2018', itemLabel: '2018' },
  { itemValue: '2019', itemLabel: '2019' },
  { itemValue: '2020', itemLabel: '2020' },
  { itemValue: '2021', itemLabel: '2021' },
  { itemValue: '2022', itemLabel: '2022' },
  { itemValue: '2023', itemLabel: '2023' },
  { itemValue: '2024', itemLabel: '2024' },
];

//STATUSES
export const STATUSES = [
  { itemValue: 'vigente', itemLabel: 'VIGENTE' },
  { itemValue: 'cerrado', itemLabel: 'CERRADO' },
];

// //SORTING OPTIONS
// export const SORTING_OPTIONS = [
//   { itemValue: ALPHABETICAL_AZ, itemLabel: "DESCENDENTE" },
//   { itemValue: ALPHABETICAL_ZA, itemLabel: "ASCENDENTE" },
//   { itemValue: LAST_UPDATED, itemLabel: "ULTIMO MODIFICADO" },
//   { itemValue: OLDEST_UPDATED, itemLabel: "MAS ANTIGUO" },
// ];

//=========================================EMPLEADOS=======================================
//Escolaridad
export const ESCOLARIDAD = [
  { itemValue: 'licenciatura', itemLabel: 'licenciatura' },
  { itemValue: 'preparatoria', itemLabel: 'preparatoria' },
  { itemValue: 'secundaria', itemLabel: 'secundaria' },
  { itemValue: 'primaria', itemLabel: 'primaria' },
  { itemValue: 'maestría', itemLabel: 'maestría' },
  { itemValue: 'doctorado', itemLabel: 'doctorado' },
  { itemValue: 'n/a', itemLabel: 'n/a' },
];

//Titulos
export const TITULOS = [
  {
    itemValue: 'administración de empresas',
    itemLabel: 'administración de empresas',
  },
  { itemValue: 'contaduría pública', itemLabel: 'contaduría pública' },
  { itemValue: 'ingeniería civil', itemLabel: 'ingeniería civil' },
  { itemValue: 'arquitectura', itemLabel: 'arquitectura' },
  { itemValue: 'eléctrica', itemLabel: 'eléctrica' },
  { itemValue: 'mecánica', itemLabel: 'mecánica' },
  { itemValue: 'química', itemLabel: 'química' },
];

//Experiencia
export const EXPERIENCIAS = [
  { itemValue: 'contabilidad', itemLabel: 'contabilidad' },
  {
    itemValue: 'integración de estados financieros',
    itemLabel: 'integración de estados financieros',
  },
  { itemValue: 'supervisión de obra', itemLabel: 'supervisión de obra' },
  { itemValue: 'diseño arquitectónico', itemLabel: 'diseño arquitectónico' },
  { itemValue: 'dibujo técnico', itemLabel: 'dibujo técnico' },
  { itemValue: 'pruebas de laboratorio', itemLabel: 'pruebas de laboratorio' },
  { itemValue: 'taquigrafía', itemLabel: 'taquigrafía' },
];

//Software
export const SOFTWARES = [
  { itemValue: 'contpaq', itemLabel: 'contpaq' },
  { itemValue: 'sap', itemLabel: 'sap' },
  { itemValue: 'excel', itemLabel: 'excel' },
  { itemValue: 'word', itemLabel: 'word' },
  { itemValue: 'power point', itemLabel: 'power point' },
  { itemValue: 'autocad', itemLabel: 'autocad' },
  { itemValue: 'archicad', itemLabel: 'archicad' },
];

//Idiomas
export const IDIOMAS = [
  { itemValue: 'inglés', itemLabel: 'inglés' },
  { itemValue: 'español', itemLabel: 'español' },
  { itemValue: 'francés', itemLabel: 'francés' },
  { itemValue: 'portugués', itemLabel: 'portugués' },
];
//Nivel de idioma
export const NIVEL_IDIOMA = [
  { itemValue: 'principiante', itemLabel: 'principiante' },
  { itemValue: 'intermedio', itemLabel: 'intermedio' },
  { itemValue: 'avanzado', itemLabel: 'avanzado' },
  { itemValue: 'lengua materna', itemLabel: 'lengua materna' },
];

//Herramientas
export const H_CLASIFICACION = [
  { itemValue: 'vehiculo', itemLabel: 'vehiculo' },
  { itemValue: 'maquinaria', itemLabel: 'maquinaria' },
  { itemValue: 'equipo', itemLabel: 'equipo' },
];
export const H_NOMBRE = [
  { itemValue: 'automóvil', itemLabel: 'automóvil' },
  { itemValue: 'mezcladora', itemLabel: 'mezcladora' },
  { itemValue: 'estación total', itemLabel: 'estación total' },
  { itemValue: 'retroescavadora', itemLabel: 'retroescavadora' },
];
export const H_TIPO = [
  { itemValue: 'estándar', itemLabel: 'estándar' },
  { itemValue: 'chica', itemLabel: 'chica' },
  { itemValue: 'laica', itemLabel: 'laica' },
  { itemValue: 'automático', itemLabel: 'automático' },
  { itemValue: 'diesel', itemLabel: 'diesel' },
];
