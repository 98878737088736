import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const LogInMainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogInMainCard = styled.div`
  width: 50vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.75);
`;

export const LogoContainer = styled.div`
  margin-top: 10px;
`;

export const InputsContainer = styled.div`
  margin: 10px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  margin: 10px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const useStyles = makeStyles(() => ({
  formulary: {
    width: '100%',
  },
  divider: {
    margin: '2rem 0',
  },
  commonDataContainer: {
    marginTop: '1rem',
  },
  dimensionsTitle: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
}));
