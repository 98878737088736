import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { AxiosContext } from '../../../../Contexts/AxiosContext/AxiosContext';
import { ProjectContext } from '../../../../Contexts/ProjectContext/ProjectContext';
import { BACKEND_DATE_FORMAT } from '../../../../Constants/generalConstants';
import { userContext } from '../../../../Contexts/UserContext/UserContext';

export const DEPENDENCY_REJECTED = 'DEPENDENCY_REJECTED';
export const AWAITING_DEPENDENCY_APPROVAL = 'AWAITING_DEPENDENCY_APPROVAL';
export const AWAITING_INVOICE = 'AWAITING_INVOICE';
export const DEPENDENCY_APPROVED = 'DEPENDENCY_APPROVED';
export const INVOICED = 'INVOICED';
export const PAID = 'PAID';
export const PAYMENT_PENDING = 'PAYMENT_PENDING';
export const REJECTED = 'REJECTED';

export const ESTIMATION_STATUS_DICTIONARY = {
  DEPENDENCY_REJECTED: 'Rechazado por dependencia',
  AWAITING_DEPENDENCY_APPROVAL: 'En revisión',
  AWAITING_INVOICE: 'Por facturar',
  DEPENDENCY_APPROVED: 'Aprobado por dependencia',
  INVOICED: 'Facturado',
  PAID: 'Cobrado',
  PAYMENT_PENDING: 'Pendiente de pago',
  REJECTED: 'Rechazada',
};
export const ESTIMATION_DIALOG_DICTIONARY = {
  DEPENDENCY_REJECTED: 'Cambiar a estado: Rechazado por dependencia',
  AWAITING_DEPENDENCY_APPROVAL: 'Cambiar a estado: En revisión',
  AWAITING_INVOICE: 'Cambiar a estado: Por Facturar',
  DEPENDENCY_APPROVED: 'Cambiar a estado: Aprobado por dependencia',
  INVOICED: 'Cambiar a estado: Facturado',
  PAID: 'Cambiar a estado: Cobrado',
  PAYMENT_PENDING: 'Cambiar a estado: Pendiente de pago',
  REJECTED: 'Cambiar a estado: Rechazada',
};

export default function DependencyFeedbackDialog({
  dependencyFeedbackOpen,
  handleDependencyFeedbackClose,
  nextState,
  estimation,
  refetch,
}) {
  // eslint-disable-next-line no-unused-vars
  const [_projectState, setProjectState] = React.useContext(ProjectContext);
  const [newComment, setNewComment] = React.useState('');
  const [userState] = React.useContext(userContext);

  const axiosInstance = React.useContext(AxiosContext);
  const {
    id,
    project: { id: projectId },
    parsedComments,
  } = estimation || { project: {} };

  const myParsedComments = Array.isArray(parsedComments) ? parsedComments : [];
  console.log({ myParsedComments });

  const handleStateChange = async () => {
    await axiosInstance({
      url: `/api/estimation/${id}/update-status`,
      data: JSON.stringify(nextState),
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let newComments;
    try {
      myParsedComments.push({
        timeStamp: new Date().toISOString(),
        comment: newComment,
        state: nextState,
        author: userState.userDetails.name,
      });
      newComments = JSON.stringify(myParsedComments);
    } catch (error) {
      newComments = `${newComments}, ${newComment}`;
    }

    const { contractNumber, convenio, period, estimationTotal } = estimation;

    await axiosInstance.put(`/api/project/${projectId}/estimation`, {
      id,
      estimationTotal: estimationTotal,
      contractNumber,
      convenio,
      period,
      comments: newComments,
    });
    refetch();
  };
  return (
    <div>
      <Dialog open={dependencyFeedbackOpen} onClose={handleDependencyFeedbackClose}>
        <DialogTitle>
          Marcar {nextState === DEPENDENCY_APPROVED ? 'aprobado' : 'rechazado'} por dependencia
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor escribe un resumen de la retroalimentación recibida por la dependencia.
          </DialogContentText>
          <Divider />
          <Typography variant="h6">Log de cambios:</Typography>
          {myParsedComments.map(({ timeStamp, comment, state }, index) => {
            return (
              <div
                key={`${index}_${timeStamp}`}
                style={{
                  padding: '3px',
                  borderBottom: 'solid #A0A0A0 1px',
                  borderTop: index === 0 ? 'solid #A0A0A0 1px' : null,
                }}
              >
                <Typography>
                  {format(new Date(timeStamp), BACKEND_DATE_FORMAT)} -{' '}
                  {ESTIMATION_STATUS_DICTIONARY[state]}: {comment}
                </Typography>
              </div>
            );
          })}
          <TextField
            margin="dense"
            id="name"
            label="Retro"
            type="text"
            multiline
            minRows={10}
            fullWidth
            variant="outlined"
            onChange={(event) => {
              setNewComment(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDependencyFeedbackClose}>Cancelar</Button>
          <Button
            onClick={() => {
              handleStateChange();
              handleDependencyFeedbackClose();
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
