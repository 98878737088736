import styled from 'styled-components';

export const ShoppingMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  flex: 1;
  justify-content: center;
  align-items: stretch;
`;

export const ShoppingCartLabel = styled.p`
  font-weight: 500;
  font-size: 1rem;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  margin: 10px;
  color: white;
  max-width: 200px;
  text-align: center;
`;
