import styled from 'styled-components';

export const MainProjectResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const DataHeaderTitle = styled.h2`
  margin: 0;
  font-weight: 300;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const DataPairResumeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  margin-right: 20px;
`;

export const DataPairText = styled.p`
  font-size: 18px;
  margin: 0;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 200;
`;
