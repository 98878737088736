import React, { useState } from 'react';
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import clsx from 'clsx';
import { getIn } from 'formik';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileButtonText: {
    cursor: 'pointer',
  },
  errorContainer: {
    backgroundColor: '#FCACAC',
  },
}));

const SimpleFileUpload = ({
  setFieldValue,
  setFieldTouched,
  field,
  form,
  loadingDocument,
  setDocument,
}) => {
  const classes = useStyles();
  const { name: fieldName } = field || {};
  const { errors } = form || {};
  const [documentToUploadS3, setDocumentToUploadS3] = useState(null);

  const _handleFile = (event, setFieldValue) => {
    const fileLocation = event.target.files[0] || null;
    if (fileLocation) {
      setDocument && setDocument(fileLocation);
      setDocumentToUploadS3(fileLocation);
      setFieldValue && fieldName && setFieldValue(fieldName, true);
    }
  };

  const error = !!getIn(errors, fieldName);

  return (
    <>
      {!loadingDocument ? (
        <>
          <input
            id="upload"
            accept={'*/*'}
            style={{
              display: 'none',
            }}
            type="file"
            onChange={(event) => {
              _handleFile(event, setFieldValue);
            }}
            onClick={() => {
              setFieldTouched && setFieldTouched(fieldName);
            }}
          />
          <label htmlFor="upload">
            <div className={classes.container}>
              <IconButton color="primary" aria-label="upload picture" component="span">
                <AttachFileIcon />
              </IconButton>
              <h6 className={clsx(classes.fileButtonText, error ? classes.errorContainer : null)}>
                {!documentToUploadS3
                  ? 'Seleccionar archivo PDF, Excell o Word'
                  : 'Reemplazar archivo PDF, Excell o Word'}
              </h6>
            </div>
          </label>
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default SimpleFileUpload;
