export const getInitialValues = (currentFilter) => {
  return {
    inventoryType: currentFilter?.inventoryType || '',
    clasification: currentFilter?.clasification || '',
    equipment: currentFilter?.equipment || '',
    inventoryNumber: currentFilter?.inventoryNumber || '',
    description: currentFilter?.description || '',
    brand: currentFilter?.brand || '',
    model: currentFilter?.model || '',
    serialNumber: currentFilter?.serialNumber || '',
    responsable: currentFilter?.responsable || '',
    project: currentFilter?.project || '',
    status: currentFilter?.status || '',
    nickname: currentFilter?.nickname || '',
    set: currentFilter?.set || '',
    EMA: currentFilter?.EMA || '',
    AMAAC: currentFilter?.AMAAC || '',
  };
};
