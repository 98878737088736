import React, { useState } from 'react';
import { DescriptionOutlined, DeleteOutline } from '@material-ui/icons';
import styled from 'styled-components';
import { FolderDiv, FolderLabel } from '../ProjectCloudScreenStyles';
import { deleteFile, downloadFile } from '../../../Utils/s3Utils';
import Alert, { WARNING_TYPE } from '../../../Components/Alert/Alert';

const DeletIconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 2px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FileComponent = (props) => {
  const { folderName = '', bucket, refetchFiles, deleteEnabled } = props;

  const getFolderNameFromKey = () => {
    const pathElements = folderName
      .split('/')
      .filter((element) => !!element)
      .reverse();
    return pathElements[0];
  };

  const folderSimplifiedName = getFolderNameFromKey();
  const [openDeleteDialog, setDeleteOpenAlert] = useState(false);

  const handleDelete = () => {
    deleteFile(folderName, bucket, () => {
      setDeleteOpenAlert(false);
      refetchFiles();
    });
  };

  const handleDownload = () => {
    downloadFile(folderName, bucket, (data) => {
      window.open(data, '_blank');
    });
  };

  return (
    <>
      <FolderDiv onDoubleClick={handleDownload}>
        {deleteEnabled && (
          <DeletIconContainer
            onClick={(event) => {
              event.stopPropagation();
              setDeleteOpenAlert(true);
            }}
          >
            <DeleteOutline fontSize="small" />
          </DeletIconContainer>
        )}
        <DescriptionOutlined fontSize="inherit" />
        <FolderLabel>{folderSimplifiedName}</FolderLabel>
      </FolderDiv>
      <Alert
        type={WARNING_TYPE}
        title="Borrar archivo de la nube"
        content="Estas a punto de borrar un elemento, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setDeleteOpenAlert(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setDeleteOpenAlert(false)}
        onBackdropPress={() => {
          setDeleteOpenAlert(false);
        }}
      />
    </>
  );
};

export default FileComponent;
