import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { useStyles } from './ProjectUsersScreenStyles';
import NewProfileFormulary from './NewProfileFormulary';
import Dialog from './../../Containers/GenericDialog';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Table from '../../Components/Table';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { ProjectContext } from '../../Contexts/ProjectContext/ProjectContext';
import { useFetch } from '../../Hooks/useFetch';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import { userContext } from '../../Contexts/UserContext/UserContext';

export const GIVE_GRANT_TYPE = 'GIVE_GRANT_TYPE';
export const REMOVE_GRANT_TYPE = 'REMOVE_GRANT_TYPE';
export const FIRST_ASSIGNMENT_TYPE = 'FIRST_ASSIGNMENT_TYPE';
export const GIVE_MODULE_ACCESS_TYPE = 'GIVE_MODULE_ACCESS_TYPE';
export const REMOVE_MODULE_ACCESS_TYPE = 'REMOVE_MODULE_ACCESS_TYPE';
export const PROFILE_LOAD_TYPE = 'PROFILE_LOAD_TYPE';

const PermisosScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const [projectState, setProjectState] = useContext(ProjectContext);
  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);

  const history = useHistory();
  const location = useLocation();
  const { project } = useParams();
  const { pathname } = location;
  //---------------------------------------------------------------------------
  const { projectGrants } = projectState;
  const { userDetails = {} } = userState || {};
  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [openNewUserForm, setOpenNewUserForm] = useState(false);
  const [deleteWarningDialogState, setDeleteWarningDialogState] = useState(false);
  const [userToDelete, setUserToDelete] = useState(false);
  const [userProjectGrants, setUserProjectGrants] = useState({});
  const [generalAccessUser, setGeneralAccessUser] = useState(false);

  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { data: users } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/user/list',
  });

  const { data: projectData, refetch: refetchProject } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${project}`,
  });
  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (userDetails) {
      console.log(userDetails);
      const projectGrants = (userDetails?.profile?.grants || [])
        .filter((grant) => grant.module === 'PROYECTOS')
        .map((grant) => grant.name);
      const permiso = projectGrants.includes('ACCESO GLOBAL A PERMISOS DE PROYECTO');
      setGeneralAccessUser(permiso);
    }
  }, [userDetails]);

  useEffect(() => {
    if (projectData) {
      setProjectState && setProjectState(projectData);

      const { projectGrants = [] } = projectState;

      let tempGrants = {};
      projectGrants.forEach((grant) => {
        if (!(grant?.grantName in tempGrants)) tempGrants[grant?.grantName] = [];
        tempGrants[grant.grantName].push(grant?.user?.username);
      });
      setUserProjectGrants(tempGrants);
    }
  }, [projectData]);
  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------

  const getData = () => {
    return (projectGrants || [])
      .map(({ user, grantName }) => ({
        name: user.name,
        username: user.username,
        grantName,
        userId: user.id,
      }))
      .reduce((prev, curr) => {
        return prev.map((grant) => grant.username).includes(curr.username) ? prev : [...prev, curr];
      }, []);
  };

  const TABLE_HEADERS = [
    { title: 'Nombre', field: 'name' },
    { title: 'Usuario', field: 'username' },
  ];

  const actions = [
    {
      icon: 'visibility',
      tooltip: 'Ver perfil',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowData) => {
        const path = `${pathname}/${rowData.userId}`;
        history.push(path);
      },
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Agregar usuario
          </Button>
        );
      },
      isFreeAction: true,
      tooltip: 'Ver perfil',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setOpenNewUserForm(true);
      },
      hidden: !(
        userProjectGrants?.EDIT_PERMISSIONS?.includes(userDetails?.username) || generalAccessUser
      ),
    },
    {
      icon: () => {
        return <Button variant="contained">Regresar</Button>;
      },
      isFreeAction: true,
      tooltip: 'Regresar',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        const { pathname } = location;
        const pathArray = pathname.split('/');
        const newPath = pathArray.splice(0, pathArray.length - 1).join('/');
        history.push(newPath);
      },
    },
    {
      icon: 'delete',
      tooltip: 'Eliminar usuario',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowData) => {
        setDeleteWarningDialogState(true);
        const { userId } = rowData;
        setUserToDelete(userId);
      },
      hidden: !userProjectGrants?.EDIT_PERMISSIONS?.includes(userDetails?.username),
    },
  ];

  return (
    <div className={styles.container}>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Table
            title={`Usuarios en el Proyecto #${projectState?.projectId || ''}: ${
              projectState?.projectName || ''
            }`}
            data={getData()}
            columns={TABLE_HEADERS}
            actions={actions}
          />
        </Grid>
      </Grid>
      <Dialog openDialog={openNewUserForm} dialogTitle={'Agregar usuario'} maxWidth="md">
        <NewProfileFormulary
          setOpen={setOpenNewUserForm}
          users={users}
          refetchProject={refetchProject}
        />
      </Dialog>
      <Alert
        type={WARNING_TYPE}
        title="Advertencia"
        content={`Estas por eliminar todos los permisos y privilegios del usuario en el proyecto. ¿Estas seguro?`}
        actions={[
          {
            text: 'Eliminar',
            onClick: async () => {
              const userGrants = projectGrants.filter((grant) => grant.user.id === userToDelete);

              for (let i = 0; i < userGrants.length; i++) {
                const { id } = userGrants[i];
                await AxiosInstance.delete(`api/project/${project}/grant/${id}`);
              }
              refetchProject();
              setUserToDelete(null);
              setDeleteWarningDialogState(false);
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setDeleteWarningDialogState(false);
              setUserToDelete(null);
            },
          },
        ]}
        open={deleteWarningDialogState}
        onClose={() => setDeleteWarningDialogState(false)}
        onBackdropPress={() => {
          setDeleteWarningDialogState(false);
        }}
      />
    </div>
  );
};

export default withNavigation(PermisosScreen);
