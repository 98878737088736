export const normalizePath = (path) => {
  return path
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ú/g, 'u')
    .replace(/ñ/g, 'n');
};

export const cerosPrefix = (number, cerosAmount) => {
  const numberString = `${Number(number)}`;
  const ceros = new Array(cerosAmount - numberString.length).fill(0);
  return `${ceros.join('')}${numberString}`;
};
