import React from 'react';
import { Folder, DeleteOutline } from '@material-ui/icons';
import styled from 'styled-components';
import { FolderDiv, FolderLabel } from '../ProjectCloudScreenStyles';
import Alert, { WARNING_TYPE } from '../../../Components/Alert/Alert';
import { deleteFile } from '../../../Utils/s3Utils';

const DeletIconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 2px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FolderComponent = (props) => {
  const { folderName = '', addToPath, deleteEnabled = true, bucket, refetchFiles } = props;
  const [openDeleteDialog, setDeleteOpenAlert] = React.useState(false);

  const getFolderNameFromKey = () => {
    const pathElements = folderName
      .split('/')
      .filter((element) => !!element)
      .reverse();
    return pathElements[0];
  };

  const folderSimplifiedName = getFolderNameFromKey();

  const handleDelete = () => {
    deleteFile(folderName, bucket, () => {
      setDeleteOpenAlert(false);
      refetchFiles();
    });
  };

  const handleDoubleClick = () => {
    addToPath && addToPath(folderSimplifiedName);
  };
  return (
    <>
      <FolderDiv onDoubleClick={handleDoubleClick}>
        {deleteEnabled && (
          <DeletIconContainer
            onClick={(event) => {
              event.stopPropagation();
              setDeleteOpenAlert(true);
            }}
          >
            <DeleteOutline fontSize="small" />
          </DeletIconContainer>
        )}
        <Folder fontSize="inherit" />
        <FolderLabel>{folderSimplifiedName}</FolderLabel>
      </FolderDiv>
      <Alert
        type={WARNING_TYPE}
        title="Borrar archivo de la nube"
        content="Estas a punto de borrar un elemento, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setDeleteOpenAlert(false);
            },
          },
        ]}
        open={openDeleteDialog}
        onClose={() => setDeleteOpenAlert(false)}
        onBackdropPress={() => {
          setDeleteOpenAlert(false);
        }}
      />
    </>
  );
};

export default FolderComponent;
