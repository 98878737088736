import * as yup from 'yup';
import { validateDateObject } from '../../../Constants/generalConstants';
import { NO_PROJECT } from '../../../Hooks/useProjectsForCatalogs';

yup.addMethod(yup.string, 'validateProject', function (args) {
  const { message } = args;

  return this.test('validateProject', message, function (value) {
    const { path, createError } = this;
    return Number(value) || value === NO_PROJECT || createError({ path, message });
  });
});

export const getInitialValues = (currentEmployee) => {
  const {
    names = '',
    lastName1 = '',
    lastName2 = '',
    position = {
      id: null,
    },
    area = {
      id: null,
    },
    phoneNumber = '',
    email = '',
    bankAccountNumber = '',
    bankName = '',
    curp = '',
    degree = {
      id: null,
    },
    jobStartDate = new Date(),
    professionalLicense = '',
    rfc = '',
    socialSecurityNumber = '',
    company = '',
    user = {
      id: null,
    },
    projects = [],
  } = currentEmployee || {};

  const result = {
    position,
    names,
    lastName1,
    lastName2,
    area,
    phoneNumber,
    email,
    bankAccountNumber,
    bankName,
    curp,
    degree,
    jobStartDate: validateDateObject(jobStartDate),
    professionalLicense,
    rfc,
    socialSecurityNumber,
    company,
    user,
  };

  if (projects?.length) {
    result.project = projects[0].id || null;
  } else {
    result.project = NO_PROJECT;
  }

  return result;
};

const CAMPO_OBLIGATORIO = 'Campo obligatorio';
const STRING_OBLIGATORIO = yup.string().required(CAMPO_OBLIGATORIO).typeError(CAMPO_OBLIGATORIO);
const STRING_NO_OBLIGATORIO = yup.string().typeError(CAMPO_OBLIGATORIO);
const DATE_OBLIGATORIA = yup.date().required(CAMPO_OBLIGATORIO).typeError(CAMPO_OBLIGATORIO);
const MASTER_DATA_OBLIGATORIO = yup.object().shape({
  id: yup.number().required(CAMPO_OBLIGATORIO).typeError(CAMPO_OBLIGATORIO).nullable(),
});

export const validationSchema = yup.object().shape({
  //------------------------------------------------------------
  // VALORES STRING OBLIGATORIOS
  //------------------------------------------------------------
  names: STRING_OBLIGATORIO,
  lastName1: STRING_OBLIGATORIO,
  lastName2: STRING_OBLIGATORIO,
  phoneNumber: STRING_OBLIGATORIO,
  curp: STRING_OBLIGATORIO,
  rfc: STRING_OBLIGATORIO,
  jobStartDate: DATE_OBLIGATORIA,
  //------------------------------------------------------------
  email: STRING_NO_OBLIGATORIO,
  socialSecurityNumber: STRING_NO_OBLIGATORIO,
  company: STRING_NO_OBLIGATORIO,
  professionalLicense: STRING_NO_OBLIGATORIO,
  bankName: STRING_NO_OBLIGATORIO,
  bankAccountNumber: STRING_NO_OBLIGATORIO,
  //------------------------------------------------------------
  // PROYECTO
  //------------------------------------------------------------
  project: yup
    .string()
    .validateProject('Campo obligatorio')
    .required('Campo obligatorio')
    .typeError('Campo obligatorio'),
  //------------------------------------------------------------
  // VALORES CON ID OBLIGATORIOS
  //------------------------------------------------------------
  degree: MASTER_DATA_OBLIGATORIO,
  position: MASTER_DATA_OBLIGATORIO,
  area: MASTER_DATA_OBLIGATORIO,
  //------------------------------------------------------------
  user: yup
    .object()
    .shape({
      id: yup.number().typeError(CAMPO_OBLIGATORIO).nullable(),
    })
    .nullable(),
});
