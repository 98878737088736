import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import { getInitialValues } from './validation';
// Icons
import { useStyles } from './materialStyles';
import Checkbox from '../../../Components/FormFields/FormikCheckboxes';

function BackupFormulary(props) {
  const { setOpen, currentFilter, setColumnFields } = props;
  const styles = useStyles();

  return (
    <Grid container>
      <Formik
        initialValues={getInitialValues(currentFilter)}
        // validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setColumnFields(values);
          setSubmitting(false);
          setOpen(false);
        }}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form className={styles.formulary}>
              <Grid container alignItems="center" alignContent="center">
                <Grid item xs={12} md={4}>
                  <Field name="position">
                    {(props) => (
                      <Checkbox formikName={'position'} label={'Puesto'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="names">
                    {(props) => (
                      <Checkbox formikName={'names'} label={'Nombre(s)'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="lastName1">
                    {(props) => (
                      <Checkbox
                        formikName={'lastName1'}
                        label={'Apellido Paterno'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="lastName2">
                    {(props) => (
                      <Checkbox
                        formikName={'lastName2'}
                        label={'Apellido Materno'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="area">
                    {(props) => (
                      <Checkbox formikName={'area'} label={'Area'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="project">
                    {(props) => (
                      <Checkbox formikName={'project'} label={'Proyecto'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="phoneNumber">
                    {(props) => (
                      <Checkbox
                        formikName={'phoneNumber'}
                        label={'Teléfono'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="email">
                    {(props) => (
                      <Checkbox formikName={'email'} label={'Email'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="degree">
                    {(props) => (
                      <Checkbox
                        formikName={'degree'}
                        label={'Título / Profesión'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="company">
                    {(props) => (
                      <Checkbox formikName={'company'} label={'Empresas'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="curp">
                    {(props) => (
                      <Checkbox formikName={'curp'} label={'CURP'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="rfc">
                    {(props) => (
                      <Checkbox formikName={'rfc'} label={'RFC'} props={props} noPadding />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="jobStartDate">
                    {(props) => (
                      <Checkbox
                        formikName={'jobStartDate'}
                        label={'Fecha de Ingreso'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="bankName">
                    {(props) => (
                      <Checkbox
                        formikName={'bankName'}
                        label={'Institución Bancaria'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="bankAccountNumber">
                    {(props) => (
                      <Checkbox
                        formikName={'bankAccountNumber'}
                        label={'# Cuenta Bancaria'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="professionalLicense">
                    {(props) => (
                      <Checkbox
                        formikName={'professionalLicense'}
                        label={'Cédula Profesional'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field name="socialSecurityNumber">
                    {(props) => (
                      <Checkbox
                        formikName={'socialSecurityNumber'}
                        label={'# Seguro Social'}
                        props={props}
                        noPadding
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={6}
              >
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (!isValid) {
                        alert('La información ingresada no es correcta o está incompleta');
                      }
                    }}
                  >
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={styles.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

export default BackupFormulary;
