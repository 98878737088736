export const dummyProject = {
  title: 'Proyecto SCT 001',
  equipos: [
    {
      id: '465546',
      nombre: 'Laptop',
      data: '7',
    },
    {
      id: '4655646',
      nombre: 'Auto',
      data: '2',
    },
  ],
  recursosHumanos: [
    {
      id: '46554ss6',
      nombre: 'Octavio',
      titulo: 'Ingeniero',
      data: '89',
    },
  ],
  evidencias: [
    {
      id: '465512346',
      titulo: 'Evidencia 1',
      data: ['Evidencia', 'Evidencia', 'Evidencia'],
    },
    {
      id: '4625546',
      titulo: 'Evidencia 2',
      data: ['Evidencia', 'Evidencia', 'Evidencia'],
    },
  ],
  conceptos: ['Concepto1', 'Concepto2', 'Concepto3'],
  estimaciones: [
    {
      id: '46555646',
      titulo: 'Estimación 1',
      data: '5568',
    },
  ],
};

export const dummyGraphData = [
  {
    id: 'france',
    color: 'hsl(245, 70%, 50%)',
    data: [
      {
        x: 'Jan',
        y: 70,
      },
      {
        x: 'Feb',
        y: 162,
      },
      {
        x: 'Mar',
        y: 110,
      },
      {
        x: 'Apr',
        y: 285,
      },
      {
        x: 'May',
        y: 83,
      },
    ],
  },
];
