import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';
import { userContext } from '../../../Contexts/UserContext/UserContext';
import { useFetch } from '../../../Hooks/useFetch';

export const CREATING = 'CREATING';
export const EDITING = 'EDITING';

export const useUrlData = () => {
  const { stockId, project: projectId } = useParams();
  const [user] = useContext(userContext);
  const search = useLocation().search;
  const stockType = new URLSearchParams(search).get('stockType');
  const AxiosInstance = useContext(AxiosContext);
  const [editStocksGrant, setEditStocksGrant] = useState(true);

  const {
    data: currentElement,
    updateUrl: updateCurrentElementUrl,
    isCurrentElementLoading,
  } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/stock/${stockId}`,
    skip: !stockId,
  });

  const { data: projectData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${projectId}`,
    skip: !projectId,
  });

  useEffect(() => {
    if (projectData && user?.userDetails?.id) {
      const { projectGrants } = projectData;
      const {
        userDetails: { id },
      } = user;
      const grant = projectGrants.find((grant) => {
        return grant?.user?.id === id && grant?.grantName === 'EDIT_STOCKS';
      });
      setEditStocksGrant(!!grant);
    }
  }, [projectData, user]);

  useEffect(() => {
    if (!currentElement && updateCurrentElementUrl) {
      updateCurrentElementUrl(`/api/stock/${stockId}`);
    }
  }, [stockId, updateCurrentElementUrl, currentElement]);

  return {
    projectId,
    stockType,
    currentElement,
    updateCurrentElementUrl,
    isCurrentElementLoading,
    stockId,
    action: window.location.href.includes('editar') ? EDITING : CREATING,
    editStocksGrant,
  };
};
