import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { GraphContainer } from './DataCardGraphStyles';
import MyResponsiveLine from './ResponsiveGraph/ResponsiveGraph';
import MyCalendarChart from './CalendarGraph/CalendarGraph';
import MyPieChart from './PieGraph/PieGraph';
import MyResponsiveBar from './BarChart/BarChart';
import {
  DataCardMainContainer,
  ProyectTitle,
  MoreInfoLink,
  CardHeaderContainer,
  CloseButtonContainer,
} from '../DataCardStyles';
import { normalizeTitleToUrl } from '../DataCardsUtils';
//
export const PIE_CHART = 'pie';
export const CALENDAR_CHART = 'calendar';
export const BAR_CHART = 'bars';

const DataCard = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const {
    type = 'default',
    data,
    startDate,
    endDate,
    seeMorePath,
    clickDate,
    closeButtonEnabled,
    onClose,
  } = props;

  const handleSeeMore = () => {
    history.push(`${pathname}/${seeMorePath && normalizeTitleToUrl(seeMorePath)}`);
  };

  const getChart = () => {
    switch (type) {
      case CALENDAR_CHART:
        return (
          <MyCalendarChart
            data={data}
            startDate={startDate}
            endDate={endDate}
            clickDate={clickDate}
          />
        );
      case PIE_CHART:
        return <MyPieChart data={data} />;
      case BAR_CHART:
        return <MyResponsiveBar data={data} />;
      default:
        return (
          <MyResponsiveLine
            data={props.data || defaultData}
            leftAxiss={props.leftAxiss || 'Avance'}
            bottomAxiss={props.bottomAxiss || 'Tiempo'}
          />
        );
    }
  };
  //DATA
  let defaultData = [
    {
      id: 'Default',
      color: 'hsl(245, 70%, 50%)',
      data: [],
    },
  ];
  //

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DataCardMainContainer
      style={{
        minWidth: '100%',
        minHeight: '250px',
        height: '250px',
        boxSizing: 'border-box',
      }}
    >
      <CardHeaderContainer>
        <ProyectTitle>{props.title || ''}</ProyectTitle>
        {closeButtonEnabled && (
          <CloseButtonContainer onClick={handleClose}>
            <CloseIcon />
          </CloseButtonContainer>
        )}
      </CardHeaderContainer>
      <GraphContainer>{getChart()}</GraphContainer>
      {seeMorePath && <MoreInfoLink onClick={handleSeeMore}>Ver más...</MoreInfoLink>}
    </DataCardMainContainer>
  );
};

export default DataCard;
