import React from 'react';
import { ShoppingCart } from '@material-ui/icons';
import { ShoppingCartLabel } from '../../ShoppingMap.styled';
import { useCartStateContext } from '../../Store/CartState';
import { ButtonsContainer, FloatingButton } from '../MapComponent/MapComponent.styled';

const MapButtons = () => {
  const { targetProject, setModalVisibility } = useCartStateContext();

  return (
    <>
      <ButtonsContainer>
        <FloatingButton onClick={() => setModalVisibility(true)}>
          <ShoppingCart style={{ color: 'white', fontSize: '3rem' }} />
        </FloatingButton>
        {!!targetProject?.projectName && (
          <ShoppingCartLabel>Destino: {targetProject?.projectName || ''}</ShoppingCartLabel>
        )}
      </ButtonsContainer>
    </>
  );
};

export default MapButtons;
