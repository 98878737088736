import { ResponsiveLine } from '@nivo/line';
import React from 'react';

const MyResponsiveLine = (props) => (
  <ResponsiveLine
    data={props.data}
    margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: true,
      reverse: false,
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: props.bottomAxiss || 'time',
      legendOffset: 36,
      legendPosition: 'middle',
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      gridYValues: {},
      legend: props.leftAxiss || 'count',
      legendOffset: -40,
      legendPosition: 'middle',
    }}
    enableGridX={false}
    enableGridY={false}
    colors={'#3F51B5'}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel="%"
    pointLabelYOffset={-12}
    useMesh={true}
  />
);

export default MyResponsiveLine;
