import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { MainContainer } from './QualityDocumentsScreenStyles';
import { withNavigation } from './../../Containers/AppNavigation/AppNavigation';
import NewDocumentFormulary from './NewDocumentFormulary/Formulary';
import NewVersionFileFormulary from './NewDocumentVersion/Formulary';
import Table from './../../Components/Table';
import Dialog from '../../Containers/GenericDialog';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { useFetch } from '../../Hooks/useFetch';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import DialogActions from '../../Containers/GenericDialog/DialogActions';
import { handleDownload } from '../../Utils/QualityFilesUtils';
import { userContext } from '../../Contexts/UserContext/UserContext';
import { ROSHARE_GRANTS } from '../../.gen/grantsAndModules.generated';
// import { CALIDAD } from '../../Constants/grantsModules';

export const useStyles = makeStyles(() => ({
  fakeButton: {
    backgroundColor: '#3f4ab5',
    fontSize: '15px',
    fontWeight: 200,
    color: 'white',
    borderRadius: '5px',
    padding: '10px',
    margin: 0,
  },
}));

const FILES_BUCKET = 'roshare-qualitylibrary';

const QualityDocumentsScreen = () => {
  const styles = useStyles();
  const AxiosInstance = useContext(AxiosContext);

  const [newDocumentModalStatus, setNewDocumentModalStatus] = useState(false);
  const [newDocumentVersionModalStatus, setNewDocumentVersionModalStatus] = useState(false);
  const [documentToEdit, setDocumentToEdit] = useState(null);
  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const [downloadDialogStatus, setDownloadDialogStatus] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [uploadPath, setUploadPath] = useState('');
  const [historyMode, setHistoryMode] = useState(false);
  const [downloadAlertOpen, setDownloadOpenAlert] = useState(false);
  const [fileToDownload, setFileToDownload] = useState(null);

  // CREAR INSTANCIA PARA LEER EL userContext;
  // RECORDAR QEU DATOS DE PERFILES DE CALIDAD ESTA EN userState.userDetails.qualityDocumentAccessList
  const [userState] = useContext(userContext);

  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === ROSHARE_GRANTS.SISTEMAS_DE_GESTION.MAIN_MODULE)
    .map((grant) => grant.id);

  const { data: allQualityDocuments, refetch: refetchDocuments } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/quality-document/list',
  });

  const { data: elementFiles, updateParams } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: uploadPath ? { path: uploadPath } : undefined,
    skip: !downloadDialogStatus,
  });

  const handleDelete = async () => {
    const { code } = documentToDelete;
    await AxiosInstance.delete(`/api/quality-document/${code}`);
    setDeleteDialogStatus(false);
    setDocumentToDelete(null);
    refetchDocuments();
  };

  const TABLE_HEADERS = [
    { title: 'Código', field: 'code' },
    { title: 'Nombre del documento', field: 'name' },
    { title: 'Revisión', field: 'revision' },
    { title: 'Fecha de revisión', field: 'revisionDate' },
    { title: 'Estado del documento', field: 'status.value' },
    { title: 'Ubicación / Distribución', field: 'location.value' },
    { title: 'Folder', field: 'folder.value' },
    { title: 'Grupo de operación', field: 'operationGroup.value' },
  ];

  const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];

  const getFilesList = () => {
    const mappedFiles = elementFiles?.files?.map((file) => {
      const fileName = file?.key?.split('/').pop();
      const rawFileNumber = fileName.split('_').pop();
      const fileNumber = Number(rawFileNumber.split('.')[0]);
      return {
        fileName: fileName,
        key: file?.key,
        fileNumber,
      };
    });

    if (historyMode) {
      return mappedFiles;
    } else {
      const newstVersion = (mappedFiles || []).reduce((newstFile, currentFile) => {
        if (!newstFile) {
          return currentFile;
        }
        if (currentFile.fileNumber > newstFile.fileNumber) {
          return currentFile;
        }
      }, null);

      return newstVersion ? [newstVersion] : [];
    }
  };

  const mapElements = (element) => {
    const { code, name, revision, revisionDate, status, location, folder, operationGroup } =
      element;

    return {
      code: code,
      name: name,
      revision: revision,
      revisionDate: revisionDate,
      status: status,
      location: location,
      folder: folder,
      operationGroup: operationGroup,
    };
  };

  const filterElement = (element) => {
    if (
      allowedModuleSections.find(
        (grant) =>
          grant?.name === ROSHARE_GRANTS.SISTEMAS_DE_GESTION.LECTURA_DE_LISTA_MAESTRA_RESTRINGIDOS,
      )
    ) {
      return true;
    }
    if (element?.operationGroup?.value?.toUpperCase() === 'GENERALES') {
      return true;
    } else if (
      userState?.userDetails?.qualityDocumentAccessList.find(
        (userDocument) => userDocument.code === element.code,
      )
    ) {
      return true;
    }
  };

  const actions = [
    //Capturar nuevo documento
    {
      icon: () => <p className={styles.fakeButton}>NUEVO REGISTRO</p>,
      tooltip: 'Nuevo Registro',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setDocumentToEdit(null);
        setNewDocumentModalStatus(true);
      },
      isFreeAction: true,
      hidden: !allowedModuleSections?.includes(
        ROSHARE_GRANTS.SISTEMAS_DE_GESTION.SUBIR_ARCHIVOS_A_LA_LISTA_MAESTRA,
      ),
    },
    //Actualizar versión de documento
    //Editar datos del documento.
    {
      icon: 'edit',
      tooltip: 'editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setDocumentToEdit(rowElement);
        setNewDocumentModalStatus(true);
      },
      hidden: !allowedModuleSections?.includes(
        ROSHARE_GRANTS.SISTEMAS_DE_GESTION.EDITAR_PARAMETROS_DE_ARCHIVO_DE_LISTA_MAESTRA,
      ),
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setDocumentToDelete(rowElement);
        setDeleteDialogStatus(true);
      },
      hidden: !allowedModuleSections?.includes(
        ROSHARE_GRANTS.SISTEMAS_DE_GESTION.ELIMINAR_ARCHIVO_DE_LISTA_MAESTRA,
      ),
    },
    {
      icon: 'folder',
      tooltip: 'Subir nueva versión',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { code } = rowElement;
        setDocumentToEdit(rowElement);
        setUploadPath(`${code}/`);
        setNewDocumentVersionModalStatus(true);
      },
      hidden: !allowedModuleSections?.includes(
        ROSHARE_GRANTS.SISTEMAS_DE_GESTION.ACTUALIZAR_VERSIONES,
      ),
    },
    {
      icon: CloudDownloadIcon,
      tooltip: 'Descargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { code } = rowElement;
        setDownloadDialogStatus(true);
        updateParams({ path: `${code}/`, bucket: FILES_BUCKET });
      },
      hidden: !allowedModuleSections?.includes(
        ROSHARE_GRANTS.SISTEMAS_DE_GESTION.DESCARGAR_ARCHIVOS,
      ),
    },
  ];

  const fileTableActions = [
    {
      icon: 'cloud_download',
      tooltip: 'descargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        setFileToDownload(rowElement);
        setDownloadOpenAlert(true);
      },
    },
    {
      icon: () => (
        <p className={styles.fakeButton}>
          {historyMode ? 'Ver más reciente' : 'Ver todas las versiones'}
        </p>
      ),
      tooltip: 'Mostrar historia del archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        setHistoryMode((prev) => !prev);
      },
      isFreeAction: true,
      hidden: !allowedModuleSections?.includes(
        ROSHARE_GRANTS.SISTEMAS_DE_GESTION.VER_HISTORIAL_DE_VERSIONES,
      ),
    },
  ];

  return (
    <>
      <MainContainer>
        <Table
          title={`Documentos de calidad`}
          data={(allQualityDocuments || []).map(mapElements).filter(filterElement)}
          columns={TABLE_HEADERS}
          actions={actions}
          style={{ width: '100%' }}
        />
      </MainContainer>
      <Dialog
        openDialog={newDocumentModalStatus}
        dialogTitle={documentToEdit ? 'Editar Documento' : 'Nuevo Documento'}
        maxWidth="md"
      >
        <NewDocumentFormulary
          currentElement={documentToEdit}
          setOpen={() => setNewDocumentModalStatus(false)}
          refetchDocuments={refetchDocuments}
        />
      </Dialog>
      <Alert
        type={WARNING_TYPE}
        title="Borrar documento de calidad"
        content="Estas a punto de borrar un documento de calidad, recuerda que esta acción es irreversible."
        actions={[
          {
            text: 'Eliminar',
            onClick: () => {
              handleDelete();
            },
          },
          {
            text: 'Cancelar',
            onClick: () => {
              setDeleteDialogStatus(false);
            },
          },
        ]}
        open={deleteDialogStatus}
        onClose={() => setDeleteDialogStatus(false)}
        onBackdropPress={() => {
          setDeleteDialogStatus(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Registro de descarga"
        content="Estás por descargar un archivo del sistema, recuerda que esta acción dejará un registro en el sistema"
        actions={[
          {
            text: 'DESCARGAR',
            onClick: () => handleDownload(fileToDownload, AxiosInstance, setDownloadOpenAlert),
          },
        ]}
        open={downloadAlertOpen}
        onClose={() => setDownloadOpenAlert(false)}
        onBackdropPress={() => {
          setDownloadOpenAlert(false);
        }}
      />
      <Dialog openDialog={downloadDialogStatus} dialogTitle={'Descarga de archivo'} maxWidth="md">
        <Table
          title="Archivos de Elemento"
          data={getFilesList()}
          columns={FILE_TABLE_HEADERS}
          actions={fileTableActions}
          options={{
            pageSize: 5,
            actionsColumnIndex: -1,
          }}
        />
        <DialogActions
          secondaryLabel="Cancelar"
          primaryLabel={null}
          secondaryAction={() => setDownloadDialogStatus(false)}
        />
      </Dialog>
      <Dialog
        openDialog={newDocumentVersionModalStatus}
        dialogTitle={'Subir nueva versión de archivo'}
        maxWidth="md"
      >
        <NewVersionFileFormulary
          currentElement={documentToEdit}
          setOpen={() => setNewDocumentVersionModalStatus(false)}
          refetchDocuments={refetchDocuments}
        />
      </Dialog>
    </>
  );
};

export default withNavigation(QualityDocumentsScreen);
