import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import ScatterPlot from '@material-ui/icons/ScatterPlot';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { getInitialValues, createFolderPath } from './validation';
import DropZone from './../../../../Components/DropZone';
import s3Axios from './../../../../Utils/AxiosS3Instance';
import Input from '../../../../Components/FormFields/FormikIconInput';
import Select from '../../../../Components/FormFields/FormikSelect';
import { AxiosContext } from '../../../../Contexts/AxiosContext/AxiosContext';
import { ProjectContext } from '../../../../Contexts/ProjectContext/ProjectContext';
import { useFetch } from '../../../../Hooks/useFetch';
// Icons
import { useStyles } from '../../../InventoryScreen/NewElementFormulary/materialStyles';
import Table from '../../../../Components/Table';
import { deleteFile } from '../../../../Utils/s3Utils';

const yesNoOptions = [
  { itemValue: 'SI', itemLabel: 'SI' },
  { itemValue: 'NO', itemLabel: 'NO' },
];

function BackupFormulary(props) {
  const { setOpen, currentElement, modalFileMode } = props;
  const styles = useStyles();

  const AxiosInstance = useContext(AxiosContext);
  const [projectState, setProjectState] = useContext(ProjectContext);
  const [createdEvidence, setCreatedEvidence] = useState(null);
  const [folderPath, setFolderPath] = useState('');

  const FILES_BUCKET = 'roshare-evidences-files';

  const { data: userDetails } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/user/me',
  });

  const {
    data: elementFiles,
    updateParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: folderPath
      ? { path: folderPath, bucket: FILES_BUCKET }
      : { bucket: FILES_BUCKET },
  });

  //----- Catalog data ---------------------------------------------------------------
  const [projectConcepts, setProjectConcepts] = useState([]);
  //------------------------------------------------------------------------------
  useEffect(() => {
    const { concepts } = projectState;
    setProjectConcepts(
      concepts
        .filter(({ isParent }) => !isParent)
        .map(({ code, conceptName, id }) => {
          return { itemLabel: `${code} - ${conceptName}`, itemValue: `${id}` };
        }),
    );
  }, [projectState]);

  useEffect(() => {
    const folderPath = createFolderPath(createdEvidence || currentElement, projectState);
    setFolderPath(folderPath);
  }, [createdEvidence, currentElement]);

  useEffect(() => {
    updateParams({ path: folderPath, bucket: FILES_BUCKET });
  }, [folderPath]);

  //------------------------------------------------------------------------------

  const parseCurrentElement = (currElement) => {
    if (!currElement) {
      return {};
    }
    const {
      approved: approvedString,
      estimable: estimableString,
      usedForEstimation: usedForEstimationString,
    } = currElement;

    const booleanValues = {
      approved: approvedString === 'SI',
      estimable: estimableString === 'SI',
      usedForEstimation: usedForEstimationString === 'SI',
    };

    return { ...currElement, ...booleanValues };
  };

  const mapValues = (values) => {
    const { concept, description, quantity, title, estimable } = values;

    const result = {
      author: {
        id: (userDetails || {}).id,
      },
      concept:
        estimable === 'SI'
          ? {
              id: Number(concept),
            }
          : null,
      description,
      quantity: Number(quantity),
      title,
      estimable: estimable === 'SI',
    };

    // haciendo put
    if (currentElement) {
      result.id = currentElement.id;
    }
    return result;
  };

  const handlePost = async (values) => {
    const method = currentElement ? 'put' : 'post';
    const evidenceToPublish = { ...parseCurrentElement(currentElement), ...mapValues(values) };
    const { data: newEvidence } = await AxiosInstance[method](
      `/api/project/${projectState.id}/evidence`,
      evidenceToPublish,
    );
    console.log(newEvidence);
    const { evidences } = projectState;

    const newEvidences =
      method === 'post'
        ? [...evidences, newEvidence]
        : evidences.map((evidence) =>
            evidence.id === currentElement.id ? evidenceToPublish : evidence,
          );

    setCreatedEvidence(newEvidence);
    setProjectState({ ...projectState, evidences: newEvidences });
  };

  const creationForm = (
    <Formik
      initialValues={getInitialValues(currentElement)}
      // validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        console.log(values);
        await handlePost(values);
        resetForm();
        setSubmitting(false);
        // setOpen(false);
      }}
    >
      {({ isValid, isSubmitting, values }) => {
        return (
          <Form className={styles.formulary}>
            <Grid container alignItems="center" alignContent="center" spacing={6}>
              <Grid item xs={12} md={8}>
                <Field name="title">
                  {(props) => (
                    <Input
                      formikName={'title'}
                      label={'Título'}
                      Icon={PostAddIcon}
                      props={props}
                      type="text"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="estimable">
                  {(props) => (
                    <Select
                      formikName={'estimable'}
                      label={'Estimable'}
                      menuItemList={yesNoOptions}
                      props={props}
                      Icon={ScatterPlot}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={10}>
                <Field name="concept">
                  {(props) => (
                    <Select
                      formikName={'concept'}
                      label={'Concepto'}
                      menuItemList={projectConcepts}
                      props={props}
                      Icon={ScatterPlot}
                    />
                  )}
                </Field>
              </Grid>
              {values.estimable === 'SI' && (
                <>
                  <Grid item xs={12} md={2}>
                    <Field name="quantity">
                      {(props) => (
                        <Input
                          formikName={'quantity'}
                          label={'Cantidad'}
                          Icon={PostAddIcon}
                          props={props}
                          type="number"
                        />
                      )}
                    </Field>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <Field name="description">
                  {(props) => (
                    <Input
                      formikName={'description'}
                      label={'Descripción'}
                      Icon={PostAddIcon}
                      props={props}
                      type="text"
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container alignItems="center" alignContent="center" justify="center" spacing={6}>
              <Grid item xs={12} md={3} className={styles.button}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    if (!isValid) {
                      alert('La información ingresada no es correcta o está incompleta');
                    }
                  }}
                >
                  {currentElement ? 'Guardar cambios' : 'Crear'}
                </Button>
              </Grid>
              <Grid item xs={12} md={3} className={styles.button}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
  const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];
  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------
  const getFile = async (s3DownloadKey) => {
    const signedUrl = (
      await s3Axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`)
    ).data;

    window.open(signedUrl, '_blank');
    //win.focus();
  };

  const handleFileDelete = (key) => {
    deleteFile(key, FILES_BUCKET, () => {
      // setDeleteOpenAlert(false);
      refetchFiles();
    });
  };
  const fileTableActions = [
    {
      icon: 'cloud_download',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${folderPath}${fileName}`;
        getFile(key);
      },
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${folderPath}${fileName}`;
        handleFileDelete(key);
      },
    },
  ];
  const uploadingFiles = (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <DropZone
          path={folderPath}
          bucket={FILES_BUCKET}
          closeDialog={() => console.log(false)}
          refetch={refetchFiles}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button onClick={() => setOpen(false)} variant="contained" color="primary">
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Table
          title="Archivos de Elemento"
          data={elementFiles?.files.map((file) => ({
            fileName: file.key.split('/').pop(),
          }))}
          columns={FILE_TABLE_HEADERS}
          actions={fileTableActions}
        />
      </Grid>
    </Grid>
  );

  const modalMode = createdEvidence || modalFileMode;
  return modalMode ? uploadingFiles : creationForm;
  // return uploadingFiles;
}

export default BackupFormulary;
