import * as yup from 'yup';
import { validateDateObject } from './../../../Constants/generalConstants';

export const validationSchema = yup.object().shape({
  contractKey: yup.string().required('Campo obligatorio'),
  country: yup.string().required('Campo obligatorio'),
  region: yup.string().required('Campo obligatorio'),
  clientType: yup.string().required('Campo obligatorio'),
  client: yup.object().shape({
    id: yup.number().required('Campo obligatorio').nullable(),
  }),
  projectType: yup.string().required('Campo obligatorio'),
  classification: yup.string().required('Campo obligatorio'),
  amount: yup.string().required('Campo obligatorio'),
  startDate: yup.date().required('Campo Obligatorio'),
  endDate: yup.date().required('Campo Obligatorio'),
  latitude: yup.number('Este valor debe ser un número').min(-90).max(90),
  longitude: yup.number('Este valor debe ser un número').min(-180).max(180),
  projectId: yup.string().required('Campo obligatorio'),
});

export const getInitialValues = (currentElement = {}, extraParams) => {
  const { historicScreen } = extraParams || {};

  const {
    amount = 0,
    classification = 'infraestructura',
    client = { id: null },
    clientType = 'publico',
    company = '',
    contractKey = '',
    country = 'mexico',
    projectId,
    projectStatus = historicScreen ? 'CLOSED' : 'ACTIVE',
    projectName = '',
    projectDescription = '',
    projectType = 'proyecto',
    region = 'Aguascalientes',
    latitude,
    longitude,
    endDate = new Date(),
    startDate = new Date(),
  } = currentElement || {};

  return {
    projectId: projectId,
    contractKey,
    country,
    region,
    clientType,
    client,
    projectType,
    classification,
    projectName,
    projectDescription,
    amount,
    startDate: validateDateObject(startDate),
    endDate: validateDateObject(endDate),
    company,
    latitude: latitude || 0,
    longitude: longitude || 0,
    projectStatus,
  };
};

export const preparePayload = () => {
  return {};
};
