import { format } from 'date-fns';
import { NO_PROJECT } from '../../../Hooks/useProjectsForCatalogs';
import { BACKEND_DATE_FORMAT } from '../../../Constants/generalConstants';

export const mapValues = (values) => {
  const result = {
    ...values,
    jobStartDate: format(values.jobStartDate, BACKEND_DATE_FORMAT),
  };
  if (values.project !== NO_PROJECT) {
    result.projects = [{ id: values.project }];
  } else {
    result.projects = [];
  }
  delete result.project;
  return result;
};
