import React, { useEffect, useState } from 'react';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { LocationOn as MarkerIcon, LocationCity, Close as CloseIcon } from '@material-ui/icons';
import { useHistory } from 'react-router';
import mapboxgl from 'mapbox-gl';
import {
  CloseContainer,
  IconContainer,
  MarkerContainer,
  MarkerTitle,
  TitleOptionsContainer,
  WarehouseIconContainer,
} from './MapComponent.styled';
import MarkerOption from '../MarkerOption/MarkerOption';
import { useCartStateContext } from '../../Store/CartState';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = ReactMapboxGl({
  accessToken:
    process.env.REACT_APP_MAPBOX_TOKEN ||
    'pk.eyJ1Ijoib2N0cGFyIiwiYSI6ImNrdW9oazB5eTMxMHkydnQ5Z21pYnFoamYifQ.J66_yFHj0basgENppg0x1A',
});

const MapComponent = () => {
  const history = useHistory();

  const {
    filteredProjects: projectsInMap,
    filteredWarehouses: warehouses,
    selectedProject,
    selectedWarehouse,
    targetProject,
    setSelectedProject,
    setSelectedWarehouse,
    setTargetProject,
  } = useCartStateContext();

  const getNorthEastCorner = () => {
    if (projectsInMap?.length + warehouses?.length) {
      const latitudes = Math.max(...[...projectsInMap, ...warehouses].map((p) => p.latitude));
      const longitudes = Math.min(...[...projectsInMap, ...warehouses].map((p) => p.longitude));
      return [longitudes, latitudes];
    }
  };

  const getSouthWestCorner = () => {
    if (projectsInMap?.length + warehouses?.length) {
      const latitudes = [...projectsInMap, ...warehouses].map((p) => Number(p.latitude));
      const longitudes = [...projectsInMap, ...warehouses].map((p) => Number(p.longitude));
      const minLat = Math.min(...latitudes);
      const maxLon = Math.max(...longitudes);
      return [maxLon, minLat];
    }
  };

  const [zoom] = useState(15);
  const [northEastCorner, setNorthEastCorner] = useState(getNorthEastCorner());
  const [southWestCorner, setSouthWestCorner] = useState(getSouthWestCorner());

  const getFitBounds = () => {
    return northEastCorner && southWestCorner
      ? [
          // Longitude, Latitude
          northEastCorner, // southwestern corner of the bounds
          southWestCorner, // northeastern corner of the bounds
        ]
      : null;
  };

  useEffect(() => {
    if (projectsInMap?.length && warehouses?.length) {
      setNorthEastCorner(getNorthEastCorner());
      setSouthWestCorner(getSouthWestCorner());
    }
  }, [projectsInMap, warehouses]);

  const markerOptions = [
    {
      label: 'Hacer proyecto destino',
      onClick: (projectId) => {
        setTargetProject(projectId);
        setSelectedProject(null);
      },
    },
    {
      label: 'Ver inventarios',
      onClick: (projectId) => {
        window.open(`/proyectos/${projectId}/inventarios`);
      },
    },
    {
      label: 'Ir a proyecto',
      onClick: (projectId) => {
        history.push(`/proyectos/${projectId}`);
      },
    },
  ];

  const renderWarehouses = () => {
    return warehouses
      ?.filter((warehouse) => {
        if (selectedProject) {
          return false;
        }
        if (!selectedWarehouse) {
          return true;
        } else {
          return selectedWarehouse.itemValue === warehouse.itemValue;
        }
      })
      .map(({ itemLabel, latitude, longitude }, index) => {
        return (
          <Marker
            key={`warehouse_${index}`}
            coordinates={[longitude, latitude]}
            anchor="bottom"
            onClick={() => {}}
          >
            <MarkerContainer>
              <WarehouseIconContainer zoom={zoom}>
                <LocationCity style={{ color: 'inherit', fontSize: 'inherit' }} />
              </WarehouseIconContainer>
              <TitleOptionsContainer>
                <MarkerTitle>{itemLabel}</MarkerTitle>
                {!!selectedWarehouse &&
                  markerOptions.map(({ label, onClick }, index) => (
                    <MarkerOption key={index} label={label} onClick={onClick} />
                  ))}
              </TitleOptionsContainer>
              {!!selectedWarehouse && (
                <CloseContainer
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedWarehouse(null);
                  }}
                >
                  <CloseIcon style={{ color: 'inherit', fontSize: 'inherit' }} />
                </CloseContainer>
              )}
            </MarkerContainer>
          </Marker>
        );
      });
  };

  const renderProjects = () => {
    return projectsInMap
      .filter((project) => {
        if (selectedWarehouse) {
          return false;
        }
        if (selectedProject) {
          return project.id === selectedProject.id;
        } else {
          return true;
        }
      })
      .map(({ latitude, longitude, projectName, id }, index) => (
        <Marker
          key={`project_${index}`}
          coordinates={[longitude, latitude]}
          // coordinates={[-100.2882779,25.6401629]}
          anchor="bottom"
          onClick={() => {
            const clickedProject = projectsInMap.find((project) => project.id === id);
            setSelectedProject(clickedProject);
          }}
        >
          <MarkerContainer>
            <IconContainer zoom={zoom} isTargetProject={targetProject?.id === id}>
              <MarkerIcon style={{ color: 'inherit', fontSize: 'inherit' }} />
            </IconContainer>
            <TitleOptionsContainer>
              <MarkerTitle>{projectName}</MarkerTitle>
              {!!selectedProject &&
                markerOptions.map((markerOption, index) => (
                  <MarkerOption
                    key={index}
                    label={markerOption.label}
                    onClick={() => markerOption.onClick(id)}
                  />
                ))}
            </TitleOptionsContainer>
            {!!selectedProject && (
              <CloseContainer
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedProject(null);
                }}
              >
                <CloseIcon style={{ color: 'inherit', fontSize: 'inherit' }} />
              </CloseContainer>
            )}
          </MarkerContainer>
        </Marker>
      ));
  };

  return getFitBounds() ? (
    <Map
      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={{
        height: '88vh',
      }}
      fitBounds={getFitBounds()}
      fitBoundsOptions={{
        padding: { top: 150, bottom: 150, left: 150, right: 150 },
        duration: 0,
      }}
    >
      {getFitBounds() ? [...renderWarehouses(), ...renderProjects()] : null}
    </Map>
  ) : null;
};

export default MapComponent;
