import React, { useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { DataContainer, CardTitle, MapContainerStyle } from './DataCardMapStyles';
import { DataCardMainContainer, MoreInfoLink } from '../DataCardStyles';
import { normalizeTitleToUrl } from '../DataCardsUtils';
import './DataCardMap.css';

mapboxgl.accessToken =
  'pk.eyJ1Ijoiam9yZ2VkdWFyZG8yNyIsImEiOiJja2puNGF6dDYwcWRuMnhuMHZ6anltODd2In0.tksmOPK9ClqJvZ9xCAMJ_g';

const Map = (props) => {
  const mapContainerRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  //const [lng, setLng] = useState(-100.31);  //may be useful if we want to dynamically change the center of the map
  //const [lat, setLat] = useState(25.68);
  //const [zoom, setZoom] = useState(5);
  const { title = 'Mapa', data = [], icon = '', onClick = '', popupEnabled = false } = props;

  const handleSeeMore = () => {
    title && history.push(`${pathname}/${normalizeTitleToUrl(title)}`);
  };

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-100.31, 25.68],
      zoom: 5,
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.on('load', function () {
      map.resize();
    });

    data.forEach((item) => {
      if (icon !== '' || item.icon) {
        const customIcon = item.icon ? item.icon : icon;
        const marker = document.createElement('div');
        marker.className = 'marker';
        marker.style = `background-image: url('${customIcon}')`;

        let MapMarker = new mapboxgl.Marker(marker).setLngLat(item.coords);

        if (popupEnabled) {
          MapMarker.setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(`<h3>${item.user}</h3><p>${item.popupData}</p>`),
          );
        }

        MapMarker.addTo(map);

        if (onClick !== '' || item.onClick) {
          MapMarker.getElement().addEventListener('click', item.onClick ? item.onClick : onClick);
        }
      }
    });

    return () => map.remove();
  }, [data]);

  return (
    <DataCardMainContainer>
      <CardTitle>{title}</CardTitle>
      <DataContainer>
        <MapContainerStyle ref={mapContainerRef} />
      </DataContainer>
      <MoreInfoLink onClick={handleSeeMore}>Ver mas ...</MoreInfoLink>
    </DataCardMainContainer>
  );
};

export default Map;
