// Icons
import Polymer from '@material-ui/icons/Polymer';
import DonutSmall from '@material-ui/icons/DonutSmall';
import SettingsBrightness from '@material-ui/icons/SettingsBrightness';
import AllOut from '@material-ui/icons/AllOut';
import LocalTaxiOutlined from '@material-ui/icons/LocalTaxiOutlined';
import CallSplit from '@material-ui/icons/CallSplit';
import { ShoppingCart, Map } from '@material-ui/icons';
import GroupIcon from '@material-ui/icons/Group';
import BackupIcon from '@material-ui/icons/Backup';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BallotIcon from '@material-ui/icons/Ballot';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ComputerIcon from '@material-ui/icons/Computer';
import BusinessIcon from '@material-ui/icons/Business';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ExploreIcon from '@material-ui/icons/Explore';
import DirectionsIcon from '@material-ui/icons/Directions';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SettingsIcons from '@material-ui/icons/Settings';
import RoomIcon from '@material-ui/icons/Room';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import LandscapeIcon from '@material-ui/icons/Landscape';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TollIcon from '@material-ui/icons/Toll';
import BuildIcon from '@material-ui/icons/Build';
import CommuteIcon from '@material-ui/icons/Commute';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { ImLab } from 'react-icons/im';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {
  CALIDAD,
  COBROS,
  DATOS_CATALOGOS,
  HISTORICOS,
  INVENTARIOS,
  PERFILES,
  PROYECTOS,
  CLIENTES,
  RECURSOS_HUMANOS,
  REGISTRO_DESCARGAS,
  USUARIOS,
  COSTOS,
  CARRITO_DE_COMPRAS,
  SOLICITUDES_COSTOS,
  ANUNCIOS,
} from './../../../Constants/grantsModules';

export const menuRouteList = [
  {
    routeName: 'Documentos Legales',
    routePath: '/menu/documentos-legales',
    Icon: SettingsBrightness,
  },
  {
    routeName: 'Inventarios',
    routePath: '/menu/inventarios',
    Icon: AllOut,
  },
  {
    routeName: 'Recursos Humanos',
    routePath: '/menu/rh',
    Icon: LocalTaxiOutlined,
  },
  {
    routeName: 'Calidad',
    routePath: '/menu/calidad',
    Icon: CallSplit,
  },
];

export const generalRouteList = [
  {
    routeName: 'Base de Concursos',
    routePath: '/general/base-concursos',
    Icon: SettingsBrightness,
  },
  {
    routeName: 'Lista de Contratos',
    routePath: '/general/lista-contratos',
    Icon: AllOut,
  },
  {
    routeName: 'Georeferencias',
    routePath: '/general/georeferencias',
    Icon: LocalTaxiOutlined,
  },
  {
    routeName: 'Parámetros / Indicadores',
    routePath: '/general/parametros-indicadores',
    Icon: CallSplit,
  },
];
export const actionsRouteList = [
  {
    routeName: 'Licitación',
    routePath: '/acciones/licitacion',
    Icon: SettingsBrightness,
  },
  {
    routeName: 'Apertura',
    routePath: '/acciones/apertura',
    Icon: AllOut,
  },
  {
    routeName: 'Desarrollo',
    routePath: '/acciones/desarrollo',
    Icon: LocalTaxiOutlined,
  },
  {
    routeName: 'Cierre',
    routePath: '/acciones/cierre',
    Icon: CallSplit,
  },
];
export const operationRouteList = [
  {
    routeName: 'Legal',
    routePath: '/operacion/licitacion',
    Icon: SettingsBrightness,
  },
  {
    routeName: 'Apertura',
    routePath: '/operacion/apertura',
    Icon: AllOut,
  },
  {
    routeName: 'Desarrollo',
    routePath: '/operacion/desarrollo',
    Icon: LocalTaxiOutlined,
  },
  {
    routeName: 'Cierre',
    routePath: '/operacion/cierre',
    Icon: CallSplit,
  },
];

// export const inventoryRouteList = [
//   {
//     routeName: 'Legal',
//     routePath: '/inventarios/licitacion',
//     Icon: SettingsBrightness,
//   },
// ];

export const inventoryRouteList = [
  {
    routeName: 'Equipo de Cómputo',
    routePath: '/inventarios/equipo_de_computo',
    Icon: ComputerIcon,
    canViewProfile: 'VER EQUIPO DE COMPUTO',
  },
  {
    routeName: 'Equipo de Oficina',
    routePath: '/inventarios/equipo_de_oficina',
    Icon: EmojiTransportationIcon,
    canViewProfile: 'VER EQUIPO DE OFICINA',
  },
  {
    routeName: 'Mobiliario',
    routePath: '/inventarios/mobiliario',
    Icon: BusinessIcon,
    canViewProfile: 'VER MOBILIARIO',
  },
  {
    routeName: 'Auscultación de Infraestructura',
    routePath: '/inventarios/auscultacion_de_infraestructura',
    Icon: ExploreIcon,
    canViewProfile: 'VER AUSCULTACION DE INFRAESTRUCTURA',
  },
  {
    routeName: 'Ingeniería de Tránsito',
    routePath: '/inventarios/ingenieria_de_transito',
    Icon: DirectionsIcon,
    canViewProfile: 'VER INGENIERIA DE TRANSITO',
  },
  {
    routeName: 'Auscultación de la Estructura',
    routePath: '/inventarios/auscultacion_de_la_estructura',
    Icon: AccountBalanceIcon,
    canViewProfile: 'VER AUSCULTACION DE LA ESTRUCTURA',
  },
  {
    routeName: 'Geología Geofísica y Georadar',
    routePath: '/inventarios/geologia_geofisica_y_georadar',
    Icon: RoomIcon,
    canViewProfile: 'VER GEOLOGIA GEOFISICA Y GEORADAR',
  },
  {
    routeName: 'Geotecnia',
    routePath: '/inventarios/geotecnia',
    Icon: GpsFixedIcon,
    canViewProfile: 'VER GEOTECNIA',
  },
  {
    routeName: 'Topografía',
    routePath: '/inventarios/topografia',
    Icon: LandscapeIcon,
    canViewProfile: 'VER TOPOGRAFIA',
  },
  {
    routeName: 'Drones',
    routePath: '/inventarios/drones',
    Icon: AirplanemodeActiveIcon,
    canViewProfile: 'VER DRONES',
  },
  {
    routeName: 'Laboratorio',
    routePath: '/inventarios/laboratorio',
    Icon: ImLab,
    canViewProfile: 'VER LABORATORIO',
  },
  {
    routeName: 'Maquinaria Pesada y/o Especial',
    routePath: '/inventarios/maquinaria_pesada_y_o_especial',
    Icon: LocalShippingIcon,
    canViewProfile: 'VER MAQUINARIA PESADA Y ESPECIAL',
  },
  {
    routeName: 'Maquinaria Ligera',
    routePath: '/inventarios/maquinaria_ligera',
    Icon: TollIcon,
    canViewProfile: 'VER MAQUINARIA LIGERA',
  },
  {
    routeName: 'Herramienta Menor',
    routePath: '/inventarios/herramienta_menor',
    Icon: BuildIcon,
    canViewProfile: 'VER HERRAMIENTA MENOR',
  },
  {
    routeName: 'Vehículos',
    routePath: '/inventarios/vehiculos',
    Icon: CommuteIcon,
    canViewProfile: 'VER VEHICULOS',
  },
  {
    routeName: 'Estado de Vehículos',
    routePath: '/inventarios/detalle-vehiculos',
    Icon: CommuteIcon,
    canViewProfile: 'VER VEHICULOS',
  },
];

export const projectRouteList = [
  {
    routeName: 'Históricos',
    routePath: '/historicos',
    module: HISTORICOS,
    Icon: BackupIcon,
  },
  {
    routeName: 'Mapa',
    routePath: '/carrito',
    hasSubRouts: false,
    module: CARRITO_DE_COMPRAS,
    Icon: Map,
  },
  {
    routeName: 'Historial de Carrito de compras',
    routePath: '/historial-carrito',
    hasSubRouts: false,
    module: CARRITO_DE_COMPRAS,
    grant: 'HISTORICOS',
    Icon: ShoppingCart,
  },
  {
    routeName: 'Proyectos',
    routePath: '/proyectos',
    hasSubRouts: false,
    module: PROYECTOS,
    Icon: AccountTreeIcon,
  },
];

export const sistemasDeGestionRouteList = [
  {
    routeName: 'Biblioteca de Calidad',
    routePath: '/biblioteca-calidad',
    module: CALIDAD,
    Icon: BackupIcon,
  },
  {
    routeName: 'Lista maestra',
    routePath: '/documentos-calidad',
    module: CALIDAD,
    Icon: BackupIcon,
  },
  {
    routeName: 'Registro de descargas',
    routePath: '/registro-descargas',
    module: REGISTRO_DESCARGAS,
    Icon: BallotIcon,
  },
  {
    routeName: 'Perfiles Sistemas de Gestión',
    routePath: '/perfiles-calidad',
    hasSubRouts: false,
    module: PERFILES,
    Icon: SupervisorAccountIcon,
  },
];

export const configurationRouteList = [
  {
    routeName: 'Datos de Catálogos',
    routePath: '/master-data',
    module: DATOS_CATALOGOS,
    Icon: DataUsageIcon,
  },
  {
    routeName: 'Anuncio pagina principal',
    routePath: '/anuncio-home',
    module: ANUNCIOS,
    Icon: DataUsageIcon,
  },
  {
    routeName: 'Usuarios',
    routePath: '/usuarios',
    module: USUARIOS,
    Icon: GroupIcon,
  },
  {
    routeName: 'Perfiles',
    routePath: '/perfiles',
    hasSubRouts: false,
    module: PERFILES,
    Icon: SupervisorAccountIcon,
  },
];

export const mainOfficeRouteList = [
  {
    routeName: 'Inventarios',
    routePath: '/inventarios',
    hasSubRouts: true,
    module: INVENTARIOS,
    Icon: PostAddIcon,
    subRouts: [...inventoryRouteList],
  },
  {
    routeName: 'Carga masiva de inventarios',
    routePath: '/inventarios/carga-masiva',
    Icon: ComputerIcon,
    module: INVENTARIOS,
    canViewProfile: 'VER CSV',
  },
  {
    routeName: 'Recursos Humanos',
    routePath: '/recursos-humanos',
    hasSubRouts: false,
    module: RECURSOS_HUMANOS,
    Icon: PeopleAltIcon,
  },
  {
    routeName: 'Clientes',
    routePath: '/clientes',
    hasSubRouts: false,
    module: CLIENTES,
    Icon: AccountTreeIcon,
  },
];

export const financesRouteList = [
  {
    routeName: 'Cobros',
    routePath: '/cobros',
    hasSubRouts: false,
    module: COBROS,
    Icon: AttachMoneyIcon,
  },
  {
    routeName: 'Costos',
    routePath: '/costos',
    hasSubRouts: false,
    module: COSTOS,
    Icon: MonetizationOnIcon,
  },
  {
    routeName: 'Solicitudes de gastos',
    routePath: '/solicitudes-costos',
    hasSubRouts: false,
    module: SOLICITUDES_COSTOS,
    Icon: MonetizationOnIcon,
  },
];
//------------------------------------------------------------------------------------------------------------------------------------
//--- IMPORTANTE ---------------------------------------------------------------------------------------------------------------------
/**
 * El atributo environment en los objetos de routeList, tiene como unico objetivo ser un filtro para los entornos de AWS
 *
 * Solo aquellas rutas con environment: [] seran mostradas en los entornos de AWS, esto para evitar que el cliente
 * se encuentre con partes incompletas del desarrollo.
 */
//------------------------------------------------------------------------------------------------------------------------------------
export const routeList = [
  {
    routeName: 'Mi Sitio',
    routePath: '/',
    Icon: DashboardIcon,
  },
  {
    routeName: 'Proyectos',
    hasSubRouts: true,
    Icon: AccountTreeIcon,
    subRouts: [...projectRouteList],
  },
  {
    routeName: 'Sistemas de Gestión',
    hasSubRouts: true,
    module: null,
    Icon: BallotIcon,
    subRouts: [...sistemasDeGestionRouteList],
  },

  {
    routeName: 'Oficina central',
    subRouts: [...mainOfficeRouteList],
    module: null,
    Icon: BallotIcon,
  },
  {
    routeName: 'Finanzas',
    routePath: '/operacion',
    subRouts: financesRouteList,
    module: null,
    Icon: MonetizationOnIcon,
  },
  {
    routeName: 'Laboratorio',
    routePath: '/laboratorio',
    hasSubRouts: false,
    module: PROYECTOS,
    Icon: ImLab,
  },
  {
    routeName: 'Configuración',
    subRouts: [...configurationRouteList],
    module: null,
    Icon: SettingsIcons,
  },
];

// Don't delete
export const exampleList = [
  {
    routeName: 'Tab Container',
    routePath: '/tab-container/tab-one',
    Icon: Polymer,
    hasSubRouts: true,
    subRouts: [
      {
        routeName: 'Tab 1',
        routePath: '/tab-container/tab-one',
        Icon: DonutSmall,
      },
      {
        routeName: 'Tab 2',
        routePath: '/tab-container/tab-two',
        Icon: DonutSmall,
      },
    ],
  },
  {
    routeName: 'Inner Rout',
    routePath: '/inner-rout',
    hasSubRouts: false,
    Icon: DonutSmall,
  },
];
