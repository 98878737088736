import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import axios from 'axios';
import queryString from 'query-string';

const CustomDropzone = (props) => {
  const { path, closeDialog, refetch, bucket, multiple, nameOverride } = props;
  // specify upload params and url for your files

  const overrideName = (oldName, newName) => {
    if (!oldName) {
      return '';
    }
    const fileTerminator = 'jpg';
    if (!fileTerminator || !newName) {
      return oldName;
    }
    return `${newName}.${fileTerminator}`;
  };

  const getUploadParams = async ({ file, meta }) => {
    const key = `${path || ''}${overrideName(meta.name, nameOverride)}`;
    const params = {
      bucket: bucket || 'respaldo-roshare',
      key,
      filetype: file.type,
    };
    const url = `https://x3v5fdavwf.execute-api.us-east-1.amazonaws.com/beta?${queryString.stringify(
      params,
    )}`;
    const postUrl = await axios.get(url);
    const headers = {
      'Content-Type': params.filetype,
    };
    return { url: postUrl.data, body: file, method: 'put', headers };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = () => {};

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
    refetch && refetch();
    closeDialog && closeDialog();
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxSize={1000000}
      onSubmit={handleSubmit}
      inputContent={'Haz click o arrastra un archivo para subirlo a la nube'}
      submitButtonContent={'Guardar'}
      inputWithFilesContent="Agregar más archivos"
      multiple={multiple}
      // accept="image/*,audio/*,video/*,.txt"
    />
  );
};

export default CustomDropzone;
