import { useContext, useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { useProjectsForCatalogs } from './useProjectsForCatalogs';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const DIRECT_COST = 'DIRECT';
export const INDIRECT_COST = 'INDIRECT';

export const FUNDREQUEST_STATUS = {
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  REQUESTED: 'REQUESTED',
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
};
export const FUNDREQUEST_DICTIONARY = {
  APPROVED: 'Aprobada',
  DRAFT: 'Borrador',
  REQUESTED: 'Solicitada',
  CLOSED: 'Cerrado',
  CANCELLED: 'Cancelado',
};

export const useFundRequests = (args) => {
  const { filters, skip, requestId } = args || {};
  const AxiosInstance = useContext(AxiosContext);
  const [fundRequests, setFundRequests] = useState(null);
  const [fundRequestId, setFundRequestId] = useState(requestId ?? null);
  const [fundRequest, setFundRequest] = useState(null);

  const projects = useProjectsForCatalogs();

  const {
    data: rawFundRequestsData,
    refetch,
    isLoading: areFundRequestsLoading,
    updateParams: updateFundRequestsParams,
  } = useFetch({
    initialUrl: '/api/project/fund-request/list',
    axiosInstance: AxiosInstance,
    initialParams: filters,
    skip,
  });

  const refetchFundRequests = () => {
    refetch();
  };

  useEffect(() => {
    if (rawFundRequestsData && !areFundRequestsLoading && projects?.length) {
      const { content } = rawFundRequestsData;
      const fundRequestsWithProjects = content.map((fundReq) => {
        const { projectCosts } = fundReq;
        const fundRequestProject = projects.find(
          ({ itemValue }) => fundReq.projectId === itemValue,
        );
        const totalRequestedAmount = projectCosts.reduce((accumulator, cost) => {
          return accumulator + cost.requestedAmount || 0;
        }, 0);
        const totalApprovedAmount = projectCosts.reduce((accumulator, cost) => {
          return accumulator + cost.approvedAmount || 0;
        }, 0);
        return {
          ...fundReq,
          projectName: fundRequestProject?.itemLabel || 'PROYECTO NO ENCONTRADO',
          totalRequestedAmount,
          totalApprovedAmount,
        };
      });
      setFundRequests(fundRequestsWithProjects);
    }
  }, [rawFundRequestsData, areFundRequestsLoading, projects]);

  useEffect(() => {
    if (fundRequests && fundRequestId !== null) {
      const newFundRequest = fundRequests.find(
        (fundRequest) => fundRequest.id === Number(fundRequestId),
      );
      setFundRequest(newFundRequest);
    }
  }, [fundRequestId, fundRequests]);

  return {
    fundRequests,
    refetchFundRequests,
    areFundRequestsLoading,
    updateFundRequestsParams,
    updateFundRequestId: setFundRequestId,
    fundRequest,
  };
};
