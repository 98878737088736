import { useContext } from 'react';
import { VEHICULOS } from './useAssetData';
import { useFetch } from './useFetch';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const useVehicles = () => {
  const axiosInstance = useContext(AxiosContext);

  const {
    data: vehicles,
    isLoading: areVehiclesLoading,
    refetch: refetchVehicles,
  } = useFetch({
    initialUrl: '/api/stock/list',
    initialParams: {
      stockType: VEHICULOS,
    },
    axiosInstance: axiosInstance,
  });

  return { vehicles, areVehiclesLoading, refetchVehicles };
};
