import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton, TableContainer, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useParams } from 'react-router-dom';
import Table from './../../Components/Table';
import {
  InputElementsContainer,
  ItemListTableMainContainer,
  DataElementContainer,
} from './ItemListTableStyles';
import Dialog from './../../Containers/GenericDialog/index';
import DropZone from './../../Components/DropZone';
import axios from './../../Utils/AxiosS3Instance';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { deleteFile } from '../../Utils/s3Utils';
import DialogActions from '../../Containers/GenericDialog/DialogActions';
import { normalizePath } from '../../Utils/normalizePath';

const FILES_BUCKET = 'roshare-laboratory';

const ItemListTable = ({ createdLabTestOrder }) => {
  const { orderNumber, samples: backendSamples } = createdLabTestOrder || {};
  const AxiosInstance = useContext(AxiosContext);
  const [newElementTitle, setNewElementTitle] = useState(null);
  const [newElementQuantity, setNewElementQuantity] = useState(0);
  const [samplePath, setSamplePath] = useState('');
  const [samples, setSamples] = useState([]);
  const [newFileDialog, setNewFileDialogOpen] = useState(false);
  const { labTestId } = useParams();

  const { data: masterData } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/masterData/list',
    initialParams: {
      entityList: ['Muestra de Laboratorio'],
    },
  });

  const {
    data: sampleFiles,
    updateParams,
    refetch: refetchFiles,
  } = useFetch({
    initialUrl: '/list-all-objects',
    initialParams: samplePath ? { path: samplePath } : undefined,
  });

  useEffect(() => {
    if (backendSamples && backendSamples.length) {
      const formatedSamples = backendSamples.map((sampleObject) => ({
        ...sampleObject,
        sampleName: sampleObject.sample.value,
      }));
      setSamples(formatedSamples);
    }
  }, [backendSamples]);

  const handleNewElementTitleChange = (event) => {
    const fullObject = masterData.find((element) => element.value === event.target.textContent);
    setNewElementTitle(fullObject);
  };

  const handleNewElementQuantityChange = (event) => {
    const numericValue = Number(event.target.value);
    const validatedValue = numericValue > 0 ? numericValue : 0;
    setNewElementQuantity(`${validatedValue}`);
  };

  const handleNewElement = async () => {
    if (!(newElementQuantity && newElementTitle)) {
      alert('No values');
      return;
    }

    const newSample = {
      quantity: newElementQuantity,
      sample: { id: newElementTitle.id },
    };

    try {
      await AxiosInstance.post(`/api/lab-test-order/${orderNumber}/sample`, newSample);
      newSample.sampleName = newElementTitle.value;
      setSamples((prev) => [...prev, newSample]);
      setNewElementQuantity(0);
      setNewElementTitle(null);
    } catch {
      alert('error creating sample');
    }
  };

  const S3_BUCKET = 'roshare-laboratory';

  const TABLE_HEADERS = [
    { title: 'Tipo de muestra', field: 'sampleName' },
    { title: 'Cantidad', field: 'quantity' },
  ];

  const FILE_TABLE_HEADERS = [{ title: 'Archivo', field: 'fileName' }];

  const getFile = async (s3DownloadKey) => {
    const signedUrl = (
      await axios.get(`/s3-object/url?key=${s3DownloadKey}&bucket=${FILES_BUCKET}`)
    ).data;

    window.open(signedUrl, '_blank');
    //win.focus();
  };

  const handleFileDelete = (key) => {
    deleteFile(key, FILES_BUCKET, () => {
      // setDeleteOpenAlert(false);
      refetchFiles();
    });
  };

  const actions = [
    {
      icon: 'edit',
      tooltip: 'editar',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        // getFile(rowElement.key);
      },
      // hidden: !allowedModuleSections.includes("EDITAR"),
    },
    {
      icon: 'folder',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { id: sampleId } = rowElement;
        const path = normalizePath(`evidencias_laboratorio/${labTestId}/${sampleId}/`);
        setSamplePath(path);
        updateParams({ path: path, bucket: S3_BUCKET });
        setNewFileDialogOpen(true);
      },
      // hidden: !allowedModuleSections.includes("VER ARCHIVOS"),
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const sampleId = rowElement.id;
        try {
          AxiosInstance.delete(`/api/lab-test-order/${orderNumber}/sample/${sampleId}`);
          setSamples((prev) => prev.filter((sample) => sample.id !== sampleId));
        } catch {
          alert('Error deleting');
        }
      },
      // hidden: !allowedModuleSections.includes("EDITAR"),
    },
  ];

  const fileTableActions = [
    {
      icon: 'cloud_download',
      tooltip: 'cargar archivo',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${samplePath}${fileName}`;
        getFile(key);
      },
    },
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { fileName } = rowElement;
        const key = `${samplePath}${fileName}`;
        handleFileDelete(key);
      },
    },
  ];

  return (
    <>
      <ItemListTableMainContainer>
        <InputElementsContainer>
          <DataElementContainer flex={2}>
            <Autocomplete
              id="client-select"
              options={masterData}
              getOptionLabel={(option) => option.value}
              value={newElementTitle}
              renderInput={(params) => (
                <TextField {...params} label="Tipo de muestra" variant="outlined" />
              )}
              onChange={handleNewElementTitleChange}
            />
          </DataElementContainer>
          <DataElementContainer flex={1}>
            <TextField
              style={{ width: '100%' }}
              label="Piezas"
              type="number"
              variant="outlined"
              value={newElementQuantity}
              onChange={handleNewElementQuantityChange}
            />
          </DataElementContainer>
          <DataElementContainer>
            <IconButton aria-label="add" size="medium" onClick={handleNewElement}>
              <AddIcon fontSize="inherit" color="primary" />
            </IconButton>
          </DataElementContainer>
        </InputElementsContainer>
        <TableContainer>
          <Table
            title="Muestras de la solicitud"
            columns={TABLE_HEADERS}
            data={samples}
            actions={actions}
          />
        </TableContainer>
      </ItemListTableMainContainer>
      <Dialog openDialog={newFileDialog} dialogTitle={'Archivos de muestra'} maxWidth="md">
        <DropZone
          path={samplePath}
          closeDialog={() => setNewFileDialogOpen(false)}
          refetch={() => {
            refetchFiles();
          }}
          bucket={FILES_BUCKET}
        />
        <Table
          title="Archivos de la muestra"
          data={(sampleFiles?.files || []).map((file) => {
            const fileName = file.key.split('/').pop();
            return {
              fileName,
            };
          })}
          columns={FILE_TABLE_HEADERS}
          actions={fileTableActions}
          options={{
            actionsColumnIndex: -1,
            pageSize: 5,
          }}
        />
        <DialogActions
          secondaryLabel="Cancelar"
          primaryLabel={null}
          secondaryAction={() => setNewFileDialogOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default ItemListTable;
