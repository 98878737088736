import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import ErrorScreen from './../../Screens/ErrorScreen';
import { userContext } from '../../Contexts/UserContext/UserContext';
const GrantedRoute = (props) => {
  const [userState] = useContext(userContext);
  const { grantModule, specificGrants } = props;
  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModules = grants.map((grant) => grant.module);

  if (!grantModule) {
    return <Route {...props} />;
  }

  const getSpecificBoolean = () => {
    if (specificGrants?.length) {
      const allowedModuleSections = grants
        .filter((grant) => grant.module === grantModule)
        .map((grant) => grant.name);
      const includes = allowedModuleSections.reduce((accumulator, current) => {
        return specificGrants.includes(current) || accumulator;
      }, false);
      return includes;
    } else {
      return true;
    }
  };

  if (
    allowedModules.includes('MASTER') &&
    allowedModules.includes(grantModule) &&
    getSpecificBoolean()
  ) {
    return <Route {...props} />;
  } else {
    return (
      <Route {...props}>
        <ErrorScreen notgranted />
      </Route>
    );
  }
};

export default GrantedRoute;
