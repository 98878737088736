import { useContext, useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const useClients = () => {
  const AxiosInstance = useContext(AxiosContext);
  const [clients, setClients] = useState(null);

  const { data: clientData, isLoading } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/client/list`,
  });

  const sortingClient = (a, b) => {
    if (a.clientName > b.clientName) {
      return 1;
    }
    if (b.clientName > a.clientName) {
      return -1;
    }
    return 0;
  };

  const mappingClient = (client) => ({ itemLabel: client.clientName, itemValue: client.id });

  useEffect(() => {
    if (clientData) {
      setClients(clientData.sort(sortingClient).map(mappingClient));
    }
  }, [clientData]);

  return { clients, areClientsLoading: isLoading };
};
