import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CircularProgress, Grid } from '@material-ui/core';
import { useStyles } from './PaymentScreenStyles';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Table from '../../Components/Table';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';

const PaymentScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------
  let { project } = useParams();
  const history = useHistory();
  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [fullProjects, setFullProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);

  //---------------------------------------------------------------------------
  // useFetch hooks
  // //---------------------------------------------------------------------------
  const { data: projects } = useFetch({
    axiosInstance: AxiosInstance,
    // initialParams: { stockType: tableConfiguration?.stockType },
    initialUrl: `/api/project/list`,
  });

  //---------------------------------------------------------------------------
  // useEffect hooks;
  //---------------------------------------------------------------------------
  useEffect(() => {
    const fillEstimations = async () => {
      if (projects && AxiosInstance) {
        setLoadingProjects(true);
        const promises = projects.map(async (project) => {
          const { data: fullProjectData } = await AxiosInstance.get(`/api/project/${project.id}`);
          return fullProjectData;
        });
        const projectsData = await Promise.all(promises);
        setLoadingProjects(false);
        setFullProjects(projectsData);
      }
    };
    if (projects?.length) {
      fillEstimations();
    }
  }, [projects]);

  useEffect(() => {}, [project]);
  //---------------------------------------------------------------------------
  const mapProjectElements = (element) => {
    console.log(element);
    const { projectId, estimations, id, projectName } = element;

    const paidEstimations = estimations.filter((estimation) => {
      return estimation.estimationStatus === 'PAID';
    });
    const unPaidEstimations = estimations.filter((estimation) => {
      return estimation.estimationStatus !== 'PAID';
    });

    const paidAmount = paidEstimations.reduce((accumulator, current) => {
      return accumulator + current.estimationTotal;
    }, 0);
    const unPaidAmount = unPaidEstimations.reduce((accumulator, current) => {
      return accumulator + current.estimationTotal;
    }, 0);

    const toBePaidEstimations = unPaidEstimations.length;
    const _element = {
      projectName: `Proyecto ${projectId} - ${projectName || ''}`,
      paidEstimations: paidEstimations.length,
      paidAmount: `$ ${paidAmount}`,
      unPaidAmount: `$ ${unPaidAmount}`,
      toBePaidEstimations,
      dbProjectId: id,
    };
    return _element;
  };

  //---------------------------------------------------------------------------
  // S3 interaction functions.
  //---------------------------------------------------------------------------

  const handleProyectSelection = (event, rowElement) => {
    const { dbProjectId } = rowElement;
    history.push(`/cobros/${dbProjectId}`);
  };
  //---------------------------------------------------------------------------
  // Table Actions
  //---------------------------------------------------------------------------
  const PROJECT_TABLE_HEADERS = [
    { title: 'Nombre de Proyecto', field: 'projectName' },
    { title: 'Est. Pagadas', field: 'paidEstimations' },
    { title: 'Total Cobrado', field: 'paidAmount' },
    { title: 'Est. No Pagadas', field: 'toBePaidEstimations' },
    { title: 'Total por cobrar', field: 'unPaidAmount' },
  ];

  const projectTableActions = [
    {
      icon: 'remove_red_eye',
      tooltip: 'Ver Detalles',
      iconProps: {
        color: 'secondary',
      },
      onClick: handleProyectSelection,
    },
  ];
  //---------------------------------------------------------------------------

  const AllProjectsTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Table
            title={'Proyectos'}
            data={(fullProjects || []).map(mapProjectElements)}
            columns={PROJECT_TABLE_HEADERS}
            actions={projectTableActions}
            onRowClick={handleProyectSelection}
          />
        </Grid>
      </Grid>
    </>
  );

  const renderScreen = () => {
    const tableToRender = AllProjectsTable;

    if (!loadingProjects) {
      return tableToRender;
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <div className={styles.container}>
      <h1>Cobranza de estimaciones</h1>
      {renderScreen()}
    </div>
  );
};

export default withNavigation(PaymentScreen);
