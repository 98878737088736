import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { userContext } from '../../Contexts/UserContext/UserContext';
import { useProjectsAuthorizedUsers } from '../../Hooks/useProjectsForCatalogs';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    overflow: 'visible',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loader: {
    margin: 'auto',
  },
  ulStyle: {
    marginTop: 0,
  },
  buttonsColumn: {
    padding: '15px',
  },
  documentsContainer: {
    padding: '10px',
  },
  mainContainer: {
    padding: '10px',
    border: 'solid #f0f0f0 1px',
    borderRadius: '5px',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default function ProjectsAvailable() {
  const classes = useStyles();
  const [userState] = useContext(userContext);
  const history = useHistory();

  const { userProjects } = useProjectsAuthorizedUsers(userState.username);

  const cardContentComponent = (projs) => (
    <>
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          md={12}
          container
          direction="column"
          className={classes.documentsContainer}
        >
          {projectsListComponent(projs)}
        </Grid>
      </Grid>
    </>
  );

  const projectsListComponent = (projs) => {
    return (
      <>
        {projs.map((proj, index) => (
          <Box className={classes.mainContainer} key={index}>
            <Typography
              variant="body1"
              color="primary"
            >{`${proj.projectId} - ${proj.projectName}`}</Typography>
            <Button
              variant="contained"
              onClick={() => {
                history.push(`/proyectos/${proj.id}`);
              }}
            >
              Abrir
            </Button>
          </Box>
        ))}
      </>
    );
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Mis proyectos" />
      {!userProjects ? (
        <CircularProgress size={60} className={classes.loader} />
      ) : (
        cardContentComponent(userProjects)
      )}
    </Card>
  );
}
