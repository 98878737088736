import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { useStyles } from './PaymentScreenStyles';
import Dialog from './../../Containers/GenericDialog';
import NewPaymentDocument from './NewPaymentDocument/NewPaymentDocument';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import Table from '../../Components/Table';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import Alert, { WARNING_TYPE } from '../../Components/Alert/Alert';
import {
  INVOICED,
  PAID,
} from '../ProjectCatalogScreens/EstimacionesScreen/DependencyFeedbackDialog/DependencyFeedbackDialog';

const ProjectPaymentsScreen = () => {
  //---------------------------------------------------------------------------
  // STYLES
  //---------------------------------------------------------------------------
  const styles = useStyles();

  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const AxiosInstance = useContext(AxiosContext);
  //---------------------------------------------------------------------------
  let { project } = useParams();
  const history = useHistory();
  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [selectedEstimation, setSelectedEstimation] = useState(null);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [noFileAlert, setNoFileAlert] = useState(false);
  const [approvalConfirmation, setApprovalConfirmation] = useState(false);
  const [alreadyPaidWarning, setAlreadyPaidWarning] = useState(false);
  const [estimationToApprove, setEstimationToApprove] = useState(null);

  //---------------------------------------------------------------------------
  // useFetch hooks
  // //---------------------------------------------------------------------------
  const { data: selectedProject, isLoading: isSelectedProjectLoading } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/${project}`,
    skip: !project,
  });

  //---------------------------------------------------------------------------

  const mapProjectEstimations = (estimation) => {
    const {
      project: { projectName, projectId },
      estimationStatus,
      estimationTotal,
      creationDate,
      id,
      period,
      contractNumber,
      convenio,
    } = estimation;
    const _estimation = {
      project,
      projectId,
      projectIdString: `${projectName}`,
      projectNumber: projectId,
      estimationStatus,
      estimationTotal,
      estimationTotalString: `$ ${estimationTotal}`,
      creationDate,
      id,
      period,
      contractNumber,
      convenio,
    };
    return _estimation;
  };

  //---------------------------------------------------------------------------
  const ESTIMATION_TABLE_HEADERS = [
    { title: 'Nombre Proyecto', field: 'projectIdString' },
    { title: 'Número de proyecto', field: 'projectNumber' },
    { title: 'Monto', field: 'estimationTotalString' },
    { title: 'Periodo', field: 'period' },
    { title: 'No. De Estimación', field: 'contractNumber' },
    { title: 'No. De Contrato o de Convenio', field: 'convenio' },
    {
      title: 'Estado',
      field: 'estimationStatus',
      render: (rowData) => {
        switch (rowData.estimationStatus) {
          case PAID:
            return 'Pagada';
          case INVOICED:
            return 'Facturada';
          default:
            return '';
        }
      },
    },
    { title: 'Fecha de creación', field: 'creationDate' },
  ];

  const estimationTableActions = [
    {
      icon: 'folder',
      tooltip: 'Ver Comprobantes',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        const { estimations } = selectedProject;
        const { id } = rowElement;
        const estimationToSelect = estimations.find((estimation) => estimation.id == id);
        setSelectedEstimation(estimationToSelect);
        setFileModalOpen(true);
      },
    },
    {
      icon: 'local_atm',
      tooltip: 'Marcar como pagado',
      iconProps: {
        color: 'secondary',
      },
      onClick: async (event, rowElement) => {
        const { id: estimationId } = rowElement;

        if (rowElement.estimationStatusString !== 'Pagada') {
          setEstimationToApprove(estimationId);
          setApprovalConfirmation(true);
        } else {
          setAlreadyPaidWarning(true);
        }
      },
    },
    {
      icon: () => {
        return (
          <Button variant="contained" color="primary">
            Regresar
          </Button>
        );
      },
      tooltip: 'New Record',
      iconProps: {
        color: 'secondary',
      },
      onClick: () => {
        // setSelectedProject(null);
        history.push('/cobros');
      },
      isFreeAction: true,
    },
  ];
  //---------------------------------------------------------------------------

  const SingleProjectTable = (
    <>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
        <Grid item>
          <Table
            title={'Proyectos'}
            data={(selectedProject?.estimations || [])
              .filter(({ estimationStatus }) => [PAID, INVOICED].includes(estimationStatus))
              .map(mapProjectEstimations)}
            columns={ESTIMATION_TABLE_HEADERS}
            actions={estimationTableActions}
            onRowClick={() => alert('estimation details')}
          />
        </Grid>
      </Grid>
      <Dialog openDialog={fileModalOpen} dialogTitle={'Evidencias de pago'} maxWidth="md">
        <NewPaymentDocument
          currentEstimation={selectedEstimation}
          currentProject={selectedProject}
          setOpen={setFileModalOpen}
        />
      </Dialog>
      <Alert
        type={WARNING_TYPE}
        title="Accion irreversible"
        content="Está por confirmar un pago de una estimación, esta acción es irreversible. ¿Seguro que desea continuar?"
        actions={[
          {
            text: 'Aprobar',
            onClick: async () => {
              const data = JSON.stringify('PAID');

              const config = {
                method: 'put',
                url: `/api/estimation/${estimationToApprove}/update-status`,
                data: data,
                headers: {
                  'Content-Type': 'application/json',
                },
              };
              await AxiosInstance(config);
              setApprovalConfirmation(false);
              setEstimationToApprove(null);
            },
          },
          {
            text: 'Cancelar',
            onClick: async () => {
              setApprovalConfirmation(false);
              setEstimationToApprove(null);
            },
          },
        ]}
        open={approvalConfirmation}
        onClose={() => setApprovalConfirmation(false)}
        onBackdropPress={() => {
          setApprovalConfirmation(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Acción no permitida"
        content="No es posible aprobar una estimación si no se ha cargado mínimo un archivo como comprobante de pago"
        actions={[
          {
            text: 'OK',
            onClick: () => {
              setNoFileAlert(false);
            },
          },
        ]}
        open={noFileAlert}
        onClose={() => setNoFileAlert(false)}
        onBackdropPress={() => {
          setNoFileAlert(false);
        }}
      />
      <Alert
        type={WARNING_TYPE}
        title="Acción no permitida"
        content="Esta estimación ya ha sido catalogada como pagada, no es necesario hacerlo nuevamente"
        actions={[
          {
            text: 'OK',
            onClick: () => {
              setAlreadyPaidWarning(false);
            },
          },
        ]}
        open={alreadyPaidWarning}
        onClose={() => setAlreadyPaidWarning(false)}
        onBackdropPress={() => {
          setAlreadyPaidWarning(false);
        }}
      />
    </>
  );

  const renderScreen = () => {
    if (!isSelectedProjectLoading) {
      return SingleProjectTable;
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <div className={styles.container}>
      <h1>Cobranza de estimaciones</h1>
      {renderScreen()}
    </div>
  );
};

export default withNavigation(ProjectPaymentsScreen);
