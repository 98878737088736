import React from 'react';
import { useParams, useLocation } from 'react-router';
import queryString from 'query-string';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import LaboratoryDashboard from '../LaboratoryDashboard/LaboratoryDashboard';
import LabWorkDetails from '../LabWorkDetails/LabWorkDetails';
import NewLabworkScreen from '../NewLabworkScreen';

const LaboratorySreen = () => {
  const { labTestId } = useParams();
  const location = useLocation();
  const { action } = queryString.parse(location.search.replace(/\?/g, ''));

  const renderScreen = () => {
    if (labTestId) {
      if (labTestId === 'new') {
        return <NewLabworkScreen />;
      } else {
        switch (action) {
          case 'edit':
            return <NewLabworkScreen />;
          default:
            return <LabWorkDetails />;
        }
      }
    } else {
      return <LaboratoryDashboard />;
    }
  };

  return renderScreen();
};

export default withNavigation(LaboratorySreen);
