import { ShoppingCart } from '@material-ui/icons';
import {
  AUSCULTACION,
  AUSCULTACION_ESTRUCTURA,
  DRONES,
  EQUIPO_COMPUTO,
  EQUIPO_OFICINA,
  GEOFISICA,
  GEOTECNIA,
  HERRAMIENTAS,
  INGENIERIA_TRANSITO,
  LABORATORIO,
  MAQUINARIA,
  MAQUINARIA_PESADA,
  MOBILIARIO,
  TOPOGRAFIA,
  VEHICULOS,
} from '../../Hooks/useAssetData';

const stockMapper = (element) => {
  const { projects, stockId, warehouse, responsible, description, stockType, equipment, id } =
    element;
  const projectName = projects[0]
    ? `${projects[0].projectName} - ${projects[0].projectId}`
    : 'Sin asignar';
  const { value: wareHouseName = 'Almacén no asignado' } = warehouse || {};

  const { names, lastName1, lastName2 } = responsible || {};
  const responsibleName = responsible ? `${names} ${lastName1} ${lastName2}` : 'Sin responsable';

  return {
    id,
    projectName,
    stockId,
    wareHouseName,
    responsibleName,
    responsible,
    description,
    stockType,
    equipment,
  };
};

export const SELECTABLE_ELEMENTS = [
  {
    title: 'Equipo de Cómputo',
    icon: ShoppingCart,
    assetType: EQUIPO_COMPUTO,
    mapper: stockMapper,
  },
  {
    title: 'Equipo de Oficina',
    icon: ShoppingCart,
    assetType: EQUIPO_OFICINA,
    mapper: stockMapper,
  },
  {
    title: 'Mobiliario',
    icon: ShoppingCart,
    assetType: MOBILIARIO,
    mapper: stockMapper,
  },
  {
    title: 'Auscultación de Infraestructura',
    icon: ShoppingCart,
    assetType: AUSCULTACION,
    mapper: stockMapper,
  },
  {
    title: 'Ingeniería de Tránsito',
    icon: ShoppingCart,
    assetType: INGENIERIA_TRANSITO,
    mapper: stockMapper,
  },
  {
    title: 'Auscultación de la Estructura',
    icon: ShoppingCart,
    assetType: AUSCULTACION_ESTRUCTURA,
    mapper: stockMapper,
  },
  {
    title: 'Geología Geofísica y Georadar',
    icon: ShoppingCart,
    assetType: GEOFISICA,
    mapper: stockMapper,
  },
  {
    title: 'Geotecnia',
    icon: ShoppingCart,
    assetType: GEOTECNIA,
    mapper: stockMapper,
  },
  {
    title: 'Topografía',
    icon: ShoppingCart,
    assetType: TOPOGRAFIA,
    mapper: stockMapper,
  },
  {
    title: 'Drones',
    icon: ShoppingCart,
    assetType: DRONES,
    mapper: stockMapper,
  },
  {
    title: 'Laboratorio',
    icon: ShoppingCart,
    assetType: LABORATORIO,
    mapper: stockMapper,
  },
  {
    title: 'Maquinaria Pesada y/o Especial',
    icon: ShoppingCart,
    assetType: MAQUINARIA_PESADA,
    mapper: stockMapper,
  },
  {
    title: 'Maquinaria Ligera',
    icon: ShoppingCart,
    assetType: MAQUINARIA,
    mapper: stockMapper,
  },
  {
    title: 'Herramienta Menor',
    icon: ShoppingCart,
    assetType: HERRAMIENTAS,
    mapper: stockMapper,
  },
  {
    title: 'Vehículos',
    icon: ShoppingCart,
    assetType: VEHICULOS,
    mapper: stockMapper,
  },
];
