import React, { useContext, useState, useEffect } from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  IconButton,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import VerifiedUser from '@material-ui/icons/VerifiedUser';

import { useStyles } from './MenuStyle';
import MenuListItem from './MenuListItem/MenuListItem';
import { userContext } from './../../../Contexts/UserContext/UserContext';
import { routeList } from './routeList';
import UserMenu from '../UserMenu';
import { useFetch } from '../../../Hooks/useFetch';
import { AxiosContext } from '../../../Contexts/AxiosContext/AxiosContext';

const Menu = (props) => {
  const classes = useStyles();
  const { displayDesktopMenu, setDisplayDesktopMenu } = props;

  const [userState, setUserState] = useContext(userContext);
  const AxiosInstance = useContext(AxiosContext);
  const [parentOfUserMenu, setParentOfUserMenu] = useState(null);
  const [mouseIsOnMenu, setMouseIsOnMenu] = useState(false);

  const { data: userDetails } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/user/me',
  });

  const handleExit = () => {
    setUserState({});
  };

  const handleMouseHoverEnter = () => {
    setMouseIsOnMenu(true);
    setDisplayDesktopMenu(true);
  };

  const handleMouseHoverLeave = () => {
    setMouseIsOnMenu(false);
  };

  useEffect(() => {
    const closeMenu = () => {
      if (mouseIsOnMenu === false) setDisplayDesktopMenu(false);
    };

    const intervalRef = setInterval(closeMenu, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      clearInterval(intervalRef);
    };
  }, [displayDesktopMenu, mouseIsOnMenu]);

  useEffect(() => {
    if (userDetails) {
      setUserState({ ...userState, userDetails });
    }
  }, [userDetails]);

  const ClosedMenuHeader = (
    <IconButton onClick={() => setDisplayDesktopMenu(true)}>
      <ArrowForwardIosIcon />
    </IconButton>
  );

  const OpenMenuHeader = (
    <Grid container direction="row">
      <Grid item xs={10}>
        <ListItem
          button
          onClick={(event) => {
            setParentOfUserMenu(event.currentTarget);
          }}
        >
          <ListItemIcon>
            <VerifiedUser className={classes.userIcon} style={{ fontSize: 41 }} />
          </ListItemIcon>
          <ListItemText
            style={{ color: 'white' }}
            primary={userDetails?.name || userState?.username || ''}
          />
        </ListItem>
      </Grid>
      <Grid item xs={2}>
        <IconButton
          onClick={() => setDisplayDesktopMenu(false)}
          style={{ maxWidth: '90%', minHeight: '80%', marginTop: '3px' }}
        >
          <ArrowBackIosIcon style={{ paddingLeft: '10px', color: 'white' }} />
        </IconButton>
      </Grid>
    </Grid>
  );

  const MenuElements = (
    <div
      aria-hidden="true"
      onFocus={() => {}}
      onMouseEnter={handleMouseHoverEnter}
      onMouseLeave={handleMouseHoverLeave}
      onMouseOver={handleMouseHoverEnter}
    >
      {displayDesktopMenu ? OpenMenuHeader : ClosedMenuHeader}
      <Divider />
      <List>
        {/* AQUI IMPLEMENTAR LÓGICA DE FILTRO DE MODULOS EN EL DRAWER Jorge Eduardo */}
        {routeList
          .filter((route) =>
            window?.location?.href?.includes('localhost') || window?.location?.href?.includes('qa')
              ? true
              : !route?.environment?.includes('QA'),
          )
          .filter((route) => {
            const { grants = [] } = userDetails?.profile || {};
            const allowedModules = grants.map((grant) => grant.module);
            const { module: routeModule, grant: routeGrant } = route;
            const allowedGrants = grants
              .filter((grant) => grant.module === routeModule)
              .map((grant) => grant.name);
            const hasGrant = routeGrant ? allowedGrants.includes(routeGrant) : true;
            const hasModule = routeModule ? allowedModules.includes(routeModule) : true;
            return hasModule && hasGrant;
          })
          .map((element, index) =>
            displayDesktopMenu ? (
              <MenuListItem
                element={element}
                index={index}
                key={`${index}`}
                displayText={displayDesktopMenu}
              />
            ) : (
              <ListItem key={index} style={{ marginRight: 0, paddingRight: 0 }}>
                {' '}
                <ListItemIcon style={{ color: 'white' }}>
                  {' '}
                  <element.Icon />{' '}
                </ListItemIcon>{' '}
              </ListItem>
            ),
          )}
      </List>
      <UserMenu
        parentOfUserMenu={parentOfUserMenu}
        setParentOfUserMenu={setParentOfUserMenu}
        handleExit={handleExit}
      />
    </div>
  );

  return MenuElements;
};

export default Menu;
