import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ButtonPadContainer } from './ButtonPadStyles';
import ButtonElement from './ButtonElement';

const ButtonPad = (props) => {
  const { title, types, selectType } = props;

  return (
    <ButtonPadContainer>
      <Typography variant="h4" style={{ marginBottom: '1rem' }}>
        {title || ''}
      </Typography>
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={3}>
        {types.map((type, index) => (
          <ButtonElement
            title={type?.label}
            onClick={() => selectType(type)}
            key={`${type.label}_${index}`}
            icon={type?.icon}
          />
        ))}
      </Grid>
    </ButtonPadContainer>
  );
};

export default ButtonPad;
