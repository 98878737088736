import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  container: {},
  button: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
}));

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
`;

export const ProfileHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProfilesTitle = styled.h1`
  font-size: 30px;
  font-weight: 200;
  flex: 1;
  margin: 0;
  padding: 0;
  text-align: center;
`;
export const FormContainer = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
`;
