import { Button } from '@material-ui/core';
import React from 'react';
import { EDITING } from '../../hooks/useUrlData';

const SubmitButton = ({ touched, action, isSubmitting }) => {
  const hasChanged = (touched) => {
    return !!Object.keys(touched || {}).length;
  };

  const getButtonText = () => {
    if (!hasChanged(touched)) {
      return 'No hay cambios';
    } else {
      return action === EDITING ? 'Guardar cambios' : 'Crear';
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      style={{ width: '100%' }}
      disabled={isSubmitting || !hasChanged(touched)}
    >
      {getButtonText()}
    </Button>
  );
};

export default SubmitButton;
