const getBrowserVersion = () => {
  const { userAgent } = navigator;

  if (userAgent.includes('Firefox/')) {
    // Firefox
    const afterChrome = userAgent.split('Firefox/')[1];
    const version = afterChrome.split(' ')[0];

    return { version: version, browser: 'Firefox' };
  } else if (userAgent.includes('Chrome/')) {
    const afterChrome = userAgent.split('Chrome/')[1];
    const version = afterChrome.split(' ')[0];

    return { version: version, browser: 'Chrome' };
  } else if (userAgent.includes('Safari/')) {
    const afterChrome = userAgent.split('Safari/')[1];
    const version = afterChrome.split(' ')[0];

    return { version: version, browser: 'Safari' };
    // Safari
  }
};

export default getBrowserVersion;
