import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from './../../../../Components/Table';
import { useCartStateContext } from '../../Store/CartState';
import { pdfRequerimientoDeRecursos } from '../../../../Utils/pdfCreator';
import { userContext } from '../../../../Contexts/UserContext/UserContext';

const CartModal = () => {
  const [userState] = useContext(userContext);

  const {
    cart,
    humanResourcesCart,
    removeStockFromCart,
    modalVisibility,
    setModalVisibility,
    targetProject,
  } = useCartStateContext();

  const onClose = () => setModalVisibility(false);
  const onBackdropPress = () => setModalVisibility(false);

  const generatePDF = async () => {
    if (targetProject?.projectName) {
      await pdfRequerimientoDeRecursos(
        true,
        {
          stockElements: cart,
          employeesElements: humanResourcesCart,
        },
        targetProject.projectName,
        userState?.username,
      );
      setModalVisibility(false);
    } else {
      alert('Por favor selecciona un proyecto destino');
    }
  };
  const TABLE_HEADERS = [
    { title: 'Tipo de Inventario', field: 'inventoryType' },
    { title: 'Equipo', field: 'equipment' },
    { title: '# de Inventario', field: 'stockId' },
    { title: 'Descripción', field: 'description' },
    { title: 'Status', field: 'statusValue' },
    { title: 'Responsable', field: 'responsibleName' },
    { title: 'Proyecto', field: 'project' },
  ];
  const EMPLOYEES_TABLE_HEADERS = [
    { title: 'Nombre', field: 'name' },
    { title: 'Área', field: 'area' },
    { title: 'E-Mail', field: 'email' },
  ];

  const actions = [
    {
      icon: 'delete',
      tooltip: 'eliminar',
      iconProps: {
        color: 'secondary',
      },
      onClick: (event, rowElement) => {
        removeStockFromCart(rowElement);
      },
    },
  ];

  const mapData = (stock) => {
    const { responsible, projects, status } = stock;

    const project = projects?.length ? projects[0].projectName : 'Sin proyecto asignado';
    const { names, lastName1, lastName2 } = responsible || {};
    const responsibleName = responsible ? `${names} ${lastName1} ${lastName2}` : 'Sin responsable';
    const statusValue = status.value;
    return { ...stock, project, responsibleName, statusValue };
  };
  const mapEmployeeData = (employee) => {
    const { names, lastName1, lastName2, area, email } = employee;
    const tableObject = {
      employee,
      name: `${names} ${lastName1} ${lastName2}`,
      area: area?.value || 'Sin área',
      email: email || 'Sin email registrado',
    };
    return tableObject;
  };

  return (
    <Dialog
      open={modalVisibility}
      onClose={onClose}
      onBackdropClick={onBackdropPress}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      style={{ padding: '50px' }}
      fullScreen={true}
    >
      <DialogTitle id="alert-dialog-title">Elementos seleccionados</DialogTitle>
      <DialogContent
        style={{
          fontSize: '20px',
          justifyContent: 'center',
          textAlign: 'center',
          paddingTop: '30px',
          paddingBottom: '30px',
        }}
      >
        <Table
          title={`Elementos seleccionados`}
          data={(cart || []).map(mapData)}
          columns={TABLE_HEADERS}
          actions={actions}
        />
        <Table
          title={`Empleados seleccionados`}
          data={(humanResourcesCart || []).map(mapEmployeeData)}
          columns={EMPLOYEES_TABLE_HEADERS}
          actions={actions}
        />
      </DialogContent>
      <DialogActions>
        {userState?.username && targetProject && (
          <Button onClick={() => generatePDF()}>Crear Requisición</Button>
        )}
        <Button onClick={() => setModalVisibility(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CartModal;
