import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';

import { useStyles } from './FormikSelectStyle';

function FormikSelectDisplay({
  formikName,
  label,
  Icon,
  helperLabel,
  menuItemList,
  props,
  disabled,
  secondaryIconColor,
  centerText,
  customOnBlur,
  customOnChange,
}) {
  const { field, form } = props;
  const { value } = field;
  const { handleChange, handleBlur, touched, errors } = form;
  const styles = useStyles();
  const error = touched[formikName] && errors[formikName];

  const normalizeValue = (value) => (typeof value === 'string' ? value.toUpperCase() : value);

  const localOnChange = (event) => {
    handleChange(event);
    customOnChange && customOnChange(event);
  };

  return (
    <TextField
      id={formikName}
      select
      label={label}
      className={`${styles.textField} ${centerText ? styles.centerTexField : ''}`}
      value={value}
      onChange={localOnChange}
      onBlur={customOnBlur || handleBlur}
      size="small"
      SelectProps={{
        MenuProps: {
          className: styles.menu,
        },
      }}
      InputProps={{
        name: formikName,
        id: formikName,
        startAdornment: Icon && (
          <InputAdornment position="start">
            <Icon
              className={
                error ? styles.iconError : secondaryIconColor ? styles.iconSecondary : styles.icon
              }
              style={{ fontSize: 20 }}
            />
          </InputAdornment>
        ),
      }}
      helperText={error ? error : helperLabel}
      variant="outlined"
      disabled={disabled}
      fullWidth
      error={error ? true : false}
    >
      {menuItemList &&
        menuItemList.map(({ itemValue, itemLabel }) => (
          // <Tooltip title={itemLabel ? normalizeValue(itemLabel) : ""}>
          <MenuItem key={itemValue} value={itemValue ? normalizeValue(itemValue) : ''}>
            {itemLabel ? normalizeValue(itemLabel) : ''}
          </MenuItem>
          // </Tooltip>
        ))}
    </TextField>
  );
}

export default FormikSelectDisplay;
