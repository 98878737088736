import styled from 'styled-components';

export const MainSpreadSheetContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  justify-items: stretch;
  align-items: center;
  justify-content: stretch;
  align-content: start;
  margin: 0;
`;

export const CellText = styled.p`
  ${(props) =>
    props.column === 1 || props.header || props.last
      ? `
        background-color: #F9F9FA;
        position: sticky;
        padding: 5px;
        border-bottom: 1px solid #E3E4E4;
        `
      : ''}
  font-weight: ${(props) => (props.header || props.fixed ? 500 : 300)};
  ${(props) => ((props.column === 1 || props.last) && props.header ? 'z-index:1000;' : '')}
  font-size: 14px;
  padding: 5px;
  ${(props) => (props.column === 1 ? 'left: 0;' : '')}
  ${(props) => (props.header ? 'top: 0;' : '')}
    ${(props) => (props.last ? 'right: 0;' : '')}
    margin:0;
  grid-column-start: ${(props) => props.column};
  grid-row-start: ${(props) => props.row};
  border: solid #f0f0f0 1px;
  white-space: nowrap;
  text-align: center;
  ${(props) =>
    props.empty
      ? `
        background-color: white;
        border: none;
        color: white;
        padding: 0;
    `
      : ''}
`;
