import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formulary: {
    width: '100%',
  },
  divider: {
    margin: '2rem 0',
  },
  img: {
    maxHeight: '8rem',
  },
  paper: {
    width: theme.spacing(46),
    height: theme.spacing(46),
  },
  button: {
    [theme.breakpoints.up('md')]: {
      padding: '0 40rem',
    },
  },
}));
