import { Grid } from '@material-ui/core';
import React from 'react';

const FoldersContainer = (props) => {
  const { children, xs } = props;
  return (
    <Grid container direction="row">
      {React.Children.map(children, (child) => {
        return (
          <Grid item xs={xs}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FoldersContainer;
