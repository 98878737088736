import React from 'react';
import {
  TimeWarningText,
  StatusIndicatorContainer,
  StatusIndicatorCircle,
  RowContainer,
} from './ExpiringDateIndicator.styled';
import { getWarningLevel } from '../../utils/getWarningLevel';

const ExpiringDateIndicator = ({ currentDate }) => {
  const warningLevel = getWarningLevel(currentDate);

  return currentDate ? (
    <RowContainer>
      <TimeWarningText warningLevel={warningLevel}>{currentDate}</TimeWarningText>
      {!!currentDate && (
        <StatusIndicatorContainer>
          <StatusIndicatorCircle warningLevel={warningLevel} />
        </StatusIndicatorContainer>
      )}
    </RowContainer>
  ) : (
    <></>
  );
};

export default ExpiringDateIndicator;
