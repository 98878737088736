import { jsPDF } from 'jspdf';
import { format } from 'date-fns';
import { postNewFile } from './s3Utils';

export const pdfRequerimientoDeRecursos = async (upload, values, targetProject, userName) => {
  try {
    const { stockElements, employeesElements } = values;
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'cm',
      format: 'letter',
    });

    // ENCABEZADO
    doc.setTextColor('#000');
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');

    doc.text(`Requerimiento de recursos`, 1, 1.3);
    doc.setLineWidth(0.05);
    doc.line(1, 1.5, 21, 1.5, 'S');

    doc.setFontSize(10);
    doc.text(
      `A continuación se enlistaran los movimientos requeridos así como los responsables de los mismos.`,
      1,
      2,
    );

    let initialLine = 3;
    (stockElements || []).forEach((element) => {
      const { stockId, equipment, assignedToProject, responsible, projects } = element;

      const project = projects?.length ? projects[0] : {};

      const maxWidth = 100;
      const rowText = `- ${equipment || ''} con id: ${stockId || ''}, de ${
        assignedToProject ? project?.projectName || 'Sin asignar' : 'Sin asignar'
      } a ${targetProject || null}, responsable: ${
        responsible
          ? (responsible?.names || '') +
            (responsible?.lastName1 || '') +
            (responsible?.lastName2 || '')
          : 'Sin asignar'
      }`;
      const rowLength = rowText.length;

      const numOfRows = rowLength / maxWidth + 1;

      for (let i = 0; i < numOfRows; i++) {
        doc.text(rowText.substring(i * maxWidth, i * maxWidth + maxWidth), 2, initialLine);
        initialLine = initialLine + 0.5;
      }

      if (initialLine >= 23) {
        doc.addPage();
        initialLine = 1;
      } else {
        initialLine = initialLine + 0.5;
      }
    });

    (employeesElements || []).forEach((element) => {
      const { lastName1, lastName2, names, email, projects, assignedToProject } = element;

      const project = projects?.length ? projects[0] : {};

      const maxWidth = 100;
      const rowText = `- ${names || ''} ${lastName1 || ''} ${lastName2 || ''} con email: ${
        email || ''
      }, de ${assignedToProject ? project?.projectName || 'Sin asignar' : 'Sin asignar'} a ${
        targetProject || null
      }`;
      const rowLength = rowText.length;

      const numOfRows = rowLength / maxWidth + 1;

      for (let i = 0; i < numOfRows; i++) {
        doc.text(rowText.substring(i * maxWidth, i * maxWidth + maxWidth), 2, initialLine);
        initialLine = initialLine + 0.5;
      }

      if (initialLine >= 23) {
        doc.addPage();
        initialLine = 1;
      } else {
        initialLine = initialLine + 0.5;
      }
    });

    doc.text(`Favor de confirmar de recibido`, 1, initialLine + 1);

    const fechaActual = format(new Date(), 'yyyy-MM-dd');
    const docName = `${userName}_requisicion_${fechaActual}_${targetProject}.pdf`;
    if (upload) {
      const fileData = doc.output('arraybuffer');
      await postNewFile(fileData, docName, 'requisiciones', {
        customBucket: 'roshare-shopping-cart',
        handleSuccess: () => console.log('success uploading carrito pdf'),
      });
      doc.save(docName);
    } else {
      doc.save(docName);
    }
  } catch (e) {
    console.log('ERROR al crear el PDF del carrito: ', e);
  }
};
