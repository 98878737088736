import { makeStyles } from '@material-ui/core/styles';

export const useInventoryHistoryStyles = makeStyles(() => ({
  container: {},
  button: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  fakeButton: {
    backgroundColor: '#3f4ab5',
    fontSize: '15px',
    fontWeight: 200,
    color: 'white',
    borderRadius: '5px',
    padding: '10px',
    margin: 0,
  },
}));
