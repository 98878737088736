import { useContext, useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { AxiosContext } from '../Contexts/AxiosContext/AxiosContext';

export const NO_PROJECT = 'NO_PROJECT';
export const useProjectsForCatalogs = () => {
  const AxiosInstance = useContext(AxiosContext);
  const [options, setOptions] = useState([]);

  const { data: projectsInPlatform } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/list`,
  });

  const mapProject = (project) => ({
    itemValue: project.id,
    itemLabel: `${project.projectId} - ${project.projectName}`,
  });

  const noProjectOption = {
    itemValue: NO_PROJECT,
    itemLabel: 'Sin asignar a proyecto',
  };

  useEffect(() => {
    if (!options?.length && projectsInPlatform) {
      const selectableOptions = projectsInPlatform.map(mapProject);
      setOptions([noProjectOption, ...selectableOptions]);
    }
  }, [noProjectOption, options.length, projectsInPlatform]);

  return options;
};
export const useProjectsAuthorizedUsers = (username) => {
  const AxiosInstance = useContext(AxiosContext);
  const [options, setOptions] = useState([]);
  const [userProjects, setUserProjects] = useState([]);

  const { data: projectsInPlatform } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: `/api/project/list`,
  });

  const mapProject = (project) => {
    const { projectGrants } = project;
    const projectUsers = projectGrants.reduce((prev, curr) => {
      if (!prev.find(({ id }) => curr.user.id === id)) {
        return [...prev, curr.user];
      }
      return prev;
    }, []);
    return {
      id: project.id,
      projectName: project.projectName,
      projectId: project.projectId,
      projectUsers,
    };
  };

  const getUserProjects = (username, projects) => {
    return projects.filter((project) => {
      const { projectUsers } = project;

      return !!projectUsers.find((user) => user.username === username);
    });
  };
  useEffect(() => {
    if (!options?.length && projectsInPlatform) {
      const selectableOptions = projectsInPlatform.map(mapProject);
      setOptions(selectableOptions);
      const newUserProjects = getUserProjects(username, selectableOptions);
      setUserProjects(newUserProjects);
    }
  }, [options.length, projectsInPlatform, username]);

  return { options, userProjects };
};
