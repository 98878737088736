import styled from 'styled-components';

export const QualityProfileScreenMainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  flex: 1;
`;

export const FakeButton = styled.div`
  background-color: #263a57;
  font-size: 12px;
  font-weight: 200;
  color: white;
  border-radius: 5px;
  padding: 0 10px;
  margin: 0;
`;
