//ACTION TYPES:

export const ADD_STOCK_TO_CART = 'ADD_STOCK_TO_CART';
export const ADD_EMPLOYEE_TO_CART = 'ADD_EMPLOYEE_TO_CART';
export const REMOVE_STOCK_FROM_CART = 'REMOVE_STOCK_FROM_CART';
export const CLEAN_CART = 'CLEAN_CART';
export const CREATE_PDF = 'CREATE_PDF';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const LOAD_STOCK = 'LOAD_STOCK';
export const REFETCH_STOCK = 'REFETCH_STOCK';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const REFETCH_PROJECTS = 'REFETCH_PROJECTS';
export const LOAD_WAREHOUSES = 'LOAD_WAREHOUSES';
export const REFETCH_WAREHOUSES = 'REFETCH_WAREHOUSES';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const REMOVE_SELECTED_PROJECT = 'REMOVE_SELECTED_PROJECT';
export const SET_SELECTED_WAREHOUSE = 'SET_SELECTED_WAREHOUSE';
export const REMOVE_SELECTED_WAREHOUSE = 'REMOVE_SELECTED_WAREHOUSE';
export const SET_TARGET_PROJECT = 'SET_TARGET_PROJECT';
export const SET_FILTERED_PROJECTS = 'SET_FILTERED_PROJECTS';
export const REMOVE_TARGET_PROJECT = 'REMOVE_TARGET_PROJECT';
export const SET_FILTERED_WAREHOUSES = 'SET_FILTERED_WAREHOUSES';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
export const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES';
export const STOCK_TARGET = 'STOCK_TARGET';
export const RH_TARGET = 'RH_TARGET';
export const SET_SEARCH_TARGET = 'SET_SEARCH_TARGET';

export const ShoppingCartInitialState = {
  cart: [],
  humanResourcesCart: [],
  filter: {
    project: null,
    stockType: null,
    stockId: null,
    employeeTitle: null,
    employeeId: null,
  },
  availableStock: [],
  filteredStock: [],
  projects: [],
  filteredProjects: [],
  warehouses: [],
  filteredWarehouses: [],
  selectedProject: null,
  selectedWarehouse: null,
  targetProject: null,
  modalVisibility: false,
  availableEmployees: [],
  searchTarget: STOCK_TARGET,
};

export const stateFunctions = {
  addStockToCart: () => {},
  addEmployeeToCart: () => {},
  removeStockFromCart: () => {},
  cleanCart: () => {},
  createPdf: () => {},
  updateFilters: () => {},
  loadStock: () => {},
  loadProjects: () => {},
  loadWarehouses: () => {},
  setSelectedProject: () => {},
  removeSelectedProject: () => {},
  setSelectedWarehouse: () => {},
  removeSelectedWarehouse: () => {},
  setTargetProject: () => {},
  removeTargetProject: () => {},
  setFilteredProjects: () => {},
  setFilteredWarehouses: () => {},
  resetFilters: () => {},
  setModalVisibility: () => {},
  loadEmployees: () => {},
  setSearchTarget: () => {},
};

export const shoppingCartReducer = (state, action) => {
  switch (action.type) {
    case ADD_STOCK_TO_CART:
      const { payload } = action;
      const { newStock } = payload;
      const { cart } = state;
      return { ...state, cart: [...cart, newStock] };
    case ADD_EMPLOYEE_TO_CART:
      const { humanResourcesCart } = state;
      return { ...state, humanResourcesCart: [...humanResourcesCart, action.payload.newEmployee] };
    case REMOVE_STOCK_FROM_CART:
      const {
        payload: { stockToRemove },
      } = action;
      const newCart = state.cart.filter((stock) => stock.id !== stockToRemove.id);
      return { ...state, cart: newCart };
    case CLEAN_CART:
      return { ...state, cart: [] };
    case CREATE_PDF:
      return state;
    case UPDATE_FILTER:
      const { newFilter } = action.payload;
      return { ...state, filter: { ...state.filter, ...newFilter } };
    case LOAD_STOCK:
      const { stock } = action.payload;
      return { ...state, availableStock: stock };
    case LOAD_PROJECTS:
      const { projects } = action.payload;
      return { ...state, projects };
    case LOAD_WAREHOUSES:
      const { warehouses } = action.payload;
      return { ...state, warehouses };
    case SET_SELECTED_PROJECT:
      return { ...state, selectedProject: action.payload.selectedProject };
    case REMOVE_SELECTED_PROJECT:
      return { ...state, selectedProject: null };
    case SET_SELECTED_WAREHOUSE:
      return { ...state, selectedWarehouse: action.payload.selectedWarehouse };
    case REMOVE_SELECTED_WAREHOUSE:
      return { ...state, selectedWarehouse: null };
    case SET_TARGET_PROJECT:
      return { ...state, targetProject: action.payload.targetProject };
    case REMOVE_TARGET_PROJECT:
      return { ...state, targetProject: null };
    case SET_FILTERED_PROJECTS:
      return { ...state, filteredProjects: action.payload.filteredProjects };
    case SET_FILTERED_WAREHOUSES:
      return { ...state, filteredWarehouses: action.payload.filteredWarehouses };
    case RESET_FILTERS:
      return { ...state, filter: ShoppingCartInitialState.filter };
    case SET_MODAL_VISIBILITY: {
      return { ...state, modalVisibility: action.payload.modalVisibility };
    }
    case LOAD_EMPLOYEES: {
      return { ...state, availableEmployees: action.payload.availableEmployees };
    }
    case SET_SEARCH_TARGET: {
      return { ...state, searchTarget: action.payload.searchTarget };
    }
    default:
      return state;
  }
};
