import React from 'react';
import { ResponsiveCalendar } from '@nivo/calendar';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const MyResponsiveCalendar = ({ data, startDate: from, endDate: to, clickDate: clicked }) => (
  <ResponsiveCalendar
    data={data}
    from={from}
    to={to}
    emptyColor="#eeeeee"
    colors={['#f47560', '#61cdbb']}
    margin={{ top: 4, right: 4, bottom: 4, left: 4 }}
    yearSpacing={5}
    monthBorderColor="#ffffff"
    dayBorderWidth={2}
    dayBorderColor="#ffffff"
    onClick={(event, day) => {
      clicked && clicked(day, event);
    }}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'row',
        translateY: 36,
        itemCount: 4,
        itemWidth: 42,
        itemHeight: 36,
        itemsSpacing: 14,
        itemDirection: 'right-to-left',
      },
    ]}
  />
);

export default MyResponsiveCalendar;
