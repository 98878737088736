import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { TitleProjectElement, TitleProjectButtons } from './ProjectsScreenStyles';
import Dialog from './../../Containers/GenericDialog';
import NewProjectFormulary from './NewProjectFormulary/Formulary';
import { withNavigation } from '../../Containers/AppNavigation/AppNavigation';
import DataCard from '../../Components/DataCards/DataCardProgressBars';
import Table from '../../Components/Table';
import { useFetch } from '../../Hooks/useFetch';
import { AxiosContext } from '../../Contexts/AxiosContext/AxiosContext';
import { userContext } from '../../Contexts/UserContext/UserContext';
import { normalizeTitleToUrl } from '../../Components/DataCards/DataCardsUtils';
import { FakeButton } from '../QualityProfilesScreen/QualityProfilesScreenStyles';

const dummyProjectList = [];

for (let i = 0; i < 10; i++) {
  dummyProjectList.push(`Proyecto ${i + 1}`);
}

const ProjectsScreen = () => {
  //---------------------------------------------------------------------------
  // REACT ROUTER DOM HOOKS
  //---------------------------------------------------------------------------
  const AxiosInstance = useContext(AxiosContext);
  const [userState] = useContext(userContext);

  //---------------------------------------------------------------------------
  // useState hooks
  //---------------------------------------------------------------------------
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(false);
  const [tempFetchProject, setTempFetchProject] = useState();
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [cardsView, setCardsView] = useState(false);
  const [cardSearch, setCardSearch] = React.useState('');

  //---------------------------------------------------------------------------
  // useFetch hooks
  //---------------------------------------------------------------------------
  const { data } = useFetch({
    initialUrl: '/list-all-objects',
  });

  const {
    data: projectData,
    refetch: refreshProjectData,
    updateUrl,
  } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: ``,
  });

  const { data: allProjects, refetch: refetchProjects } = useFetch({
    axiosInstance: AxiosInstance,
    initialUrl: '/api/project/list',
  });

  const handleTempGet = async () => {
    if (tempFetchProject) {
      updateUrl(`/api/project/${selectedProject?.id}`);
      await refreshProjectData();
    }
  };

  useEffect(() => {
    handleTempGet();
  }, [tempFetchProject]);

  useEffect(() => {
    if (
      projectData?.id === selectedProject?.id &&
      tempFetchProject &&
      allowedModuleSections.includes('EDITAR PROYECTOS')
    ) {
      setEditDialogOpen(true);
      setTempFetchProject(false);
    }
  }, [tempFetchProject, projectData]);

  useEffect(() => {
    if (allProjects && Array.isArray(allProjects) && allProjects.length) {
      const filtered = (allProjects || [])
        .filter((project) => {
          const active = project.projectStatus === 'ACTIVE';
          const projectIncludesGrants = projectsGrants[project?.projectId]?.includes(
            userDetails?.username,
          );
          const { grants = {} } = userDetails?.profile || {};
          const generalAccess = grants
            .map((grant) => grant.name)
            .includes('VER TODOS LOS PROYECTOS');
          return (active && projectIncludesGrants) || generalAccess;
        })
        .filter((project) => {
          const resultado = cardSearch
            ? `${project.projectId} ${project.projectName}`
                .toLocaleLowerCase()
                .includes(cardSearch.toLowerCase())
            : true;
          return resultado;
        })
        .map((filteredProject) => ({
          ...filteredProject,
          amount: `$ ${filteredProject.amount.toFixed(2)}`,
        }));

      setFilteredProjects([...filtered]);
    }
  }, [allProjects, cardSearch]);

  let projectsGrants = {};
  if (allProjects) {
    allProjects.forEach((project) => {
      projectsGrants[project?.projectId || '-1'] = project.projectGrants?.map((element) => {
        return element?.user?.username || '';
      });
    });
  }

  const { folders = [] } = data || {};
  const { userDetails = {} } = userState || {};
  const { grants = [] } = userDetails?.profile || {};
  const allowedModuleSections = grants
    .filter((grant) => grant.module === 'PROYECTOS')
    .map((grant) => grant.name);

  const renderCards = () => {
    return (filteredProjects || []).map((project, projectIndex) => {
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} key={projectIndex}>
          <DataCard
            project={project}
            editProfile={allowedModuleSections.includes('EDITAR PROYECTOS')}
            deleteProfile={allowedModuleSections.includes('ELIMINAR PROYECTOS')}
            setEditDialogOpen={setTempFetchProject}
            refetch={refetchProjects}
            setProjectToEdit={setSelectedProject}
          />
        </Grid>
      );
    });
  };

  const history = useHistory();
  let location = useLocation();

  const { pathname } = location;
  const renderTable = () => {
    const TABLE_HEADERS = [
      { title: 'No. de Proyecto', field: 'projectId' },
      { title: 'Contrato', field: 'contractKey' },
      { title: 'Proyecto', field: 'projectName' },
      // { title: 'Monto', field: 'amount' },
      { title: 'Status', field: 'projectStatus' },
      // { title: "Tipo", field: "projectType" },
      { title: 'Inicio', field: 'startDate' },
      { title: 'Empresa', field: 'company' },
    ];

    const TABLE_ACTIONS = [
      {
        icon: 'editIcon',
        tooltip: 'Editar',
        iconProps: {
          color: 'secondary',
        },
        onClick: (event, rowElement) => {
          setSelectedProject && setSelectedProject(rowElement);
          setTempFetchProject && setTempFetchProject(true);
        },
      },
      {
        icon: () => {
          return (
            <FakeButton>
              <Typography variant="h6">Exportar Proyectos</Typography>
            </FakeButton>
          );
        },
        tooltip: 'Exportar Proyectos',
        iconProps: {
          color: 'secondary',
        },
        onClick: () => {
          console.log('Exportar', allProjects);
          const dictionary = {
            id: 'id',
            projectId: 'Número de proyecto',
            projectName: 'Nombre del proyecto',
            projectDescription: 'Descripción del proyecto',
            contractKey: 'Número de contrato',
            projectType: 'Tipo de proyecto',
            classification: 'Clasificación',
            projectStatus: 'Status del proyecto',
            amount: 'Monto',
            totalPaid: 'Total pagado',
            totalCost: 'Costo total',
            startDate: 'Fecha de inicio',
            endDate: 'Fecha final',
            client: 'Cliente',
            company: 'Empresa',
          };
          const csvValues = allProjects.map((project) => {
            const { client, totalCost, ...rest } = project;
            const workCopy = { ...rest };
            delete workCopy.projectGrants;
            delete workCopy.latitude;
            delete workCopy.longitude;
            workCopy.client = client.clientName;
            workCopy.totalCost = Number(totalCost) || 0;
            const copiaEnEspanol = Object.keys(workCopy).reduce((accumulator, property) => {
              return { ...accumulator, [dictionary[property]]: workCopy[property] };
            }, {});
            return copiaEnEspanol;
          });
          console.log(csvValues);

          const toCSV = (json) => {
            json = Object.values(json);
            let csv = '';
            let keys = (json[0] && Object.keys(json[0])) || [];
            csv += `${keys.join(',')}\n`;
            for (let line of json) {
              csv += `${keys.map((key) => `"${line[key]}"`).join(',')}\n`;
            }
            return csv;
          };

          const csv = toCSV(csvValues);
          console.log(csv);

          const encodedUri = encodeURI(csv);

          const element = document.createElement('a');
          element.setAttribute('href', `data:text/csv;charset=utf-8,\uFEFF${encodedUri}`);
          element.setAttribute('download', 'proyectos.csv');
          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();
          document.body.removeChild(element);
        },
        isFreeAction: true,
      },
    ];
    return (
      <Table
        style={{ flex: 1 }}
        title=""
        data={filteredProjects}
        columns={TABLE_HEADERS}
        actions={TABLE_ACTIONS}
        onRowClick={(event, rowElement) => {
          history.push(`${pathname}/${normalizeTitleToUrl(`${rowElement?.id}`)}`);
        }}
        options={{
          search: false,
          filtering: true,
        }}
      />
    );
  };
  return (
    <>
      <div>
        <TitleProjectElement>
          <h1>Proyectos</h1>
        </TitleProjectElement>
        {allowedModuleSections.includes('CREAR PROYECTOS') && (
          <TitleProjectButtons>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDialogOpen(true)}
              style={{ float: 'right' }}
            >
              Nuevo Proyecto
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCardsView((prev) => !prev)}
              style={{ float: 'right', marginRight: '1rem' }}
            >
              {cardsView ? 'Vista de lista' : 'Vista de tarjetas'}
            </Button>
          </TitleProjectButtons>
        )}
      </div>

      <Grid container direction="row-reverse" style={{ width: '100%', marginBottom: '5px' }}>
        {cardsView ? (
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              placeholder="Busca por No. de proyecto o nombre"
              onChange={(event) => {
                setCardSearch(event.target.value);
              }}
              style={{ width: '100%' }}
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
        {cardsView ? renderCards() : renderTable()}
      </Grid>
      <Dialog openDialog={editDialogOpen} dialogTitle={'Editar Proyecto'} maxWidth="md">
        <NewProjectFormulary
          folders={folders}
          setOpen={setEditDialogOpen}
          currentElement={projectData}
          refetchProjects={refetchProjects}
        />
      </Dialog>
      <Dialog openDialog={dialogOpen} dialogTitle={'Nuevo Proyecto'} maxWidth="md">
        <NewProjectFormulary
          folders={folders}
          setOpen={setDialogOpen}
          refetchProjects={refetchProjects}
        />
      </Dialog>
    </>
  );
};

export default withNavigation(ProjectsScreen);
