import React from 'react';
import TextField from '@mui/material/TextField';
import { getIn } from 'formik';
import FormikDatePickerDesktop from '../FormikDatePickerDesktop/FormikDatePickerDesktop';

function FormikIconInputDisplay({
  formikName: propsName,
  label,
  field,
  form,
  multiline,
  rows,
  disabled,
  type,
  step,
  props,
  ...otherProps
}) {
  const { field: propsField, form: propsForm } = props || {};
  const { value, name } = field || propsField;
  const formikName = propsName || name;
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = form || propsForm;
  const fieldTouched = getIn(touched, formikName);
  const fieldErrorMessage = getIn(errors, formikName);
  const error = fieldTouched && fieldErrorMessage;

  React.useEffect(() => {
    if (value) {
      validateForm && validateForm();
    }
  }, [value, validateForm]);

  return type !== 'date' ? (
    <TextField
      variant="outlined"
      id={formikName}
      label={label}
      onBlur={(event) => {
        handleChange(event);
        handleBlur(event);
      }}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      // value={value || "" /*this is for a warning of uncontrolled component*/}
      defaultValue={value}
      fullWidth
      error={error}
      helperText={error ? fieldErrorMessage : undefined}
      type={type}
      inputProps={{
        step,
      }}
      {...otherProps}
    />
  ) : (
    <FormikDatePickerDesktop
      label={label}
      onChange={(newValue) => {
        setFieldValue && setFieldValue(name, newValue);
      }}
      onBlur={() => {
        setFieldTouched && setFieldTouched(name);
      }}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
}

export default FormikIconInputDisplay;
