import React from 'react';
import Grid from '@material-ui/core/Grid';
import StorageIcon from '@material-ui/icons/Storage';
import { ButtonContainer } from './ButtonPadStyles';

const ButtonElement = (props) => {
  const { title, icon, onClick } = props;
  return (
    <Grid item xs={2}>
      <ButtonContainer onClick={onClick}>
        {icon || <StorageIcon fontSize="large" />}
        <h3 style={{ textAlign: 'center' }}>{title || 'Master Data'}</h3>
      </ButtonContainer>
    </Grid>
  );
};

export default ButtonElement;
